import { CSSProperties } from "@mui/material/styles/createMixins";
import { Theme } from "@mui/system";

const GetScrollbarStyle = (theme: Theme): CSSProperties => {
    return {
        scrollbarWidth: "thin",
        scrollbarColor: `${theme.palette.grey[400]} transparent`,
        "&::-webkit-scrollbar": {
            width: 8,
            height: 8,
        },
        "&::-webkit-scrollbar-track": {},
        "&::-webkit-scrollbar-thumb": {
            borderRadius: 4,
            backgroundColor: theme.palette.grey[400],
        },
        "&::-webkit-scrollbar-corner": {
            background: "rgba(0, 0, 0, 0)",
        },
    };
};

export default GetScrollbarStyle;
