import { Checkset, ChecksetDefinition, ChecksetGroup } from "../../Connectors/_Models/Checkset";
import { DatasourceProperty } from "../../Connectors/_Models/DatasourceProperty";

export enum ActionType {
    NewChecksetDefinition,
    LoadCustomProperties,

    ChangeChecksetDefinitionName,

    AddGroup,
    AddChildGroup,
    ChangeGroupName,
    RemoveGroup,

    NewCheck,
    LoadCheck,
    ChangeCheckName,
    UpdateCheck,
    SaveCheck,
    RemoveCheck,

    OpenChecksetDefinitionDrawer,
    CloseChecksetDefinitionDrawer,
    OpenChecksetDefinitionConfigDrawer,
    CloseChecksetDefinitionConfigDrawer,

    SetRemoteProperty,

    LoadChecksetDefinition,
    AddCustomProperty,

    ClearActiveChecksetEdited,
    SetActiveCheckesIsEdited,

    ClearCurrentChecksetConfigurationEdited,
    SetCurrentChecksetConfigurationEdited,
    EnableChecksetValidation,
    DisableChecksetValidation
}

export interface NewChecksetDefinition {
    type: ActionType.NewChecksetDefinition;
}

export interface LoadChecksetDefinition {
    type: ActionType.LoadChecksetDefinition;
    payload: { checkset: Checkset|null };
}

export interface ChangeChecksetDefinitionName {
    type: ActionType.ChangeChecksetDefinitionName;
    payload: { name: string };
}

export interface AddGroup {
    type: ActionType.AddGroup;
    payload: { name: string };
}

export interface AddChildGroup {
    type: ActionType.AddChildGroup;
    payload: { name: string; parent: ChecksetGroup };
}

export interface ChangeGroupName {
    type: ActionType.ChangeGroupName;
    payload: { name: string; group: ChecksetGroup };
}

export interface RemoveGroup {
    type: ActionType.RemoveGroup;
    payload: { group: ChecksetGroup };
}

export interface NewCheck {
    type: ActionType.NewCheck;
    payload: { check: ChecksetDefinition; target: ChecksetGroup };
}

export interface LoadCheck {
    type: ActionType.LoadCheck;
    payload: { check: ChecksetDefinition; group: ChecksetGroup };
}

export interface ChangeCheckName {
    type: ActionType.ChangeCheckName;
    payload: { name: string };
}

export interface UpdateCheck {
    type: ActionType.UpdateCheck;
    payload: { check: ChecksetDefinition };
}

export interface SaveCheck {
    type: ActionType.SaveCheck;
}

export interface RemoveCheck {
    type: ActionType.RemoveCheck;
    payload: { check: ChecksetDefinition };
}

export interface OpenChecksetDefinitionDrawer {
    type: ActionType.OpenChecksetDefinitionDrawer;
}

export interface CloseChecksetDefinitionDrawer {
    type: ActionType.CloseChecksetDefinitionDrawer;
}

export interface OpenChecksetDefinitionConfigDrawer {
    type: ActionType.OpenChecksetDefinitionConfigDrawer;
}

export interface CloseChecksetDefinitionConfigDrawer {
    type: ActionType.CloseChecksetDefinitionConfigDrawer;
}


export interface SetRemoteProperty {
    type: ActionType.SetRemoteProperty;
    payload: { datasourceProperties: DatasourceProperty[] };
}

export interface LoadCustomProperties {
    type: ActionType.LoadCustomProperties;
}

export interface AddCustomProperty {
    type: ActionType.AddCustomProperty;
    payload: { datasourceProperty: DatasourceProperty };
}
export interface SetActiveChecksetIsEdited {
    type: ActionType.SetActiveCheckesIsEdited;
}

export interface ClearActiveChecksetEdited {
    type: ActionType.ClearActiveChecksetEdited;
}

export interface SetCurrentChecksetConfigurationEdited {
    type: ActionType.SetCurrentChecksetConfigurationEdited;
}

export interface ClearCurrentChecksetConfigurationEdited {
    type: ActionType.ClearCurrentChecksetConfigurationEdited;
}

export interface EnableChecksetValidation {
    type: ActionType.EnableChecksetValidation;
}

export interface DisableChecksetValidation {
    type: ActionType.DisableChecksetValidation;
}

export type ChecksetDefinitionActions =
    | NewChecksetDefinition
    | LoadChecksetDefinition
    | AddGroup
    | AddChildGroup
    | ChangeGroupName
    | RemoveGroup
    | ChangeChecksetDefinitionName
    | NewCheck
    | LoadCheck
    | ChangeCheckName
    | UpdateCheck
    | SaveCheck
    | RemoveCheck
    | OpenChecksetDefinitionDrawer
    | CloseChecksetDefinitionDrawer
    | OpenChecksetDefinitionConfigDrawer
    | CloseChecksetDefinitionConfigDrawer
    | SetRemoteProperty
    | LoadCustomProperties
    | AddCustomProperty
    | SetActiveChecksetIsEdited
    | ClearActiveChecksetEdited
    | ClearCurrentChecksetConfigurationEdited
    | SetCurrentChecksetConfigurationEdited
    | EnableChecksetValidation
    | DisableChecksetValidation;
