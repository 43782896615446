import {ChecksetDefinition, ChecksetGroup, ChecksetType} from "../Connectors/_Models/Checkset";
import { DocumentChecksetResultChecks } from "../Connectors/_Models/DataFolderDocument";
import { DocumentChecksetResultSimplePropertyChecks } from "../Connectors/_Models/DataFolderDocument";
export const resolveFailedChecksetResults = (groups: ChecksetGroup[], dc: DocumentChecksetResultChecks[]) => {
    let allObjects: string[] = [];
    const resolveFailedChecksetResultsRecursive = (gs:ChecksetGroup[]) => {
        for (let group of gs) {
            let items = group.items.filter(x=>x.isFolder) as ChecksetGroup[];
            let defs = group.items.filter(x=>!x.isFolder) as ChecksetDefinition[];
            resolveFailedChecksetResultsRecursive(items);
            for (let checkDef of defs) {
                let item = dc.find(x => x.checkDefinitionId === checkDef.id);
                if (item) {
                    if (item.checkType === ChecksetType.SIMPLE_PROPERTY_CHECK) {
                        let simpPropDef = item as DocumentChecksetResultSimplePropertyChecks;
                        for (let failedElement of simpPropDef.failedElementIds) {
                            allObjects.push(failedElement);
                        }
                    }
                }
            }
        }
    }
    resolveFailedChecksetResultsRecursive(groups);
    return  allObjects;
};