import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { Button } from "@mui/material";
import ForgeFileBrowser from "../../Components/ForgeFileBrowser";
import RequestInterceptor from "../../Utils/Interceptors/RequestInterceptor";
import { loginRequest } from "../../_Configuration/configuration";
import { useEffect, useState } from "react";
import {
  getDataFolders,
  getDocumentsFulltree,
} from "../../Connectors/dataFolders";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";

const PowerBi = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const account = useAccount(accounts[0]);
  const [revitFiles, setRevitFiles] = useState<DataFolderDocument[]>([]);
  const getAllFiles = async () => {
    const rootfolders = await getDataFolders();
    let files: DataFolderDocument[] = [];
    for (const folder of rootfolders) {
      const fulltree = await getDocumentsFulltree(folder.id);
      for (const file of fulltree.documents) {
        files.push(file);
      }
      if (fulltree.children) {
        let folders = fulltree.children;
        for (let i = 0; i < folders.length; i++) {
          const child = folders[i];
          if (child.documents) {
            for (const file of child.documents) {
              files.push(file);
            }
          }
          if (child.children) {
            folders = [...folders, ...child.children];
          }
        }
      }
    }
    setRevitFiles(files.filter((x) => x.fileName.endsWith(".rvt")));
  };

  useEffect(() => {
    getAllFiles();
  }, []);

  const login = () => {
    instance.loginRedirect();
  };

  const powerbiRedirect = async (doc:DataFolderDocument ) => {
    const token = escape(`{"token":"${tok}","id":"${doc.id}"}`);
    const uri = `https://oauth.powerbi.com/views/oauthredirect.html?token=${token}`;
    //redirect to powerbi
    window.location.href = uri;
  };
  const [tok, setTok] = useState("");
  useEffect(() => {
    if (account) {
      let accessTokenRequest = {
        ...loginRequest,
        account,
      };
      //
      //get accesstoken
      instance.acquireTokenSilent(accessTokenRequest).then((response) => {
        setTok(response.accessToken);
      });
    }
  }, []);
  return (
    <RequestInterceptor>
      <div>
        <h1>PowerBi</h1>
        {!isAuthenticated ? (
          <Button onClick={login}>Login</Button>
        ) : (
          <div>
            {revitFiles.map((x) => (
              <div>
                <Button onClick={()=>powerbiRedirect(x)}>{x.fileName}</Button>
              </div>
            ))}
          </div>
        )}
      </div>
    </RequestInterceptor>
  );
};
export default PowerBi;
