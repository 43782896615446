import { Box, Button, List, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import "gantt-task-react/dist/index.css";

import React, { useContext, useEffect, useState } from "react";
import PageHeader from "../../Components/PageHeader";
import { getProjectDocuments, getProjectModel, getProjects, ProjectResponse } from "../../Connectors/projects";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";
import NotifiedAccordion from "../../Components/NotifiedAccordion";
import { BobContext } from "../../Context/BobContext/BobContextProvider";
import { setActiveProjectModel } from "../../Context/BobContext/Reducer";
import { VisualiseType } from "../../Context/BobContext/State";
import { useConditionalFetch } from "../../Utils/conditionalFetch";
import BobProjectModelDetail from "./BobProjectModelDetail";
import LoadingIndicator from "../../Components/LoadingIndicator";


interface BobProjectsOverviewProps {
    open: boolean;
    selectedProject: ProjectResponse;
}

const BobProjectsOverview = ({ open, selectedProject }: BobProjectsOverviewProps) => {
    const { state, dispatch } = useContext(BobContext);
    const [hasBeenOpened, setHasBeenOpened] = useState<boolean>(false);
    const [documentsLoading, documents, setDocuments] = useConditionalFetch<DataFolderDocument[]>([], open || hasBeenOpened, getProjectDocuments, selectedProject.id);
    useEffect(() => {
        console.log("set has been opened");
        setHasBeenOpened(hasBeenOpened || open)
    }, [open]);
    const selectDocumentInPlanMode = (x: DataFolderDocument) => {
        dispatch(setActiveProjectModel(selectedProject, x, VisualiseType.Planning));
    };
    const selectDocumentInCamMode = (x: DataFolderDocument) => {
        dispatch(setActiveProjectModel(selectedProject, x, VisualiseType.Camera));
    };
    return <>
        {documentsLoading ? <LoadingIndicator /> :
            <List>
                {documents?.map((x, y) => (
                    <ListItem key={y}>
                        <Button onClick={() => selectDocumentInPlanMode(x)}>
                            {x.fileName}
                        </Button>
                    </ListItem>
                ))}
            </List>}
    </>;
}


const Bob = () => {
    const { state, dispatch } = useContext(BobContext);

    const [projectsLoading, projects] = useConditionalFetch<ProjectResponse[]>([], true, getProjects);
    const [selectedProject, setSelectedProject] = useState<ProjectResponse | null>(null);
    const [documentsLoading, documents, setDocuments] = useConditionalFetch<DataFolderDocument[]>([], selectedProject, getProjectDocuments, selectedProject?.id);

    const [selectedDocument, setSelectedDocument] = useState<DataFolderDocument | null>(null);

    return <div>
        <PageHeader title="Bob" breadcrumb={[]}></PageHeader>
        <List>
            
            {projectsLoading ? <LoadingIndicator /> : projects?.map((x, y) => (
                <Box>
                    <Button
                        onClick={()=>{
                            getProjectModel(x.id).then(model=>{
                                dispatch(setActiveProjectModel(x, model, VisualiseType.Planning));
                            })
                        }}
                    >{x.name}</Button>
                </Box>
                // <NotifiedAccordion Title={() => (<Typography>{x.name}</Typography>)} Body={
                //     (open) => <BobProjectsOverview open={open} selectedProject={x} />
                // } />
                ))
            }
            {/*// <ListItem key={y} onClick={() => setSelectedProject(x)}>{x.name}</ListItem>))*/}
        </List>
        <BobProjectModelDetail />

    </div>;

}


export default Bob;

