import { Box, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import AppBar from "../../Components/ApplicationBar";
import NavigationDrawer from "../../Components/NavigationDrawer";
import { SIDEBAR_WIDTH } from "../../Constants/Interface";
import { GlobalContext } from "../../Context/GlobalContext/GlobalContextProvider";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";

const ContentContainer = styled("main", { shouldForwardProp: (prop) => !["open", "isMobile"].some((x) => x === prop) })<{
    open: boolean;
    isMobile: boolean;
}>(({ theme, open, isMobile }) => ({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: theme.spacing(2, 0.5, 2, 2),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: isMobile ? 0 : `-${SIDEBAR_WIDTH}px`,
    ...(open &&
        !isMobile && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
    overflow: "auto",
}));

const Spacer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const ScrollContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: "calc(100vh - 96px)",
    overflowY: "scroll",
    paddingRight: theme.spacing(0.5),
    ...GetScrollbarStyle(theme),
}));

interface AppContainerProps {
    children: JSX.Element | JSX.Element[];
}

const AppContainer = ({ children }: AppContainerProps) => {
    const { state: globalState } = useContext(GlobalContext);
    const [open, setOpen] = useState(!globalState.sideBarCollapsed);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        setOpen(!globalState.sideBarCollapsed);
    }, [globalState.sideBarCollapsed]);

    return (
        <Box sx={{ display: "flex", height: "100vh" }}>
            {globalState.applicationBarCollapsed ?
                <ScrollContainer>{children}</ScrollContainer>
                :
                <>
                    <AppBar />
                    <NavigationDrawer />
                    <ContentContainer open={open} isMobile={isMobile}>
                        <Spacer />
                        <ScrollContainer>{children}</ScrollContainer>
                    </ContentContainer>
                </>
            }
        </Box>
    );
};

export default AppContainer;
