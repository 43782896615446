import {ProjectResponse} from "../../Connectors/projects";
import {DataFolderDocument} from "../../Connectors/_Models/DataFolderDocument";
import { Planning } from "../../Connectors/_Models/Planning";
import { PlanningTask } from "../../Connectors/_Models/PlanningTask";
export enum VisualiseType {
    None = "",
    Camera = "camera",
    Planning = "planning"
}

export interface BobState {
    project:ProjectResponse|null;
    model: DataFolderDocument|null;
    visualiseType:VisualiseType;
    planning:Planning|null;
    planningTask:PlanningTask|null;
    startDate:Date;
    endDate:Date;
}

export const initialBobState: BobState = {
    project:null,
    model:null,
    visualiseType:VisualiseType.None,
    planning:null,
    planningTask:null,
    startDate:new Date(),
    endDate:new Date()
};
