// import {Box} from "@mui/system";
// import {useContext, useEffect, useState} from "react";
// import {ChecksetRuleSet, SizeCheck} from "../../../Connectors/_Models/Checkset";
// import {DatasourceOperand} from "../../../Connectors/_Models/DatasourceOperand";
// import {DatasourceProperty} from "../../../Connectors/_Models/DatasourceProperty";
// import {DatasourceUnit} from "../../../Connectors/_Models/DatasourceUnit";
// import {ChecksetDefinitionContext} from "../../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
// import {PropertyAddAlertTypes, PropertySelectProperties} from "./PropertySelectProperties"
// import {uuid4} from "@sentry/utils";
// import {DatasourceDataType} from "../../../Connectors/_Models/DatasourceDataType";
// import {setCurrentChecksetConfigurationEdited, updatecheck} from "../../../Context/ChecksetDefinitionContext/Reducer";
// import {Autocomplete, Button, TextField, Typography} from "@mui/material";
// import {faMinus, faPlus} from "@fortawesome/pro-solid-svg-icons";

import { Card, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { ChecksetDefinitionContext } from "../../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
import { SizeCheck } from "../../../Connectors/_Models/Checkset";
import { useContext } from "react";
import { updatecheck } from "../../../Context/ChecksetDefinitionContext/Reducer";

// import FontAwesomeSvgIcon from "../../../Components/FontAwesomeSvgIcon";

// const parseValue = (value: any, dataType: DatasourceDataType): any => {
//     if (dataType.storageType === "Text") return value;
//     if (dataType.storageType === "Number") {
//         if (isNaN(value)) value = null;
//         return parseFloat(value);
//     }
//     if (dataType.storageType === "Bool") {
//         if (typeof value === "boolean") return value;
//         return ["yes", "true", "1"].some((x) => x === value?.toLowerCase()?.trim());
//     }
//     throw new Error(`Unhandled storage type '${dataType.storageType}'`);
// };
const SizeCheckPropertyComponent = () => {
    const { state, dispatch } = useContext(ChecksetDefinitionContext);
    let check = state.activeCheck as SizeCheck;
    let onPropChange = (e: any) => {
        check.minSize = Number.parseInt(e.target.value);
        dispatch(updatecheck(check));
    };
    let onPropChange2 = (e: any) => {
        check.maxSize = Number.parseInt(e.target.value);
        dispatch(updatecheck(check));
    };
    return <Card sx={{ m: 1 }}>
        <CardHeader title="Size check property" />
        <Divider />

        <CardContent sx={{ ":last-child": { pb: 2 } }}>
        <Typography variant="body2">Min size:</Typography>
            <TextField
                size="small"
                value={check.minSize}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                onChange={onPropChange}
            />
             <Typography variant="body2">Max size:</Typography>
            <TextField
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                size="small"
                value={check.maxSize}
                onChange={onPropChange2}
            />
        </CardContent>
    </Card>
}

// const SizeCheckPropertyComponent = ({props, dataTypes, target, propertyAddAlert}: PropertySelectProperties) => {
//     const {state, dispatch} = useContext(ChecksetDefinitionContext);
//     const [selection, setSelection] = useState<{
//         [key: string]: { prop: DatasourceProperty | null; operand: DatasourceOperand | null; value: string | null; unit: DatasourceUnit | null };
//     }>();
//     const [loading, setLoading] = useState<boolean>(false);
//     const [loaded, setLoaded] = useState<boolean>(false);
//     useEffect(() => {
//         setSelection({[uuid4()]: {prop: null, operand: null, value: null, unit: null}});
//     }, []);

//     useEffect(() => {
//         if (!props || props.length === 0 || !dataTypes || dataTypes.length === 0 || state.activeCheckIsNew || loaded) return;

//         setLoading(true);

//         const check = state.activeCheck as SizeCheck;
//         const selection: { [key: string]: { prop: DatasourceProperty | null; operand: DatasourceOperand | null; value: string; unit: DatasourceUnit | null } } =
//             {};


//         if (Object.keys(selection).length === 0) selection[uuid4()] = {
//             prop: null,
//             operand: null,
//             value: "",
//             unit: null
//         };

//         setSelection(selection);
//         setLoaded(true);
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [props, dataTypes, target, state.activeCheck, state.activeCheckIsNew]);

//     useEffect(() => {
//         if (!selection || loading) return;

//         const keys = Object.keys(selection);
//         for (let i = 0; i < keys.length; i++) {
//             const rule = selection[keys[i]];
//             let value: any = null;
//             if (rule && rule.prop && rule.operand && rule.prop.datatype) {
//                 value = parseValue(rule.value, rule.prop.datatype);
//             }
//             console.log("value set to:", rule, value);

//             rules.push({
//                 property: rule?.prop ? rule.prop.name : "",
//                 propertyId: rule?.prop?.id,
//                 propertyGroup: rule?.prop ? rule.prop.groupName : "",
//                 propertyGroupId: rule?.prop?.groupId,
//                 operatorId: rule?.operand?.id,
//                 value,
//                 dataTypeId: rule?.prop?.datatype ? rule.prop.datatype.id : undefined,
//                 unitId: rule.unit?.id,
//                 isCaseSensitive: false,
//             });
//         }

//         const check = {...state.activeCheck} as SizeCheck;
//         const ruleset: ChecksetRuleSet = {
//             ruleBehaviour: "AndAlso",
//             simplePropertyRules: rules,
//         };

//         if (target === "scope") {
//             check.scope = check.scope ?? {targetCategories: [], ruleSet: null};
//             check.scope.ruleSet = ruleset;
//         } else if (target === "ruleset") {
//             check.ruleSet = ruleset;
//         }

//         dispatch(updatecheck(check));

//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [selection]);

//     useEffect(() => {
//         setLoading(false);
//     }, [selection]);

//     const onDecrement = (key: string) => {
//         const currentSelection = {...selection};
//         delete currentSelection[key];

//         if (Object.keys(currentSelection).length === 0) {
//             setSelection({[uuid4()]: {prop: null, operand: null, value: null, unit: null}});
//         } else {
//             setSelection(currentSelection);
//         }
//         dispatch(setCurrentChecksetConfigurationEdited());
//     };

//     const onIncrement = () => {
//         const currentSelection = {...selection, [uuid4()]: {prop: null, operand: null, value: null, unit: null}};
//         setSelection(currentSelection);
//         dispatch(setCurrentChecksetConfigurationEdited());
//     };

//     const propertyAddedAlertVisible = () => propertyAddAlert?.visible && propertyAddAlert?.alertType === PropertyAddAlertTypes.PropertyAddedType;

//     const getPropertiesValid = (index: number, field: string) => {
//         if (!state.validateChecksets) return true;
//         let keys: string[] = [];
//         if (selection != null) {
//             keys = Object.keys(selection);
//         }
//         if (keys.length > 1) {
//             try {
//                 if (selection && (selection[keys[index]] as any)[field] === null) {
//                     return false;
//                 }
//             } catch (error) {
//                 return false;
//             }
//         }

//         return true;
//     }
//     const getValueIsValid = (index: number) => {
//         if (!state.validateChecksets) return true;
//         let keys: string[] = [];
//         if (selection != null) {
//             keys = Object.keys(selection);
//         }
//         try {
//             if (selection) {
//                 let prop = selection[keys[index]];
//                 if (prop.value === null || prop.value === "" || prop.value === undefined) return false;
//                 return true;
//             }
//         } catch (error) {
//             return false;
//         }

//         return false;
//     }
//     return (
//         <>
//             <Typography variant="body2">Properties:</Typography>
//             {selection &&
//                 Object.keys(selection).map((key, index) => {
//                     const activeSelection = selection[key];
//                     const isLast = index + 1 === Object.keys(selection).length;
//                     let availableOperands: DatasourceOperand[] = [];
//                     let dataType:DatasourceDataType|undefined=undefined;
//                     if (activeSelection?.prop?.datatype?.id) {
//                         let dtId = activeSelection.prop.datatype.id;
//                          dataType = dataTypes.find((dataType) => dataType.id === dtId);
//                         if (dataType?.supportedOperators) {
//                             availableOperands = dataType.supportedOperators;
//                         }
//                     }
//                     return (
//                         <Box key={key} sx={{display: "flex", mt: 1, mb: 1}}>
//                             <Autocomplete
//                                 className={propertyAddedAlertVisible() ? "shine-background" : ""}
//                                 size="small"
//                                 options={props.sort((a, b) => a.groupName.localeCompare(b.groupName) || a.name.localeCompare(b.name))}
//                                 sx={{width: 300}}
//                                 getOptionLabel={(option) => option.name}
//                                 groupBy={(option) => option.groupName}
//                                 renderInput={(params) => <TextField
//                                     error={!getPropertiesValid(index, "prop")} {...params} />}
//                                 value={activeSelection?.prop}
//                                 onChange={(event: any, newValue: DatasourceProperty | null) => {
//                                     dispatch(setCurrentChecksetConfigurationEdited());
//                                     const currentSelection = {...selection};
//                                     currentSelection[key].prop = newValue;

//                                     if (!newValue) {
//                                         currentSelection[key].operand = null;
//                                         currentSelection[key].unit = null;
//                                     }

//                                     setSelection(currentSelection);
//                                 }}
//                                 isOptionEqualToValue={(option, value) => option.id === value.id}
//                             />

//                         </Box>
//                     );
//                 })}
//         </>
//     );
// };

export default SizeCheckPropertyComponent;

