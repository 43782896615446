import { DataFolder } from "../../Connectors/_Models";
import { Checkset } from "../../Connectors/_Models/Checkset";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";

export interface HealthCheckState {
    forgeHub: string | null;
    forgeProject: string | null;
    forgeRootFolder: string | null;
    checksets: Checkset[] | null;
}

export const initialHealthCheckState: HealthCheckState = {
    forgeHub: null,
    forgeProject: null,
    forgeRootFolder: null,
    checksets: null,
};
