import { DataFolderDocument, DocumentChecksetResult, DocumentChecksetResultChecks } from "../../Connectors/_Models/DataFolderDocument";

export interface SelectedObjects{
    ids:string[];
    color:string;
}

export interface ChecksetResultState {
    checksetResultDocument: DataFolderDocument | null;
    checksetResultDrawerActive: boolean;
    checksetResultsChecks: DocumentChecksetResultChecks[];
    selectedObjects: SelectedObjects[];
    objectsInScope:string[];
    failedElements:string[];
    highlightFailedElements:boolean;
}

export const initialChecksetResultState: ChecksetResultState = {
    checksetResultDocument: null,
    checksetResultDrawerActive: false,
    checksetResultsChecks:[],
    selectedObjects:[],
    objectsInScope:[],
    failedElements:[],
    highlightFailedElements:false
};

