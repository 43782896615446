import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

interface ContentDrawerProps {
    open: boolean;
    title: string;
    children?: JSX.Element | JSX.Element[];
    action?: JSX.Element | JSX.Element[];
    onClose?: () => void;
    width?: string | number;
}

const ContentDrawer = ({ children, title, open, onClose, width, action }: ContentDrawerProps) => {
    const theme = useTheme();

    return (
        <Drawer
            variant="temporary"
            anchor="right"
            open={open}
            onClose={onClose}
            sx={{
                display: {
                    xs: "none",
                    sm: "block",
                    width: width ?? "50%",
                },
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: width ?? "50%",
                    overflow: "hidden",
                },
            }}
        >
            <DrawerHeader>
                <IconButton onClick={onClose}>
                    <FontAwesomeSvgIcon icon={faTimes} />
                </IconButton>

                <Typography
                    variant="h6"
                    noWrap
                    sx={{
                        mr: "auto",
                        display: "flex",
                        placeContent: "center",
                        padding: theme.spacing(1),
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        userSelect: "none",
                    }}
                >
                    {title}
                </Typography>
                {action}
            </DrawerHeader>
            <Divider />
            <Box
                sx={{
                    p: 2,
                    backgroundColor: theme.palette.background.default,
                    flex: 1,
                    display: "flex",
                }}
            >
                {children}
            </Box>
        </Drawer>
    );
};

export default ContentDrawer;
