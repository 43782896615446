import { Breadcrumbs, Fab, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import { HealthCheckDocumentsContext } from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";
import { useContext } from "react";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { faChevronLeft } from "@fortawesome/pro-duotone-svg-icons";
import { goBackFolders } from "../../Context/HealthCheckDocumentsContext/Reducer";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    margin: theme.spacing(1, 1, 2, 1),
    userSelect: "none",
}));

interface PageHeaderProps {
    title: string;
    breadcrumb: string[];
    children?: JSX.Element | JSX.Element[];
    onClick?: (value: string, index: number) => void;
}

const PageHeader = ({ title, breadcrumb, children, onClick }: PageHeaderProps) => {

    const { state, dispatch } = useContext(HealthCheckDocumentsContext);

    const breadcrumbClicked = (value: string, index: number) => {
        if (onClick) onClick(value, index);
    };

    const goBack = () =>{
        if (onClick) onClick("back", breadcrumb.length-2);

    };

    return (
        <Container>
            <Box>
                <Typography variant="h1" sx={{ fontSize: "calc(1.3rem + .6vw)", fontWeight: 500, lineHeight: 1 }}>

                    {state?.selectedFolders.length > 0 ?
                        <Fab size="small" sx={{ mr: 1 }} onClick={()=>{goBack()}}>
                            <FontAwesomeSvgIcon icon={faChevronLeft} sx={{ fontSize: 16 }} />
                        </Fab> : <></>}
                    {title}
                </Typography>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    {breadcrumb.map((x, index) => (
                        <Typography variant="caption" key={index} onClick={() => breadcrumbClicked(x, index)}>
                            {x}
                        </Typography>
                    ))}
                </Breadcrumbs>
            </Box>
            <Box sx={{ ml: "auto", alignSelf: "center" }}>{children}</Box>
        </Container>
    );
};

export default PageHeader;
