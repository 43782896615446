import {Outlet} from "react-router-dom";
import {
    ChecksetDefinitionContextProvider
} from "../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
import {ChecksetResultContextProvider} from "../../Context/ChecksetResultContext/ChecksetResultContextProvider";
import {
    ForgeDocumentBrowserContextProvider
} from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import {HealthCheckContextProvider} from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import {
    HealthCheckContextDocumentsProvider
} from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";

import AppContainer from "../../Features/AppContainer";
import RequestInterceptor from "../../Utils/Interceptors/RequestInterceptor";

// TODO: Redirect user to login page when they are not authenticated

const HealthCheck = () => {
    return (
        <RequestInterceptor>
            <AppContainer>
                <HealthCheckContextProvider>
                    <HealthCheckContextDocumentsProvider>
                        <ForgeDocumentBrowserContextProvider>
                            <ChecksetDefinitionContextProvider>
                                <ChecksetResultContextProvider>
                                    <Outlet/>
                                </ChecksetResultContextProvider>
                            </ChecksetDefinitionContextProvider>
                        </ForgeDocumentBrowserContextProvider>
                    </HealthCheckContextDocumentsProvider>
                </HealthCheckContextProvider>
            </AppContainer>
        </RequestInterceptor>
    );
};

export default HealthCheck;
