import { Box } from "@mui/system";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ChecksetRuleSet, ChecksetType, SimplePropertyCheck, SimplePropertyRule } from "../../../Connectors/_Models/Checkset";
import { DatasourceOperand } from "../../../Connectors/_Models/DatasourceOperand";
import { DatasourceProperty } from "../../../Connectors/_Models/DatasourceProperty";
import { DatasourceUnit } from "../../../Connectors/_Models/DatasourceUnit";
import { ChecksetDefinitionContext } from "../../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
import { PropertyAddAlert, PropertyAddAlertTypes, PropertySelectProperties } from "./PropertySelectProperties"
import { uuid4 } from "@sentry/utils";
import { DatasourceDataType } from "../../../Connectors/_Models/DatasourceDataType";
import { loadCustomProperties, setCurrentChecksetConfigurationEdited, setRemoteProperty, updatecheck } from "../../../Context/ChecksetDefinitionContext/Reducer";
import { Autocomplete, Button, Card, CardContent, CardHeader, Divider, TextField, Typography } from "@mui/material";
import { faMinus, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { styled, useTheme } from "@mui/material/styles";

import FontAwesomeSvgIcon from "../../../Components/FontAwesomeSvgIcon";
import { DatasourceCategory } from "../../../Connectors/_Models/DatasourceCategory";
import { Datasource, getCategories, getDatatypes, getProperties } from "../../../Connectors/datasource";

const parseValue = (value: any, dataType: DatasourceDataType): any => {
    if (dataType.storageType === "Text") return value;
    if (dataType.storageType === "Number") {
        if (isNaN(value)) value = null;
        return parseFloat(value);
    }
    if (dataType.storageType === "Bool") {
        if (typeof value === "boolean") return value;
        return ["yes", "true", "1"].some((x) => x === value?.toLowerCase()?.trim());
    }
    throw new Error(`Unhandled storage type '${dataType.storageType}'`);
};


const SimplePropertyComponent = () => {
    const theme = useTheme();
    const [cats, setCats] = useState<DatasourceCategory[]>([]);
    const [propertyAddAlert, setPropertyAddAlert] = useState<PropertyAddAlert>({ text: "", visible: false, alertType: PropertyAddAlertTypes.PropertyAddedType, handle: null });
    const source: Datasource = { source: "Forge" };
    const { state, dispatch } = useContext(ChecksetDefinitionContext);
    const [props, setProps] = useState<DatasourceProperty[]>([]);
    const [dataTypes, setDataTypes] = useState<DatasourceDataType[]>([]);


    

    useEffect(() => {
        getCategories(source).then((data) => {
            const unique = [...new Map(data.map((item) => [item.displayName, item])).values()];
            setCats(unique);
        });
        getProperties(source).then((x) => {
            dispatch(setRemoteProperty(x));
            dispatch(loadCustomProperties());
        });
        getDatatypes(source).then((x) => setDataTypes(x));
        // getOperators(source).then((x) => console.log("ops:", x));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setProps([...state.remoteProperties, ...state.customProperties]);
    }, [state.remoteProperties, state.customProperties]);

    return <Box
        sx={{
            p: 2,
            backgroundColor: theme.palette.background.default,
            flex: 1,
            display: "flex",
            flexDirection: "column",
        }}
    >
        {/* <Card sx={{ m: 1 }}>
<CardActionArea>
    <CardHeader title="Scope Configuration" action={<FontAwesomeSvgIcon icon={faPencil} sx={{ ml: "auto", fontSize: 16 }} />} />
    <CardContent sx={{ ":last-child": { pb: 2 } }}>
        <Typography>Some information message for the user describing what the scope configuration actually does.</Typography>
    </CardContent>
</CardActionArea>
</Card> */}

        <Card sx={{ m: 1 }}>
            <CardHeader title="Scope Configuration" />
            <Divider />

            <CardContent sx={{ ":last-child": { pb: 2 } }}>
                <CategoriesSelection cats={cats} />
                <Test props={props} dataTypes={dataTypes} propertyAddAlert={propertyAddAlert} target="ruleset" />
            </CardContent>
        </Card>

        {/* <Card sx={{ m: 1 }}>
<CardActionArea>
    <CardHeader title="Property Configuration" action={<FontAwesomeSvgIcon icon={faPencil} sx={{ ml: "auto", fontSize: 16 }} />} />
    <CardContent sx={{ ":last-child": { pb: 2 } }}>
        <Typography>Some information message for the user describing what the scope configuration actually does.</Typography>
    </CardContent>
</CardActionArea>
</Card> */}

        <Card sx={{ m: 1 }}>
            <CardHeader title="Properties Configuration" />
            <Divider />

            <CardContent sx={{ ":last-child": { pb: 2 } }}>
                <Test props={props} dataTypes={dataTypes} propertyAddAlert={propertyAddAlert} target="ruleset" />
            </CardContent>
        </Card>

        <PropertyCreator dataTypes={dataTypes} propertySaveCallback={(group: string, name: string, type: string) =>{return false}}/>
        {/*onAddCustomProperty(group, name, type)} />*/}
    </Box>
}

const Test = ({ props, dataTypes, target, propertyAddAlert }: PropertySelectProperties) => {
    const { state, dispatch } = useContext(ChecksetDefinitionContext);
    const [selection, setSelection] = useState<{
        [key: string]: { prop: DatasourceProperty | null; operand: DatasourceOperand | null; value: string | null; unit: DatasourceUnit | null };
    }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);
    useEffect(() => {
        setSelection({ [uuid4()]: { prop: null, operand: null, value: null, unit: null } });
    }, []);

    useEffect(() => {
        if (!props || props.length === 0 || !dataTypes || dataTypes.length === 0 || state.activeCheckIsNew || loaded) return;

        setLoading(true);

        const check = state.activeCheck as SimplePropertyCheck;
        const selection: { [key: string]: { prop: DatasourceProperty | null; operand: DatasourceOperand | null; value: string; unit: DatasourceUnit | null } } =
            {};
        const dataset = target === "scope" ? check.scope!.ruleSet : check.ruleSet;

        if (!dataset) return;

        for (let i = 0; i < dataset!.simplePropertyRules.length; i++) {
            const propertyTarget = dataset!.simplePropertyRules[i];
            let targetProperty = props.find((x) => x.id === propertyTarget.propertyId);
            let dataType: DatasourceDataType | undefined = undefined;
            if (!targetProperty && propertyTarget.dataTypeId) {
                targetProperty = state.customProperties.find(x => x.datatype && x.datatype.id === propertyTarget.dataTypeId);
                dataType = targetProperty?.datatype;
            }

            if (targetProperty) {
                if (!dataType && targetProperty?.datatype?.id) {
                    dataType = dataTypes.find((x) => x.id === targetProperty!.datatype!.id);
                }
                if (!dataType) continue;
                const targetOperator = dataType.supportedOperators.find((x) => x.id === propertyTarget.operatorId);
                const targetUnit = targetOperator?.requiresSecondOperand === true ? dataType.supportedUnits.find((x) => x.id === propertyTarget.unitId) : null;
                selection[uuid4()] = {
                    prop: targetProperty,
                    operand: targetOperator ?? null,
                    value: propertyTarget.value,
                    unit: targetUnit ?? null
                };
            }
        }

        if (Object.keys(selection).length === 0) selection[uuid4()] = {
            prop: null,
            operand: null,
            value: "",
            unit: null
        };

        setSelection(selection);
        setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, dataTypes, target, state.activeCheck, state.activeCheckIsNew]);

    useEffect(() => {
        if (!selection || loading) return;

        const rules: SimplePropertyRule[] = [];
        const keys = Object.keys(selection);
        for (let i = 0; i < keys.length; i++) {
            const rule = selection[keys[i]];
            let value: any = null;
            if (rule && rule.prop && rule.operand && rule.prop.datatype) {
                value = parseValue(rule.value, rule.prop.datatype);
            }
            console.log("value set to:", rule, value);

            rules.push({
                property: rule?.prop ? rule.prop.name : "",
                propertyId: rule?.prop?.id,
                propertyGroup: rule?.prop ? rule.prop.groupName : "",
                propertyGroupId: rule?.prop?.groupId,
                operatorId: rule?.operand?.id,
                value,
                dataTypeId: rule?.prop?.datatype ? rule.prop.datatype.id : undefined,
                unitId: rule.unit?.id,
                isCaseSensitive: false,
            });
        }

        const check = { ...state.activeCheck } as SimplePropertyCheck;
        const ruleset: ChecksetRuleSet = {
            ruleBehaviour: "AndAlso",
            simplePropertyRules: rules,
        };

        if (target === "scope") {
            check.scope = check.scope ?? { targetCategories: [], ruleSet: null };
            check.scope.ruleSet = ruleset;
        } else if (target === "ruleset") {
            check.ruleSet = ruleset;
        }

        dispatch(updatecheck(check));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    useEffect(() => {
        setLoading(false);
    }, [selection]);

    const onDecrement = (key: string) => {
        const currentSelection = { ...selection };
        delete currentSelection[key];

        if (Object.keys(currentSelection).length === 0) {
            setSelection({ [uuid4()]: { prop: null, operand: null, value: null, unit: null } });
        } else {
            setSelection(currentSelection);
        }
        dispatch(setCurrentChecksetConfigurationEdited());
    };

    const onIncrement = () => {
        const currentSelection = { ...selection, [uuid4()]: { prop: null, operand: null, value: null, unit: null } };
        setSelection(currentSelection);
        dispatch(setCurrentChecksetConfigurationEdited());
    };

    const propertyAddedAlertVisible = () => propertyAddAlert?.visible && propertyAddAlert?.alertType === PropertyAddAlertTypes.PropertyAddedType;

    const getPropertiesValid = (index: number, field: string) => {
        if (!state.validateChecksets) return true;
        let keys: string[] = [];
        if (selection != null) {
            keys = Object.keys(selection);
        }
        if (keys.length > 1) {
            try {
                if (selection && (selection[keys[index]] as any)[field] === null) {
                    return false;
                }
            } catch (error) {
                return false;
            }
        }

        return true;
    }
    const getValueIsValid = (index: number) => {
        if (!state.validateChecksets) return true;
        let keys: string[] = [];
        if (selection != null) {
            keys = Object.keys(selection);
        }
        try {
            if (selection) {
                let prop = selection[keys[index]];
                if (prop.value === null || prop.value === "" || prop.value === undefined) return false;
                return true;
            }
        } catch (error) {
            return false;
        }

        return false;
    }
    return (
        <>
            <Typography variant="body2">Properties:</Typography>
            {selection &&
                Object.keys(selection).map((key, index) => {
                    const activeSelection = selection[key];
                    const isLast = index + 1 === Object.keys(selection).length;
                    let availableOperands: DatasourceOperand[] = [];
                    let dataType: DatasourceDataType | undefined = undefined;
                    if (activeSelection?.prop?.datatype?.id) {
                        let dtId = activeSelection.prop.datatype.id;
                        dataType = dataTypes.find((dataType) => dataType.id === dtId);
                        if (dataType?.supportedOperators) {
                            availableOperands = dataType.supportedOperators;
                        }
                    }
                    return (
                        <Box key={key} sx={{ display: "flex", mt: 1, mb: 1 }}>
                            <Autocomplete
                                className={propertyAddedAlertVisible() ? "shine-background" : ""}
                                size="small"
                                options={props.sort((a, b) => a.groupName.localeCompare(b.groupName) || a.name.localeCompare(b.name))}
                                sx={{ width: 300 }}
                                getOptionLabel={(option) => option.name}
                                groupBy={(option) => option.groupName}
                                renderInput={(params) => <TextField
                                    error={!getPropertiesValid(index, "prop")} {...params} />}
                                value={activeSelection?.prop}
                                onChange={(event: any, newValue: DatasourceProperty | null) => {
                                    dispatch(setCurrentChecksetConfigurationEdited());
                                    const currentSelection = { ...selection };
                                    currentSelection[key].prop = newValue;

                                    if (!newValue) {
                                        currentSelection[key].operand = null;
                                        currentSelection[key].unit = null;
                                    }

                                    setSelection(currentSelection);
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                            />

                            <Autocomplete
                                size="small"
                                options={availableOperands.sort((a, b) => a.symbol.localeCompare(b.symbol))}
                                sx={{ width: 200, ml: 1 }}
                                getOptionLabel={(option) => option.symbol}
                                renderInput={(params) => <TextField {...params} />}
                                value={activeSelection?.operand}
                                onChange={(event: any, newValue: DatasourceOperand | null) => {
                                    dispatch(setCurrentChecksetConfigurationEdited());
                                    const currentSelection = { ...selection };
                                    currentSelection[key].operand = newValue;

                                    if (newValue?.requiresSecondOperand === false) {
                                        currentSelection[key].value = null;
                                        currentSelection[key].unit = null;
                                    }

                                    if (activeSelection.prop?.datatype?.id) {
                                        let dtId = activeSelection.prop.datatype.id;
                                        const dataType = dataTypes.find((dataType) => dataType.id === dtId);
                                        if (dataType && currentSelection[key].unit === null) {
                                            currentSelection[key].unit = dataType.supportedUnits.length > 0 ? dataType.supportedUnits[0] : null;
                                            if (
                                                currentSelection[key].operand &&
                                                availableOperands.findIndex((x) => x.id === currentSelection[key].operand?.id) === -1
                                            ) {
                                                currentSelection[key].operand = null;
                                            }
                                        }
                                    }

                                    setSelection(currentSelection);
                                }}
                                disabled={activeSelection?.prop === null}
                            />

                            <TextField
                                size="small"
                                sx={{ width: 100, ml: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                value={activeSelection?.value}
                                disabled={activeSelection?.operand?.requiresSecondOperand !== true}
                                error={!getValueIsValid(index)}
                                onChange={(event) => {
                                    dispatch(setCurrentChecksetConfigurationEdited());
                                    const currentSelection = { ...selection };
                                    currentSelection[key].value = event.currentTarget.value;
                                    setSelection(currentSelection);
                                }}
                            />

                            {dataType?.supportedUnits && dataType.supportedUnits.length > 0 && (
                                <Autocomplete
                                    size="small"
                                    options={dataType.supportedUnits.sort((a, b) => a.displayName.localeCompare(b.displayName))}
                                    sx={{ width: 200, ml: 1 }}
                                    getOptionLabel={(option) => option.displayName}
                                    renderInput={(params) => <TextField {...params} />}
                                    value={activeSelection?.unit}
                                    onChange={(event: any, newValue: DatasourceUnit | null) => {
                                        dispatch(setCurrentChecksetConfigurationEdited());
                                        const currentSelection = { ...selection };
                                        currentSelection[key].unit = newValue;
                                        setSelection(currentSelection);
                                    }}
                                    disabled={activeSelection?.operand?.requiresSecondOperand !== true}
                                />
                            )}
                            <Button size="small" sx={{ minWidth: "unset", width: 40, ml: 1, mr: 1 }}
                                onClick={() => onDecrement(key)}>
                                <FontAwesomeSvgIcon icon={faMinus} sx={{ fontSize: 16 }} />
                            </Button>
                            {isLast && (
                                <Button size="small" sx={{ minWidth: "unset", width: 40 }} onClick={onIncrement}>
                                    <FontAwesomeSvgIcon icon={faPlus} sx={{ fontSize: 16 }} />
                                </Button>
                            )}
                        </Box>
                    )
                })}
        </>
    );
};

export default SimplePropertyComponent;




interface CategoriesSelectionProps {
    cats: DatasourceCategory[];
}

const CategoriesSelection = ({ cats }: CategoriesSelectionProps) => {
    const { state, dispatch } = useContext(ChecksetDefinitionContext);
    const [selection, setSelection] = useState<{ [key: string]: DatasourceCategory | null }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        setSelection({ [uuid4()]: null });
    }, []);

    useEffect(() => {
        if (!cats || cats.length === 0 || state.activeCheckIsNew || loaded) return;

        setLoading(true);

        const check = state.activeCheck as SimplePropertyCheck;
        if (check) {
            const selection: { [key: string]: DatasourceCategory | null } = {};
            if (check.scope) {
                for (let i = 0; i < check.scope!.targetCategories.length; i++) {
                    const target = check.scope!.targetCategories[i];
                    const targetCategory = cats.find((x) => x.id === target);
                    if (targetCategory) {
                        selection[uuid4()] = targetCategory;
                    }
                }
            } else {
                check.scope = {
                    ruleSet: { ruleBehaviour: "andAlso", simplePropertyRules: [] },
                    targetCategories: []
                };
            }
            setSelection(selection);
            if (!selection || Object.keys(selection).length === 0) {
                onIncrement();
            }
        }
        setLoaded(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cats, state.activeCheck, state.activeCheckIsNew]);

    useEffect(() => {
        if (!selection || loading) return;

        const categories: string[] = [];
        const keys = Object.keys(selection);
        for (let i = 0; i < keys.length; i++) {
            const category = selection[keys[i]];
            if (!category) continue;
            categories.push(category.id);
        }

        if (categories.length === 0) return;

        const check = { ...state.activeCheck } as SimplePropertyCheck;
        check.scope = check.scope ?? { targetCategories: [], ruleSet: null };
        check.scope.targetCategories = categories;

        dispatch(updatecheck(check));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selection]);

    useEffect(() => {
        setLoading(false);
    }, [selection]);

    const onDecrement = (key: string) => {
        const currentSelection = { ...selection };
        delete currentSelection[key];

        if (Object.keys(currentSelection).length === 0) {
            setSelection({ [uuid4()]: null });
        } else {
            setSelection(currentSelection);
        }
        dispatch(setCurrentChecksetConfigurationEdited());
    };

    const onIncrement = () => {
        const currentSelection = { ...selection, [uuid4()]: null };
        setSelection(currentSelection);
        dispatch(setCurrentChecksetConfigurationEdited());
    };

    const getCategorieValid = (index: number) => {
        if (!state.validateChecksets) return true;
        let keys: string[] = [];
        if (selection != null) {
            keys = Object.keys(selection);
        }
        if (keys.length > 1) {
            try {
                if (selection && selection[keys[index]] === null) return false;
            } catch (error) {
                return false;
            }
        } else {
            let invalidCat = true;
            for (let sel in selection) {
                if (selection[sel] !== null) invalidCat = false;
            }
            if (invalidCat) {
                if (state.activeCheck?.checkType === ChecksetType.SIMPLE_PROPERTY_CHECK) {
                    let sac = state.activeCheck as SimplePropertyCheck;
                    if (sac?.scope?.ruleSet?.simplePropertyRules) {
                        if (sac.scope.ruleSet.simplePropertyRules.length > 1) return false;
                        else if (sac.scope.ruleSet.simplePropertyRules.length === 1) {
                            let spr = sac.scope.ruleSet.simplePropertyRules[0];
                            if (spr.property === "" &&
                                spr.dataTypeId === undefined &&
                                spr.propertyGroupId === undefined &&
                                (spr.value === null || isNaN(spr.value) || spr.value === undefined)) return false;
                        }
                    }
                }
            }
        }

        return true;
    }

    return (
        <>
            <Typography variant="body2">Target categories:</Typography>
            {selection &&
                Object.keys(selection).map((key, index) => {
                    const activeSelection = selection[key];
                    const isLast = index + 1 === Object.keys(selection).length;

                    return (
                        <Box key={key} sx={{ display: "flex", mt: 1, mb: 1 }}>
                            <Autocomplete
                                size="small"
                                options={cats.sort((a: any, b: any) => a.displayName.localeCompare(b.displayName))}
                                sx={{ width: 300 }}
                                getOptionLabel={(option: any) => option.displayName}
                                renderInput={(params) => <TextField error={!getCategorieValid(index)} {...params} />}
                                value={activeSelection}
                                onChange={(event: any, newValue: DatasourceCategory | null) => {
                                    dispatch(setCurrentChecksetConfigurationEdited());
                                    const currentSelection = { ...selection };
                                    currentSelection[key] = newValue;
                                    setSelection(currentSelection);
                                }}
                                getOptionDisabled={(option) => Object.values(selection).some((x) => x?.id === option.id)}
                            />
                            <Button size="small" sx={{ minWidth: "unset", width: 40, ml: 1, mr: 1 }} onClick={() => onDecrement(key)}>
                                <FontAwesomeSvgIcon icon={faMinus} sx={{ fontSize: 16 }} />
                            </Button>
                            {isLast && (
                                <Button size="small" sx={{ minWidth: "unset", width: 40 }} onClick={onIncrement}>
                                    <FontAwesomeSvgIcon icon={faPlus} sx={{ fontSize: 16 }} />
                                </Button>
                            )}
                        </Box>
                    );
                })}
        </>
    );
};

interface PropertiesSelectionProps {
    props: DatasourceProperty[];
    dataTypes: DatasourceDataType[];
    target: "scope" | "ruleset";
    propertyAddAlert?: PropertyAddAlert;
}



interface PropertyCreatorProps {
    dataTypes: DatasourceDataType[];
    propertySaveCallback: (group: string, name: string, type: string) => boolean;
}

const PropertyCreator = ({ dataTypes, propertySaveCallback }: PropertyCreatorProps) => {
    const [adding, setAdding] = useState<Boolean>(false);
    const [propName, setPropName] = useState<string>("");
    const [typeName, setTypeName] = useState<string>("");
    const [nameIsValid, setNameIsValid] = useState<number>(0);

    /**
     * Try saving the property using propertySaveCallback, close PropertyCreator dialog if succeeds.
     */
    const saveProperty = () => {
        let name = propName.split(".");
        if (propertySaveCallback(name[0], name[1], typeName)) {
            closeDialog();
        }
    };
    /**
     * Close the PropertyCreator dialog
     */
    const closeDialog = () => {
        setAdding(false);
        setNameIsValid(0);
    };

    /**
     * Callback method for the html onChange tag to change the current property
     * @param e change event for the input element
     */
    const onPropChange = (e: ChangeEvent<HTMLInputElement>) => {
        setNameIsValid(validName(e.currentTarget.value));
        setPropName(e.currentTarget.value);
    };

    /**
     * Callback method for the html onChange tag to change the current property
     * @param e change event for the input element
     */
    const onTypeChange = (e: string | null) => {
        if (e === null) setTypeName("");
        else setTypeName(e);
    };

    /**
     * Validates if text is valid property text
     * @param name Text to validate
     * @returns 0 if not validated (incomplete data). 1 if valid text. -1 if invalid text
     */
    const validName = (name: String) => {
        if (name.length === 0) return 0;
        let textSections = name.split(".");
        if (textSections.length < 2) return 0;
        else if (textSections.length === 2) {
            if (textSections[0] !== "") {
                if (textSections[1] !== "")
                    return 1;
                else
                    return 0
            }
        }
        return -1;
    }
    // Flatten dataTypes to a string array.
    const stringTypes = dataTypes.map(x => x.name);
    return (<Box sx={{ m: 1 }}>
        {
            !adding ?
                <Button variant="contained" onClick={() => setAdding(true)}>Add new property</Button>
                :
                <Card  >
                    <CardHeader title="Add new property" />
                    <Divider />
                    <CardContent sx={{ p: 2 }}>
                        <Typography variant="body2">Properties:</Typography>
                        <Box sx={{ display: "flex", mt: 1, mb: 1 }}><TextField
                            size="small"
                            error={nameIsValid === -1}
                            sx={{ width: 500, ml: 1, borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            onChange={onPropChange}
                        ></TextField></Box>
                        {
                            (nameIsValid === 1) ?
                                <>
                                    <Typography variant="body2">Type:</Typography>
                                    <Box sx={{ display: "flex", mt: 1, mb: 1 }}>
                                        <Autocomplete
                                            size="small"
                                            options={stringTypes}
                                            sx={{ width: 500, ml: 1 }}
                                            renderInput={(params) => <TextField {...params} />}
                                            onChange={(event: any, newValue: string | null) => onTypeChange(newValue)}
                                            value={typeName}
                                        />
                                    </Box>
                                </> :
                                <></>
                        }
                        <Box sx={{ display: "flex", mt: 1, mb: 1 }}>
                            <Button color="error" sx={{ mr: 1 }} variant="contained" onClick={() => closeDialog()}>Discard</Button>
                            <Button color="success" variant="contained" onClick={() => saveProperty()}>Save</Button>
                        </Box>

                    </CardContent>
                </Card>
        }
    </Box>);
}