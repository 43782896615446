import { DataFolder } from "../../Connectors/_Models";
import { Checkset } from "../../Connectors/_Models/Checkset";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";

export enum ActionType {
    SetDataFolders,
    AddDataFolder,
    SetActiveDataFolder,
    GoBackFolders,

    SetDocuments,
    AddDocument,
    UpdateDocumentChecksets,
    RemoveDocument,
    SetRawFolder,
    PatchFolder
}
export interface SetRawFolder {
    type: ActionType.SetRawFolder;
    payload: { dataFolder: DataFolder };
}
export interface AddDataFolder {
    type: ActionType.AddDataFolder;
    payload: { dataFolder: DataFolder };
}

export interface SetActiveDataFolder {
    type: ActionType.SetActiveDataFolder;
    payload: { dataFolder: DataFolder };
}

export interface SetDocuments {
    type: ActionType.SetDocuments;
    payload: { documents: DataFolderDocument[] };
}

export interface GoBackFolders {
    type: ActionType.GoBackFolders;
    payload: {amountOfFolders:number}
}

export interface UpdateDocumentChecksets {
    type: ActionType.UpdateDocumentChecksets;
    payload: { document: DataFolderDocument; checksets: string[] };
}

export interface RemoveDocument {
    type: ActionType.RemoveDocument;
    payload: { id: string };
}
export interface PatchFolder {
    type: ActionType.PatchFolder;
    payload: { dataFolder: DataFolder,activateFolder:boolean,rawFolders?:DataFolder };
}

export type HealthCheckDocumentsActions =
    | AddDataFolder
    | SetActiveDataFolder
    | GoBackFolders
    | SetDocuments
    | UpdateDocumentChecksets
    | RemoveDocument
    | SetRawFolder
    | PatchFolder;
