import { createContext, Dispatch, useReducer } from "react";
import { ChecksetDefinitionActions } from "./Actions";
import checksetDefinitionReducer from "./Reducer";
import { ChecksetDefinitionState, initialChecksetDefinitionState } from "./State";

interface ChecksetDefinitionContextProps {
    children: JSX.Element | JSX.Element[];
}

const ChecksetDefinitionContext = createContext<{ state: ChecksetDefinitionState; dispatch: Dispatch<ChecksetDefinitionActions> }>({
    state: initialChecksetDefinitionState,
    dispatch: () => undefined,
});

const ChecksetDefinitionContextProvider = ({ children }: ChecksetDefinitionContextProps) => {
    const [state, dispatch] = useReducer(checksetDefinitionReducer, initialChecksetDefinitionState);

    return <ChecksetDefinitionContext.Provider value={{ state, dispatch }}>{children}</ChecksetDefinitionContext.Provider>;
};

export { ChecksetDefinitionContext, ChecksetDefinitionContextProvider };
