import React, { PropsWithChildren, RefObject } from "react";
import Typography from "@mui/material/Typography";
import type { NodeModel } from "@minoru/react-dnd-treeview";
import styles from "./CustomNode.module.css";
import FontAwesomeSvgIcon from "../../../Components/FontAwesomeSvgIcon";
import { faGripLines } from "@fortawesome/pro-duotone-svg-icons";

type Props = {
  node: NodeModel;
  depth: number;
  isOpen: boolean;
  testIdPrefix?: string;
  handleRef: RefObject<any>;
  onToggle: (id: NodeModel["id"]) => void;
};

export const CustomNode = ({
  testIdPrefix = "",
  ...props
}: PropsWithChildren<Props>) => {
  const { id } = props.node;
  const indent = props.depth * 24;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    props.onToggle(props.node.id);
  };

  return (
    <div
      className={styles.root}
      style={{ paddingInlineStart: indent }}
      data-testid={`${testIdPrefix}custom-node-${id}`}
    >
      <div
        className={`${styles.handle} drag-handle`}
        ref={props.handleRef}
        data-testid={`drag-handle-${props.node.id}`}
      >
        <FontAwesomeSvgIcon icon={faGripLines} />
      </div>
      <div className={styles.label}>
        {props.children}
      </div>
    </div>
  );
};
