export enum ActionType {
    CollapseSidebar,
    ExpandSidebar,
    CollapseApplicationbar,
    ExpandApplicationbar,
}

export interface CollapseSidebar {
    type: ActionType.CollapseSidebar;
}

export interface ExpandSidebar {
    type: ActionType.ExpandSidebar;
}

export interface CollapseApplicationbar {
    type: ActionType.CollapseApplicationbar;
}

export interface ExpandApplicationbar {
    type: ActionType.ExpandApplicationbar;
}


export type GlobalActions = CollapseSidebar | ExpandSidebar | CollapseApplicationbar | ExpandApplicationbar;
