import { createContext, Dispatch, useReducer } from "react";
import { ChecksetResultActions } from "./Actions";
import checksetResultReducer from "./Reducer";
import { ChecksetResultState, initialChecksetResultState } from "./State";

interface ChecksetResultContextProps {
    children: JSX.Element | JSX.Element[];
}

const ChecksetResultContext = createContext<{ state: ChecksetResultState; dispatch: Dispatch<ChecksetResultActions> }>({
    state: initialChecksetResultState,
    dispatch: () => undefined,
});

const ChecksetResultContextProvider = ({ children }: ChecksetResultContextProps) => {
    const [state, dispatch] = useReducer(checksetResultReducer, initialChecksetResultState);

    return <ChecksetResultContext.Provider value={{ state, dispatch }}>{children}</ChecksetResultContext.Provider>;
};

export { ChecksetResultContext, ChecksetResultContextProvider };
