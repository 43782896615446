import { faPlus } from "@fortawesome/pro-duotone-svg-icons";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  Modal,
  TextField,
} from "@mui/material";
import { Checkbox, FormControlLabel } from "@mui/material";

import { useContext, useEffect, useState } from "react";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import FontAwesomeSvgIcon from "../../Components/FontAwesomeSvgIcon";
import ForgeOnboarding from "../../Components/ForgeOnboarding";
import HealthCheckDocuments from "../../Components/HealthCheckDocuments";
import LoadingIndicator from "../../Components/LoadingIndicator";
import PageHeader from "../../Components/PageHeader";
import {
  getDataFolderForgeLink,
  getDataFolders,
  getDocumentsFulltree,
  removeDataFolderForgeLink,
} from "../../Connectors/dataFolders";
import { authorizeForge, checkForgeToken } from "../../Connectors/forgeDms";
import { ForgeDocumentBrowserContext } from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import { openForgeDocumentDrawer } from "../../Context/ForgeDocumentBrowserContext/Reducer";
import { HealthCheckContext } from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import {
  clearForgeLink,
  setForgeLink,
} from "../../Context/HealthCheckContext/Reducer";
import ChecksetResultDrawer from "../../Features/ChecksetResultDrawer";
import ForgeDocumentSelectionDrawer from "../../Features/ForgeDocumentSelectionDrawer";
import {
  goBackFolders,
  patchAndActivateFolder,
  patchFolder,
  setActiveDataFolder,
  setRawFolder,
} from "../../Context/HealthCheckDocumentsContext/Reducer";
import { HealthCheckDocumentsContext } from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";
import FolderCreator from "../../Components/FolderCreator";
import { EForgeBrowseMode } from "../../Context/ForgeDocumentBrowserContext/State";
import { DataFolder } from "../../Connectors/_Models";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";
import {
  Bim360DataFolderConfig,
  DataFolderConfig,
} from "../../Connectors/_Models/DataFolder";
import FileUploader from "../../Components/FileUploader/FileUploader";
import ListItemText from "@mui/material/ListItemText";
import Snackbar from "@mui/material/Snackbar";
import { ConvertFolderStatus } from "../../Connectors/_Models/ConvertFolderStatus";
import ForgeDocumentManagementTree from "../../Components/ForgeDocumentManagement/ForgeDocumentManagementTree";
import ScrollContainer from "../../Components/ForgeOnboarding/ForgeOnboarding";
import ForgeDocumentManagementContent from "../../Components/ForgeDocumentManagement/ForgeDocumentManagementContent";
import {
  convertFolder,
  createConvertFolderConfig,
  getConvertFolderConfigs,
} from "../../Connectors/ifc";
import { ConvertFolderConfig } from "../../Connectors/_Models/ConvertFolderConfig";
import RevitToIfcConverter from "../../Components/RevitToIfcConverter";
import {
  generatePbit,
  getPowerbiMaterials,
  startPowerBiExtract,
} from "../../Connectors/powerbi";
import { ChecksetResultContext } from "../../Context/ChecksetResultContext/ChecksetResultContextProvider";

enum IFCConvertStatus {
  NotStarted,
  Started,
  Finished,
  Failed,
}

const Home = () => {
  const { state: healthCheckState, dispatch: healthCheckDispatch } =
    useContext(HealthCheckContext);
  const {
    state: healthCheckDocumentsState,
    dispatch: healthCheckDocumentsDispatch,
  } = useContext(HealthCheckDocumentsContext);
  const { dispatch: forgeDocumentDispatch, state: forgeDocumentState } =
    useContext(ForgeDocumentBrowserContext);
  const [forgeTokenValid, setForgeTokenValid] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadedUrlFolder, setLoadedUrlFolder] = useState<boolean>(false);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [convertIfc, setConvertIfc] = useState<boolean>(false);
  const [convertingStatus, setConvertingStatus] =
    useState<ConvertFolderStatus | null>(null);

  useEffect(() => {
    checkForgeToken()
      .then((data) => {
        setForgeTokenValid(data);
      })
      .catch(() => {
        setForgeTokenValid(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openIfcConvertDialog = async () => {
    setConvertIfc(true);
  };

  useEffect(() => {
    if (forgeTokenValid) return;

    if (location.pathname === "/health-check/forge-callback") {
      const code = searchParams.get("code");
      if (!code) return;
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}/health-check`
      );

      authorizeForge(
        `${window.location.origin}/health-check/forge-callback`,
        code
      ).then(() => {
        setForgeTokenValid(true);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgeTokenValid]);

  useEffect(() => {
    console.log("selectedfolder changed");
    if (
      healthCheckDocumentsState.selectedFolders &&
      healthCheckDocumentsState.selectedFolders.length > 0
    ) {
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}/health-check/folder/${btoa(
          JSON.stringify(healthCheckDocumentsState.selectedFolders)
        )}`
      );
    } else {
      window.history.replaceState(
        {},
        document.title,
        `${window.location.origin}/health-check/`
      );
    }
  }, [healthCheckDocumentsState.activeDataFolder]);

  // useEffect(() => {
  //     if (!healthCheckState.activeDataFolder) {
  //         setLoading(true);
  //
  //         getDataFolders().then((data) => {
  //             healthCheckDispatch(setDataFolders(data));
  //             healthCheckDispatch(setActiveDataFolder(data[0]));
  //         });
  //     }
  //
  //     if (healthCheckState.forgeHub && healthCheckState.forgeProject && healthCheckState.forgeRootFolder) return;
  //     getForgeLink();
  //
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [healthCheckState.activeDataFolder, location]);
  const params = useParams();
  useEffect(() => {
    if (!healthCheckDocumentsState.activeDataFolder) {
      setLoading(true);

      getDataFolders().then(async (data) => {
        //TODO fix this once api calls are fixed
        console.log(data);
        let dataFolder: DataFolder = {
          id: "root",
          name: "root",
          children: data,
          documents: [],
          config: undefined,
          linkLoaded: undefined,
          checksLoaded: false,
          parentFolder: undefined,
        };
        data = data.map((x) => {
          x.parentFolder = dataFolder;
          return x;
        });
        healthCheckDocumentsDispatch(setRawFolder(dataFolder));
        if (params.folders && !loadedUrlFolder) {
          let selectedFolders: number[] = JSON.parse(
            atob(params.folders as string)
          ) as number[];
          if (selectedFolders.length > 0 && selectedFolders[0] < data.length) {
            try {
              let x = await getDocumentsFulltree(data[selectedFolders[0]].id);
              x.linkLoaded = true;
              x.parentFolder = data[selectedFolders[0]].parentFolder;
              if (dataFolder.children) {
                dataFolder.children[selectedFolders[0]] = x;
              }

              let activeFolder = x;
              let skipOne = false;
              for (let sf of selectedFolders) {
                if (!skipOne) {
                  skipOne = true;
                  continue;
                }
                if (activeFolder.children) {
                  activeFolder.children[sf].parentFolder = activeFolder;
                  activeFolder = activeFolder.children[sf];
                } else {
                  break;
                }
              }
              setLoadedUrlFolder(false);
              healthCheckDocumentsDispatch(
                patchAndActivateFolder(activeFolder, dataFolder)
              );
              setLoading(false);

              return;
            } catch (e) {
              console.error("invalid folder url");
            }
          }
        }
        console.log(params);
        dataFolder.children = data.map((x) => {
          return { ...x, parentFolder: dataFolder, linkLoaded: false };
        }) as DataFolder[];

        healthCheckDocumentsDispatch(setActiveDataFolder(dataFolder));
        setLoadedUrlFolder(false);
        setLoading(false);
        // console.log(healthCheckDocumentsState.rawFolders.folders);
        // healthCheckDocumentsState.rawFolders.id = data[0].id;
        // healthCheckDocumentsDispatch(setDataFolders(healthCheckDocumentsState.rawFolders.folders));
        // healthCheckDocumentsDispatch(setActiveDataFolder(healthCheckDocumentsState.rawFolders));
      });
    } else {
    }

    if (
      healthCheckState.forgeHub &&
      healthCheckState.forgeProject &&
      healthCheckState.forgeRootFolder
    )
      return;
    getForgeLink();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthCheckDocumentsState.activeDataFolder, location]);

  // TODO: Move to utils
  const onForgeAuthenticate = () => {
    const clientId = "7R3mhWP3uL6tOAbGyL19uS0mHNiLaa2b";
    const redirect_uri = `${window.location.origin}/health-check/forge-callback`;
    const url = `https://developer.api.autodesk.com/authentication/v1/authorize?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=code&scope=data:read data:write`;
    sessionStorage.removeItem("forgeRedirectCache");
    window.location.href = url;
  };

  const getForgeLink = () => {
    if (!healthCheckDocumentsState.activeDataFolder) return;

    setLoading(true);

    getDataFolderForgeLink(healthCheckDocumentsState.activeDataFolder.id).then(
      (data) => {
        if (data) {
          healthCheckDispatch(
            setForgeLink(data.hub, data.project, data.folder)
          );
        }

        setLoading(false);
      }
    );
  };

  const onOpenDms = () => {
    forgeDocumentDispatch(openForgeDocumentDrawer(EForgeBrowseMode.File));
  };
  const getBreadcrumbs = () => {
    let breadcrumbs = ["Health Check", "Documents"];
    let folders = healthCheckDocumentsState.rawFolders;
    if (folders !== null) {
      for (let index of healthCheckDocumentsState.selectedFolders) {
        if (folders.children !== undefined) {
          // putting this if check outside of the for loop results in compile error
          breadcrumbs.push(folders.children[index].name);
          folders = folders.children[index];
        }
      }
    }
    return breadcrumbs;
  };
  const onClickBreadcrumb = (name: string, index: number) => {
    let breadcrumbs = getBreadcrumbs();
    let amountOfFoldersToGoBack = breadcrumbs.length - index - 1;
    if (amountOfFoldersToGoBack < 1) return;
    healthCheckDocumentsDispatch(goBackFolders(amountOfFoldersToGoBack));
  };

  const isMirrorFolder = () => {
    if (!healthCheckDocumentsState.activeDataFolder) return false;
    //        let config = healthCheckDocumentsState.activeDataFolder.config as Bim360DataFolderConfig;
    let folder = healthCheckDocumentsState.activeDataFolder;
    let mirrorFolders = false;
    if (folder.config && folder.config.type === "bim360") {
      mirrorFolders =
        (folder.config as Bim360DataFolderConfig).mirrorFolders === true;
    }
    while (!mirrorFolders && folder.parentFolder) {
      folder = folder.parentFolder;
      if (folder.config && folder.config.type === "bim360")
        mirrorFolders =
          (folder.config as Bim360DataFolderConfig).mirrorFolders === true;
    }
    return mirrorFolders;
  };

  const forgeConversionState = () => {
    if (!convertingStatus) return <></>;
    let severity: AlertColor = "info";
    if (convertingStatus.finished) severity = "success";
    if (convertingStatus.error) severity = "error";
    return (
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Card>
          <Alert
            variant="outlined"
            severity={severity}
            onClose={() => setConvertingStatus(null)}
          >
            <div>
              {convertingStatus.error
                ? "Error"
                : convertingStatus.finished
                ? "Converted"
                : "Converting"}
            </div>
            <List sx={{ maxHeight: "40vh" }}>
              {convertingStatus.filesStatus ? (
                Object.keys(convertingStatus.filesStatus).map(
                  (x: any, i: number) => (
                    <>
                      {i !== 0 ? <Divider /> : <></>}
                      <ListItemText
                        primary={x}
                        secondary={convertingStatus.filesStatus[x]}
                      />
                    </>
                  )
                )
              ) : (
                <></>
              )}
            </List>
          </Alert>
        </Card>
      </Snackbar>
    );
  };
  const [pbit, setPbit] = useState(false);
  return loading || !healthCheckDocumentsState.activeDataFolder ? (
    <LoadingIndicator />
  ) : (
    <>
      <PageHeader
        title={healthCheckDocumentsState?.activeDataFolder!.name}
        onClick={(x, y) => {
          onClickBreadcrumb(x, y);
        }}
        breadcrumb={getBreadcrumbs()}
      >
        {isMirrorFolder() ? (
          <Button onClick={() => openIfcConvertDialog()} variant="contained">
            RVT -&gt; IFC
          </Button>
        ) : (
          <></>
        )}
        {isMirrorFolder() ? (
          <Button onClick={() => setPbit(true)} variant="contained">
            PBit
          </Button>
        ) : (
          <></>
        )}
        {pbit ? <PbitGenerator onFinished={() => setPbit(false)} /> : <></>}
        <RevitToIfcConverter
          convertIfc={convertIfc}
          setConvertIfc={(x) => {
            setConvertIfc(x);
          }}
        />
        <FileUploader folder={healthCheckDocumentsState.activeDataFolder} />
        <FolderCreator sx={{ mr: 1 }} />
        <Button
          variant="contained"
          disabled={
            !(healthCheckState.forgeRootFolder !== null && forgeTokenValid)
          }
          onClick={onOpenDms}
          startIcon={<FontAwesomeSvgIcon icon={faPlus} />}
        >
          Add BIM360 Document
        </Button>
      </PageHeader>
      {forgeConversionState()}
      <>
        {!forgeTokenValid && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{ m: 1 }}
            action={
              <Button
                color="inherit"
                size="small"
                onClick={onForgeAuthenticate}
              >
                RECONNECT
              </Button>
            }
          >
            Connection to your BIM360 account expired, please reconnect...
          </Alert>
        )}

        <HealthCheckDocuments />
        <ForgeDocumentSelectionDrawer />
        <ChecksetResultDrawer />
      </>

      <Alert
        variant="filled"
        severity="error"
        sx={{ m: 1 }}
        action={
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              removeDataFolderForgeLink(
                healthCheckDocumentsState.activeDataFolder!.id
              );
              healthCheckDispatch(clearForgeLink());
            }}
          >
            UNLINK
          </Button>
        }
      >
        Remove BIM360 account link to restart the onboarding process for
        testing...
      </Alert>
    </>
  );
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const PbitGenerator = ({ onFinished }: { onFinished?: () => void }) => {
  const { state, dispatch } = useContext(ChecksetResultContext);
  const { state: healthCheckState } = useContext(HealthCheckContext);
  const {
    state: healthCheckDocumentsState,
    dispatch: healthCheckDocumentsDispatch,
  } = useContext(HealthCheckDocumentsContext);

  // return <ul>
  //     {state.activeDataFolder?.documents?.map((x: DataFolderDocument) => {
  //         return <li>{x.fileName}</li>
  //     })}
  // </ul>;

  const [checkedFiles, setCheckedFiles] = useState<string[]>([]);

  const handleToggle = (fileName: string) => {
    // Check if the file is already selected
    const currentIndex = checkedFiles.indexOf(fileName);
    const newChecked = [...checkedFiles];

    if (currentIndex === -1) {
      newChecked.push(fileName); // Add to checked list
    } else {
      newChecked.splice(currentIndex, 1); // Remove from checked list
    }

    setCheckedFiles(newChecked);
  };

  const sleep = (t: number) =>
    new Promise((resolve) =>
      setTimeout(() => {
        resolve(null);
      }, t)
    );
  const generateDownloadablePbit = async (documentId: string) => {
    if (
      !healthCheckDocumentsState.activeDataFolder
    )
      return;
    let mats = await getPowerbiMaterials(
      healthCheckDocumentsState.activeDataFolder.id,
      documentId
    );
    console.log(mats);
    if (mats == null) {
      await startPowerBiExtract(
        healthCheckDocumentsState.activeDataFolder.id,
        documentId
      );
    }
    let breaker = 0;
    while (mats == null && breaker < 120) {
      await sleep(1000);
      mats = await getPowerbiMaterials(
        healthCheckDocumentsState.activeDataFolder.id,
        documentId
      );
      breaker++;
    }
    if (mats == null) {
      return false;
    }
    return true;
    // else{
    //     setConvertingPbit("");
    //     let fileName = state.checksetResultDocument?.fileName.split(".rvt")[0];
    //     let blobData =await generatePbit(healthCheckDocumentsState.activeDataFolder.id,state.checksetResultDocument.id,fileName);
    //     const href = URL.createObjectURL(blobData as Blob);
    //     const link = document.createElement('a');
    //     link.href = href;
    //     link.setAttribute('download', `${fileName}.pbit`); //or any other extension
    //     document.body.appendChild(link);
    //     link.click();

    //     // clean up "a" element & remove ObjectURL
    //     document.body.removeChild(link);
    //     URL.revokeObjectURL(href);
    // }
  };

  const downloadPbit = async () => {
    if (!healthCheckDocumentsState.activeDataFolder) return;
    let promises = [];
    let docs = [];
    for (let f of checkedFiles) {
      let doc = healthCheckDocumentsState.activeDataFolder?.documents?.find(
        (x) => x.fileName === f
      );
      if (doc) {
        promises.push(generateDownloadablePbit(doc.id));
        docs.push(doc);
      }
    }
    let results = await Promise.all(promises);
    if (results.includes(false)) {
      //failed
      alert("failed to generate pbit");
      return;
    }
    let filenames = docs.map((x) => x.fileName).join(";");
    let documentIds = docs.map((x) => x.id).join(";");
    let folderid  = healthCheckDocumentsState.activeDataFolder.id;
    let folderNames = docs.map((x) => folderid).join(";");
    let blobData = await generatePbit(
      folderNames,
      documentIds,
      filenames
    );
    const href = URL.createObjectURL(blobData as Blob);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `generated.pbit`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    onFinished?.();
  };
  return (
    <Modal open={true}>
      <Box sx={{ ...style, width: 400 }}>
        <Box>
          <Button onClick={() => downloadPbit()}>Generate</Button>
          <Button onClick={() => onFinished?.()}>Close</Button>
        </Box>
        {healthCheckDocumentsState.activeDataFolder?.documents?.map(
          (x: DataFolderDocument) => (
            <FormControlLabel
              key={x.fileName}
              control={
                <Checkbox
                  checked={checkedFiles.includes(x.fileName)}
                  onChange={() => handleToggle(x.fileName)}
                  value={x.fileName}
                />
              }
              label={x.fileName}
            />
          )
        )}
      </Box>
    </Modal>
  );
};

export default Home;
