

export const getDashboardData = () => {
    return new Promise((resolve, reject) => {
        resolve(
            [
                [
                    {
                        "id": "3d21e003-f70b-4ea4-75a1-08dad6c53f8f",
                        "externalId": "urn:adsk.wipprod:dm.lineage:ZQ72G8YHTli0Ip1fRTUY9A",
                        "fileName": "Technicalschoolcurrentm.rvt",
                        "autoTrackChanges": true,
                        "dataSource": "bim360",
                        "creationDate": "2022-12-06T07:07:50.6881251",
                        "fileType": "rvt",
                        "documentVersions": [
                            {
                                "revitVersion": 2021,
                                "latestAvailableVersionLabel": "V1",
                                "latestAvailableVersionNumber": 1,
                                "versionUrn": "urn:adsk.wipprod:fs.file:vf.ZQ72G8YHTli0Ip1fRTUY9A?version=1",
                                "objectId": "https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/978e256e-5b45-44cd-8933-68a1e3acdc8d.rvt",
                                "viewerDerivativeUrn": "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLlpRNzJHOFlIVGxpMElwMWZSVFVZOUE_dmVyc2lvbj0x",
                                "id": "0cb43329-a6dc-4d0b-776d-08dad6c53f95",
                                "versionLabel": "V1",
                                "versionNumber": 1,
                                "versionDate": "2022-12-06T07:07:50.68812",
                                "filesize": 16261120,
                                "parentDocument": null,
                                "checksetResults": [
                                    {
                                        "id": "590246fd-f7a6-49c4-04bb-08db4c9e5008",
                                        "runRequestDate": "2023-05-04T12:51:43.4174908",
                                        "runCompletionDate": "2023-05-04T12:51:46.5690284",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "One or more errors occurred. (Object reference not set to an instance of an object.)\n   at System.Threading.Tasks.Task.ThrowIfExceptional(Boolean includeTaskCanceledExceptions)\r\n   at System.Threading.Tasks.Task`1.GetResultCore(Boolean waitCompletionNotification)\r\n   at DStudio.BIMCradle.HealthCheck.API.Services.CheckAutomation.DesignAutomationCheckRunner.RunCheck(Guid checksetId, Guid userID, Guid datafolder, List`1 documentId) in C:\\Users\\HakanSanli\\source\\repos\\HealthCheck_Api\\DStudio.BIMCradle.HealthCheck.API\\Services\\CheckAutomation\\DesignAutomationCheckRunner.cs:line 191",
                                                "id": "f722c5ff-5d0d-4cb7-ae6c-08db4c9e51ce",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    }
                                ]
                            }
                        ],
                        "documentVersionId": "0cb43329-a6dc-4d0b-776d-08dad6c53f95",
                        "dataFolderId": "d3374432-c01e-4a2f-314d-08dad6c6f0f3",
                        "checksets": null,
                        "documentMeta": null
                    },
                    {
                        "id": "043fd60e-107c-4d95-75a2-08dad6c53f8f",
                        "externalId": "urn:adsk.wipprod:dm.lineage:RLj_X8x9T8ajR_Dpzds99A",
                        "fileName": "racadvancedsampleproject.rvt",
                        "autoTrackChanges": true,
                        "dataSource": "bim360",
                        "creationDate": "2022-12-06T07:07:51.294825",
                        "fileType": "rvt",
                        "documentVersions": [
                            {
                                "revitVersion": 2021,
                                "latestAvailableVersionLabel": "V1",
                                "latestAvailableVersionNumber": 1,
                                "versionUrn": "urn:adsk.wipprod:fs.file:vf.RLj_X8x9T8ajR_Dpzds99A?version=1",
                                "objectId": "https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/06b5d792-2a71-498c-be17-28e61e0c3595.rvt",
                                "viewerDerivativeUrn": "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLlJMal9YOHg5VDhhalJfRHB6ZHM5OUE_dmVyc2lvbj0x",
                                "id": "c1bdfad4-bc5f-4222-776e-08dad6c53f95",
                                "versionLabel": "V1",
                                "versionNumber": 1,
                                "versionDate": "2022-12-06T07:07:51.2948194",
                                "filesize": 18083840,
                                "parentDocument": null,
                                "checksetResults": [
                                    {
                                        "id": "833c8a11-13d8-42a4-04bc-08db4c9e5008",
                                        "runRequestDate": "2023-05-04T12:53:57.2206719",
                                        "runCompletionDate": "2023-05-04T12:53:57.8223604",
                                        "checkResults": [
                                            {
                                                "message": "Too Big",
                                                "id": "c7579f0a-a96b-40ba-87f0-d5c25ee298f5",
                                                "checkDefinitionId": "4e898b33-5e81-441c-965a-8ae591c48984",
                                                "documentId": "c1bdfad4-bc5f-4222-776e-08dad6c53f95",
                                                "percentage": 0,
                                                "checkType": "sizeCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 0
                                    }
                                ]
                            }
                        ],
                        "documentVersionId": "c1bdfad4-bc5f-4222-776e-08dad6c53f95",
                        "dataFolderId": "d3374432-c01e-4a2f-314d-08dad6c6f0f3",
                        "checksets": null,
                        "documentMeta": null
                    },
                    {
                        "id": "4213c333-0638-4816-75ab-08dad6c53f8f",
                        "externalId": "urn:adsk.wipprod:dm.lineage:QLDC8K0FSC2DEF7A7X6AZw",
                        "fileName": "rme_basic_sample_project_2022.rvt",
                        "autoTrackChanges": true,
                        "dataSource": "bim360",
                        "creationDate": "2022-12-06T07:07:57.0161993",
                        "fileType": "rvt",
                        "documentVersions": [
                            {
                                "revitVersion": 2022,
                                "latestAvailableVersionLabel": "V1",
                                "latestAvailableVersionNumber": 1,
                                "versionUrn": "urn:adsk.wipprod:fs.file:vf.QLDC8K0FSC2DEF7A7X6AZw?version=1",
                                "objectId": "https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/d40b67a0-8cdd-44e0-ad3f-d675bddcae06.rvt",
                                "viewerDerivativeUrn": "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLlFMREM4SzBGU0MyREVGN0E3WDZBWnc_dmVyc2lvbj0x",
                                "id": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                "versionLabel": "V1",
                                "versionNumber": 1,
                                "versionDate": "2022-12-06T07:07:57.0161935",
                                "filesize": 30482432,
                                "parentDocument": null,
                                "checksetResults": [
                                    {
                                        "id": "c6a18e54-bbac-4d1c-688c-08daecbd69b7",
                                        "runRequestDate": "2023-01-02T12:32:29.2656919",
                                        "runCompletionDate": "2023-01-02T12:33:04.750284",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "482da0f6-c6e1-4f42-301b-08daecbd7d11",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "4098c34d-9d4f-4a3f-688d-08daecbd69b7",
                                        "runRequestDate": "2023-01-02T12:32:34.0084153",
                                        "runCompletionDate": "2023-01-02T12:33:04.7809491",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "ab5fd730-2215-4b59-301c-08daecbd7d11",
                                                "checkDefinitionId": "ab97e5c2-82f1-418e-a138-8ee87ec5fed5",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "6e0cd457-5ff6-454d-688e-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:23:04.9044439",
                                        "runCompletionDate": "2023-01-03T06:23:45.9067713",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "79fd9eb0-0f04-4d83-301f-08daecbd7d11",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "b9bea83f-7fe5-48b6-688f-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:23:07.6781917",
                                        "runCompletionDate": "2023-01-03T06:23:37.7558156",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 0,
                                                "failedElements": 0,
                                                "passedElements": 0,
                                                "failedElementIds": [],
                                                "passedElementIds": [],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Windows": []
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Windows": []
                                                },
                                                "id": "6a285f98-68bc-4b1c-301d-08daecbd7d11",
                                                "checkDefinitionId": "ed756e0b-1940-4e1b-ad57-ece4d6b52eb2",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 100,
                                                "checkType": "simplePropertyCheck"
                                            },
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "f3f9b2b8-62d7-4ab7-301e-08daecbd7d11",
                                                "checkDefinitionId": "4b05a252-fd7d-4920-b7b0-912c1014166c",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 52
                                    },
                                    {
                                        "id": "a2f47e71-7d8c-4ff8-6890-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:27:32.9471424",
                                        "runCompletionDate": "2023-01-03T06:28:02.8966096",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "c134bac0-af3e-4945-3020-08daecbd7d11",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "e2dc2605-2ae3-409d-6891-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:27:34.697782",
                                        "runCompletionDate": "2023-01-03T06:28:16.6757172",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "8f0d07d4-1a1c-42f8-3021-08daecbd7d11",
                                                "checkDefinitionId": "9e313a7e-79bf-45d2-920d-103e008670ef",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            },
                                            {
                                                "elementsInScope": 0,
                                                "failedElements": 0,
                                                "passedElements": 0,
                                                "failedElementIds": [],
                                                "passedElementIds": [],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Windows": []
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Windows": []
                                                },
                                                "id": "92616450-ee69-40a5-3022-08daecbd7d11",
                                                "checkDefinitionId": "e41906dc-7cab-48db-8622-45e4422a9913",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 100,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 52
                                    },
                                    {
                                        "id": "40c5cad2-69a5-4d8a-6892-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:29:10.838106",
                                        "runCompletionDate": "2023-01-03T06:29:39.4503928",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "e8d59072-4876-4daa-3023-08daecbd7d11",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "2ee96b4d-3ae1-4a89-6893-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:29:12.4311055",
                                        "runCompletionDate": "2023-01-03T06:29:41.9469314",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 0,
                                                "failedElements": 0,
                                                "passedElements": 0,
                                                "failedElementIds": [],
                                                "passedElementIds": [],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Windows": []
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Windows": []
                                                },
                                                "id": "e77eb5d3-cadd-4fb9-3024-08daecbd7d11",
                                                "checkDefinitionId": "d652f9c3-6343-4564-ab10-12dbaaba0b62",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 100,
                                                "checkType": "simplePropertyCheck"
                                            },
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "e138189b-9c25-41d6-3025-08daecbd7d11",
                                                "checkDefinitionId": "9084290c-de74-4b3b-816f-41187b09011a",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 52
                                    },
                                    {
                                        "id": "5ed1607e-e480-4807-6894-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:37:35.5627991",
                                        "runCompletionDate": "2023-01-03T06:38:05.4161785",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ]
                                                },
                                                "id": "537ab3ee-7d70-4d7e-3026-08daecbd7d11",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "69231d00-b1ed-4d96-6895-08daecbd69b7",
                                        "runRequestDate": "2023-01-03T06:37:37.5686236",
                                        "runCompletionDate": "2023-01-03T06:38:10.7793256",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 43,
                                                "failedElements": 35,
                                                "passedElements": 8,
                                                "failedElementIds": [
                                                    "580450",
                                                    "580461",
                                                    "580472",
                                                    "580483",
                                                    "580494",
                                                    "580505",
                                                    "580516",
                                                    "580527",
                                                    "580538",
                                                    "580556",
                                                    "580582",
                                                    "580593",
                                                    "580604",
                                                    "580619",
                                                    "580634",
                                                    "580645",
                                                    "580656",
                                                    "580667",
                                                    "580678",
                                                    "580689",
                                                    "580700",
                                                    "580711",
                                                    "580723",
                                                    "580730",
                                                    "580741",
                                                    "580767",
                                                    "580778",
                                                    "580789",
                                                    "580822",
                                                    "580829",
                                                    "580844",
                                                    "580853",
                                                    "580860",
                                                    "580871",
                                                    "580882"
                                                ],
                                                "passedElementIds": [
                                                    "579747",
                                                    "580427",
                                                    "580450",
                                                    "580461",
                                                    "580472",
                                                    "580483",
                                                    "580494",
                                                    "580505",
                                                    "580516",
                                                    "580527",
                                                    "580538",
                                                    "580545",
                                                    "580556",
                                                    "580567",
                                                    "580582",
                                                    "580593",
                                                    "580604",
                                                    "580619",
                                                    "580634",
                                                    "580645",
                                                    "580656",
                                                    "580667",
                                                    "580678",
                                                    "580689",
                                                    "580700",
                                                    "580711",
                                                    "580723",
                                                    "580730",
                                                    "580741",
                                                    "580748",
                                                    "580767",
                                                    "580778",
                                                    "580789",
                                                    "580800",
                                                    "580811",
                                                    "580822",
                                                    "580829",
                                                    "580844",
                                                    "580853",
                                                    "580860",
                                                    "580871",
                                                    "580882",
                                                    "580889"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Ceilings": [
                                                        "579747",
                                                        "580427",
                                                        "580450",
                                                        "580461",
                                                        "580472",
                                                        "580483",
                                                        "580494",
                                                        "580505",
                                                        "580516",
                                                        "580527",
                                                        "580538",
                                                        "580545",
                                                        "580556",
                                                        "580567",
                                                        "580582",
                                                        "580593",
                                                        "580604",
                                                        "580619",
                                                        "580634",
                                                        "580645",
                                                        "580656",
                                                        "580667",
                                                        "580678",
                                                        "580689",
                                                        "580700",
                                                        "580711",
                                                        "580723",
                                                        "580730",
                                                        "580741",
                                                        "580748",
                                                        "580767",
                                                        "580778",
                                                        "580789",
                                                        "580800",
                                                        "580811",
                                                        "580822",
                                                        "580829",
                                                        "580844",
                                                        "580853",
                                                        "580860",
                                                        "580871",
                                                        "580882",
                                                        "580889"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Ceilings": [
                                                        "580450",
                                                        "580461",
                                                        "580472",
                                                        "580483",
                                                        "580494",
                                                        "580505",
                                                        "580516",
                                                        "580527",
                                                        "580538",
                                                        "580556",
                                                        "580582",
                                                        "580593",
                                                        "580604",
                                                        "580619",
                                                        "580634",
                                                        "580645",
                                                        "580656",
                                                        "580667",
                                                        "580678",
                                                        "580689",
                                                        "580700",
                                                        "580711",
                                                        "580723",
                                                        "580730",
                                                        "580741",
                                                        "580767",
                                                        "580778",
                                                        "580789",
                                                        "580822",
                                                        "580829",
                                                        "580844",
                                                        "580853",
                                                        "580860",
                                                        "580871",
                                                        "580882"
                                                    ]
                                                },
                                                "id": "3e3a7906-7f4d-4187-3027-08daecbd7d11",
                                                "checkDefinitionId": "610ad3e0-d2a8-4afa-9425-8436368bc54a",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 19,
                                                "checkType": "simplePropertyCheck"
                                            },
                                            {
                                                "elementsInScope": 167,
                                                "failedElements": 136,
                                                "passedElements": 31,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844",
                                                    "580450",
                                                    "580461",
                                                    "580472",
                                                    "580483",
                                                    "580494",
                                                    "580505",
                                                    "580582",
                                                    "580593",
                                                    "580604",
                                                    "580619",
                                                    "580829",
                                                    "580844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844",
                                                    "579747",
                                                    "580427",
                                                    "580450",
                                                    "580461",
                                                    "580472",
                                                    "580483",
                                                    "580494",
                                                    "580505",
                                                    "580516",
                                                    "580527",
                                                    "580538",
                                                    "580545",
                                                    "580556",
                                                    "580567",
                                                    "580582",
                                                    "580593",
                                                    "580604",
                                                    "580619",
                                                    "580634",
                                                    "580645",
                                                    "580656",
                                                    "580667",
                                                    "580678",
                                                    "580689",
                                                    "580700",
                                                    "580711",
                                                    "580723",
                                                    "580730",
                                                    "580741",
                                                    "580748",
                                                    "580767",
                                                    "580778",
                                                    "580789",
                                                    "580800",
                                                    "580811",
                                                    "580822",
                                                    "580829",
                                                    "580844",
                                                    "580853",
                                                    "580860",
                                                    "580871",
                                                    "580882",
                                                    "580889"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ],
                                                    "OST_Ceilings": [
                                                        "579747",
                                                        "580427",
                                                        "580450",
                                                        "580461",
                                                        "580472",
                                                        "580483",
                                                        "580494",
                                                        "580505",
                                                        "580516",
                                                        "580527",
                                                        "580538",
                                                        "580545",
                                                        "580556",
                                                        "580567",
                                                        "580582",
                                                        "580593",
                                                        "580604",
                                                        "580619",
                                                        "580634",
                                                        "580645",
                                                        "580656",
                                                        "580667",
                                                        "580678",
                                                        "580689",
                                                        "580700",
                                                        "580711",
                                                        "580723",
                                                        "580730",
                                                        "580741",
                                                        "580748",
                                                        "580767",
                                                        "580778",
                                                        "580789",
                                                        "580800",
                                                        "580811",
                                                        "580822",
                                                        "580829",
                                                        "580844",
                                                        "580853",
                                                        "580860",
                                                        "580871",
                                                        "580882",
                                                        "580889"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ],
                                                    "OST_Ceilings": [
                                                        "580450",
                                                        "580461",
                                                        "580472",
                                                        "580483",
                                                        "580494",
                                                        "580505",
                                                        "580582",
                                                        "580593",
                                                        "580604",
                                                        "580619",
                                                        "580829",
                                                        "580844"
                                                    ]
                                                },
                                                "id": "780b8c92-47a2-46ba-3028-08daecbd7d11",
                                                "checkDefinitionId": "f8bb739f-fa76-4da0-8f40-50e68e6f0ecf",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 19,
                                                "checkType": "simplePropertyCheck"
                                            },
                                            {
                                                "elementsInScope": 167,
                                                "failedElements": 118,
                                                "passedElements": 49,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573615",
                                                    "573637",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573681",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "575897",
                                                    "575953",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577416",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844",
                                                    "579747",
                                                    "580427",
                                                    "580450",
                                                    "580461",
                                                    "580472",
                                                    "580483",
                                                    "580494",
                                                    "580505",
                                                    "580516",
                                                    "580527",
                                                    "580538",
                                                    "580545",
                                                    "580556",
                                                    "580567",
                                                    "580582",
                                                    "580593",
                                                    "580604",
                                                    "580619",
                                                    "580634",
                                                    "580645",
                                                    "580656",
                                                    "580667",
                                                    "580678",
                                                    "580689",
                                                    "580700",
                                                    "580711",
                                                    "580723",
                                                    "580730",
                                                    "580741",
                                                    "580748",
                                                    "580767",
                                                    "580778",
                                                    "580789",
                                                    "580800",
                                                    "580811",
                                                    "580822",
                                                    "580829",
                                                    "580844",
                                                    "580853",
                                                    "580860",
                                                    "580871",
                                                    "580882",
                                                    "580889"
                                                ],
                                                "message": "Check executed ok",
                                                "version": "V2",
                                                "elementsInScopePerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573615",
                                                        "573637",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573681",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "575897",
                                                        "575953",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577416",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ],
                                                    "OST_Ceilings": [
                                                        "579747",
                                                        "580427",
                                                        "580450",
                                                        "580461",
                                                        "580472",
                                                        "580483",
                                                        "580494",
                                                        "580505",
                                                        "580516",
                                                        "580527",
                                                        "580538",
                                                        "580545",
                                                        "580556",
                                                        "580567",
                                                        "580582",
                                                        "580593",
                                                        "580604",
                                                        "580619",
                                                        "580634",
                                                        "580645",
                                                        "580656",
                                                        "580667",
                                                        "580678",
                                                        "580689",
                                                        "580700",
                                                        "580711",
                                                        "580723",
                                                        "580730",
                                                        "580741",
                                                        "580748",
                                                        "580767",
                                                        "580778",
                                                        "580789",
                                                        "580800",
                                                        "580811",
                                                        "580822",
                                                        "580829",
                                                        "580844",
                                                        "580853",
                                                        "580860",
                                                        "580871",
                                                        "580882",
                                                        "580889"
                                                    ]
                                                },
                                                "failedElementsPerCategory": {
                                                    "OST_Doors": [
                                                        "573577",
                                                        "573578",
                                                        "573579",
                                                        "573580",
                                                        "573583",
                                                        "573584",
                                                        "573586",
                                                        "573587",
                                                        "573668",
                                                        "573669",
                                                        "573670",
                                                        "573671",
                                                        "573672",
                                                        "573673",
                                                        "573674",
                                                        "573675",
                                                        "573676",
                                                        "573677",
                                                        "573692",
                                                        "573693",
                                                        "573694",
                                                        "573695",
                                                        "573698",
                                                        "573699",
                                                        "573701",
                                                        "573702",
                                                        "573705",
                                                        "573708",
                                                        "573709",
                                                        "573711",
                                                        "573712",
                                                        "573721",
                                                        "573722",
                                                        "573723",
                                                        "573724",
                                                        "573725",
                                                        "573726",
                                                        "573747",
                                                        "573748",
                                                        "573749",
                                                        "573750",
                                                        "573757",
                                                        "573758",
                                                        "573759",
                                                        "573760",
                                                        "573761",
                                                        "573762",
                                                        "573783",
                                                        "573784",
                                                        "575084",
                                                        "575087",
                                                        "575088",
                                                        "575089",
                                                        "575090",
                                                        "575091",
                                                        "575092",
                                                        "575093",
                                                        "575094",
                                                        "575095",
                                                        "575096",
                                                        "575097",
                                                        "575098",
                                                        "575099",
                                                        "575420",
                                                        "575421",
                                                        "575422",
                                                        "575423",
                                                        "575424",
                                                        "575425",
                                                        "575426",
                                                        "575427",
                                                        "575884",
                                                        "575886",
                                                        "575887",
                                                        "575888",
                                                        "575891",
                                                        "576023",
                                                        "576024",
                                                        "576025",
                                                        "576026",
                                                        "576027",
                                                        "576045",
                                                        "576046",
                                                        "576047",
                                                        "576048",
                                                        "576049",
                                                        "576050",
                                                        "576051",
                                                        "576052",
                                                        "576053",
                                                        "576667",
                                                        "576692",
                                                        "576717",
                                                        "576742",
                                                        "576766",
                                                        "576790",
                                                        "576814",
                                                        "576838",
                                                        "576862",
                                                        "577822",
                                                        "577823",
                                                        "577824",
                                                        "577861",
                                                        "577953",
                                                        "577954",
                                                        "578641",
                                                        "578642",
                                                        "578643",
                                                        "578644",
                                                        "578646",
                                                        "578647",
                                                        "579164",
                                                        "579165",
                                                        "579166",
                                                        "579167",
                                                        "579168",
                                                        "580927",
                                                        "627844"
                                                    ],
                                                    "OST_Ceilings": []
                                                },
                                                "id": "a7ad5140-2d57-4f29-3029-08daecbd7d11",
                                                "checkDefinitionId": "fc398b69-650f-4ba4-ac45-ec6932eebd57",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 29,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 22
                                    },
                                    {
                                        "id": "6a5cbb34-9c23-4750-04be-08db4c9e5008",
                                        "runRequestDate": "2023-05-10T07:25:53.8562918",
                                        "runCompletionDate": "2023-05-10T07:25:53.9984253",
                                        "checkResults": [
                                            {
                                                "message": "Too Big",
                                                "id": "37c36e98-efdd-4e1a-9130-4474f46361be",
                                                "checkDefinitionId": "6a203cbb-f1f9-420f-970c-4dac4127a3fa",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 0,
                                                "checkType": "sizeCheck"
                                            },
                                            {
                                                "message": "Too Big",
                                                "id": "defcff3f-baf1-474b-beff-64d3c29c90ef",
                                                "checkDefinitionId": "6ee17f35-c2c8-4117-98d8-10f8f6aba7a1",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 0,
                                                "checkType": "sizeCheck"
                                            },
                                            {
                                                "message": "Too Big",
                                                "id": "741b3d67-7552-46f9-82ab-90d87c6f1982",
                                                "checkDefinitionId": "fc9dff56-6d87-4ae3-9956-1af7e8702834",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 0,
                                                "checkType": "sizeCheck"
                                            },
                                            {
                                                "message": "Too Big",
                                                "id": "438b2bee-3975-4d71-b59f-b2d54e3b0968",
                                                "checkDefinitionId": "00696c77-3767-41b4-bc48-84961b5787bc",
                                                "documentId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                                                "percentage": 0,
                                                "checkType": "sizeCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 0
                                    }
                                ]
                            }
                        ],
                        "documentVersionId": "fb05430d-887c-4bd2-7777-08dad6c53f95",
                        "dataFolderId": "1e66c4f9-3379-4a33-314e-08dad6c6f0f3",
                        "checksets": null,
                        "documentMeta": null
                    }
                ],
                [
                    {
                        "id": "6da806c4-0094-4b68-4b82-08dad6bdd4c1",
                        "externalId": "urn:adsk.wipprod:dm.lineage:eNL5EEzWRGKJ8vLLpuQirg",
                        "fileName": "Revit Area Test.rvt",
                        "autoTrackChanges": true,
                        "dataSource": "bim360",
                        "creationDate": "2022-12-05T12:40:03.2356798",
                        "fileType": "rvt",
                        "documentVersions": [
                            {
                                "revitVersion": 2022,
                                "latestAvailableVersionLabel": "V1",
                                "latestAvailableVersionNumber": 1,
                                "versionUrn": "urn:adsk.wipprod:fs.file:vf.eNL5EEzWRGKJ8vLLpuQirg?version=1",
                                "objectId": "https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/bf5b56b7-39cb-440e-b0a8-c5f68735140d.rvt",
                                "viewerDerivativeUrn": "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLmVOTDVFRXpXUkdLSjh2TExwdVFpcmc_dmVyc2lvbj0x",
                                "id": "d000b742-861c-4c27-1177-08dad6bdd4c3",
                                "versionLabel": "V1",
                                "versionNumber": 1,
                                "versionDate": "2022-12-05T12:40:03.2354904",
                                "filesize": 6287360,
                                "parentDocument": null,
                                "checksetResults": [
                                    {
                                        "id": "9242d764-022e-4a5a-85b9-08dad6bdd98d",
                                        "runRequestDate": "2022-12-05T12:40:11.3164885",
                                        "runCompletionDate": "2022-12-05T12:40:40.3192947",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 0,
                                                "failedElements": 0,
                                                "passedElements": 0,
                                                "failedElementIds": [],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "4094cb5c-47fd-440a-7ebd-08dad6bde921",
                                                "checkDefinitionId": "22cf41df-489f-4fe0-8bf2-a73ec53e6acc",
                                                "documentId": "d000b742-861c-4c27-1177-08dad6bdd4c3",
                                                "percentage": 100,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 100
                                    },
                                    {
                                        "id": "a210ba54-1981-4510-abee-08dad753a763",
                                        "runRequestDate": "2022-12-15T11:46:46.641967",
                                        "runCompletionDate": "2022-12-15T11:47:13.6581402",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 0,
                                                "failedElements": 0,
                                                "passedElements": 0,
                                                "failedElementIds": [],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "7b43fe7e-e0c3-4537-043b-08dad753a9d5",
                                                "checkDefinitionId": "ab97e5c2-82f1-418e-a138-8ee87ec5fed5",
                                                "documentId": "d000b742-861c-4c27-1177-08dad6bdd4c3",
                                                "percentage": 100,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 100
                                    },
                                    {
                                        "id": "a5bc0376-a62c-49c4-abef-08dad753a763",
                                        "runRequestDate": "2022-12-15T11:46:59.3927574",
                                        "runCompletionDate": "2022-12-15T11:47:25.2027807",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 0,
                                                "failedElements": 0,
                                                "passedElements": 0,
                                                "failedElementIds": [],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "3f60e624-6e24-453e-043c-08dad753a9d5",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "d000b742-861c-4c27-1177-08dad6bdd4c3",
                                                "percentage": 100,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 100
                                    },
                                    {
                                        "id": "cb1d0ea6-3783-4807-1f09-08daecae50a5",
                                        "runRequestDate": "2023-01-02T10:57:52.6256954",
                                        "runCompletionDate": "2023-01-02T10:57:52.6662035",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "a7f050b7-e57d-4484-4128-08daecae50d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "e0415a8b-725d-4a7d-1f0a-08daecae50a5",
                                        "runRequestDate": "2023-01-02T10:57:52.9733789",
                                        "runCompletionDate": "2023-01-02T10:57:53.0700192",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "582d1df5-17f1-475a-4129-08daecae50d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    }
                                ]
                            }
                        ],
                        "documentVersionId": "d000b742-861c-4c27-1177-08dad6bdd4c3",
                        "dataFolderId": "44e746a4-f534-41ff-6232-08dab598cdb8",
                        "checksets": null,
                        "documentMeta": null
                    },
                    {
                        "id": "b759cc41-57bd-4c56-4b83-08dad6bdd4c1",
                        "externalId": "urn:adsk.wipprod:dm.lineage:QLDC8K0FSC2DEF7A7X6AZw",
                        "fileName": "rme_basic_sample_project_2022.rvt",
                        "autoTrackChanges": true,
                        "dataSource": "bim360",
                        "creationDate": "2022-12-05T12:54:37.9562381",
                        "fileType": "rvt",
                        "documentVersions": [
                            {
                                "revitVersion": 2022,
                                "latestAvailableVersionLabel": "V1",
                                "latestAvailableVersionNumber": 1,
                                "versionUrn": "urn:adsk.wipprod:fs.file:vf.QLDC8K0FSC2DEF7A7X6AZw?version=1",
                                "objectId": "https://developer.api.autodesk.com/oss/v2/buckets/wip.dm.prod/objects/d40b67a0-8cdd-44e0-ad3f-d675bddcae06.rvt",
                                "viewerDerivativeUrn": "dXJuOmFkc2sud2lwcHJvZDpmcy5maWxlOnZmLlFMREM4SzBGU0MyREVGN0E3WDZBWnc_dmVyc2lvbj0x",
                                "id": "3682287a-4768-4fd8-1178-08dad6bdd4c3",
                                "versionLabel": "V1",
                                "versionNumber": 1,
                                "versionDate": "2022-12-05T12:54:37.9562042",
                                "filesize": 30482432,
                                "parentDocument": null,
                                "checksetResults": [
                                    {
                                        "id": "99477ced-4c15-4170-abdc-08dad753a763",
                                        "runRequestDate": "2022-12-06T06:32:31.6812796",
                                        "runCompletionDate": "2022-12-06T06:33:14.9381027",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "9e821066-ff58-4e89-0412-08dad753a9d5",
                                                "checkDefinitionId": "22cf41df-489f-4fe0-8bf2-a73ec53e6acc",
                                                "documentId": "3682287a-4768-4fd8-1178-08dad6bdd4c3",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "33e7df76-b21f-4852-abdd-08dad753a763",
                                        "runRequestDate": "2022-12-06T06:32:34.409783",
                                        "runCompletionDate": "2022-12-06T06:32:35.8932095",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "a403654e-a4f1-4dab-040f-08dad753a9d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "0ac8486f-3471-4150-abde-08dad753a763",
                                        "runRequestDate": "2022-12-06T06:32:36.301419",
                                        "runCompletionDate": "2022-12-06T06:32:37.3185509",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "5a1ab058-d0e6-4130-0410-08dad753a9d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "c13c2842-e784-45cf-abdf-08dad753a763",
                                        "runRequestDate": "2022-12-06T06:32:37.4433972",
                                        "runCompletionDate": "2022-12-06T06:33:09.1199351",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "1ee80353-7bac-43ce-0411-08dad753a9d5",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "3682287a-4768-4fd8-1178-08dad6bdd4c3",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "56a4d7e8-cee8-4f75-abf0-08dad753a763",
                                        "runRequestDate": "2022-12-15T11:48:17.8729638",
                                        "runCompletionDate": "2022-12-15T11:48:48.1224174",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "bb1b623f-c269-4ec0-043d-08dad753a9d5",
                                                "checkDefinitionId": "6977ca6d-b0fd-462b-b4ea-3e1cd1a274ad",
                                                "documentId": "3682287a-4768-4fd8-1178-08dad6bdd4c3",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "dd6b07f6-1122-43f4-abf1-08dad753a763",
                                        "runRequestDate": "2022-12-15T11:48:19.7388123",
                                        "runCompletionDate": "2022-12-15T11:48:49.3525151",
                                        "checkResults": [
                                            {
                                                "elementsInScope": 124,
                                                "failedElements": 118,
                                                "passedElements": 6,
                                                "failedElementIds": [
                                                    "573577",
                                                    "573578",
                                                    "573579",
                                                    "573580",
                                                    "573583",
                                                    "573584",
                                                    "573586",
                                                    "573587",
                                                    "573668",
                                                    "573669",
                                                    "573670",
                                                    "573671",
                                                    "573672",
                                                    "573673",
                                                    "573674",
                                                    "573675",
                                                    "573676",
                                                    "573677",
                                                    "573692",
                                                    "573693",
                                                    "573694",
                                                    "573695",
                                                    "573698",
                                                    "573699",
                                                    "573701",
                                                    "573702",
                                                    "573705",
                                                    "573708",
                                                    "573709",
                                                    "573711",
                                                    "573712",
                                                    "573721",
                                                    "573722",
                                                    "573723",
                                                    "573724",
                                                    "573725",
                                                    "573726",
                                                    "573747",
                                                    "573748",
                                                    "573749",
                                                    "573750",
                                                    "573757",
                                                    "573758",
                                                    "573759",
                                                    "573760",
                                                    "573761",
                                                    "573762",
                                                    "573783",
                                                    "573784",
                                                    "575084",
                                                    "575087",
                                                    "575088",
                                                    "575089",
                                                    "575090",
                                                    "575091",
                                                    "575092",
                                                    "575093",
                                                    "575094",
                                                    "575095",
                                                    "575096",
                                                    "575097",
                                                    "575098",
                                                    "575099",
                                                    "575420",
                                                    "575421",
                                                    "575422",
                                                    "575423",
                                                    "575424",
                                                    "575425",
                                                    "575426",
                                                    "575427",
                                                    "575884",
                                                    "575886",
                                                    "575887",
                                                    "575888",
                                                    "575891",
                                                    "576023",
                                                    "576024",
                                                    "576025",
                                                    "576026",
                                                    "576027",
                                                    "576045",
                                                    "576046",
                                                    "576047",
                                                    "576048",
                                                    "576049",
                                                    "576050",
                                                    "576051",
                                                    "576052",
                                                    "576053",
                                                    "576667",
                                                    "576692",
                                                    "576717",
                                                    "576742",
                                                    "576766",
                                                    "576790",
                                                    "576814",
                                                    "576838",
                                                    "576862",
                                                    "577822",
                                                    "577823",
                                                    "577824",
                                                    "577861",
                                                    "577953",
                                                    "577954",
                                                    "578641",
                                                    "578642",
                                                    "578643",
                                                    "578644",
                                                    "578646",
                                                    "578647",
                                                    "579164",
                                                    "579165",
                                                    "579166",
                                                    "579167",
                                                    "579168",
                                                    "580927",
                                                    "627844"
                                                ],
                                                "passedElementIds": null,
                                                "message": "Check executed ok",
                                                "version": "V1",
                                                "elementsInScopePerCategory": null,
                                                "failedElementsPerCategory": null,
                                                "id": "53fe67d3-8d3e-44d5-043e-08dad753a9d5",
                                                "checkDefinitionId": "ab97e5c2-82f1-418e-a138-8ee87ec5fed5",
                                                "documentId": "3682287a-4768-4fd8-1178-08dad6bdd4c3",
                                                "percentage": 5,
                                                "checkType": "simplePropertyCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "done",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": 5
                                    },
                                    {
                                        "id": "da722270-53b4-4b56-1f05-08daecae50a5",
                                        "runRequestDate": "2023-01-02T10:44:24.7590899",
                                        "runCompletionDate": "2023-01-02T10:44:25.1833239",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "06540efd-d182-4e0b-4124-08daecae50d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "1576a02a-4e2c-4e8c-1f06-08daecae50a5",
                                        "runRequestDate": "2023-01-02T10:44:25.3570078",
                                        "runCompletionDate": "2023-01-02T10:44:25.3879538",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "379ffa65-56bd-41ed-4125-08daecae50d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "542dd0f0-b056-4bdb-1f07-08daecae50a5",
                                        "runRequestDate": "2023-01-02T10:54:38.5647161",
                                        "runCompletionDate": "2023-01-02T10:54:38.6249249",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "ba016483-4f27-4529-4126-08daecae50d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "5b147fbb-5ef3-4caf-1f08-08daecae50a5",
                                        "runRequestDate": "2023-01-02T10:54:38.8103045",
                                        "runCompletionDate": "2023-01-02T10:54:38.8562896",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Check not evaluated, due to processing errors",
                                                "id": "21a2a4d6-ff92-41b0-4127-08daecae50d5",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "334d3cc5-7967-4f82-ffbe-08daecb0568e",
                                        "runRequestDate": "2023-01-02T10:58:53.6750475",
                                        "runCompletionDate": "2023-01-02T10:58:54.6350402",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "d621fcb6-561c-4047-b462-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "d9fa2200-916f-426a-ffbf-08daecb0568e",
                                        "runRequestDate": "2023-01-02T10:58:55.4151443",
                                        "runCompletionDate": "2023-01-02T10:58:55.5020719",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "d1cdc677-346e-4efe-b463-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "6af735f0-7ba4-42fe-ffc4-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:05:48.8407928",
                                        "runCompletionDate": "2023-01-02T11:05:48.8714167",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "af1eb84a-eb15-4c74-b46b-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "df6b3213-cf8f-496c-ffc5-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:05:49.048625",
                                        "runCompletionDate": "2023-01-02T11:05:49.0978065",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "8862bafa-d4a4-4749-b46c-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "791f912a-9445-42a5-ffc8-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:11:25.8881748",
                                        "runCompletionDate": "2023-01-02T11:11:25.9178592",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "5ab941d7-122a-4e5e-b475-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "35607fe7-8d32-4ada-ffc9-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:11:26.0836039",
                                        "runCompletionDate": "2023-01-02T11:11:26.1180001",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "74d9a199-95a9-409e-b476-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "0d431e1e-5fe0-4173-ffca-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:22:21.5674844",
                                        "runCompletionDate": "2023-01-02T11:22:21.6137655",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "8c4f55c9-86fc-4fa6-b477-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "1c6d1368-e3f2-412a-ffcb-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:22:21.7857095",
                                        "runCompletionDate": "2023-01-02T11:22:21.8096585",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "370f7b6a-deca-458c-b478-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "8e84737d-c951-42bb-ffcc-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:22:24.787988",
                                        "runCompletionDate": "2023-01-02T11:22:24.8111852",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "767aa35f-5606-4eef-b479-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "13d3ca8c-5c6e-43ea-ffcd-08daecb0568e",
                                        "runRequestDate": "2023-01-02T11:22:25.0253743",
                                        "runCompletionDate": "2023-01-02T11:22:25.0505697",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.",
                                                "id": "73a52312-ceec-47d2-b47a-08daecb05707",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "f5a4178e-3b57-43a8-a4c2-08daecb432f4",
                                        "runRequestDate": "2023-01-02T11:26:31.9322642",
                                        "runCompletionDate": "2023-01-02T11:26:32.533362",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.\n   at DStudio.BIMCradle.HealthCheck.API.Services.CheckAutomation.DesignAutomationCheckRunner.RunCheck(Guid checksetId, Guid userID, Guid datafolder, List`1 documentId) in D:\\TFS\\Cradle\\HealthCheck_Api\\DStudio.BIMCradle.HealthCheck.API\\Services\\CheckAutomation\\DesignAutomationCheckRunner.cs:line 159",
                                                "id": "38031184-a2cc-4e90-a9f7-08daecb43339",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    },
                                    {
                                        "id": "faae8a3c-b0cb-4935-a4c3-08daecb432f4",
                                        "runRequestDate": "2023-01-02T11:26:32.7003876",
                                        "runCompletionDate": "2023-01-02T11:26:32.7916578",
                                        "checkResults": [
                                            {
                                                "percentage": -1,
                                                "info": "Object reference not set to an instance of an object.\n   at DStudio.BIMCradle.HealthCheck.API.Services.CheckAutomation.DesignAutomationCheckRunner.RunCheck(Guid checksetId, Guid userID, Guid datafolder, List`1 documentId) in D:\\TFS\\Cradle\\HealthCheck_Api\\DStudio.BIMCradle.HealthCheck.API\\Services\\CheckAutomation\\DesignAutomationCheckRunner.cs:line 159",
                                                "id": "17cf7119-4dfb-41eb-a9f8-08daecb43339",
                                                "checkDefinitionId": "00000000-0000-0000-0000-000000000000",
                                                "documentId": "00000000-0000-0000-0000-000000000000",
                                                "checkType": "failedCheck"
                                            }
                                        ],
                                        "userId": "8c34f798-100a-447c-bec1-d25449f1046a",
                                        "status": "error",
                                        "document": null,
                                        "checkset": null,
                                        "percentage": -1
                                    }
                                ]
                            }
                        ],
                        "documentVersionId": "3682287a-4768-4fd8-1178-08dad6bdd4c3",
                        "dataFolderId": "44e746a4-f534-41ff-6232-08dab598cdb8",
                        "checksets": null,
                        "documentMeta": null
                    }
                ]
            ]
        );
    });
}