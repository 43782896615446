import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ChangeEvent, FormEvent, useContext, useState} from 'react';
import {SxProps, Theme} from '@mui/material';
import FontAwesomeSvgIcon from '../FontAwesomeSvgIcon';
import {faPlus} from '@fortawesome/pro-duotone-svg-icons';
import {HealthCheckDocumentsContext} from '../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider';
import {addDataFolder} from '../../Context/HealthCheckDocumentsContext/Reducer';
import {addFolder, createRootFolder} from '../../Connectors/dataFolders';
import LoadingIndicator from '../LoadingIndicator';
import {DataFolder} from "../../Connectors/_Models";

interface CreateFolderProps {
    sx?: SxProps<Theme>;
}

const CreateFolder = ({sx}: CreateFolderProps) => {
    const [open, setOpen] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [loading, setLoading] = useState(false);

    const {state, dispatch} = useContext(HealthCheckDocumentsContext);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFolderName("");
    };
    const handleCreate = async () => {
        if (!state?.activeDataFolder?.id) return;
        try {
            setLoading(true);
            let newFolder: DataFolder | null = null;
            if (state.activeDataFolder.id === "root") {
                newFolder = await createRootFolder({
                    name: folderName
                });
            } else {
                newFolder = await addFolder(state.activeDataFolder.id, {
                    name: folderName
                });
            }

            if (newFolder) {
                dispatch(addDataFolder({
                    id: newFolder.id,
                    name: folderName,
                    children: [],
                    documents: [],

                }));
            }
            setLoading(false);
        } catch (e) {
            //TODO show failed notification
            setLoading(false);

        }
        handleClose();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFolderName(event.currentTarget.value);
    };
    return (
        <>
            <Button onClick={handleClickOpen} sx={sx}
                    variant="contained"

                    startIcon={<FontAwesomeSvgIcon icon={faPlus}/>}
            >
                New folder
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>New folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        variant="standard"
                        value={folderName}
                        onChange={(e) => handleChange(e)}
                    />
                    {loading ? <LoadingIndicator/> : <></>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleCreate}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default CreateFolder;