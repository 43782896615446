import { Drawer } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import { SIDEBAR_WIDTH } from "../../Constants/Interface";
import { GlobalContext } from "../../Context/GlobalContext/GlobalContextProvider";
import { collapseSidebar } from "../../Context/GlobalContext/Reducer";
import themesConfig from "../../Themes/ThemesConfig";
import NavigationDrawerContent from "./NavigationDrawerContent";

const NavigationDrawer = () => {
    const { state: globalState, dispatch: globalDispatch } = useContext(GlobalContext);
    const [open, setOpen] = useState(!globalState.sideBarCollapsed);

    const container = window !== undefined ? () => window.document.body : undefined;

    // TODO: Setup Themese in Themes folder and use a reference to the created theme directly instead of creating another theme here.
    const darkTheme = createTheme(themesConfig.defaultDark);

    useEffect(() => {
        setOpen(!globalState.sideBarCollapsed);
    }, [globalState.sideBarCollapsed]);

    const onCloseDrawer = () => {
        globalDispatch(collapseSidebar());
    };

    return (
        <ThemeProvider theme={darkTheme}>
            {/* Drawer that is rendered on mobile */}
            <Drawer
                container={container}
                variant="temporary"
                open={open}
                onClose={onCloseDrawer}
                ModalProps={{ keepMounted: true }}
                sx={{
                    display: {
                        xs: "block",
                        sm: "none",
                    },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "100%",
                    },
                }}
            >
                <NavigationDrawerContent />
            </Drawer>

            {/* Default drawer that is rendered on desktop */}
            <Drawer
                variant="persistent"
                anchor="left"
                open={open}
                sx={{
                    display: {
                        xs: "none",
                        sm: "block",
                        width: SIDEBAR_WIDTH,
                    },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: SIDEBAR_WIDTH,
                        overflow: "hidden",
                    },
                }}
            >
                <NavigationDrawerContent />
            </Drawer>
        </ThemeProvider>
    );
};

export default NavigationDrawer;
