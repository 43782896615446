import { EnvConfiguration } from "./AppConfiguration";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const staging: EnvConfiguration = {
    api: "https://app-health-check-api-stg.azurewebsites.net/",
    msal: {
        auth: {
            clientId: "c32b726a-4b02-4507-8550-9fd9405e24df",
            authority: "https://bedstudiodevb2c.b2clogin.com/bedstudiodevb2c.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN",
            knownAuthorities: ["bedstudiodevb2c.b2clogin.com"],
            redirectUri: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: isIE || isEdge || isFirefox,
        },
    },
    sentry: {
        dsn: "https://dc4993e152aa427c9f45e0530446f199@o399431.ingest.sentry.io/6617019",
        env: "staging",
        sampleRate: 1.0,
    }
};
