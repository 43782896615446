import { DataFolderDocument, DocumentChecksetResult, DocumentChecksetResultChecks } from "../../Connectors/_Models/DataFolderDocument";

export enum ActionType {
    OpenChecksetDrawerForDocument,
    CloseChecksetResult,
    SetChecksetResultChecks,
    ClearChecksetResultChecks,
    ClearSelectedObjects,
    AddSelectedObjects,
    RemoveSelectedObjects,
    LoadChecksetResult,
    LoadChecksetElements,
    ToggleHighlightFailedElements
}

export interface LoadChecksetResult {
    type: ActionType.LoadChecksetResult;
    payload: { document: DataFolderDocument };
}

export interface OpenChecksetDrawerForDocument {
    type: ActionType.OpenChecksetDrawerForDocument;
    payload: { document: DataFolderDocument };
}

export interface CloseChecksetResult {
    type: ActionType.CloseChecksetResult;
}

export interface SetChecksetResultChecks {
    type: ActionType.SetChecksetResultChecks;
    payload: { checks: DocumentChecksetResultChecks[] };
}

export interface ClearChecksetResultChecks {
    type: ActionType.ClearChecksetResultChecks;
}

export interface ClearSelectedObjects {
    type: ActionType.ClearSelectedObjects;
}
export interface RemoveSelectedObjects {
    type: ActionType.RemoveSelectedObjects;
    payload: { objects: string[] };
}
export interface AddSelectedObjects {
    type: ActionType.AddSelectedObjects;
    payload: { objects: string[],color:string };
}

export interface LoadChecksetElements {
    type: ActionType.LoadChecksetElements;
    payload: { objects: string[],failedElements: string[] };
}
export interface ToggleHighlightFailedElements {
    type: ActionType.ToggleHighlightFailedElements;
    payload: { };
}

export type ChecksetResultActions = LoadChecksetResult | OpenChecksetDrawerForDocument | CloseChecksetResult | SetChecksetResultChecks | ClearChecksetResultChecks | ClearSelectedObjects | RemoveSelectedObjects | AddSelectedObjects | LoadChecksetElements | ToggleHighlightFailedElements;


