import {ModelViewerProps, ModelViewerType} from "./ModelViewerProperties";
import ForgeViewer from "./ForgeViewer";
import IfcViewer from "./IfcViewer/IfcViewer";


const ModelViewer = (params: ModelViewerProps) => {
    switch (params.type) {
        case  ModelViewerType.Forge:
            return <ForgeViewer {...params}/>;
        case  ModelViewerType.Ifc:
            return <IfcViewer {...params}/>;
        default:
            return <></>;
    }
}


export default ModelViewer;