import { ListItem, ListItemText } from "@mui/material";
import { styled } from "@mui/system";
import { NavigationGroup, NavigationItem } from "./Navigation";
import SidebarItem from "./SidebarItem";

const ListHeaderItem = styled(ListItem)(() => ({
    marginTop: "30px",
    "&:first-of-type": {
        marginTop: 0,
    },
}));

interface SidebarGroupProps {
    group: NavigationGroup;
    renderGroup: boolean;
}

const renderItems = (items: NavigationItem[]) => {
    return items.map((item, index) => <SidebarItem key={index} item={item} />);
};

const SidebarGroup = ({ group, renderGroup }: SidebarGroupProps) => {
    return (
        <>
            {renderGroup && (
                <ListHeaderItem>
                    <ListItemText
                        primary={group.label}
                        secondary={group.description}
                        primaryTypographyProps={{ variant: "button" }}
                        secondaryTypographyProps={{ variant: "overline", sx: { lineHeight: "initial" } }}
                    />
                </ListHeaderItem>
            )}
            {renderItems(group.children)}
        </>
    );
};

export default SidebarGroup;
