// *** NPM ***
import React, { MouseEventHandler, useEffect, useState } from "react";

// *** OTHER ***
import { EventOption } from "../../types/public-types";
import { BarTask } from "../../types/bar-task";
import Arrow from "../../components/Arrow";
import { handleTaskBySVGMouseEvent, taskXCoordinate } from "../../helpers/bar-helper";
import { isKeyboardEvent } from "../../helpers/other-helper";
import TaskItem from "../../containers/TaskItem/examples/TaskItemOriginal";
import {
	BarMoveAction,
	GanttContentMoveAction,
	GanttEvent,
} from "../../types/gantt-task-actions";

// *** TYPES ***
export type IProps = {
	tasks: BarTask[];
	highlightedTasks:Number[];
	activityTaskSelected: (taskIndex: number) => void;
	spotlightStart: Date;
	spotlightEnd: Date;
	statusLineDate: Date;
	linialChange: (date: Date, type: "spotlightStart" | "spotlightEnd" | "linial") => void,
	dates: Date[];
	ganttEvent: GanttEvent;
	selectedTask: BarTask | undefined;
	rowHeight: number;
	columnWidth: number;
	timeStep: number;
	svg?: React.RefObject<SVGSVGElement>;
	svgWidth: number;
	taskHeight: number;
	arrowColor: string;
	arrowIndent: number;
	fontSize: string;
	fontFamily: string;
	rtl: boolean;
	setGanttEvent: (value: GanttEvent) => void;
	setFailedTask: (value: BarTask | null) => void;
	setSelectedTask: (taskId: string) => void;
	visualStartDate: Date;
	visualEndDate: Date;
} & EventOption;

const GanttTaskContentOriginal = (props: IProps) => {
	// *** PROPS ***
	const {
		tasks,
		spotlightStart,
		highlightedTasks,
		activityTaskSelected,
		spotlightEnd,
		linialChange,
		statusLineDate,
		dates,
		ganttEvent,
		selectedTask,
		rowHeight,
		columnWidth,
		timeStep,
		svg,
		taskHeight,
		arrowColor,
		arrowIndent,
		fontFamily,
		fontSize,
		rtl,
		setGanttEvent,
		setFailedTask,
		setSelectedTask,
		onDateChange,
		onProgressChange,
		onDoubleClick,
		onDelete,
		visualStartDate,
		visualEndDate

	} = props;

	// *** USE STATE ***
	const [xStep, setXStep] = useState(0);
	const [initEventX1Delta, setInitEventX1Delta] = useState(0);
	const [isMoving, setIsMoving] = useState(false);

	const point = svg?.current?.createSVGPoint();

	let dateDelta = 2678400000;
	if (dates.length > 0) {

		dateDelta = dates[1].getTime() -
			dates[0].getTime() -
			dates[1].getTimezoneOffset() * 60 * 1000 +
			dates[0].getTimezoneOffset() * 60 * 1000;
	}

	// *** HANDLERS ***
	const handleBarEventStart = async (
		action: GanttContentMoveAction,
		task: BarTask,
		event?: React.MouseEvent | React.KeyboardEvent
	) => {
		if (!event) {
			if (action === "select") {
				setSelectedTask(task.id);
			}
		}
		// Keyboard events
		else if (isKeyboardEvent(event)) {
			if (action === "delete") {
				if (onDelete) {
					try {
						const result = await onDelete(task);
						if (result !== undefined && result) {
							setGanttEvent({ action, changedTask: task });
						}
					} catch (error) {
						console.error("Error on Delete. " + error);
					}
				}
			}
		}

		// Mouse Events
		else if (action === "mouseenter") {
			if (!ganttEvent.action) {
				setGanttEvent({
					action,
					changedTask: task,
					originalSelectedTask: task,
				});
			}
		} else if (action === "mouseleave") {
			if (ganttEvent.action === "mouseenter") {
				setGanttEvent({ action: "" });
			}
		} else if (action === "dblclick") {
			!!onDoubleClick && onDoubleClick(task);
		}

		// Change task event start
		else if (action === "move") {
			if (!svg?.current || !point) return;
			point.x = event.clientX;
			const cursor = point.matrixTransform(
				svg.current.getScreenCTM()?.inverse()
			);
			setInitEventX1Delta(cursor.x - task.x1);
			setGanttEvent({
				action,
				changedTask: task,
				originalSelectedTask: task,
			});
		} else {
			setGanttEvent({
				action,
				changedTask: task,
				originalSelectedTask: task,
			});
		}
	};
	const [linialType, setLinialType] = useState<"spotlightStart" | "spotlightEnd" | "linial" | null>(null);
	const handleMouseMove = async (event: MouseEvent) => {
		if (!svg?.current || !point || !linialType) return;
		try {
			point.x = event.clientX;
			const cursor = point.matrixTransform(svg.current.getScreenCTM()?.inverse());
			let d = new Date((dates[0].getTime()) + cursor.x * dateDelta / columnWidth)
			linialChange(d, linialType);//todo rename to lineal
		} catch (ex) {
			console.error(ex)
		}
		//linealChange(d,"spotlightStart")
	};
	useEffect(() => {
		if (linialType != null) {
			document.addEventListener("mouseup", handleMouseUp);
			document.addEventListener("mousemove", handleMouseMove);
		}
	}, [linialType]);
	const handleMouseUp = (event: MouseEvent) => {
		setLinialType(null);
		document.removeEventListener("mouseup", handleMouseUp);
		document.removeEventListener("mousemove", handleMouseMove);
	};

	const linialMouseDown = (e: React.MouseEvent<SVGElement>, type: "spotlightStart" | "spotlightEnd" | "linial") => {
		setLinialType(type);
	};


	let spotlightStartingPoint = taskXCoordinate(spotlightStart, dates, dateDelta, columnWidth);
	let spotlightEndingPoint = taskXCoordinate(spotlightEnd, dates, dateDelta, columnWidth);
	let statusLinePoint = taskXCoordinate(statusLineDate, dates, dateDelta, columnWidth);
	let barEnd = taskXCoordinate(dates[dates.length - 1], dates, dateDelta, columnWidth) + columnWidth;
	useEffect(() => {
		const dateDelta =
			dates[1].getTime() -
			dates[0].getTime() -
			dates[1].getTimezoneOffset() * 60 * 1000 +
			dates[0].getTimezoneOffset() * 60 * 1000;
		const newXStep = (timeStep * columnWidth) / dateDelta;
		setXStep(newXStep);
	}, [columnWidth, dates, timeStep]);

	useEffect(() => {
		const handleMouseMove = async (event: MouseEvent) => {
			if (!ganttEvent.changedTask || !point || !svg?.current) return;
			event.preventDefault();

			point.x = event.clientX;
			const cursor = point.matrixTransform(
				svg?.current.getScreenCTM()?.inverse()
			);

			const { isChanged, changedTask } = handleTaskBySVGMouseEvent(
				cursor.x,
				ganttEvent.action as BarMoveAction,
				ganttEvent.changedTask,
				xStep,
				timeStep,
				initEventX1Delta,
				rtl
			);
			if (isChanged) {
				setGanttEvent({ action: ganttEvent.action, changedTask });
			}
		};

		const handleMouseUp = async (event: MouseEvent) => {
			const { action, originalSelectedTask, changedTask } = ganttEvent;
			if (!changedTask || !point || !svg?.current || !originalSelectedTask)
				return;
			event.preventDefault();

			point.x = event.clientX;
			const cursor = point.matrixTransform(
				svg?.current.getScreenCTM()?.inverse()
			);
			const { changedTask: newChangedTask } = handleTaskBySVGMouseEvent(
				cursor.x,
				action as BarMoveAction,
				changedTask,
				xStep,
				timeStep,
				initEventX1Delta,
				rtl
			);

			const isNotLikeOriginal =
				originalSelectedTask.start !== newChangedTask.start ||
				originalSelectedTask.end !== newChangedTask.end ||
				originalSelectedTask.progress !== newChangedTask.progress;

			// remove listeners
			svg.current.removeEventListener("mousemove", handleMouseMove);
			svg.current.removeEventListener("mouseup", handleMouseUp);
			setGanttEvent({ action: "" });
			setIsMoving(false);

			// custom operation start
			let operationSuccess = true;
			if (
				(action === "move" || action === "end" || action === "start") &&
				onDateChange &&
				isNotLikeOriginal
			) {
				try {
					const result = await onDateChange(
						newChangedTask,
						newChangedTask.barChildren
					);
					if (result !== undefined) {
						operationSuccess = result;
					}
				} catch (error) {
					operationSuccess = false;
				}
			} else if (onProgressChange && isNotLikeOriginal) {
				try {
					const result = await onProgressChange(
						newChangedTask,
						newChangedTask.barChildren
					);
					if (result !== undefined) {
						operationSuccess = result;
					}
				} catch (error) {
					operationSuccess = false;
				}
			}

			// If operation is failed - return old state
			if (!operationSuccess) {
				setFailedTask(originalSelectedTask);
			}
		};

		if (
			!isMoving &&
			(ganttEvent.action === "move" ||
				ganttEvent.action === "end" ||
				ganttEvent.action === "start" ||
				ganttEvent.action === "progress") &&
			svg?.current
		) {
			svg.current.addEventListener("mousemove", handleMouseMove);
			svg.current.addEventListener("mouseup", handleMouseUp);
			setIsMoving(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		ganttEvent,
		xStep,
		initEventX1Delta,
		onProgressChange,
		timeStep,
		onDateChange,
		svg,
		isMoving,
	]);


	return (
		<g className="content">
			<g className="arrows" fill={arrowColor} stroke={arrowColor}>
				{tasks.map((task) => {
					return task.barChildren.map((child) => {
						const taskTo = tasks[child.index];

						return (
							<Arrow
								key={`Arrow from ${task.id} to ${tasks[child.index].id}`}
								taskFromIndex={task.index}
								taskFromX1={task.x1}
								taskFromX2={task.x2}
								taskFromY={task.y}
								taskToIndex={taskTo.index}
								taskToX1={taskTo.x1}
								taskToX2={taskTo.x2}
								taskToY={taskTo.y}
								rowHeight={rowHeight}
								taskHeight={taskHeight}
								arrowIndent={arrowIndent}
								rtl={rtl}
								// style
								rootStyle={{ fill: arrowColor, stroke: arrowColor }}
							/>
						);
					});
				})}
			</g>
			<g className="bar" fontFamily={fontFamily} fontSize={fontSize}>
				{tasks.map((task) => {
					return (
						<TaskItem
							task={task}
							arrowIndent={arrowIndent}
							taskHeight={taskHeight}
							isProgressChangeable={!!onProgressChange && !task.isDisabled}
							isDateChangeable={!!onDateChange && !task.isDisabled}
							isDelete={!task.isDisabled}
							onEventStart={handleBarEventStart}
							key={task.id}
							isSelected={!!selectedTask && task.id === selectedTask.id}
							rtl={rtl}
						/>
					);
				})}

				<rect x="0px" y="0" style={{ "height": tasks.length * 50 + "px", "width": spotlightStartingPoint + "px", "fill": "rgba(0,0,0,0.3)", "filter": "drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.5))" }}></rect>
				<rect onMouseDown={e => linialMouseDown(e, "spotlightStart")} x={spotlightStartingPoint + "px"} y="0" style={{ "cursor": "e-resize", "height": tasks.length * 50 + "px", "width": "4px", "fill": "blue", "filter": "drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.5))" }}></rect>
				<rect onMouseDown={e => linialMouseDown(e, "linial")} x={statusLinePoint + "px"} y="0" style={{ "cursor": "e-resize", "height": tasks.length * 50 + "px", "width": "4px", "fill": "yellow", "filter": "drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.5))" }}></rect>

				<rect x={spotlightEndingPoint + "px"} y="0" style={{ "height": tasks.length * 50 + "px", "width": (barEnd - spotlightEndingPoint) + "px", "fill": "rgba(0,0,0,0.3)", "filter": "drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.5))" }}></rect>
				<rect onMouseDown={e => linialMouseDown(e, "spotlightEnd")} x={spotlightEndingPoint + "px"} y="0" style={{ "cursor": "e-resize", "height": tasks.length * 50 + "px", "width": "4px", "fill": "blue", "filter": "drop-shadow( 0px 0px 2px rgba(0, 0, 0, 0.5))" }}></rect>
			</g>
		</g>
	);

};

export default GanttTaskContentOriginal;
