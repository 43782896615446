import { createTheme, styled, useTheme } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { Box, Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar, Typography, useMediaQuery } from "@mui/material";
import { MouseEvent, useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../Context/GlobalContext/GlobalContextProvider";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { faArrowRightFromBracket, faCog, faUser } from "@fortawesome/pro-duotone-svg-icons";
import themesConfig from "../../Themes/ThemesConfig";
import { expandSidebar } from "../../Context/GlobalContext/Reducer";
import { SIDEBAR_WIDTH } from "../../Constants/Interface";
import { useMsal } from "@azure/msal-react";

const Container = styled(MuiAppBar, { shouldForwardProp: (prop) => !["open", "isMobile"].some((x) => x === prop) })<{
    open: boolean;
    isMobile: boolean;
}>(({ theme, open, isMobile }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open &&
        !isMobile && {
            width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
            marginLeft: SIDEBAR_WIDTH,
            transition: theme.transitions.create(["margin", "width"], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    borderBottom: "1px solid #e2e8f0",
    boxShadow: "none",
}));

const AppBar = () => {
    const { state: globalState, dispatch: globalDispatch } = useContext(GlobalContext);
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null);
    const [open, setOpen] = useState(!globalState.sideBarCollapsed);
    const [name, setName] = useState<string | null>(null);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const { accounts, instance } = useMsal();

    useEffect(() => {
        if (accounts.length > 0) {
            setName(accounts[0].name!);
        } else {
            setName(null);
        }
    }, [accounts]);

    useEffect(() => {
        setOpen(!globalState.sideBarCollapsed);
    }, [globalState.sideBarCollapsed]);

    // TODO: Setup Themese in Themes folder and use a reference to the created theme directly instead of creating another theme here.
    const darkTheme = createTheme(themesConfig.defaultDark);

    const onSidebarOpen = () => {
        globalDispatch(expandSidebar());
    };

    const onMenuOpen = (e: MouseEvent<HTMLElement>) => {
        setAnchorElement(e.currentTarget);
    };

    const onMenuClose = () => {
        setAnchorElement(null);
    };

    const onLogout = () => {
        instance.logoutRedirect();
    };
    if(globalState.applicationBarCollapsed) return <></>
    return (
        <Container position="fixed" theme={darkTheme} open={open} isMobile={isMobile}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="Open sidebar navigation"
                    edge="start"
                    sx={{ mr: 2, ...(open && { display: "none" }) }}
                    onClick={() => onSidebarOpen()}
                >
                    <FontAwesomeSvgIcon icon={faBars} />
                </IconButton>

                <img src="https://dstudio.be/wp-content/themes/dstudio/img/logo.svg" alt="Logo D-studio" height={32} />

                <div style={{ marginLeft: "auto" }}>
                    <Button
                        color="inherit"
                        size="large"
                        aria-label="account of the current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={(e) => onMenuOpen(e)}
                        // endIcon={<Avatar />}
                    >
                        <Box sx={{ display: { xs: "none", sm: "block" }, textAlign: "right", lineHeight: "initial" }}>
                            <Typography variant="button" sx={{ display: "block", lineHeight: "initial" }}>
                                {name}
                            </Typography>
                            {/* <Typography variant="caption" sx={{ lineHeight: "initial" }}>
                            Administrator
                        </Typography> */}
                        </Box>
                    </Button>
                    <Menu
                        id="menu-appbar"

                        anchorEl={anchorElement}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                        open={Boolean(anchorElement)}
                        onClose={() => onMenuClose()}
                        sx={{ mt: "40px"}}
                    >
                        <MenuItem onClick={() => onMenuClose()} sx={{ width: 240 }} disabled>
                            <ListItemIcon>
                                <FontAwesomeSvgIcon icon={faUser} />
                            </ListItemIcon>
                            <ListItemText>Profile</ListItemText>
                        </MenuItem>
                        <MenuItem onClick={() => onMenuClose()} disabled>
                            <ListItemIcon>
                                <FontAwesomeSvgIcon icon={faCog} />
                            </ListItemIcon>
                            <ListItemText>Settings</ListItemText>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => onLogout()}>
                            <ListItemIcon>
                                <FontAwesomeSvgIcon icon={faArrowRightFromBracket} />
                            </ListItemIcon>
                            <ListItemText>Logout</ListItemText>
                        </MenuItem>
                    </Menu>
                </div>
            </Toolbar>
        </Container>
    );
};

export default AppBar;
