import { ChangeEvent, useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    // CardActionArea,
    CardHeader,
    Divider,
    Drawer,
    IconButton,
    Input,
    Typography,
    Autocomplete,
    TextField,
    Fade,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Snackbar,
    Alert,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { faMinus, faPlus, faTimes } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeSvgIcon from "../../Components/FontAwesomeSvgIcon";
import { ChecksetDefinitionContext } from "../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
import { clearCurrentChecksetConfigurationEdited, disableChecksetValidation, enableChecksetValidation, setActiveChecksetIsEdited, setCurrentChecksetConfigurationEdited, addCustomProperty, changeCheckName, closeChecksetDefinitionConfigDrawer, loadCustomProperties, saveCheck, setRemoteProperty, updatecheck } from "../../Context/ChecksetDefinitionContext/Reducer";
// import { faPencil } from "@fortawesome/pro-duotone-svg-icons";
import { Datasource, getCategories, getDatatypes, getProperties } from "../../Connectors/datasource";
import { DatasourceCategory } from "../../Connectors/_Models/DatasourceCategory";
import { uuid4 } from "@sentry/utils";
import { DatasourceProperty } from "../../Connectors/_Models/DatasourceProperty";
import { DatasourceDataType } from "../../Connectors/_Models/DatasourceDataType";
import { DatasourceOperand } from "../../Connectors/_Models/DatasourceOperand";
import { DatasourceUnit } from "../../Connectors/_Models/DatasourceUnit";
import { ChecksetRuleSet, ChecksetType, SimplePropertyCheck, SimplePropertyRule } from "../../Connectors/_Models/Checkset";
import { PropertySelectionComponents } from "./PropertiesSelectionComponents/index"
import { retval } from "./PropertiesSelectionComponents/PropertySelectProperties";
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

enum PropertyAddAlertTypes {
    PropertyAddedType,
    FailedPropertyAdd,
    InvalidProperty,
    InvalidDataType,
}
interface PropertyAddAlert {
    text: string
    visible: boolean,
    alertType: PropertyAddAlertTypes
    handle: ReturnType<typeof setTimeout> | null
}

const ChecksetDefinitionConfigDrawer = () => {
    const theme = useTheme();
    const { state, dispatch } = useContext(ChecksetDefinitionContext);


    const [propertyAddAlert, setPropertyAddAlert] = useState<PropertyAddAlert>({ text: "", visible: false, alertType: PropertyAddAlertTypes.PropertyAddedType, handle: null });

    const [invalidData, setInvalidData] = useState<boolean>(false);
    const [closeDialog, setCloseDialog] = useState<boolean>(false);



    useEffect(() => {
        setCloseDialog(false);
    }, [state.activeCheck]);

    useEffect(() => {
        dispatch(disableChecksetValidation());
    }, [state.checksetDefinitionConfigDrawerActive])

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        dispatch(changeCheckName(e.currentTarget.value));
        dispatch(setCurrentChecksetConfigurationEdited());
    };



    const validateData = () => {
        // const validateSimpleProperty = (prop: SimplePropertyRule) => {//TODO extract this to a validate property class once moore property types are introduced
        //     let targetProperty = props.find(x => x.id === prop.propertyId)
        //     if(!targetProperty?.datatype) return true;
        //     const dataType = dataTypes.find(x => x.id === targetProperty!.datatype!.id);
        //     if (!targetProperty || !dataType) return false;
        //     const targetOperator = dataType.supportedOperators.find((x) => x.id === prop.operatorId);
        //     if (targetOperator?.requiresSecondOperand === true && (prop.value === null || prop.value === undefined)) {
        //         return false;
        //     }
        //     return true;
        // };

        // const clearEmptySingleField = (ruleSet: ChecksetRuleSet) => {
        //     if (ruleSet.simplePropertyRules.length === 1) {
        //         let rs = ruleSet.simplePropertyRules[0];
        //         if (rs.property === "" &&
        //             rs.dataTypeId === undefined &&
        //             rs.propertyGroupId === undefined &&
        //             (rs.value === null || isNaN(rs.value) || rs.value === undefined)
        //         ) {
        //             ruleSet.simplePropertyRules = [];
        //         }
        //     }
        // };

        // let ac = state.activeCheck;
        // if (ac?.checkType === ChecksetType.SIMPLE_PROPERTY_CHECK) {
        //     let sac = ac as SimplePropertyCheck;
        //     console.log(sac);
        //     if (sac?.scope?.ruleSet?.simplePropertyRules) {
        //         clearEmptySingleField(sac.scope.ruleSet);
        //         for (let prop of sac.scope.ruleSet.simplePropertyRules) {
        //             if (!validateSimpleProperty(prop)) return false;
        //         }
        //     }
        //     if (sac?.ruleSet?.simplePropertyRules) {
        //         clearEmptySingleField(sac.ruleSet);
        //         for (let prop of sac.ruleSet.simplePropertyRules) {
        //             if (!validateSimpleProperty(prop)) return false;
        //         }
        //     }
        // }

        return true;
    }

    const onSave = () => {
        if (validateData()) {
            dispatch(saveCheck());
            dispatch(closeChecksetDefinitionConfigDrawer());
            dispatch(setActiveChecksetIsEdited());
            dispatch(clearCurrentChecksetConfigurationEdited());
            dispatch(disableChecksetValidation());
        } else {
            setInvalidData(true);
            dispatch(enableChecksetValidation());
        }
    };
    const onClose = () => {
        if (state.currentChecksetConfigurationEdited) {
            setCloseDialog(true);
            return;
        }
        dispatch(closeChecksetDefinitionConfigDrawer());
        dispatch(disableChecksetValidation());
    }
    const onCloseDiscarded = () => {
        setCloseDialog(false);
        dispatch(clearCurrentChecksetConfigurationEdited());
        dispatch(closeChecksetDefinitionConfigDrawer());
        dispatch(disableChecksetValidation());
    };

    /**
     * Sets the propertaddalert instance and shows it on the screen.
     * @param alertData propertyAddAlert instance to show.
     * @param alertText text to render in the alertbox
     * @param alertType type of alert.
     */
    const showPropertyAddAlert = (alertData: PropertyAddAlert, alertText: string, alertType: PropertyAddAlertTypes) => {
        if (alertData.handle !== null) {
            clearTimeout(alertData.handle);
            alertData.handle = null;
        };
        setPropertyAddAlert({ text: alertText, visible: true, alertType: alertType, handle: null });
        var handle = setTimeout(() => {
            setPropertyAddAlert({ text: alertData.text, visible: false, alertType: PropertyAddAlertTypes.PropertyAddedType, handle });
        }, 3000);
    };

    /**
     * Get the severity for the current PropertyAddAlert.
     * @returns severity as string
     */
    const getPropertyAddedAlertySeverity = () => {
        switch (propertyAddAlert.alertType) {

            case PropertyAddAlertTypes.PropertyAddedType:
                return "success";
            default:
                return "error";
        }
    };

    /**
     * Add custom property to the list and cache it locally
     * @param group name of the group for the new property
     * @param name name of the new property
     * @param type datatype used by the new property
     * @returns true if added, false if failed adding.
     */
    const onAddCustomProperty = (group: string, name: string, type: string) => {
        // if (group === null || name === null || group === "" || name === "") {
        //     showPropertyAddAlert(propertyAddAlert, "Invalid property!", PropertyAddAlertTypes.InvalidProperty);
        //     return false;
        // }
        // if (state.remoteProperties.find(x => x.groupName.toLowerCase() === group.toLowerCase() && x.name.toLowerCase() === name.toLowerCase())
        //     || state.customProperties.find(x => x.groupName.toLowerCase() === group.toLowerCase() && x.name.toLowerCase() === name.toLowerCase())) {
        //     showPropertyAddAlert(propertyAddAlert, "Property already exists!", PropertyAddAlertTypes.FailedPropertyAdd);
        //     return false;
        // }

        // let datatype = dataTypes.find(x => x.name === type);
        // if (datatype !== undefined) {
        //     let dp: DatasourceProperty = {
        //         id: undefined,
        //         groupId: undefined,
        //         groupName: group,
        //         name,
        //         datatype,
        //         supportedOperators: datatype.supportedOperators.map(x => x.id),
        //         supportedUnits: datatype.supportedUnits.map(x => x.id),
        //         customType: true,
        //     }
        //     dispatch(addCustomProperty(dp));
        //     showPropertyAddAlert(propertyAddAlert, "New property added to list!", PropertyAddAlertTypes.PropertyAddedType);

        //     return true;
        // } else {
        //     showPropertyAddAlert(propertyAddAlert, "Invalid datatype!", PropertyAddAlertTypes.InvalidDataType);
        // }
        return false;
    };

    const ProportySelectionComponentByCheckType = () => {
        const checkType = state.activeCheck?.checkType;
        if (checkType) {
            let PropComp: retval | undefined = PropertySelectionComponents[checkType];
            if (PropComp) {
                return <PropComp />;//propertyAddAlert={propertyAddAlert}
            }
        }
        return <></>;

    };

    return (
        state.activeCheck && (
            <Drawer
                variant="temporary"
                anchor="right"
                open={state.checksetDefinitionConfigDrawerActive}
                onClose={onClose}
                sx={{
                    display: {
                        xs: "none",
                        sm: "block",
                        width: "50%",
                    },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "50%",
                        overflow: "hidden",
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={onClose}>
                        <FontAwesomeSvgIcon icon={faTimes} />
                    </IconButton>

                    <Typography variant="h6" noWrap>
                        <Input
                            type="text"
                            value={state.activeCheck.name}
                            onChange={onChangeName}
                            sx={{
                                display: "flex",
                                placeContent: "center",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 600,
                                fontSize: "inherit",
                                ml: 1,
                            }}
                        />
                    </Typography>
                    <Box
                        sx={{
                            ml: "auto",
                        }}
                    >
                        <Button variant="contained" sx={{ ml: 1 }} onClick={onSave}>
                            Save
                        </Button>
                    </Box>
                </DrawerHeader>
                <Divider />
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: theme.palette.background.default,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {/* <Card sx={{ m: 1 }}>
                    <CardActionArea>
                        <CardHeader title="Scope Configuration" action={<FontAwesomeSvgIcon icon={faPencil} sx={{ ml: "auto", fontSize: 16 }} />} />
                        <CardContent sx={{ ":last-child": { pb: 2 } }}>
                            <Typography>Some information message for the user describing what the scope configuration actually does.</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card> */}


                    {ProportySelectionComponentByCheckType()}


                </Box>
                <Fade in={propertyAddAlert.visible}>
                    <Alert sx={{ position: 'fixed', right: 5, top: 5, transform: {} }} severity={getPropertyAddedAlertySeverity()}>{propertyAddAlert.text}</Alert>
                </Fade>
                <Snackbar
                    open={invalidData}
                    autoHideDuration={3000}
                    sx={{ mr: 10 }}
                    onClose={() => setInvalidData(false)}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                >
                    <Alert severity="error">Incomplete checkset definition</Alert>
                </Snackbar>
                <Dialog
                    open={closeDialog}
                    onClose={() => setCloseDialog(false)}
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You have pending changes! Are you sure you want to discard these by closing without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCloseDiscarded()}>Close</Button>
                        <Button onClick={() => setCloseDialog(false)} >Cancel</Button>
                    </DialogActions>
                </Dialog>

            </Drawer>
        )
    );
};

export default ChecksetDefinitionConfigDrawer;
