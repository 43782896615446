import {ProjectResponse} from "../../Connectors/projects";
import {DataFolderDocument} from "../../Connectors/_Models/DataFolderDocument";
import { Planning } from "../../Connectors/_Models/Planning";
import { PlanningTask } from "../../Connectors/_Models/PlanningTask";
import {VisualiseType} from "./State";

export enum ActionType {
    SetActiveProjectModel,
    ClearSelectedModel,
    SelectTask,
    SelectPlanning,
    SetDateRange,
}

export interface SetActiveProjectModel {
    type: ActionType.SetActiveProjectModel;
    project: ProjectResponse;
    model: DataFolderDocument;
    visualiseType: VisualiseType;
}

export interface SelectTask {
    type: ActionType.SelectTask;
    planning: Planning | null;
    planningTask: PlanningTask | null;
}

export interface SelectPlanning {
    type: ActionType.SelectPlanning;
    planning: Planning | null;
}

export interface ClearSelectedModel {
    type: ActionType.ClearSelectedModel;
}
export interface SetDateRange {
    type: ActionType.SetDateRange;
    startDate: Date;
    endDate: Date;
}


export type BobActions = SetActiveProjectModel | ClearSelectedModel | SelectTask | SelectPlanning| SetDateRange;
