import { createTheme, PaletteMode, ThemeOptions } from "@mui/material";

export const lightPaletteText = {
    primary: "rgb(17, 24, 39)",
    secondary: "rgb(107, 114, 128)",
    disabled: "rgb(149, 156, 169)",
};

export const darkPaletteText = {
    primary: "rgb(255,255,255)",
    secondary: "rgb(148, 163, 184)",
    disabled: "rgb(156, 163, 175)",
};

const themesConfig = {
    default: {
        palette: {
            mode: "light" as PaletteMode,
            divider: "#e2e8f0",
            text: lightPaletteText,
            common: {
                black: "rgb(17, 24, 39)",
                white: "rgb(255, 255, 255)",
            },
            primary: {
                light: "#64748b",
                main: "#1e293b",
                dark: "#0f172a",
                contrastText: darkPaletteText.primary,
            },
            secondary: {
                light: "#818cf8",
                main: "#4f46e5",
                dark: "#3730a3",
                contrastText: darkPaletteText.primary,
            },
            background: {
                paper: "#FFFFFF",
                default: "#37647b0f",
            },
            error: {
                light: "#ffcdd2",
                main: "#f44336",
                dark: "#b71c1c",
            },
            status: {
                danger: "orange",
            },
        },
    },
    defaultDark: {
        palette: {
            mode: "dark" as PaletteMode,
            divider: "rgba(241,245,249,.12)",
            text: darkPaletteText,
            common: {
                black: "rgb(17, 24, 39)",
                white: "rgb(255, 255, 255)",
            },
            primary: {
                light: "#64748b",
                main: "#334155",
                dark: "#0f172a",
                contrastText: darkPaletteText.primary,
            },
            secondary: {
                light: "#818cf8",
                main: "#4f46e5",
                dark: "#3730a3",
                contrastText: darkPaletteText.primary,
            },
            background: {
                paper: "#37647b",
                default: "#111827",
            },
            error: {
                light: "#ffcdd2",
                main: "#f44336",
                dark: "#b71c1c",
            },
            status: {
                danger: "orange",
            },
        },
    },
};

/*

    typography: {
        h1: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1.5rem",
        },
        h2: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1.125rem",
        },
        h3: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "1rem",
        },
        h4: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "0.875rem",
        },
        h5: {
            fontFamily: "Roboto, sans-serif",
            fontSize: "0.75rem",
        },
        body1: {
            fontFamily: "Roboto, sans-serif",
            color: "#606060",
        },
        body2: {
            fontFamily: "Roboto, sans-serif",
            color: "#606060",
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: "contained",
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    textTransform: "none",
                    minHeight: "56px",
                    "&.Mui-disabled": {
                        color: "#FFFFFF",
                        backgroundColor: "#434e69",
                        opacity: 0.2,
                    },
                },
                containedPrimary: {
                    "&:hover": {
                        backgroundColor: "#434e69",
                    },
                },
            },
        },
        MuiCardActionArea: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiMenuItem: {
            defaultProps: {
                disableRipple: true,
            },
        },
        MuiLink: {
            defaultProps: {
                underline: "hover",
            },
            styleOverrides: {
                root: {
                    fontFamily: "Roboto,sans-serif",
                    // fontSize: "inherit",
                    fontWeight: 600,
                    fontSize: "0.875rem",
                },
            },
        },
    },
     */

export default themesConfig;

export const theme = createTheme(themesConfig.default as ThemeOptions);
