import axios, {AxiosRequestConfig, AxiosResponse, CancelToken} from "axios";
import {configuration} from "../_Configuration/configuration";
import {DataFolder, ForgeLink} from "./_Models";
import {SimpleChecksetResponse} from "./_Models/Checkset";
import {
    DataFolderDocument,
    DocumentChecksetResult,
    DocumentDerivativesResult, EditDataFolder,
    NewDataFolder,
    NewDataFolderDocument
} from "./_Models/DataFolderDocument";

export const getDataFolders = async (): Promise<DataFolder[]> => {
    const result = await axios.get("api/DataFolders", {baseURL: configuration.api});

    return result.data as DataFolder[];
};

export const getDataFolderForgeLink = async (folderId: string): Promise<ForgeLink | undefined> => {
    try {
        const result = await axios.get(`api/DataFolders/${folderId}/Link/Bim360`, {baseURL: configuration.api});
        return result.data as ForgeLink;
    } catch (e) {
        return undefined;
    }
};

export const setDataFolderForgeLink = async (folderId: string, data: ForgeLink): Promise<void> => {
    await axios.post(`api/DataFolders/${folderId}/Link/Bim360`, data, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api
    });
};

export const syncFolder = async (folderId: string): Promise<string> => {
    let resultl = await axios.post(`api/DataFolders/${folderId}/Synchronize`, null, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api
    });
    return resultl.data as string;
};


export const removeDataFolderForgeLink = async (folderId: string): Promise<void> => {
    await axios.delete(`api/DataFolders/${folderId}/Link/Bim360`, {baseURL: configuration.api});
};

export const getDocuments = async (folderId: string): Promise<DataFolderDocument[]> => {
    const result = await axios.get(`api/DataFolders/${folderId}/Documents`, {baseURL: configuration.api});

    return result.data as DataFolderDocument[];


};

export const deleteDocument = async (folderId: string, documentId: string): Promise<void> => {
    await axios.delete(`api/DataFolders/${folderId}/Documents/${documentId}`, {baseURL: configuration.api});
};

export const deleteFolder = async (folderId: string): Promise<void> => {
    await axios.delete(`api/DataFolders/${folderId}`, {baseURL: configuration.api});
};

export const addDocument = async (folderId: string, data: NewDataFolderDocument): Promise<DataFolderDocument> => {
    const content_ = new FormData();
    let loopData = data as any;
    for (let key in loopData) {
        content_.append(key, loopData[key]);
    }
    const result = await axios.post(`api/DataFolders/${folderId}/Documents`, content_, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api,
    });

    return result.data as DataFolderDocument;
};

export const linkChecksetToDocument = async (folderId: string, documentId: string, data: string[]): Promise<SimpleChecksetResponse[]> => {
    const result = await axios.post(`api/DataFolders/${folderId}/Documents/${documentId}/Checksets`, data, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api,
    });

    return result.data as SimpleChecksetResponse[];
};

export const getChecksetResultsForDocument = async (folderId: string, documentId: string, checksetId: string): Promise<DocumentChecksetResult> => {
    const result = await axios.get(`api/DataFolders/${folderId}/Documents/${documentId}/Checksets/${checksetId}/Results`, {
        baseURL: configuration.api,
    });

    return result.data as DocumentChecksetResult;
};

export const getDocumentDerivatives = async (folderId: string, documentId: string): Promise<DocumentDerivativesResult[]> => {
    const result = await axios.get(`api/DataFolders/${folderId}/Documents/${documentId}/Derivatives`, {
        baseURL: configuration.api,
    });

    return result.data as DocumentDerivativesResult[];
};

export const getDocumentDerivative = async (folderId: string, documentId: string, id: string, fileName: string): Promise<string> => {
    const result = await axios.get(`api/DataFolders/${folderId}/Documents/${documentId}/Derivatives/${id}${fileName ? `?fileName=${encodeURIComponent(fileName)}` : ""}`, {
        baseURL: configuration.api,
    });

    return result.data as string;
};

export const getConvertedFile = async (folderId: string, documentId: string, versionId: string, fileName: string): Promise<string> => {
    const result = await axios.get(`api/DataFolders/${folderId}/Documents/${documentId}/Converted/${versionId}/${encodeURIComponent(fileName)}`, {
        baseURL: configuration.api,
    });

    return result.data as string;
};

// TODO: Move these to their own connector once theey are updated on the API

export const runCheckset = async (folderId: string, documentId: string, checkSetId: string): Promise<any> => {
    const result = await axios.post(
        `api/DA/CheckTest2`,
        {datafolderId: folderId, checkSetId: checkSetId, documents: [documentId]},
        {
            headers: {"Content-Type": "application/json"},
            baseURL: configuration.api,
        }
    );

    return result.data;
};
export const linkLatestVersion = async (folderId: string, documentId: string): Promise<any> => {
    try {

        const result = await axios.post(
            `api/DataFolders/${folderId}/Documents/${documentId}/LinkLatestVersion`,
            {},
            {
                headers: {"Content-Type": "application/json"},
                baseURL: configuration.api,
            }
        );
        return true;
    } catch (e) {
        return false;
    }
};

export const getDocumentsFulltree = async (folderId: string): Promise<DataFolder> => {
    const result = await axios.get(`api/DataFolders/${folderId}/FullTree`, {baseURL: configuration.api});
    let data = result.data as any;
    if (!data.children) data.children = [];
    if (!data.documents) data.documents = [];
    return data as DataFolder;
};

export const createRootFolder = async (data: NewDataFolder): Promise<DataFolder> => {
    const result = await axios.post(`api/DataFolders`, data, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api,
    });

    return result.data as DataFolder;
};

export const addFolder = async (folderId: string, data: NewDataFolder): Promise<DataFolder> => {
    const result = await axios.post(`api/DataFolders/${folderId}/Subfolder`, data, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api,
    });

    return result.data as DataFolder;
};

export const editFolder = async (folderId: string, data: EditDataFolder): Promise<void> => {
    const result = await axios.put(`api/DataFolders/${folderId}`, data, {
        headers: {"Content-Type": "application/json"},
        baseURL: configuration.api,
    });
};


