import { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { GlobalContext } from "./Context/GlobalContext/GlobalContextProvider";
import {
  collapseSidebar,
  expandSidebar,
} from "./Context/GlobalContext/Reducer";
import { theme } from "./Themes/ThemesConfig";
import Landing from "./Pages/Landing/Landing";
import HealthCheckHome from "./Pages/HealthCheck/Home";
import HealthCheck from "./Pages/HealthCheck";
import ChecksetDefinitions from "./Pages/HealthCheck/ChecksetDefinitions";
import HealthCheckViewer from "./Pages/HealthCheckViewer";
import ForgeOnboarding from "./Components/ForgeOnboarding/ForgeOnboarding";
import Bob from "./Pages/Bob";
import Dashboard from "./Pages/Dashboard";
import PowerBi from "./Pages/PowerBi";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb'

const App = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { dispatch: globalDispatch } = useContext(GlobalContext);

  useEffect(() => {
    if (isMobile) {
      globalDispatch(collapseSidebar());
    } else {
      globalDispatch(expandSidebar());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/health-check" element={<HealthCheck />}>
              <Route index element={<HealthCheckHome />} />
              <Route path="onboarding" element={<ForgeOnboarding />} />
              <Route path="forge-callback" element={<ForgeOnboarding />} />
              <Route path="definitions" element={<ChecksetDefinitions />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="*" element={<HealthCheckHome />} />
              <Route
                path="viewer/:modelUrn/:fileType/:broadcastId"
                element={<HealthCheckViewer />}
              ></Route>
              <Route path="folder/:folders" element={<HealthCheckHome />} />
            </Route>
            <Route path="/bob" element={<Bob />} />
            <Route path="/powerbi" element={<PowerBi />} />
          </Routes>
        </LocalizationProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
