import { retval } from "./PropertySelectProperties";
import SimplePropertyComponent from "./SimplePropertyComponent";
import SizeCheckPropertyComponent from "./SizeCheckPropertyComponent";
import FilenameCheckPropertyComponent from "./FilenameCheckPropertyComponent";

export const PropertySelectionComponents = {
    "simplePropertyCheck": SimplePropertyComponent,
    "sizeCheck": SizeCheckPropertyComponent,
    "filenameCheck": FilenameCheckPropertyComponent,

} as Record<string, retval|undefined>;
