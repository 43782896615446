import { useContext, useEffect, useRef, useState } from "react";
import { BobContext } from "../../Context/BobContext/BobContextProvider";
import { VisualiseType } from "../../Context/BobContext/State";
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { clearSelectedModel } from "../../Context/BobContext/Reducer";
import Card from "@mui/material/Card";
import IfcViewer from "../../Components/ModelViewer/IfcViewer/IfcViewer";
import {
  ModelViewerType,
  TokenRequestCallback,
  ViewerCallbacks,
} from "../../Components/ModelViewer/ModelViewerProperties";
import ForgeBroadcastTransceiver, {
  BroadcastConsumer,
  BroadcastKey,
} from "../../Components/ModelViewer/ForgeViewer/ForgeBroadcastTransceiver";
import {
  Activity,
  ActivityDetection,
  DataPoint,
  Drift,
  getActivityData,
  getLinkedVoxelArary,
  getProjectCameraActivity,
  getProjectCameraImages,
  getProjectCameras,
  getProjectConvertedDocument,
  LinkedVoxelArary,
  ProjectCameraActivityResponse,
  ProjectCameraImage,
  ProjectCameraImageDetection,
  ProjectCameraResponse,
  TaskActivityLink,
} from "../../Connectors/projects";
import {
  setElementsForScheduleAsync as setElementsForSchedule,
  createTask,
  getPlanningsForDocument,
  getAllTasksForPlanning,
} from "../../Connectors/planning";
import { useConditionalFetch } from "../../Utils/conditionalFetch";
import LoadingIndicator from "../../Components/LoadingIndicator";
//import {  PlanningTaskRequest } from "../../Connectors/_Models/PlanningTask";
import { Planning } from "../../Connectors/_Models/Planning";
import BobPlanningGantt from "./BobPlanningGantt";
import { normalizeForDemo } from "./BobPlanningGantt";
import { Task, TaskType } from "../../Components/ReactGanttChart";
import { PlanningTask } from "../../Connectors/_Models/PlanningTask";
import { PlanningTaskScheduleRequest } from "../../Connectors/_Models/PlanningTaskSchedule";
import { patchPlanningTask } from "../../Connectors/planningTask";
import { DateTimePicker, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
const compareDayHours = (a: Date, b: Date) => {
  return (
    a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate() &&
    a.getHours() === b.getHours()
  );
};
export const recursiveConvertToTask = (
  objs: PlanningTask[],
  project: string | undefined,
  indent: number
): [Task[], PlanningTask[]] => {
  let retTasks: Task[] = [];
  let pts: PlanningTask[] = [];
  let dependencies: string[] = [];
  for (let obj of objs) {
    if (obj.children) {
      obj.children = obj.children?.sort((a, b) =>
        new Date(a.startDate) > new Date(b.startDate) ? 1 : -1
      );
      for (let sched of obj.children) {
        sched.taskId = obj.taskId;
        pts.push(sched);
        let type: TaskType =
          sched.children && sched.children.length > 0 ? "project" : "task";
        const task: Task = {
          id: sched.id,
          name: sched.name,
          //start: obj.start || new Date(),
          start: normalizeForDemo(new Date(sched.startDate)),
          //end: obj.end || new Date(),
          end: normalizeForDemo(new Date(sched.endDate)),
          type,
          progress: 0,
          project,
          hideChildren: false,
          indent,
          dependencies,
        };
        if (Math.abs(task.start.getTime() - task.end.getTime()) < 500) {
          task.end = new Date(task.start.getTime() + 60 * 60 * 1000); //2 hour
        }
        if (sched.children && sched.children.length > 0) {
          let [children, schedules] = recursiveConvertToTask(
            [sched],
            task.id,
            indent + 1
          );
          pts = [...pts, ...schedules];
          retTasks = [...retTasks, task, ...children];
        } else {
          dependencies = [task.id];
          retTasks.push(task);
        }
      }
    }
  }
  return [retTasks, pts];
};

const BobProjectModelDetail = () => {
  const { state, dispatch } = useContext(BobContext);
  const [pickedIfcs, setPickedIfcs] = useState<string[] | null>(null);
  const pickedIfcsRef = useRef(pickedIfcs);
  pickedIfcsRef.current = pickedIfcs;
  const [cameraImages, setCameraImages] = useState<ProjectCameraImage[]>([]);
  const [imageIndex, setImageIndex] = useState<number>(-1);
  const [image, setImage] = useState<ProjectCameraImage>();
  const [plannings, setPlannings] = useState<Planning[]>([]);
  const [PlanningTasks, setPlanningTasks] = useState<PlanningTask[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [modelLoading, modelUrn] = useConditionalFetch<string | null>(
    null,
    state.project && state.model,
    getProjectConvertedDocument,
    state.project?.id,
    "model.xkt"
  ); //TODO
  const [imageDetection, setImageDetection] =
    useState<ProjectCameraActivityResponse | null>(null);
  const [, cameras] = useConditionalFetch<ProjectCameraResponse[]>(
    [],
    state.project,
    getProjectCameras,
    state.project?.id
  );
  const camerasRef = useRef(cameras);
  camerasRef.current = cameras;
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [loadedDate, setLoadedDate] = useState<Date>(new Date());
  const [targetDate, setTargetDate] = useState<Date | null>(null);
  const [ifcsRange, setIfcsRange] = useState<string[]>([]);
  const [currentIfcs, setCurrentIfcs] = useState<string[]>([]);
  const currentIfcsRef = useRef(currentIfcs);
  currentIfcsRef.current = currentIfcs;
  const [modifiedTasks, setModifiedTasks] = useState<Task[]>([]);
  const [highlightedTasks] = useState<number[]>([]);
  const [createdTasks, setCreatedTasks] = useState<Task[]>([]);
  const [broadcastTransceiver, setBroadcastTransceiver] =
    useState<ForgeBroadcastTransceiver | null>(null);
  const [tabValue, setTabValue] = useState(0);
  const [currentActivities, setCurrentActivities] = useState<Activity[]>([]);
  const currentActivitiesRef = useRef(currentActivities);
  currentActivitiesRef.current = currentActivities;
  const [cameraActivities, setCameraActivities] = useState<
    ProjectCameraActivityResponse[]
  >([]);
  const [imageActivities, setImageActivities] = useState<
    ProjectCameraActivityResponse[]
  >([]);
  const [activities, setActivities] = useState<{ [key: string]: Activity[] }>(
    {}
  );
  const fixImageTimeoutHandle = useRef<any>(null);
  const [selectedSchedule, setSelectedSchedule] =
    useState<PlanningTask | null>(null);
  const [cameraId, setCameraId] = useState<string>("");
  const tabValueRef = useRef(tabValue);
  tabValueRef.current = tabValue;

  const tabHandleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    //console.log(newValue);
    let cameraData = state.project?.projectMeta?.find(
      (x) => x.metaType === "CameraData"
    );
    if (newValue === 1) {
      cameraData = state.project?.projectMeta?.find(
        (x) => x.metaType === "CameraTop"
      );
    }
    if (!cameraData) return;
    console.log("camdata", cameraData, broadcastTransceiver);

    broadcastTransceiver?.send({
      key: BroadcastKey.LOAD_POSITION,
      value: cameraData.data,
    });
    //let camera = camerasRef.current.find(x => x.id === camId);
    //if (camera)
    // {
    //     (window as any).setPosition(JSON.stringify(cameraData.data));//todo refactor
    // }
  };

  useEffect(() => {
    console.log(state);
    if (state.project) {
      getActivityData(state.project?.id).then((x) => {
        //                setActivities(x);
        setCurrentActivities(x);
        return;
        let groups = x
          .map((y) => y.activityCategory.name)
          .filter((value, index, array) => array.indexOf(value) === index);
        let grouped: { [key: string]: Activity[] } = {};
        for (let group of groups) {
          grouped[group] = x.filter((y) => y.activityCategory.name === group);
        }
        setActivities(grouped);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.project]);

  const highlightTaskIfcElements = () => {
    setTimeout(() => {
      // broadcastTransceiver?.send({
      //     key: BroadcastKey.CLEAR_ALL
      // });
      // broadcastTransceiver?.send({
      //     key: BroadcastKey.SET_HIGHLIGHTED,
      //     value: {
      //         elements: pickedIfcsRef.current,
      //         color: "#00FF00"
      //     }
      // });
      // broadcastTransceiver?.send({
      //     key: BroadcastKey.SET_HIGHLIGHTED,
      //     value: {
      //         elements: currentIfcsRef.current,
      //         color: "#0000FF"
      //     }
      // });
      // if (pickedIfcsRef.current) {
      //     broadcastTransceiver?.send({
      //         key: BroadcastKey.SET_HIGHLIGHTED,
      //         value: {
      //             elements: pickedIfcsRef.current.filter(x => currentIfcsRef.current.includes(x)),
      //             color: "#FF0000"
      //         }
      //     });
      // }
    }, 500); //todo useeffeect [pickedifcs,currentifcs] doesnt work?????
  };
  useEffect(() => {
    broadcastTransceiver?.send({
      key: BroadcastKey.CLEAR_ALL,
    });
    broadcastTransceiver?.send({
      key: BroadcastKey.SET_HIGHLIGHTED,
      value: {
        elements: pickedIfcsRef.current,
        color: "#00FF00",
      },
    });
    broadcastTransceiver?.send({
      key: BroadcastKey.SET_HIGHLIGHTED,
      value: {
        elements: currentIfcsRef.current,
        color: "#0000FF",
      },
    });
    if (pickedIfcsRef.current) {
      broadcastTransceiver?.send({
        key: BroadcastKey.SET_HIGHLIGHTED,
        value: {
          elements: pickedIfcsRef.current.filter((x) =>
            currentIfcsRef.current.includes(x)
          ),
          color: "#FF0000",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickedIfcs, currentIfcs]);

  useEffect(() => {
    if (fixImageTimeoutHandle.current) {
      clearTimeout(fixImageTimeoutHandle.current);
    }
    fixImageTimeoutHandle.current = setTimeout(async () => {
      if (!state.project) return;
      let images = cameraImages;
      if (selectedDate && cameraId) {
        if (
          selectedDate.getMonth() !== loadedDate.getMonth() ||
          selectedDate.getFullYear() !== loadedDate.getFullYear() ||
          selectedDate.getDate() !== loadedDate.getDate()
        ) {
          let startDate = new Date(selectedDate);
          startDate.setHours(0);
          let endDate = new Date(startDate);
          endDate.setDate(endDate.getDate() + 1);

          images = await getProjectCameraImages(
            state.project.id,
            cameraId,
            startDate,
            endDate
          );
          setCameraImages(images);
          setLoadedDate(selectedDate);
        }
      }
      if (selectedDate && images.length > 0) {
        let selectedImages = images.filter(
          (x) => new Date(x.date).getTime() < selectedDate.getTime()
        );
        selectedImages = selectedImages.sort(
          (a, b) => b.date.getTime() - a.date.getTime()
        );
        if (selectedImages.length > 0) {
          let image = images.findIndex((x) => x.id === selectedImages[0].id);
          setImageIndex(image);
          setImage(selectedImages[0]);
          let imageActivities = cameraActivities.filter((x) => {
            let caDate = new Date(x.detectionStartDate);
            let actualCaDate = new Date(
              caDate.getFullYear(),
              caDate.getMonth() + 1,
              caDate.getDate(),
              caDate.getHours()
            );
            let imageDate = new Date(selectedImages[0].date);
            let actualImageDate = new Date(
              imageDate.getFullYear(),
              imageDate.getMonth() + 1,
              imageDate.getDate(),
              imageDate.getHours()
            );
            if (actualImageDate.getTime() === actualCaDate.getTime()) {
              return true;
            }
            return false;
          });
          setImageActivities(imageActivities);
        }
      }
    }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cameraId, selectedDate]);
  const [currentVoxelData, setCurrentVoxelData] = useState<number[][]>([]);
  const [linkedVoxelArary, setLinkedVoxelArary] = useState<LinkedVoxelArary[]>(
    []
  );
  const selectyVoxel = async (voxel: LinkedVoxelArary) => {
    let voxelData: number[][] = [];
    for (let dataPoint of voxel.dataPoints) {
      voxelData.push([dataPoint.x, dataPoint.z, -dataPoint.y]);
    }
    setCurrentVoxelData(voxelData);
  };
  const selectActivityVoxel = async (activity: Activity) => {
    if (!state.project) return;
    let voxelArray = await getLinkedVoxelArary(state.project.id, activity.id);
    setLinkedVoxelArary(voxelArray);

    //(window as any).showpos([dataPoint.x,dataPoint.z,-dataPoint.y]);
  };

  const onClickGantt = (sched: PlanningTask) => {
    setSelectedSchedule(sched);
    console.log(sched);
    setPickedIfcs([]);
    setCurrentIfcs(sched.ifcElements.map((x) => x.ifcElement));
    highlightTaskIfcElements();
  };

  useEffect(() => {
    if (!state.model) return;
    if (!state.project?.id) return;

    getAllTasksForPlanning(state.project!.id).then((x) => {
      let newTasks: Task[] = [];
      let newPlanningTasks: PlanningTask[] = [];
      //for (let plan of x) {
      let [createdTask, pts] = recursiveConvertToTask(x, undefined, 1);
      newTasks = [...newTasks, ...createdTask];
      newPlanningTasks = [...newPlanningTasks, ...pts];
      //}
      setPlanningTasks(newPlanningTasks);
      console.log("npt", newPlanningTasks);
      console.log("nt", newTasks);
      setTasks(newTasks);
      setPlannings([
        {
          id: "",
          name: "",
          description: "",
          version: "",
          planningTasks: [], //newPlanningTasks
        },
      ]);
    });
  }, [state.model]);

  const generateBroadcastConsumer = () => {
    return {
      loadedModel: () => {},
      savePosition: (pos) => {},
    } as BroadcastConsumer;
  };
  const getClosestDriftCam = (camera: ProjectCameraResponse): Drift | null => {
    if (targetDate === null) return null;
    return camera.drifts.reduce(
      (prev: Drift, curr: Drift) => {
        let currDate = new Date(curr.driftDate);
        let prevDate = prev ? new Date(curr.driftDate) : null;

        if (!prevDate) {
          return curr;
        }

        let currDiff: number = Math.abs(
          currDate.getTime() - targetDate.getTime()
        );
        let prevDiff: number = Math.abs(
          prevDate.getTime() - targetDate.getTime()
        );

        return currDiff < prevDiff ? curr : prev;
      },
      {
        id: "",
        cameraData: camera.cameraData,
        driftDate: "",
      }
    );
  };

  const selectCam = async (camId: string) => {
    if (!state.project) return;
    try {
      if (tabValueRef.current === 0) {
        let camera = camerasRef.current.find((x) => x.id === camId);
        if (camera) {
          if (
            camera.drifts &&
            camera.drifts.length > 0 &&
            targetDate !== null
          ) {
            let closestDrift = getClosestDriftCam(camera);
            if (closestDrift !== null)
              (window as any).setPosition(
                JSON.stringify(closestDrift.cameraData)
              ); //todo refactor
          } else {
            (window as any).setPosition(JSON.stringify(camera.cameraData)); //todo refactor
          }
        }
      }
    } catch (ex) {}
    setCameraActivities([]);
    setCameraId(camId);
    setCameraActivities(
      await getProjectCameraActivity(state.project.id, camId)
    );
  };

  const cleanSetPickedIfcs = (ifcs: string[]) => {
    setPickedIfcs(null);
    setTimeout(() => {
      setPickedIfcs(JSON.parse(JSON.stringify(ifcs)));
      highlightTaskIfcElements();
    }, 100);
  };

  const onSaveAllChanges = async () => {
    if (!state.project?.id) return;
    if (pickedIfcsRef.current?.length !== 0 && selectedSchedule) {
      let newelements = pickedIfcsRef.current ? pickedIfcsRef.current : [];
      let attachedelements = [...newelements, ...currentIfcsRef.current];
      let excluded = newelements.filter((x) =>
        currentIfcsRef.current.includes(x)
      );
      attachedelements = attachedelements.filter((x) => !excluded.includes(x));
      setElementsForSchedule(
        state.project?.id,
        selectedSchedule.id,
        attachedelements
      ).then((x) => {
        // selectedSchedule.ifcElements = attachedelements.map(x => ({
        //     id: 0,
        //     PlanningTaskId: "",
        //     ifcElement: x
        // }));
        setPickedIfcs([]);
        setCurrentIfcs(attachedelements);
        highlightTaskIfcElements();
      });
    }
    if (modifiedTasks.length !== 0 || createdTasks.length !== 0) {
      for (let task of createdTasks) {
        const newSched = await createTask(state.project?.id, plannings[0].id, {
          name: task.name,
          plannedStart: task.start,
          plannedEnd: task.end,
          percentage: 0,
        } as PlanningTaskScheduleRequest);
        task.id = newSched;
        console.log(newSched);
      }
      for (let task of modifiedTasks) {
        await patchPlanningTask(task.id, {
          name: task.name,
          plannedStart: task.start,
          plannedEnd: task.end,
          percentage: task.progress,
        } as PlanningTaskScheduleRequest);
      }

      setCreatedTasks([]);
      setModifiedTasks([]);
    }
  };

  // const applyIfcElements = () => {
  //     if (!state.planningTask || !state.planning || !selectedSchedule) return;
  //     attachElementsToSchedule(state.planning!.id, state.planningTask!.id, selectedSchedule.id, pickedIfcs ? pickedIfcs : []).then(x => {
  //         alert("attached");
  //     })
  // }

  const onPicked = (id: string) => {
    console.log("picked", id);
    let cur: string[] = pickedIfcsRef.current ? pickedIfcsRef.current : [];
    let ifcs = [...cur];
    if (ifcs.find((x) => x === id)) {
      ifcs = ifcs.filter((x) => x !== id);
    } else {
      ifcs.push(id);
    }
    cleanSetPickedIfcs(ifcs);
  };

  const theme = createTheme({
    components: {
      // Name of the component
      MuiListItemText: {
        styleOverrides: {
          // Name of the slot
          root: {
            "& .MuiListItemText-primary": {
              fontSize: "0.75rem", // Replace with your desired font size
            },
            "& .MuiListItemText-secondary": {
              fontSize: "0.6rem", // Replace with your desired font size
            },
          },
        },
      },
    },
  });

  useEffect(() => {
    setIfcsRange([]);
  }, [currentActivities]);
  const selectActivity = (
    activity: Activity,
    taskActivityLink: TaskActivityLink,
    activityDetection: ActivityDetection
  ) => {
    const multiplier = 2;
    // //if ((window as any).multiplier) {
    // //    multiplier = (window as any).multiplier;
    // //}
    const width = activityDetection.w / (3840 / multiplier);
    const height = activityDetection.h / (2160 / multiplier);
    setImageDetection({
      center_X: activityDetection.x / (3840 / multiplier) + width / 2,
      center_Y: activityDetection.y / (2160 / multiplier) + height / 2,
      width,
      height,
      //confidence_score: 0,
      activityLabel: "",
      detectionStartDate: "",
      detectionEndDate: "",
      projectCameraId: "",
      aiconImageId: "",
      id: "",
    });
    setPickedIfcs([]);
    selectCam(
      camerasRef.current![Number(activity.name.replace("aicha", "")) - 46].id
    );

    if (selectedDate) {
      if (activity.taskActivityLinks.length > 0) {
        let start = activity.taskActivityLinks[0].startDate;
        setTargetDate(new Date(start));
      }
    }
  };
  useEffect(() => {}, [currentActivities]);
  const [highlightedCameras, setHighlightedCameras] = useState<number[]>([]);
  const setCurrentActivitiesAndHighlightedTasks = (acts: Activity[]) => {
    setCurrentActivities(acts);
  };

  return (
    <Drawer
      open={
        state.model !== null && state.visualiseType === VisualiseType.Planning
      }
      anchor="right"
      variant="temporary"
      onClose={() => {
        dispatch(clearSelectedModel());
      }}
      sx={{
        display: {
          xs: "none",
          sm: "block",
          width: "90%",
        },
        "& .MuiDrawer-paper": {
          boxSizing: "border-box",
          width: "90%",
          overflow: "hidden",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Tabs
          value={tabValue}
          onChange={tabHandleChange}
          sx={{ position: "absolute", zIndex: 1000 }}
        >
          <Tab value={0} label="3DView" />
          <Tab value={1} label="PlanView" />
        </Tabs>
        <Card
          sx={{
            width: "fill-available",
            p: "2px",
            m: "10px",
            ml: "5px",
            height: "100%",
          }}
        >
          <Box sx={{ height: "50vh", display: "flex", flexDirection: "row" }}>
            <Box sx={{ width: "45%" }}>
              {modelLoading ? (
                <LoadingIndicator />
              ) : modelUrn ? (
                <IfcViewer
                  voxelData={currentVoxelData}
                  url={modelUrn}
                  hideControls={tabValue === 1}
                  showViewerCube={false}
                  onTokenRequest={function (
                    tokenRequestCallback: TokenRequestCallback
                  ): void {
                    //throw new Error("Function not implemented.");
                  }}
                  initializeCallbacks={function (
                    viewer: any,
                    viewerCallbacks: ViewerCallbacks
                  ): void {
                    let fbt = new ForgeBroadcastTransceiver(
                      null,
                      true,
                      viewerCallbacks,
                      generateBroadcastConsumer()
                    );
                    setBroadcastTransceiver(fbt);
                    //throw new Error("Function not implemented.");
                  }}
                  loadedDocumentCallback={function (): void {
                    let cameraData = state.project?.projectMeta?.find(
                      (x) => x.metaType === "CameraData"
                    );
                    if (!cameraData) return;
                    console.log("camdata", cameraData, broadcastTransceiver);

                    broadcastTransceiver?.send({
                      key: BroadcastKey.LOAD_POSITION,
                      value: cameraData.data,
                    });
                    //throw new Error("Function not implemented.");
                  }}
                  onPicked={onPicked}
                  savePositionCallback={function (posObject: string): void {
                    //throw new Error("Function not implemented.");
                  }}
                  selectedItem={(x) => {
                    selectCam(camerasRef.current![x].id);
                    setHighlightedCameras([]);
                  }}
                  cameras={cameras}
                  type={ModelViewerType.Ifc}
                  trueColor={true}
                  highlighedIfcs={[]}
                  visibleIfcs={ifcsRange}
                  transparent={false}
                  highlightedCameras={highlightedCameras}
                />
              ) : (
                <></>
              )}
            </Box>

            <Box sx={{ width: "45%", position: "relative" }}>
              <Card
                sx={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  zIndex: 999,
                  display: currentActivities?.length > 0 ? "block" : "none",
                }}
              >
                <ThemeProvider theme={theme}>
                  <List sx={{ padding: 1 }}>
                    {linkedVoxelArary.length !== 0 &&
                      linkedVoxelArary.map((x) => {
                        return (
                          <ListItem>
                            <Button onClick={() => selectyVoxel(x)}>
                              {x.id}
                            </Button>
                          </ListItem>
                        );
                      })}
                  </List>
                </ThemeProvider>
              </Card>
              <div style={{ position: "relative" }}>
                {imageIndex !== -1 &&
                  cameraImages.length > imageIndex &&
                  !!cameraImages[imageIndex].url && (
                    <img
                      id="bobImg"
                      alt="selected camera and date"
                      style={{
                        width: "100%",
                        minHeight: "500px",
                        display: "block",
                      }}
                      src={cameraImages[imageIndex].url}
                    />
                  )}
                {imageDetection ? (
                  <div
                    style={{
                      position: "absolute",
                      top: imageDetection.center_Y * 100 + "%",
                      left: imageDetection.center_X * 100 + "%",
                      width: imageDetection.width * 100 + "%",
                      height: imageDetection.height * 100 + "%",
                      border: "2px solid red",
                      background: "rgba(255,0,0,0.2)",
                    }}
                  >
                    {imageDetection.activityLabel}
                  </div>
                ) : null}
              </div>
              Detections:
              {imageActivities.map((x) => (
                <Button
                  onClick={() => {
                    setImageDetection(x);
                  }}
                >
                  {x.activityLabel}
                </Button>
              ))}
            </Box>

            <Box sx={{ width: "10%", overflowY: "scroll" }}>
              <ThemeProvider theme={theme}>
                {/* todo here */}
                <List sx={{ padding: 1 }}>
                  {currentActivities.length !== 0 &&
                    currentActivities
                      .filter((x) => {
                        if (!selectedDate) return false;
                        let startDate = new Date(x.startDate);
                        return compareDayHours(startDate, selectedDate);
                        //return false;
                      })
                      .map(
                        (x, y) => {
                          return (
                            <ListItem>
                              <Button onClick={() => selectActivityVoxel(x)}>
                                {x.id}
                              </Button>
                            </ListItem>
                          );
                        }
                      )}
                </List>
              </ThemeProvider>
              <ThemeProvider theme={theme}>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemButton
                    onClick={() => setCurrentActivitiesAndHighlightedTasks([])}
                  >
                    <ListItemText primary="Clear" />
                  </ListItemButton>
                </ListItem>

                {Object.keys(activities).map((x) => (
                  <ListItem sx={{ padding: 0 }}>
                    <ListItemButton
                      onClick={() =>
                        setCurrentActivitiesAndHighlightedTasks(activities[x])
                      }
                    >
                      <ListItemText primary={x} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </ThemeProvider>
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="success"
              disabled={
                (pickedIfcs === null || pickedIfcs.length === 0) &&
                modifiedTasks.length === 0 &&
                createdTasks.length === 0
              }
              onClick={onSaveAllChanges}
            >
              Save Changes
            </Button>
          </Box>
          <Box sx={{ mt: 1 }}>
            <DateTimePicker
              value={dayjs(selectedDate)}
              onChange={(newValue: any) => {
                if (!newValue) return;
                setTargetDate(newValue.toDate());
                setSelectedDate(newValue.toDate());
              }}
            />
          </Box>

          <Box sx={{ width: "100%", mt: 10 }}>
            <div style={{ height: "50vh", overflowY: "auto" }}>
              {plannings.length > 0 ? (
                <BobPlanningGantt
                  planning={plannings}
                  onClickGantt={(e) => onClickGantt(e)}
                  onIfcRangeChange={(ifcs: string[]): void => {
                    console.log(ifcsRange);
                    if (currentActivities.length > 0) return;
                    setIfcsRange(ifcs);
                  }}
                  targetDate={targetDate}
                  onDateSelected={(date: Date): void => {
                    console.log(date);
                    setTargetDate(null);
                    setSelectedDate(date);
                  }}
                  onTaskAdded={(task: Task): void => {
                    createdTasks.push(task);
                    setCreatedTasks([...createdTasks]);
                  }}
                  onTaskModified={(task: Task): void => {
                    let createdTask = createdTasks.findIndex(
                      (x) => x.id === task.id
                    );
                    if (
                      createdTask === -1 &&
                      !modifiedTasks.find((x) => x.id === task.id)
                    ) {
                      modifiedTasks.push(task);
                      setModifiedTasks([...modifiedTasks]);
                    }
                    if (createdTask !== -1) {
                      createdTasks[createdTask] = task;
                      setCreatedTasks([...createdTasks]);
                    } else {
                      let modifiedTask = modifiedTasks.findIndex(
                        (x) => x.id === task.id
                      );
                      if (modifiedTask !== -1) {
                        modifiedTasks[modifiedTask] = task;
                        setModifiedTasks([...modifiedTasks]);
                      }
                    }
                  }}
                  highlightedTasks={highlightedTasks}
                  activityTaskSelected={(taskIndex: number) => {
                    //setCurrentActivities(currentActivities.filter(x => x.taskID === PlanningTasks[taskIndex].taskId));
                    //setHighlightedTasks([]);
                  }}
                  tasks={tasks}
                  setTasks={setTasks}
                  planningTasks={PlanningTasks}
                />
              ) : (
                <></>
              )}
            </div>
          </Box>
        </Card>
      </Box>
    </Drawer>
  );
};

export default BobProjectModelDetail;
