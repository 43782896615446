import { Box, Card, Checkbox, IconButton, List, ListItem, ListItemText, styled, Typography } from "@mui/material";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";
import {
    faArrowsUpDown, faBan,
    faCamera,
    faDiagramNested,
    faLayerGroup,
    faLayerPlus, faLevelUp,
    faListTree, faLongArrowDown, faLongArrowLeft, faLongArrowRight, faLongArrowUp, faRuler
} from "@fortawesome/pro-duotone-svg-icons";
import { DistanceMeasurementsPlugin, SectionPlane, SectionPlanesPlugin, Viewer } from "@xeokit/xeokit-sdk";
import { useState } from "react";

interface IfcViewerActionsProps {
    viewer: Viewer;
    xktSectionPlanesPlug: SectionPlanesPlugin;
    distanceMeasurementPlug: DistanceMeasurementsPlugin;
    setTreeViewMode: (tvm: ETreeViewMode) => void;
    treeOpen: boolean;
    treeViewMode: ETreeViewMode;
    cameras?: any;//todo fix
    onCameraPicked?: (index: number) => void;
}


export const IfcViewerActions = ({ viewer, xktSectionPlanesPlug, distanceMeasurementPlug, setTreeViewMode, treeOpen, treeViewMode,cameras,onCameraPicked }: IfcViewerActionsProps) => {

    const [sectionPlanes, setSectionPlanes] = useState<SectionPlane[]>([]);
    const createSectionPlane = (rotation: number[]) => {
        if (!viewer) return;
        if (!xktSectionPlanesPlug) return;
        const sectionPlane = new SectionPlane(viewer.scene, {
            pos: [1.0, 1.0, 1.0],
            dir: rotation,
            active: true
        });
        //setTimeout(()=>{
        xktSectionPlanesPlug.showControl(sectionPlane.id);
        setSectionPlanes([...sectionPlanes, sectionPlane])
        //},500);
    };

    const mirrorSectionPlane = (sc: SectionPlane) => {
        sc.flipDir();
    }

    const toggleSectionPlane = (sc: SectionPlane) => {
        if (!xktSectionPlanesPlug) return;
        sc.active = !sc.active;
        setSectionPlanes([...sectionPlanes]);
        xktSectionPlanesPlug.hideControl();
    }
    const [sectionsOpen, setSectionsOpen] = useState<boolean>(false);
    const [sectionsChoicesOpen, setSectionsChoicesOpen] = useState<boolean>(false);
    const [cameraPickOpen, setCameraPickOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        console.log(event.currentTarget);
        setAnchorEl(event.currentTarget);
        //setOpen((prev) => placement !== newPlacement || !prev);
        //setPlacement(PopperPlacementType);
    };
    const focusSelection = (selection: SectionPlane) => {
        if (!xktSectionPlanesPlug) return;
        xktSectionPlanesPlug.showControl(selection.id);
    };
    return <><ActionsBarWrapper>
        <ActionsBar>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <ActionsBarSection>
                    <ActionsBarItem>
                        {sectionsChoicesOpen ?
                            <Card
                                sx={{
                                    position: "absolute",
                                    transform: "translateY(calc(-100% - 15px)) translateX(-25%)",
                                    backgroundColor: "rgba(34,34,34,.94)",
                                    color: "white",
                                    minWidth: "300px"
                                }}
                            >
                                <List>
                                    {
                                        sectionCreationDefinition.map(x => (
                                            <SectionListItem><ListItemText
                                                onClick={() => createSectionPlane(x.rotation)}>
                                                <FontAwesomeSvgIcon sx={{ mr: 1, pt: 1 }} icon={x.icon} />
                                                <Typography variant="caption"> {x.text}</Typography>
                                            </ListItemText></SectionListItem>
                                        ))
                                    }
                                </List>
                            </Card> : null}
                        <ActionsIconButton>
                            {/*<FontAwesomeSvgIcon icon={faLayerPlus} />*/}
                            <FontAwesomeSvgIcon icon={faLayerPlus}
                                onClick={() => {
                                    setSectionsChoicesOpen(!sectionsChoicesOpen);
                                    setSectionsOpen(false);
                                }} />
                        </ActionsIconButton>
                    </ActionsBarItem>
                    <ActionsBarItem>
                        {sectionsOpen ?
                            <Card
                                sx={{
                                    position: "absolute",
                                    transform: "translateY(calc100% - 15px)) translateX(-25%)",
                                    backgroundColor: "rgba(34,34,34,.94)",
                                    color: "white",
                                    minWidth: "300px"
                                }}
                            >
                                <List>
                                    {
                                        sectionPlanes.map(x => (<SectionListItem onClick={() => focusSelection(x)}>
                                            <ListItemText>
                                                <Checkbox checked={x.active} onChange={() => {
                                                    toggleSectionPlane(x)
                                                }}
                                                    sx={{
                                                        color: "white",
                                                        '&.Mui-checked': {
                                                            color: "white",
                                                        },
                                                    }}
                                                />
                                                <FontAwesomeSvgIcon sx={{ mr: 1, pt: 1 }} icon={faArrowsUpDown}
                                                    onClick={() => {
                                                        mirrorSectionPlane(x)
                                                    }} />
                                                Section {x.id}
                                            </ListItemText>
                                        </SectionListItem>))
                                    }
                                </List>
                            </Card> : null}
                        <ActionsIconButton onClick={(e) => {
                            setSectionsOpen(!sectionsOpen);
                            setSectionsChoicesOpen(false);
                        }}>
                            <FontAwesomeSvgIcon icon={faLayerGroup} />
                        </ActionsIconButton>
                    </ActionsBarItem>
                </ActionsBarSection>

                <ActionsBarSection sx={{ ml: 1 }}>

                    <ActionsBarItem>
                        <ActionsIconButton onClick={() => {
                            setTreeViewMode(ETreeViewMode.treeView)
                        }}
                            sx={{
                                boxShadow: treeOpen && treeViewMode == ETreeViewMode.treeView ? "inset 0 2px 2px 0 #000;" : "none",
                                position: "relative"
                            }}>
                            <FontAwesomeSvgIcon icon={faListTree} />
                        </ActionsIconButton>
                    </ActionsBarItem>
                    <ActionsBarItem>
                        <ActionsIconButton onClick={() => {
                            setTreeViewMode(ETreeViewMode.groupView)
                        }}
                            sx={{ boxShadow: treeOpen && treeViewMode == ETreeViewMode.groupView ? "inset 0 2px 2px 0 #000;" : "none" }}>
                            <FontAwesomeSvgIcon icon={faDiagramNested} />
                        </ActionsIconButton>
                    </ActionsBarItem>
                </ActionsBarSection>
                <ActionsBarSection sx={{ ml: 1 }}>

                    <ActionsBarItem>
                        <ActionsIconButton
                            onClick={() => {
                                distanceMeasurementPlug.control.activate()
                            }}>
                            <FontAwesomeSvgIcon icon={faRuler} />
                        </ActionsIconButton>

                    </ActionsBarItem>
                    <ActionsBarItem>
                        <ActionsIconButton
                            onClick={() => {
                                let keys = Object.keys(distanceMeasurementPlug.measurements);
                                for (let meas of keys) {
                                    distanceMeasurementPlug.measurements[meas].visible = false;
                                    delete distanceMeasurementPlug.measurements[meas];
                                }
                            }}>
                            <FontAwesomeSvgIcon icon={faBan} />
                        </ActionsIconButton>
                    </ActionsBarItem>
                </ActionsBarSection>
                {/*  */}
                {cameras ?
                    <ActionsBarSection sx={{ ml: 1 }}>
                        <ActionsBarItem>
                            {cameraPickOpen ?
                                <Card
                                    sx={{
                                        position: "absolute",
                                        transform: "translateY(calc(-100% - 15px)) translateX(-25%)",
                                        backgroundColor: "rgba(34,34,34,.94)",
                                        color: "white",
                                        minWidth: "300px"
                                    }}
                                >
                                    <List>
                                        {
                                            cameras.map((x:any,y:number) => (
                                                <SectionListItem><ListItemText
                                                    onClick={() => {
                                                        onCameraPicked && onCameraPicked(y);
                                                        setCameraPickOpen(false);
                                                    }}>
                                                    <Typography variant="caption"> Camera-{y}</Typography>
                                                </ListItemText></SectionListItem>
                                            ))
                                        }
                                    </List>
                                </Card> : null}
                            <ActionsIconButton>
                                {/*<FontAwesomeSvgIcon icon={faLayerPlus} />*/}
                                <FontAwesomeSvgIcon icon={faCamera}
                                    onClick={() => {
                                        setCameraPickOpen(!cameraPickOpen);
                                        setSectionsOpen(false);
                                    }} />
                            </ActionsIconButton>
                        </ActionsBarItem>
                    </ActionsBarSection> :
                    <></>
                }
                {/*  */}

            </div>
        </ActionsBar>
    </ActionsBarWrapper>

    </>
}



const ActionsBarWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    height: "100%",
}));
const ActionsBar = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: "70px",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
    zIndex: "999999999"
}));
const ActionsBarSection = styled(Box)(({ theme }) => ({
    backgroundColor: "rgba(34,34,34,.94)",
    boxShadow: "1px 3px 10px 0 rgb(0 0 0 / 50%)",
    borderRadius: "5px",
    color: "#f4f4f4",
    padding: "4px",
    flexDirection: "row",
    display: "flex"
}));
const ActionsBarItem = styled(Box)(({ theme }) => ({
    position: "relative",
    padding: "2px",
    border: "1px solid transparent",
    borderRadius: "5px",
    color: "white",
    "&:hover": {
        color: "blue",
        border: "1px solid cyan"
    },
}));
const ActionsIconButton = styled(IconButton)(({ theme }) => ({
    color: "white",
    borderRadius: "1px",
    "&:hover": {
        color: "#1291ff",
    },
}));

const SectionListItem = styled(ListItem)(({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
        backgroundColor: "rgba(34,34,150,.94)",
    },
}));

interface SectionDefinition {
    icon: any;
    text: string;
    rotation: number[];
}

const sectionCreationDefinition: SectionDefinition[] = [
    { text: "Horizontal", icon: faLevelUp, rotation: [0, -1, 0] },
    { text: "Vertical", icon: faLongArrowUp, rotation: [0, 0, 1] },
    { text: "Vertical", icon: faLongArrowDown, rotation: [0, 0, -1] },
    { text: "Vertical", icon: faLongArrowLeft, rotation: [1, 0, 0] },
    { text: "Vertical", icon: faLongArrowRight, rotation: [-1, 0, 0] },
]

export enum ETreeViewMode {
    treeView,
    groupView
}
