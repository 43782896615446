import axios, {AxiosRequestConfig, AxiosResponse, CancelToken} from "axios";
import {configuration} from "../_Configuration/configuration";


export const getConversionStatus = async (modelId:string): Promise<any> => {
    const result = await axios.get(`api/ConversionStatus/Ifc/${modelId}`, {baseURL: configuration.api});

    return result.data as any;
};


