import {Box} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ForgeBroadcastTransceiver, {
    BroadcastKey
} from "../../Components/ModelViewer/ForgeViewer/ForgeBroadcastTransceiver";
import {getForgeToken} from "../../Connectors/forgeDms";
import {GlobalContext} from "../../Context/GlobalContext/GlobalContextProvider";
import {collapseApplicationbar, collapseSidebar} from "../../Context/GlobalContext/Reducer";
import {ModelViewerType} from "../../Components/ModelViewer/ModelViewerProperties";
import ModelViewer from "../../Components/ModelViewer";


const HealthCheckViewer = () => {
    const {state, dispatch} = useContext(GlobalContext);
    let {modelUrn, broadcastId, fileType} = useParams();
    const [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel | null>(null);
    const [broadcastTransceiver, setBroadcastTransceiver] = useState<ForgeBroadcastTransceiver | null>(null);
    let [modelLoaded, setModelLoaded] = useState<boolean>(false);

    useEffect(() => {
        dispatch(collapseSidebar());
        dispatch(collapseApplicationbar());
    }, []);
    useEffect(() => {
        if (modelLoaded && broadcastTransceiver) {
            broadcastTransceiver?.send({key: BroadcastKey.LOADED_VIEWER});
        }
    }, [broadcastTransceiver, modelLoaded]);
    return modelUrn && state.applicationBarCollapsed ?
        <Box sx={{
            position: "fixed",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            height: "100%",
            width: "100%",
        }}>

            <ModelViewer       
            showViewerCube={true}
                         url={modelUrn}
                         type={fileType=== "ifc" ? ModelViewerType.Ifc : ModelViewerType.Forge}
                         initializeCallbacks={(a, c) => {
                             if (broadcastId) {
                                 let bc = broadcastChannel;
                                 if (!bc) {
                                     bc = new BroadcastChannel(broadcastId);
                                     setBroadcastChannel(bc);
                                 }
                                 if (bc) {
                                     let t = new ForgeBroadcastTransceiver(bc, false, c, null);
                                     setBroadcastTransceiver(t);
                                 }
                             }
                         }}
                         loadedDocumentCallback={() => {
                             setModelLoaded(true);
                         }}
                         savePositionCallback={(pos) => {
                             broadcastTransceiver?.send({key: BroadcastKey.SAVE_POSITION, value: pos});
                         }}
                         onTokenRequest={(callback) => {
                             getForgeToken().then(x => {
                                console.log("token",x);
                                (window as any).token = x;
                                 if (x != null) {
                                     callback(x, 36000)
                                 }
                             })
                         }
                         }/>
        </Box>
        : <></>;
};

export default HealthCheckViewer;