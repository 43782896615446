import { useContext, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ContentDrawer from "../../Components/ContentDrawer";
import ForgeDocumentManagementContent from "../../Components/ForgeDocumentManagement/ForgeDocumentManagementContent";
import ForgeDocumentManagementTree from "../../Components/ForgeDocumentManagement/ForgeDocumentManagementTree";
import { addDocument as addDocumentRequest, addFolder, setDataFolderForgeLink } from "../../Connectors/dataFolders";
import { ForgeDocumentBrowserContext } from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import { closeForgeDocumentDrawer, findItem } from "../../Context/ForgeDocumentBrowserContext/Reducer";
import { HealthCheckContext } from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import { HealthCheckDocumentsContext } from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";


import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import {EForgeBrowseMode, ForgeDocumentBrowserItem} from "../../Context/ForgeDocumentBrowserContext/State";
import { setDocuments } from "../../Context/HealthCheckDocumentsContext/Reducer";
import {uuid4} from "@sentry/utils";
import {DataFolderDocument} from "../../Connectors/_Models/DataFolderDocument";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(1),
    userSelect: "none",
    maxHeight: "calc(100vh - 94px)",
    flex: 1,
    ...GetScrollbarStyle(theme),
}));

export const ScrollContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(1),
    userSelect: "none",
    overflow: "auto",
    maxHeight: "calc(100vh - 94px)",
    minWidth: 250,
    ...GetScrollbarStyle(theme),
}));

const ForgeDocumentSelectionDrawer = () => {
    const { state: healthCheckState } = useContext(HealthCheckContext);
    const { state: healthCheckDocumentsState, dispatch: healthCheckDocumentsDispatch } = useContext(HealthCheckDocumentsContext);

    const { state: forgeDocumentState, dispatch: forgeDocumentDispatch } = useContext(ForgeDocumentBrowserContext);
    const [adding, setAdding] = useState(false);

    const onClose = () => {
        forgeDocumentDispatch(closeForgeDocumentDrawer());
    };

    const onAddFiles = () => {
        setAdding(true);
        const promises = [];

        for (let i = 0; i < forgeDocumentState.selection.length; i++) {
            const id = forgeDocumentState.selection[i];
            const file = findItem(forgeDocumentState.root!, id);

            if (file) {
                promises.push(
                    addDocumentRequest(healthCheckDocumentsState.activeDataFolder!.id, {
                        fileName: file.displayName,
                        autoTrackChanges: false,
                        externalId: file.id!,
                    }).then((data) => {
                        if (healthCheckDocumentsState.activeDataFolder) {
                            if (!healthCheckDocumentsState.activeDataFolder.documents) healthCheckDocumentsState.activeDataFolder.documents = [];
                            healthCheckDocumentsState.activeDataFolder.documents.push(data);
                            healthCheckDocumentsDispatch(setDocuments(healthCheckDocumentsState.activeDataFolder.documents));
                        }
                    })
                );
            }
        }

        Promise.all(promises).then(() => {
            setAdding(false);
            onClose();
        });
    };

    const addCurrentFolder = async () => {
        if (!forgeDocumentState.currentFolder || !forgeDocumentState.root?.items) return;
        if (!forgeDocumentState.linkFolder) return;
        let folder: ForgeDocumentBrowserItem | null = null;
        for (let item of forgeDocumentState.root.items) {
            if (item.id == forgeDocumentState.currentFolder) {
                folder = item;
                break;
            }
        }
        if (folder === null) return;

        await setDataFolderForgeLink(forgeDocumentState.linkFolder.id, {
            hub: healthCheckState.forgeHub ? healthCheckState.forgeHub : "",
            project: healthCheckState.forgeProject ? healthCheckState.forgeProject : "",
            folder: folder.id,
        });
        onClose();
    };


    // TODO: Remove ContentDrawer and implement logic directly
    return (
        <ContentDrawer
            title="Add a BIM360 Document"
            open={forgeDocumentState.forgeDocumentSelectionDrawerActive}
            onClose={onClose}
            width={"50%"}
            action={
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {forgeDocumentState.selection.length > 0 && (
                        <Typography variant="body2" component="span" sx={{ mr: 2 }}>
                            {forgeDocumentState.selection.length === 1 && "1 file selected."}
                            {forgeDocumentState.selection.length > 1 && `${forgeDocumentState.selection.length} files selected.`}
                        </Typography>
                    )}

                    <Box sx={{ m: 1, ml: "auto", position: "relative" }}>
                        {forgeDocumentState.forgeBrowseMode === EForgeBrowseMode.File? <Button variant="contained" disabled={forgeDocumentState.selection.length === 0 || adding} onClick={onAddFiles}>
                            Add selected files
                        </Button>
                            : <Button variant="contained" sx={{ mr: 1 }} onClick={() => { addCurrentFolder() }}>
                            Link selected folder
                        </Button>
                        }
                        {adding && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Box>
                </Box>
            }
        >
            <>
                {healthCheckState.forgeHub && healthCheckState.forgeProject && healthCheckState.forgeRootFolder && (
                    <>
                        <ScrollContainer>
                            <ForgeDocumentManagementTree
                                hub={healthCheckState.forgeHub}
                                project={healthCheckState.forgeProject}
                                folder={healthCheckState.forgeRootFolder}
                            />
                        </ScrollContainer>
                        <Container sx={{ pl: 2 }}>
                            <ForgeDocumentManagementContent />
                        </Container>
                    </>
                )}
            </>
        </ContentDrawer>
    );
};

export default ForgeDocumentSelectionDrawer;
