import { ForgeCallbacks } from "./ForgeCallbacks";
import ForgeViewer from "./ForgeViewer";
import { ViewerCallbacks } from "../ModelViewerProperties";

export enum BroadcastKey {
    SET_HIGHLIGHTED = "setHighlighted",
    CLEAR_ALL = "clearAll",
    CLEAR_ITEMS = "clearItems",
    LOADED_VIEWER = "loadedViewer",
    LOAD_POSITION = "loadPosition",
    SAVE_POSITION = "savePosition"
}

interface BroadcastData {
    key: BroadcastKey;
    value?: any;
}

export interface BroadcastConsumer {
    loadedModel: () => void;
    savePosition: (posObject: string) => void;
    onPicked?: (id: string) => void;
}

export default class {
    private broadcastChannel: BroadcastChannel | null = null;
    private broadcastConsumer: BroadcastConsumer | null = null;
    private forgeCallbacks: ViewerCallbacks | null = null;
    private local: boolean = false;
    constructor(broadcastChannel: BroadcastChannel | null, local: boolean, forgeCallbacks: ViewerCallbacks | null, broadcastConsumer: BroadcastConsumer | null) {
        this.forgeCallbacks = forgeCallbacks;
        this.broadcastConsumer = broadcastConsumer;
        this.local = local;
        if (broadcastChannel) {
            this.broadcastChannel = broadcastChannel;
            broadcastChannel.onmessage = (a) => {
                let parsed = JSON.parse(a.data) as BroadcastData;
                this.receive(parsed);
            }
        }
    }

    receive(payload: BroadcastData) {
        switch (payload.key) {
            case BroadcastKey.SET_HIGHLIGHTED:
                this.forgeCallbacks?.setHighlighted(payload.value.elements, payload.value.color);
                break;
            case BroadcastKey.CLEAR_ITEMS:
                this.forgeCallbacks?.clearItem(payload.value.elements);
                break;
            case BroadcastKey.CLEAR_ALL:
                this.forgeCallbacks?.clearAllItems();
                break;
            case BroadcastKey.LOAD_POSITION:
                this.forgeCallbacks?.loadPosition(payload.value);
                break;
            case BroadcastKey.LOADED_VIEWER:
                this.broadcastConsumer?.loadedModel();
                break;
            case BroadcastKey.SAVE_POSITION:
                this.broadcastConsumer?.savePosition(payload.value);
                break;
        }
    }

    send(data: BroadcastData) {
 //       if (!this.broadcastChannel) return;
        if (this.local) {
            this.receive(data);
        } else {
            if (!this.broadcastChannel) return;
            this.broadcastChannel.postMessage(JSON.stringify(data));
        }
    }
}