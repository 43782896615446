import { ModelViewerProps } from "../ModelViewerProperties";
import { useEffect, useRef, useState } from "react";
import {
  Viewer,
  XKTLoaderPlugin,
  SectionPlanesPlugin,
  OBJLoaderPlugin,
  NavCubePlugin,
  DistanceMeasurementsPlugin,
  Entity,
  buildSphereGeometry,
  Mesh,
  ReadableGeometry,
  Material,
} from "@xeokit/xeokit-sdk";
import { PhongMaterial } from "@xeokit/xeokit-sdk";
import ScriptLoader from "../../../Utils/ScriptLoader";
import { IfcTreeviewObject, IfcTreeView } from "./IfcTreeView";
import { ETreeViewMode, IfcViewerActions } from "./IfcViewerActions";

interface highlightedIfcsIdAndColor {
  id: string;
  color: number[];
}
const IfcViewer = ({
  url,
  onTokenRequest,
  initializeCallbacks,
  loadedDocumentCallback,
  savePositionCallback,
  selectedItem,
  trueColor,
  cameras,
  highlightedCameras,
  onPicked,
  highlighedIfcs,
  visibleIfcs,
  showViewerCube: ShowViewerCube,
  hideControls,
  transparent,
  voxelData,
}: ModelViewerProps) => {
  const [viewer, setViewer] = useState<Viewer | null>(null);
  const [xktSectionPlanesPlug, setXktSectionPlanesPlug] =
    useState<SectionPlanesPlugin | null>(null);
  const [distanceMeasurementPlug, setDistanceMeasurementPlug] =
    useState<DistanceMeasurementsPlugin | null>(null);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [treeOpen, setTreeOpen] = useState<boolean>(false);
  const [treeView, setTreeview] = useState<IfcTreeviewObject>({
    id: "",
    type: "",
    name: "",
  });
  const [treeViewMode, setTreeViewMode] = useState<ETreeViewMode>(
    ETreeViewMode.treeView
  );
  const [groupView, setGroupView] = useState<IfcTreeviewObject>({
    id: "",
    type: "",
    name: "",
  });
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [currentColorized, setCurrentColorized] = useState<
    highlightedIfcsIdAndColor[]
  >([]);
  const [cameraEntities, setCameraEntities] = useState<Entity[]>([]);
  const viewerRef = useRef(viewer);
  viewerRef.current = viewer;
  useEffect(() => {
    if (!highlighedIfcs) return;
    let newColorized: highlightedIfcsIdAndColor[] = [];
    for (const current of currentColorized) {
      if (viewer?.scene?.objects[current.id]?.colorize) {
        viewer.scene.objects[current.id].colorize = current.color;
      }
    }
    for (const highlighedIfc of highlighedIfcs) {
      if (viewer?.scene?.objects[highlighedIfc]?.colorize) {
        newColorized.push({
          id: highlighedIfc,
          color: JSON.parse(
            JSON.stringify(viewer?.scene?.objects[highlighedIfc]?.colorize)
          ) as number[],
        });
        viewer.scene.objects[highlighedIfc].colorize = [0, 1, 0];
      }
    }
    setCurrentColorized(newColorized);
  }, highlighedIfcs);

  useEffect(() => {
    if (viewer) {
      if (hideControls === true) {
        viewer.cameraControl.dragRotationRate = 0;
        viewer.cameraControl.panRightClick = false;
      } else {
        viewer.cameraControl.dragRotationRate = 360;
        viewer.cameraControl.panRightClick = true;
      }
    }
  }, [hideControls]);

  useEffect(() => {
    if (cameraEntities.length > 0) {
      let colval =
        highlightedCameras && highlightedCameras.length > 0 ? 0.2 : 1;
      console.log("colval", colval);
      for (let ce of cameraEntities) {
        ce.colorize = [colval, colval, colval, 1];
      }
      if (highlightedCameras) {
        for (let hc of highlightedCameras) {
          cameraEntities[hc].colorize = [1, 1, 1, 1];
        }
      }
    }
  }, [highlightedCameras, cameraEntities]);

  useEffect(() => {
    if (!viewer) return;
    if (!visibleIfcs) return;
    Object.keys(viewer.scene.objects).forEach((x) => {
      if (!x.includes("camera")) {
        viewer.scene.objects[x].visible = visibleIfcs.length === 0;
      }
    });
    for (let ce of cameraEntities) {
      ce.visible = true;
    }
    for (let ifc of visibleIfcs) {
      try {
        viewer.scene.objects[ifc].visible = true;
      } catch (ex) {}
    }
  }, [visibleIfcs]);

  const hexToColor = (hex: string) => {
    return [
      Number("0x" + hex.substring(1, 3)) / 255,
      Number("0x" + hex.substring(3, 5)) / 255,
      Number("0x" + hex.substring(5, 7)) / 255,
    ];
  };
  const setHighlightedObjects = (
    elementsId: string[] | number[],
    color?: string
  ) => {
    if (viewerRef.current === null) return;
    if (elementsId === undefined) return;
    if (!elementsId || elementsId.length === 0) return;
    for (let elementId of elementsId) {
      if (viewerRef.current.scene.objects[elementId] !== undefined) {
        if (
          (viewerRef.current.scene.objects[elementId] as any).actualColorize ===
          undefined
        ) {
          //todo remove any & save to a new variable
          (viewerRef.current.scene.objects[elementId] as any).actualColorize =
            viewerRef.current.scene.objects[elementId].colorize;
        }
        viewerRef.current.scene.objects[elementId].colorize = color
          ? hexToColor(color)
          : [0, 0, 0];
        viewerRef.current.scene.objects[elementId].opacity = 1.0;
      }
      //viewerRef.current.cameraFlight.flyTo(viewerRef.current.scene.objects[elementId]);
    }
  };
  const setClearedObjects = (elementsId: string[] | number[]) => {
    if (viewerRef.current === null) return;
    for (let elementId of elementsId) {
      if (viewerRef.current.scene.objects[elementId] !== null) {
        if (transparent === false) {
          if (
            (viewerRef.current.scene.objects[elementId] as any).actualColorize
          ) {
            viewerRef.current.scene.objects[elementId].colorize = (
              viewerRef.current.scene.objects[elementId] as any
            ).actualColorize;
            viewerRef.current.scene.objects[elementId].opacity = 1;
          }
        } else {
          viewerRef.current.scene.objects[elementId].colorize = [0, 0, 0];
          viewerRef.current.scene.objects[elementId].opacity = 0.4;
        }
      }
    }
  };

  const [currentVoxelData, setCurrentVoxelData] = useState<Mesh[]>([]);
  useEffect(() => {
    if (!viewer) return;
    let currentVoxels: Mesh[] = [];
    for (let voxel of currentVoxelData) {
      voxel.destroy();
    }
    if (voxelData) {
      const objLoader = new OBJLoaderPlugin(viewer, {});
      for (let voxel of voxelData) {
        // var model = objLoader.load({
        //   // Model is an Entity
        //   id: new Date().getTime().toString(),
        //   src: "/model/voxel.obj",
        //   position: voxel,
        //   scale: [0.6, 0.6, 0.6],
        // });
        // (model as any).highlight = [0,1,0];
        // (model as any).highlighted = true;

        const model =  new Mesh(viewer.scene, {
          id: "myGroundPlane",
          geometry: new ReadableGeometry(viewer.scene, buildSphereGeometry({
            center: [0,0,0],
            radius: 2.0,
            heightSegments: 60,
            widthSegments: 60
          })),
           material: new PhongMaterial(viewer.scene, {
               diffuse: [0.4, 1.0, 0.4],
               backfaces: true
           }) as Material,
           position: voxel,
           pickable: false,
           collidable: false
          
      });
        currentVoxels.push(model);

      }
    }
    setCurrentVoxelData(currentVoxels);
  }, [voxelData]);
  useEffect(() => {
    if (viewer && loaded) {
      loadedDocumentCallback();
      if (!trueColor) {
        let toClear = Object.keys(viewer.scene.objects) as string[];
        setClearedObjects(toClear);
      }
      (window as any).highlight = (elementsId: number[] | string[]) => {
        setHighlightedObjects(elementsId);
      };

      (window as any).showpos = (pos: any) => {
        const objLoader = new OBJLoaderPlugin(viewer, {});
        let cameraEntities: Entity[] = [];
        var model = objLoader.load({
          // Model is an Entity
          id: new Date().getTime().toString(),
          src: "/model/cam.obj",
          position: pos,
        });
        //{"eye":[851.0376687907767,23.179816947344356,-205.22717149768013],"look":[835.1667084482888,14.591909575648467,-204.14980955377666],"up":[-0.4739667101270633,0.8799547634200695,0.032174089352426403],"fov":60,"fovAxis":"min","near":0.1,"far":2000,"projection":"perspective"}
        cameraEntities.push(model);
      };
    }
  }, [viewer, loaded]);

  useEffect(() => {
    if (loaded && viewer && cameras && cameras.length > 0) {
      const objLoader = new OBJLoaderPlugin(viewer, {});
      if (cameras) {
        let cameraEntities: Entity[] = [];
        for (let i = 0; i < cameras.length; i++) {
          if (cameras[i]?.cameraData) {
            let eye = cameras[i].cameraData.eye;
            // eye[0]-= -20;
            // eye[1]-= -10;
            // eye[2]-= -25;
            //eye[3]-= -150;
            var model = objLoader.load({
              // Model is an Entity
              id: "camera-" + i,
              src: "/model/cam.obj",
              position: [eye[0], eye[2], -eye[1]],
            });
            //{"eye":[851.0376687907767,23.179816947344356,-205.22717149768013],"look":[835.1667084482888,14.591909575648467,-204.14980955377666],"up":[-0.4739667101270633,0.8799547634200695,0.032174089352426403],"fov":60,"fovAxis":"min","near":0.1,"far":2000,"projection":"perspective"}
            cameraEntities.push(model);
          }
        }
        setCameraEntities(cameraEntities);
        (window as any).cameraEntities = cameraEntities;
      }
    }
  }, [cameras, loaded, viewer]);

  const loadIfcFile = async (v: Viewer) => {
    const xktLoader = new XKTLoaderPlugin(v);
    //viewer.scene.setObjectsOffset(Object.keys(viewer.scene.models["ifcModel"].objects),[-147772.99845901606, 10.000000474974513, 206648.78669188594]);
    let sceneModel = xktLoader.load({
      id: "ifcModel",
      src: url,
      edges: true,
      //position: [-147772.99845901606, 10.000000474974513, 206648.78669188594]
    });

    sceneModel.on("loaded", () => {
      setLoaded(true);
      //let [x1,y1,z1,x2,y2,z2] = v.scene.models["ifcModel"].aabb as number[];

      //v.scene.setObjectsOffset(Object.keys((v.scene.models["ifcModel"] as any).objects),[(x1+x2)/-2,(y1+y2)/-2,(z1+z2)/-2]);
      //v.scene.setObjectsOffset(Object.keys((v.scene.models["ifcModel"] as any).objects),[(x2+x2)/-2,(y2+y2)/-2,(z2+z2)/-2]);

      // let objs:any[] = [];
      // const flattener = (tree: any) => {
      //     objs.push(tree);
      //     for (let obj in tree) {
      //         flattener(tree[obj].children);
      //         console.log(tree[obj]);
      //     }
      // };
      // flattener((window as any).treeview.modelTreeViews.ifcModel._objectNodes);
      // (window as any).treeobjs = objs;
      console.log("loadedModel", sceneModel);
    });
    const sectionPlanes = new SectionPlanesPlugin(v, {
      overviewCanvasId: "mySectionPlanesOverviewCanvas",
    });
    setXktSectionPlanesPlug(sectionPlanes);

    const distanceMeasurements = new DistanceMeasurementsPlugin(v, {});

    setDistanceMeasurementPlug(distanceMeasurements);

    if (ShowViewerCube) {
      new NavCubePlugin(v, {
        canvasId: "myNavCubeCanvas",
        visible: true, // Initially visible (default)
      });
    }
    // let groupviewres = (await axios.get(`${url}groupview.json`)).data as IfcTreeviewObject;
    // let treeviewres = (await axios.get(`${url}treeview.json`)).data as IfcTreeviewObject;
    // setTreeview(treeviewres)
    // setGroupView(organize(groupviewres))

    v.cameraControl.on("picked", (p) => {
      if (p?.entity?.id) {
        let id = p.entity.id as string;
        if (selectedItem) {
          if (id.includes("camera")) {
            let index = id.split("-")[1].split("#")[0];
            selectedItem(Number(index));
          } else {
            onPicked && onPicked(id);
          }
        } else if (onPicked) onPicked(id);
      }
      console.log("p", p);
    });
  };

  // const organize = (tree: any) => {
  //     let idcounter = -1;//id's have to be unique so null doesn't work. we use negative numbers to if check if this is a valid id
  //     return {
  //         id: idcounter.toString(),
  //         type: "GroupView",
  //         family: null,
  //         name: "GroupView",
  //         parent: null,
  //         children: tree.map((x: any) => ({
  //                 id: (--idcounter).toString(),
  //                 type: x.IfcClass,
  //                 family: null,
  //                 name: x.IfcClass,
  //                 parent: null,
  //                 children: x.Types[0].Objects
  //             }
  //         ))
  //     } as IfcTreeviewObject;
  // };

  useEffect(() => {
    const v = new Viewer({
      canvasId: "ifcViewer",
      transparent: true,
      entityOffsetsEnabled: true,
    });
    setViewer(v);
    loadIfcFile(v);
    (window as any).viewer = v;

    if (initializeCallbacks !== null) {
      (window as any).getPosition = () => {
        let cameraSaver = new CameraSaver();
        cameraSaver.saveCamera(v);
        let test = JSON.stringify(cameraSaver.cameraData);
        console.log(test);
        return test;
      };
      (window as any).setPosition = (s: string) => {
        let data = JSON.parse(s) as CameraData;
        {
          let y = data.eye[1];
          data.eye[1] = data.eye[2];
          data.eye[2] = -y;
        }
        {
          let y = data.look[1];
          data.look[1] = data.look[2];
          data.look[2] = -y;
        }
        {
          let y = data.up[1];
          data.up[1] = -data.up[2];
          data.up[2] = -y;
        }

        let cameraSaver = new CameraSaver(data);
        cameraSaver.restoreCamera(v);
      };
      initializeCallbacks(viewer, {
        getAllElements: () => {
          let retVal: string[] = [];
          for (let model in v.scene.objects) {
            retVal.push(model);
          }
          return retVal;
        },
        setHighlighted: (elementsId: number[] | string[], color: string) => {
          console.log("highlightobjects", elementsId, color);
          setHighlightedObjects(elementsId, color);
        },
        clearAllItems: () => {
          let toClear = Object.keys(v.scene.objects) as string[];
          setClearedObjects(toClear);
        },
        clearItem: (elementId: number[] | string[]) => {
          setClearedObjects(elementId);
        },
        loadPosition: (posObject: string) => {
          //viewer.restoreState(JSON.parse(posObject));
          let cameraSaver = new CameraSaver(
            JSON.parse(posObject) as CameraData
          );
          cameraSaver.restoreCamera(v);
        },
      });
    }
  }, []);

  const handleScriptLoad = () => {
    setScriptLoaded(true);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <ScriptLoader
        url={`https://cdnjs.cloudflare.com/ajax/libs/codemirror/5.58.1/mode/sql/sql.min.js`}
        onLoad={handleScriptLoad.bind(this)}
      />

      <canvas
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
        id={"ifcViewer"}
      ></canvas>
      <canvas
        id="mySectionPlanesOverviewCanvas"
        style={{
          position: "absolute",
          width: "250px",
          height: "250px",
          bottom: "70px",
          right: "10px",
          zIndex: "200000",
          display: "none",
        }}
      ></canvas>
      {ShowViewerCube ? (
        <canvas
          id="myNavCubeCanvas"
          style={{
            position: "absolute",
            width: "250px",
            height: "250px",
            top: "70px",
            right: "10px",
            zIndex: "200000",
          }}
        ></canvas>
      ) : (
        <></>
      )}
      {viewer &&
      xktSectionPlanesPlug &&
      distanceMeasurementPlug &&
      !hideControls ? (
        <IfcViewerActions
          viewer={viewer}
          xktSectionPlanesPlug={xktSectionPlanesPlug}
          onCameraPicked={(index) => {
            selectedItem && selectedItem(Number(index));
          }}
          cameras={cameras}
          distanceMeasurementPlug={distanceMeasurementPlug}
          setTreeViewMode={(x) => {
            setTreeOpen(true);
            setTreeViewMode(x);
          }}
          treeOpen={false}
          treeViewMode={treeViewMode}
        />
      ) : (
        <></>
      )}

      {treeOpen ? (
        <div
          id={"treeViewContainer"}
          style={{
            position: "absolute",
            left: "20px",
            top: "20px",
            bottom: "90px",
            color: "white",
          }}
        >
          <IfcTreeView
            treeview={
              treeViewMode == ETreeViewMode.treeView ? treeView : groupView
            }
            onClose={() => {
              setTreeOpen(false);
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default IfcViewer;

interface CameraData {
  eye: any;
  look: any;
  up: any;
  fov: any;
  fovAxis: any;
  near: any;
  far: any;
  projection: any;
}
class CameraSaver {
  public cameraData: CameraData = {} as CameraData;
  constructor(cameraData?: CameraData) {
    if (cameraData) {
      this.cameraData = cameraData;
    } else {
      this.cameraData = {
        eye: [0, 0, 0],
        look: [0, 0, 0],
        up: [0, 0, 0],
        fov: 0,
        fovAxis: [0, 0, 0],
        near: 0,
        far: 0,
        projection: "",
      };
    }
  }
  /**
   * Saves the state of the given {@link Scene}'s {@link Camera}.
   *
   * @param {Scene} scene The scene that contains the {@link Camera}.
   */
  saveCamera(viewer: Viewer) {
    const camera = viewer.scene.camera;
    const project = camera.project as any; //default project value doesn't have the properties we need

    this.cameraData.eye[0] = camera.eye[0];
    this.cameraData.eye[1] = camera.eye[1];
    this.cameraData.eye[2] = camera.eye[2];
    this.cameraData.look[0] = camera.look[0];
    this.cameraData.look[1] = camera.look[1];
    this.cameraData.look[2] = camera.look[2];
    this.cameraData.up[0] = camera.up[0];
    this.cameraData.up[1] = camera.up[1];
    this.cameraData.up[2] = camera.up[2];
    this.cameraData.projection = "perspective";
    this.cameraData.fov = project.fov;
    this.cameraData.fovAxis = project.fovAxis;
    this.cameraData.near = project.near;
    this.cameraData.far = project.far;
  }

  /**
   * Restores a {@link Scene}'s {@link Camera} to the state previously captured with {@link CameraMemento#saveCamera}.
   *
   * @param {Scene} scene The scene.
   * @param {Function} [done] When this callback is given, will fly the {@link Camera} to the saved state then fire the callback. Otherwise will just jump the Camera to the saved state.
   */
  restoreCamera(viewer: Viewer) {
    const camera = viewer.scene.camera;
    const savedProjection = this;
    camera.eye[0] = this.cameraData.eye[0];
    camera.eye[1] = this.cameraData.eye[1];
    camera.eye[2] = this.cameraData.eye[2];
    camera.look[0] = this.cameraData.look[0];
    camera.look[1] = this.cameraData.look[1];
    camera.look[2] = this.cameraData.look[2];
    camera.up[0] = this.cameraData.up[0];
    camera.up[1] = this.cameraData.up[1];
    camera.up[2] = this.cameraData.up[2];
    camera.perspective.fov = this.cameraData.fov;
    camera.perspective.fovAxis = this.cameraData.fovAxis;
    camera.perspective.near = this.cameraData.near;
    camera.perspective.far = this.cameraData.far;
    //forces rerender by toggling projection
    camera.projection =
      this.cameraData.projection == "perspective" ? "ortho" : "perspective";
    camera.projection = this.cameraData.projection;

    //             camera.perspective.fov = savedProjection.fov;
    //             camera.perspective.fovAxis = savedProjection.fovAxis;
    //             camera.perspective.near = savedProjection.near;
    //             camera.perspective.far = savedProjection.far;

    // // if (done) {
    // //     scene.viewer.cameraFlight.flyTo({
    // //         eye: this._eye,
    // //         look: this._look,
    // //         up: this._up,
    // //         orthoScale: savedProjection.scale,
    // //         projection: savedProjection.projection
    // //     }, () => {
    // //         restoreProjection();
    // //         done();
    // //     });
    // //} else {
    //     camera.eye = this._eye;
    //     camera.look = this._look;
    //     camera.up = this._up;
    //     camera.projection = savedProjection.projection;
    // //}
  }
}
