// *** NPM ***
import React, { useMemo } from "react";

// *** OTHER ***
import { Task } from "../../../types/public-types";
import { OptionalKeys } from "../../../types/custom";
import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-duotone-svg-icons";

// *** HELPERS ***
const localeDateStringCache: { [key: string]: string } = {};

const toLocaleDateStringFactory =
	(locale: string) =>
		(date: Date, dateTimeOptions: Intl.DateTimeFormatOptions) => {
			const key = date.toString();
			let lds = localeDateStringCache[key];
			if (!lds) {
				lds = date.toLocaleString(locale);//, dateTimeOptions);
				lds=lds.split(",")[0]
				localeDateStringCache[key] = lds;
			}
			return lds;
		};

// *** TYPES ***
const dateTimeOptions: Intl.DateTimeFormatOptions = {
	weekday: "short",
	year: "numeric",
	month: "long",
	day: "numeric",
};

export interface IProps {
	tasks: Task[];
	onAddClick: (task: Task) => void;
	highlightedTasks: Number[];
	activityTaskSelected: (taskIndex: number) => void;
	locale: string;
	expanderSymbolOpen?: JSX.Element | string;
	expanderSymbolClose?: JSX.Element | string;
	expanderSymbolEmpty?: JSX.Element | string;
	onExpanderClick: (task: Task) => void;
	// conditionals
	// TODO: MAKE MORE FLEXIBLE RENDERING (TILL NOW LEAVE IT LIKE SO)
	showStartDateColumn?: boolean;
	showEndDateColumn?: boolean;
	// styles
	rootStyle?: React.CSSProperties;
	tableRowStyle?: React.CSSProperties;
	tableRowStyleProject?: React.CSSProperties;
	tableCellStyle?: React.CSSProperties;
	tableCellStyleProject?: React.CSSProperties;
	tableCellStyleTask?: React.CSSProperties;
	tableCellStyleMileStone?: React.CSSProperties;
	tableCellWrapperStyle?: React.CSSProperties;
	expanderStyle?: React.CSSProperties;
	expanderEmptyStyle?: React.CSSProperties;
}
type TOptionalPropsKeys = Exclude<OptionalKeys<IProps>, undefined>;
type TOptionalProps = Required<Pick<IProps, TOptionalPropsKeys>>;

export const defaultProps: TOptionalProps = {
	expanderSymbolOpen: "▼",
	expanderSymbolClose: "▶",
	expanderSymbolEmpty: "",
	// conditionals
	showStartDateColumn: true,
	showEndDateColumn: true,
	// styles
	rootStyle: {
		display: "table",
		borderBottom: "#e6e4e4 1px solid",
		borderLeft: "#e6e4e4 1px solid",
	},
	tableRowStyle: {
		display: "table-row",
		textOverflow: "ellipsis",
	},
	tableRowStyleProject: {},
	tableCellStyle: {
		display: "table-cell",
		verticalAlign: "middle",
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	tableCellStyleProject: {},
	tableCellStyleTask: {},
	tableCellStyleMileStone: {},
	tableCellWrapperStyle: {
		display: "flex",
	},
	expanderStyle: {
		color: "rgb(86 86 86)",
		fontSize: "0.6rem",
		padding: "0.15rem 0.2rem 0rem 0.2rem",
		userSelect: "none",
		cursor: "pointer",
	},
	expanderEmptyStyle: {
		fontSize: "0.6rem",
		paddingLeft: "1rem",
		userSelect: "none",
	},
};

const TaskListTable = (props: IProps & typeof defaultProps) => {
	// *** PROPS ***
	const {
		tasks,
		onAddClick,
		locale,
		expanderSymbolOpen,
		expanderSymbolClose,
		expanderSymbolEmpty,
		onExpanderClick,
		// conditionals
		showStartDateColumn,
		showEndDateColumn,
		// styles
		rootStyle,
		tableRowStyle,
		tableRowStyleProject,
		tableCellStyle,
		tableCellStyleProject,
		tableCellStyleTask,
		tableCellStyleMileStone,
		tableCellWrapperStyle,
		expanderStyle,
		expanderEmptyStyle,
		highlightedTasks,
		activityTaskSelected
	} = props;

	// *** HANDLERS ***
	const toLocaleDateString = useMemo(
		() => toLocaleDateStringFactory(locale),
		[locale]
	);

	return (
		// ROOT
		<div style={rootStyle}>
			{/* TABLE ROWS */}
			{tasks.map((t, y) => {
				// expander
				let expanderSymbol: JSX.Element | string = expanderSymbolEmpty;
				if (t.hideChildren === true) {
					expanderSymbol = expanderSymbolClose;
				} else if (t.hideChildren === false) {
					expanderSymbol = expanderSymbolOpen;
				}

				// styles
				const rowStyle: React.CSSProperties = {
					...tableRowStyle,
					...(t.type === "project" ? tableRowStyleProject : {}),
				};

				const cellStyle: React.CSSProperties = {
					...tableCellStyle,
					...(t.type === "project" ? tableCellStyleProject : {}),
					...(t.type === "task" ? tableCellStyleTask : {}),
					...(t.type === "milestone" ? tableCellStyleMileStone : {}),
				};

				return (
					// TABLE ROW
					<div style={{ ...rowStyle, background: y % 2 == 0 ? "rgba(0,0,0,0.1)" : "rgba(255,255,255,0)" }} key={`${t.id}row`}>
						{/* PRIMARY CELL */}
						<div style={{...cellStyle,minWidth:"350px"}} title={t.name}>
							{t.type === "project"
								?
								<Button variant="text" sx={{float:"left"}}
									onClick={() => onAddClick(t)}
								>
									<FontAwesomeIcon icon={faPlus} />
								</Button>
								: <div style={{width:"64px", float:"left",height:"10px"}}></div>
							}
							{/* TABLE CELL WRAPPER */}
							<div style={tableCellWrapperStyle}>
								{/* EXPANDER */}
								<div>{new Array(t.indent).join("\u00A0\u00A0\u00A0\u00A0\u00A0")}</div>
								<div
									style={expanderSymbol ? expanderStyle : expanderEmptyStyle}
									onClick={() => onExpanderClick(t)}
								>
									{expanderSymbol}
								</div>

								{/* TEXT */}
								<div 
									style={{color: highlightedTasks.includes(y)? "red":"unset",cursor: highlightedTasks.includes(y)? "pointer":"unset"}}
									onClick={() => highlightedTasks.includes(y)&&   activityTaskSelected(y)}
								>
									{t.name}
									</div>
							</div>
						</div>

						{/* START DATE CELL */}
						{showStartDateColumn === true && (
							<div style={cellStyle}>
								&nbsp;{toLocaleDateString(t.start, dateTimeOptions)}
							</div>
						)}

						{/* END DATE CELL */}
						{showEndDateColumn === true && (
							<div style={cellStyle}>
								&nbsp;{toLocaleDateString(t.end, dateTimeOptions)}
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
};
TaskListTable.defaultProps = defaultProps;

export default TaskListTable;
