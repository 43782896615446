import {DataFolder} from "../../Connectors/_Models";

export interface ForgeDocumentBrowserItem {
    id: string;
    name: string;
    displayName: string;
    isFetched: boolean;

    // File specific
    urn?: string;
    fileType?: string;
    isFile: boolean;

    // Folder specific
    isExpanded: boolean;
    isFolder: boolean;
    items?: ForgeDocumentBrowserItem[];
}

export enum EForgeBrowseMode
{
    File,
    Folder
}

export interface ForgeDocumentBrowserState {
    root: ForgeDocumentBrowserItem | null;
    currentFolder?: string;
    selection: string[];

    forgeDocumentSelectionDrawerActive: boolean;
    forgeBrowseMode:EForgeBrowseMode;
    linkFolder: DataFolder|null;
}

export const initialForgeDocumentBrowserState: ForgeDocumentBrowserState = {
    root: null,
    selection: [],
    forgeDocumentSelectionDrawerActive: false,
    forgeBrowseMode:EForgeBrowseMode.File,
    linkFolder:null
};
