import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import { DatasourceCategory } from "./_Models/DatasourceCategory";
import { DatasourceDataType } from "./_Models/DatasourceDataType";
import { DatasourceOperand } from "./_Models/DatasourceOperand";
import { DatasourceProperty } from "./_Models/DatasourceProperty";

export interface Datasource {
    source: "Forge";
}

export const getCategories = async (datasource: Datasource): Promise<DatasourceCategory[]> => {
    const result = await axios.get(`api/Datasource/${datasource.source}/Categories`, { baseURL: configuration.api });

    return result.data as DatasourceCategory[];
};

export const getDatatypes = async (datasource: Datasource): Promise<DatasourceDataType[]> => {
    const result = await axios.get(`api/Datasource/${datasource.source}/Datatypes`, { baseURL: configuration.api });

    return result.data as DatasourceDataType[];
};

export const getProperties = async (datasource: Datasource): Promise<DatasourceProperty[]> => {
    const result = await axios.get(`api/Datasource/${datasource.source}/Properties`, { baseURL: configuration.api });

    return result.data as DatasourceProperty[];
};

export const getOperators = async (datasource: Datasource): Promise<DatasourceOperand[]> => {
    const result = await axios.get(`api/Datasource/${datasource.source}/Operators`, { baseURL: configuration.api });

    return result.data as DatasourceOperand[];
};
