import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import {IconButton, SxProps, Theme} from '@mui/material';
import FontAwesomeSvgIcon from '../FontAwesomeSvgIcon';
import {faPenToSquare, faPlus} from '@fortawesome/pro-duotone-svg-icons';
import { HealthCheckDocumentsContext } from '../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider';
import {addDataFolder, patchFolder} from '../../Context/HealthCheckDocumentsContext/Reducer';
import {addFolder, editFolder} from '../../Connectors/dataFolders';
import LoadingIndicator from '../LoadingIndicator';
import {DataFolder} from "../../Connectors/_Models";

interface EditFolderProps {
    folder:DataFolder;
}

const EditFolder = ({ folder }: EditFolderProps) => {
    const [open, setOpen] = useState(false);
    const [folderName, setFolderName] = useState(folder.name);
    const [loading, setLoading] = useState(false);

    const { state, dispatch } = useContext(HealthCheckDocumentsContext);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFolderName("");
    };
    const handleEdit = async () => {
        if(!state.activeDataFolder)  return;
        try {
            setLoading(true);
            await editFolder(folder.id, {
                name: folderName
            });


            let activeFolder = {...state.activeDataFolder};
            let rawFolder = activeFolder?.children?.find(x=>x.id===folder.id);
            if(rawFolder){
                rawFolder.name = folderName;
            }
            dispatch(patchFolder(activeFolder));
            
            //patch folder
            setLoading(false);
        } catch (e) {
            setLoading(false);

        }
        handleClose();
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFolderName(event.currentTarget.value);
    };
    return (
        <>
            <IconButton onClick={handleClickOpen}>
                <FontAwesomeSvgIcon icon={faPenToSquare}
                                    sx={{fontSize: 24}}/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Rename folder</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        fullWidth
                        variant="standard"
                        value={folderName}
                        onChange={(e) => handleChange(e)}
                    />
                    {loading ? <LoadingIndicator /> : <></>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleEdit}>Create</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default EditFolder;