import { useContext, useEffect, useRef, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    CircularProgress,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon, Modal, Paper, Switch,
    Typography
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { faCaretDown, faCaretRight,faCaretLeft, faSquareCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeSvgIcon from "../../Components/FontAwesomeSvgIcon";
import { Checkset, ChecksetDefinition, ChecksetGroup, ChecksetType } from "../../Connectors/_Models/Checkset";
import { faFileCheck as faFileCheckLight, faFolder } from "@fortawesome/pro-light-svg-icons";
import { ChecksetResultContext } from "../../Context/ChecksetResultContext/ChecksetResultContextProvider";
import {
    addSelectedObjects,
    clearChecksetResultChecks,
    clearSelectedObjects,
    closeChecksetResult,
    loadChecksetElements,
    removeSelectedObjects,
    setChecksetResultChecks,
    toggleHighlightFailedElements
} from "../../Context/ChecksetResultContext/Reducer";
import LoadingIndicator from "../../Components/LoadingIndicator";
import { getChecksetVersion } from "../../Connectors/checksets";
import { HealthCheckContext } from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import {
    getChecksetResultsForDocument,
    getConvertedFile,
    getDocumentDerivative,
    getDocumentDerivatives
} from "../../Connectors/dataFolders";
import {
    DocumentChecksetResult, DocumentChecksetResultChecks,
    DocumentChecksetResultSimplePropertyChecks,
    DocumentChecksetResultSizeChecks
} from "../../Connectors/_Models/DataFolderDocument";
import { getForgeToken } from "../../Connectors/forgeDms";
import { uuid4 } from "@sentry/utils";
import ForgeBroadcastTransceiver, {
    BroadcastConsumer,
    BroadcastKey
} from "../../Components/ModelViewer/ForgeViewer/ForgeBroadcastTransceiver"; //TODO rename forge to model
import {  faTableColumns, faUpRightFromSquare } from "@fortawesome/pro-duotone-svg-icons";
import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { resolveFailedChecksetResults } from "../../Utils/ChecksetResultsResolver";
import ModelViewer from "../../Components/ModelViewer";
import { ModelViewerType } from "../../Components/ModelViewer/ModelViewerProperties";
import { SelectedObjects } from "../../Context/ChecksetResultContext/State";
import { HealthCheckDocumentsContext } from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";
import Popover from "@mui/material/Popover/Popover";
import { getConversionStatus } from "../../Connectors/ConversionStatus";
import { VictoryBar, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import { generatePbit, getPowerbiMaterials, startPowerBiExtract } from "../../Connectors/powerbi";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const GroupsContainer = styled("ul")({
    padding: 0,
    margin: 0,
});

const colors = ['#3cb44b40', '#4363d840', '#f5823140', '#911eb440', '#46f0f040', '#f032e640', '#bcf60c40', '#fabebe40', '#00808040', '#e6beff40', '#9a632440', '#fffac840', '#80000040', '#aaffc340', '#80800040', '#ffd8b140', '#00007540', '#80808040', '#ffffff40', '#00000040', '#e6194b40'];
const scopeObjectColor = "#FFFF0011"
const failedObjectColor = "#eba32fff"

const ChecksetResultDrawer = () => {
    const { state, dispatch } = useContext(ChecksetResultContext);
    const { state: healthCheckState } = useContext(HealthCheckContext);
    const {
        state: healthCheckDocumentsState,
        dispatch: healthCheckDocumentsDispatch
    } = useContext(HealthCheckDocumentsContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [downloadUrl, setDownloadUrl] = useState<string>("");
    const [checksets, setChecksets] = useState<Checkset[]>();
    const [color, setColor] = useState<"success" | "warning" | "error">("success");
    const [viewerOpen, setViewerOpen] = useState<boolean>(false);
    let [broadcastId] = useState(uuid4());
    let [broadcastTransceiver, setBroadcastTransceiver] = useState<ForgeBroadcastTransceiver | null>(null);
    let [forgeLoaded, setForgeLoaded] = useState<boolean>(false);
    let [modelLoaded, setModelLoaded] = useState<boolean>(false);
    let [broadcastChannel, setBroadcastChannel] = useState<BroadcastChannel | null>(null);
    let [currentWindow, setCurrentWindow] = useState<Window | null>(null);
    let [currentPercentage, setCurrentPercentage] = useState<number>(0);
    let [checksetsStatus, setChecksetsStatus] = useState<string>("");
    let [viewerPosition, setViewerPosition] = useState<string>("");
    const theme = useTheme();
    const downloadRef = useRef<any>(null);
    const [modelUrl, setModelUrl] = useState<string>("");
    useEffect(() => { //TODO
        if (!state.checksetResultDrawerActive) return;
        //     if (!state.checksetResult) return;
        //
        //     loadChecksetResultChecks()
        //
        //     setLoading(true);
        //
        let newChecksets: Checkset[] = [];
        setChecksets(newChecksets);
        let calculatedPercentage = 0;
        let newChecksetsStatus = "Done";
        if (state?.checksetResultDocument?.latestChecksetResults) {
            for (let cr of state.checksetResultDocument.latestChecksetResults) {
                if (healthCheckState.checksets) {
                    let foundCheckset = healthCheckState.checksets.find(x => x.id == cr.checksetId && x.checksetVersionId == cr.checksetVersionId);
                    if (foundCheckset) {
                        if (newChecksetsStatus !== "Error")
                            newChecksetsStatus = cr.status;
                        newChecksets.push(foundCheckset)
                        if (calculatedPercentage === -1 || cr.percentage === -1)
                            calculatedPercentage = -1;
                        else
                            calculatedPercentage += cr.percentage;
                    }
                }
            }
        }
        setChecksetsStatus(newChecksetsStatus);
        if (calculatedPercentage !== -1 && state.checksetResultDocument?.latestChecksetResults) {
            calculatedPercentage /= state.checksetResultDocument.latestChecksetResults.length;
        }
        setCurrentPercentage(calculatedPercentage);
        fetchResults();
        if (state?.checksetResultDocument?.version?.viewerDerivativeUrn) {
            setModelUrl(state?.checksetResultDocument?.version?.viewerDerivativeUrn);
        } else {
            fetchIfcUrl();
        }
        //state.checksetResultsChecks
        setLoading(false);
        //     let color: "success" | "warning" | "error";
        //     if (state.checksetResult.percentage >= 90) color = "success";
        //     else if (state.checksetResult.percentage >= 50) color = "warning";
        //     else color = "error";
        //
        //     setColor(color);
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.checksetResultDrawerActive, state.checksetResultDocument]);
    useEffect(() => {
        if (forgeLoaded && broadcastTransceiver) {
            broadcastTransceiver?.send({ key: BroadcastKey.LOADED_VIEWER });
        }
    }, [forgeLoaded, broadcastTransceiver]);

    useEffect(() => {
        let failedElementsToChangeHighlight = state.failedElements;
        failedElementsToChangeHighlight = failedElementsToChangeHighlight.filter(x => !state.selectedObjects.find(y => y.ids.find(z => z === x)));
        broadcastTransceiver?.send({
            key: BroadcastKey.SET_HIGHLIGHTED,
            value: {
                elements: failedElementsToChangeHighlight,
                color: state.highlightFailedElements ? failedObjectColor : scopeObjectColor
            }
        });

    }, [state.highlightFailedElements])
    const [converting, setConverting] = useState<boolean>(false);
    const [convertStatus, setConvertStatus] = useState<number>(0);
    const fetchIfcUrl = async () => {
        const getConvertedDocument = async () => {
            if (!state.checksetResultDocument || !healthCheckDocumentsState.activeDataFolder) return;
            let foId = healthCheckDocumentsState.activeDataFolder.id;
            let docId = state.checksetResultDocument.id
            let url = await getConvertedFile(foId, docId, state.checksetResultDocument.version.versionId, "model.xkt");
            setModelUrl(unescape(url));
        }
        if (!state.checksetResultDocument || !healthCheckDocumentsState.activeDataFolder) return;
        if (!state.checksetResultDocument || !healthCheckDocumentsState.activeDataFolder) return;
        let conversionStatus = await getConversionStatus(state.checksetResultDocument.version.versionId);
        if (conversionStatus.percentage !== 100) {
            setConverting(true);
            let conversionInterval = setInterval(async () => {
                if (!state.checksetResultDocument) return;
                let conversionStatus = await getConversionStatus(state.checksetResultDocument.version.versionId);
                setConvertStatus(conversionStatus.percentage);
                if (conversionStatus.percentage === 100) {
                    getConvertedDocument();
                    clearInterval(conversionInterval);
                }
            }, 5000);
        } else {
            getConvertedDocument();
        }
    };

    const fetchResults = async () => {
        let checksetResults: DocumentChecksetResultChecks[] = [];
        if (state?.checksetResultDocument?.latestChecksetResults && healthCheckDocumentsState?.activeDataFolder) {
            let foId = healthCheckDocumentsState.activeDataFolder.id;
            let docId = state.checksetResultDocument.id
            let elementIds: string[] = state.objectsInScope;
            let failedElementIds: string[] = state.failedElements;
            for (let cr of state.checksetResultDocument.latestChecksetResults) {
                if (cr.percentage !== -1) {
                    try {
                        let result = await getChecksetResultsForDocument(foId, docId, cr.checksetId);
                        if (result?.checkResults) {
                            //todo remove
                            result.checkResults = result.checkResults.map(x => {
                                if (x.checkType == ChecksetType.SIMPLE_PROPERTY_CHECK) {

                                    let spc = x as DocumentChecksetResultSimplePropertyChecks;
                                    let currFailedElements = Object.keys(spc.failedElementsPerCategory).map(x => spc.failedElementsPerCategory[x]).flat();
                                    failedElementIds = [...new Set([...failedElementIds, ...currFailedElements])]; // new Set to remove duplicates
                                    let currElementsInScope = Object.keys(spc.elementsInScopePerCategory).map(x => spc.elementsInScopePerCategory[x]).flat();
                                    elementIds = [...new Set([...elementIds, ...currElementsInScope])];// new Set to remove duplicates
                                }
                                return x;
                            });

                            checksetResults = ([...checksetResults, ...result.checkResults]);

                        }
                    } catch (e) {
                        console.error("failed getting results for", docId);
                    }
                }
            }
            if (elementIds.length > 3) {
                dispatch(loadChecksetElements(elementIds, failedElementIds));
            }

            dispatch(setChecksetResultChecks(checksetResults));
        }
    }

    useEffect(() => {
        if (modelLoaded && broadcastTransceiver) {
            broadcastTransceiver?.send({
                key: BroadcastKey.SET_HIGHLIGHTED,
                value: {
                    elements: state.objectsInScope,
                    color: scopeObjectColor
                }
            });
            setTimeout(() => {
                if (state.highlightFailedElements) {
                    broadcastTransceiver?.send({
                        key: BroadcastKey.SET_HIGHLIGHTED,
                        value: {
                            elements: state.failedElements,
                            color: failedObjectColor
                        }
                    });
                }
                setTimeout(() => {

                    if (state.selectedObjects.length !== 0) {
                        for (let sobject of state.selectedObjects) {

                            broadcastTransceiver?.send({
                                key: BroadcastKey.SET_HIGHLIGHTED,
                                value: {
                                    elements: sobject.ids,
                                    color: sobject.color
                                }
                            });
                        }
                    }
                }, 10);//Just 1 should be enough for the previous broadcast to have ticked. 10 is for safety measure
            }, 10);//Just 1 should be enough for the previous broadcast to have ticked.
            if (viewerPosition) {
                broadcastTransceiver?.send({
                    key: BroadcastKey.LOAD_POSITION,
                    value: viewerPosition
                });
            }
        }
    }, [modelLoaded, broadcastTransceiver]);

    // const loadChecksetResultChecks = async () => {//TODO!!
    //     dispatch(clearChecksetResultChecks());
    //     if (state.checksetResultDocument && healthCheckState.activeDataFolder && state.checksetResult) {
    //         let foId = healthCheckState.activeDataFolder.id;
    //         let docId = state.checksetResultDocument.id
    //
    //         let res = await getChecksetResultsForDocument(foId, docId, state.checksetResult.checksetId);
    //         if (res?.checkResults)
    //             dispatch(setChecksetResultChecks(res.checkResults));
    //     }
    // };

    const onClose = () => {
        dispatch(closeChecksetResult());
        setDownloadUrl("");
        setViewerOpen(false);
        currentWindow?.close();
        setCurrentWindow(null);
    };

    const sleep = (t:number)=>new Promise(resolve => setTimeout(()=>{resolve(null)}, t));
    const [convertingPbit, setConvertingPbit] = useState<string>("");
    const generateDownloadablePbit = async () => {
        if (!state.checksetResultDocument || !healthCheckDocumentsState.activeDataFolder) return;
        if(!state.checksetResultDocument?.fileName.includes(".rvt")) return;
        setConvertingPbit("Converting");
        let mats = await getPowerbiMaterials(healthCheckDocumentsState.activeDataFolder.id,state.checksetResultDocument.id);
        console.log(mats);
        if(mats==null){
           await startPowerBiExtract(healthCheckDocumentsState.activeDataFolder.id,state.checksetResultDocument.id);
        }
        let breaker = 0;
        while(mats==null && breaker < 120){
            await sleep(1000);
            mats = await getPowerbiMaterials(healthCheckDocumentsState.activeDataFolder.id,state.checksetResultDocument.id);
            breaker++;
        }
        if(mats ==null){
            setConvertingPbit("Failed conversion");
        }else{
            setConvertingPbit("");
            let fileName = state.checksetResultDocument?.fileName.split(".rvt")[0];
            let blobData =await generatePbit(healthCheckDocumentsState.activeDataFolder.id,state.checksetResultDocument.id,fileName);
            const href = URL.createObjectURL(blobData as Blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `${fileName}.pbit`); //or any other extension
            document.body.appendChild(link);
            link.click();
            
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }
    };
    const generateDownloadableReport = async () => {
        if (!state.checksetResultDocument || state.checksetResultDocument.latestChecksetResults.length === 0 || !healthCheckDocumentsState.activeDataFolder) return;
        if (!state.checksetResultDocument || !healthCheckDocumentsState.activeDataFolder) return;
        let foId = healthCheckDocumentsState.activeDataFolder.id;
        let docId = state.checksetResultDocument.id
        let result = await getDocumentDerivatives(foId, docId);
        let id = "";
        if (state.checksetResultDocument?.latestChecksetResults[0].checksetVersionId) {
            id = state.checksetResultDocument?.latestChecksetResults[0].checksetVersionId;
        }
        let derivative = result.find(x => x.type.includes(id) && x.type.includes("Check Result_"));
        if (derivative) {
            let url = await getDocumentDerivative(foId, docId, derivative.id, `${derivative.id}.xlsx`);
            setDownloadUrl(url);
            let intervalCounter = 0;
            let downloadInterval = setInterval(() => {
                intervalCounter++;
                if (downloadRef?.current !== null || intervalCounter > 30) {
                    downloadRef?.current?.click();
                    clearTimeout(downloadInterval);
                }
            }, 100)
        }
    }


    const selectAll = () => {//TODO
        // if (!checkset?.checkgroups || !state?.checksetResultsChecks) return;
        // let allObjects = resolveFailedChecksetResults(checkset.checkgroups, state.checksetResultsChecks);
        // dispatch(addSelectedObjects(allObjects));
        // broadcastTransceiver?.send({
        //     key: BroadcastKey.SET_HIGHLIGHTED,
        //     value: {elements: allObjects}
        // });
    }

    const generateBroadcastConsumer = () => {
        return {
            loadedModel: () => {
                setModelLoaded(true);
            },
            savePosition: (pos) => {
                setViewerPosition(pos);
            }
        } as BroadcastConsumer;
    }

    const toggleElement = (x: string) => {
        // if (state.selectedObjects.find(a=>a.ids.find(parseInt(x)))) {
        //     dispatch(removeSelectedObjects([x]));
        //     broadcastTransceiver?.send({
        //         key: BroadcastKey.CLEAR_ITEMS,
        //         value: {elements: [parseInt(x)],color:getColorForObjects([x])}
        //     })
        // } else
        {
            dispatch(addSelectedObjects([x], color));
            broadcastTransceiver?.send({
                key: BroadcastKey.SET_HIGHLIGHTED,
                value: { elements: [x], color: "#FF0000" }
            })
        }
    };

    let failedObjects: string[] = [];//TODO
    // if (checkset?.checkgroups && state?.checksetResultsChecks) {
    //     failedObjects = resolveFailedChecksetResults(checkset.checkgroups, state.checksetResultsChecks);
    // }

    //state.checksetResult
    return ((
        <Drawer
            variant="temporary"
            anchor="right"
            open={state.checksetResultDrawerActive}
            onClose={onClose}
            sx={{
                display: {
                    xs: "none",
                    sm: "block",
                    width: "50%",
                },
                "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: viewerOpen ? "100%" : "50%",
                    overflow: "hidden",
                },
            }}
        >
            <DrawerHeader>
                <IconButton onClick={onClose}>
                    <FontAwesomeSvgIcon icon={faTimes} />
                </IconButton>
                <Typography
                    variant="h6"
                    noWrap
                    sx={{
                        mr: "auto",
                        display: "flex",
                        placeContent: "center",
                        padding: theme.spacing(1),
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        userSelect: "none",
                    }}
                >
                    Checkset Result
                </Typography>
            </DrawerHeader>

            <Divider />
            <Box sx={{ display: "flex", flexDirection: "row", width: "100%", height: "100%" }}>


                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: viewerOpen ? "50%" : "100%",
                        height: "100%",
                        backgroundColor: theme.palette.background.default,
                        overflowY: "auto"
                    }}>

                    <Box
                        sx={{
                            p: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Card sx={{ m: 1, mb: 0 }}>
                            <CardContent sx={{ ":last-child": { pb: 2 }, display: "flex" }}>
                                <Box>
                                    <Typography>File: {state.checksetResultDocument?.fileName}</Typography>
                                    <Typography>Checkset: {checksets ? checksets.map(x => `"${x.name}"`).join(", ") : ""}</Typography>
                                    <Typography>Status: {checksetsStatus}</Typography>
                                    <Button onClick={() => generateDownloadableReport()}>Generate report</Button>
                                    <Button onClick={() => generateDownloadablePbit()}>
                                        {convertingPbit!=="" ? convertingPbit : <>Generate pbit</>}
                                        </Button>
                                    {downloadUrl ?
                                        <Typography><a ref={downloadRef} download="test.xlsx" href={downloadUrl}>Download
                                            report</a></Typography> : <></>}
                                    {converting ?
                                        <Typography>Converting status: {convertStatus + "%"}</Typography>
                                        : <></>}
                                    {modelUrl ?
                                        <>

                                            <Typography variant="h6">Viewer</Typography>
                                            <Divider sx={{ mb: 1 }} />
                                            <Button
                                                variant="outlined"
                                                sx={{ mr: 1 }}
                                                onClick={() => {
                                                    setViewerOpen(!viewerOpen)
                                                    setModelLoaded(false);
                                                    setForgeLoaded(false);
                                                    try {
                                                        currentWindow?.close();
                                                    } catch (ex) {
                                                        setCurrentWindow(null);
                                                    }
                                                }}>
                                                <FontAwesomeSvgIcon icon={faTableColumns} />
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                onClick={() => {
                                                    setViewerOpen(false);
                                                    setModelLoaded(false);
                                                    setForgeLoaded(false);
                                                    try {
                                                        currentWindow?.close();
                                                    } catch (ex) {
                                                        setCurrentWindow(null);
                                                    }
                                                    let bc = broadcastChannel;
                                                    if (bc == null) {
                                                        bc = new BroadcastChannel(broadcastId);
                                                        setBroadcastChannel(bc);
                                                    }
                                                    if (bc) {
                                                        setBroadcastTransceiver(new ForgeBroadcastTransceiver(bc, false, null, generateBroadcastConsumer()));
                                                        let myWindow = window.open(`/health-check/viewer/${state?.checksetResultDocument?.version?.viewerDerivativeUrn}/${state?.checksetResultDocument?.fileType}/${broadcastId}`, "", "width=600,height=600");
                                                        setCurrentWindow(myWindow);
                                                    }

                                                }}>
                                                <FontAwesomeSvgIcon icon={faUpRightFromSquare} />
                                            </Button>
                                            <Button onClick={()=>{
                                                if(state.checksetResultDocument && healthCheckDocumentsState.activeDataFolder){
                                                    startPowerBiExtract(healthCheckDocumentsState.activeDataFolder.id,state.checksetResultDocument.id)
                                                }
                                            }}>
                                                POWERBI
                                            </Button>
                                        </>
                                        :
                                        <></>
                                    }
                                </Box>
                                <Box sx={{ ml: "auto" }}>
                                    <Box sx={{ position: "relative", display: "inline-flex" }}>
                                        <CircularProgress
                                            sx={{
                                                position: "absolute",
                                                right: 0,
                                                color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
                                            }}
                                            variant="determinate"
                                            value={100}
                                            size={150}
                                        />
                                        <CircularProgress variant="determinate" color={color}
                                            value={currentPercentage} size={150} />
                                        <Box
                                            sx={{
                                                top: 0,
                                                left: 0,
                                                bottom: 0,
                                                right: 0,
                                                position: "absolute",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Typography variant="caption" component="div" color="text.secondary">
                                                {`${Math.round(currentPercentage)}%`}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>

                    <Box
                        sx={{
                            p: 1,
                            pb: 7,
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Card sx={{ m: 1, mt: 0, mb: 2 }}>
                            <CardContent sx={{ flexDirection: "row", justifyContent: "space-between", display: "flex" }}>
                                <Typography variant="h5">
                                    Details
                                </Typography>
                                <Switch checked={state.highlightFailedElements}
                                    onChange={() => dispatch(toggleHighlightFailedElements())} />
                            </CardContent>
                            <Divider />
                            <CardContent sx={{ ":last-child": { pb: 2 } }}>
                                {loading ? (
                                    <LoadingIndicator />
                                ) : (<>{checksets && checksets.map((checkset, checkIndex) => {
                                    return (<GroupsContainer>
                                        <Paper
                                            sx={{ p: 1, mb: 1, backgroundColor: colors[checkIndex % colors.length] }}
                                            elevation={1}>
                                            <ChecksetSection text={checkset.name}
                                                color={colors[checkIndex % colors.length]}>
                                                {checkset?.checkgroups?.map((group, groupIndex) => (
                                                    <CheckGroup key={groupIndex} level={0} group={group}
                                                        color={colors[checkIndex % colors.length]}
                                                        broadcastTransceiver={broadcastTransceiver} />
                                                ))}
                                            </ChecksetSection>
                                        </Paper>
                                    </GroupsContainer>

                                    )
                                })}</>)}
                            </CardContent>
                        </Card>
                    </Box>

                </Box>
                {viewerOpen && state.checksetResultDocument ?
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            width: "40%",
                            height: "CALC(100vh - 65px)",
                            flexBasis: "100%",
                        }}
                    >
                        <ModelViewer
                            showViewerCube={true}
                            url={modelUrl}
                            type={state.checksetResultDocument.fileType === "ifc" ? ModelViewerType.Ifc : ModelViewerType.Forge}
                            initializeCallbacks={(a, c) => {

                                let bc = broadcastChannel;
                                if (bc == null) {
                                    bc = new BroadcastChannel(broadcastId);
                                    setBroadcastChannel(bc);
                                }
                                if (bc) {
                                    let fbt = new ForgeBroadcastTransceiver(bc, true, c, generateBroadcastConsumer());
                                    setBroadcastTransceiver(fbt);
                                }
                            }}
                            loadedDocumentCallback={() => {
                                setForgeLoaded(true);
                            }}
                            savePositionCallback={(pos) => {
                                broadcastTransceiver?.send({ key: BroadcastKey.SAVE_POSITION, value: pos });
                            }}
                            onTokenRequest={(callback) => {
                                getForgeToken().then(x => {
                                    if (x != null) {
                                        callback(x, 36000)
                                    }
                                })
                            }
                            } />

                    </Box>
                    :
                    <></>
                }
            </Box>

        </Drawer>
    ));
};


export default ChecksetResultDrawer;

interface CheckGroupProps {
    level: number;
    group: ChecksetGroup;
    broadcastTransceiver: ForgeBroadcastTransceiver | null;
    color: string;
}

const CheckGroupItemContainer = styled("li")<{ level: number }>(({ level, theme }) => ({
    listStyle: "none",
    marginLeft: theme.spacing(level * 3),
    display: "flex",
    alignItems: "center",
    minHeight: 30,
    "&:hover": {
        // background: theme.palette.grey[200],
    },
}));

const ExpandToggle = styled("div")(({ theme }) => ({
    margin: theme.spacing(0.5),
    cursor: "pointer",
    "&>svg": {
        fontSize: 14,
    },
}));

const ExpandSpacer = styled("div")(({ theme }) => ({
    margin: theme.spacing(0.5),
    width: 14,
}));

const GroupInfo = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    flex: 1,
    "&>svg": {
        fontSize: 16,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
}));

const CheckGroup = ({ level, group, broadcastTransceiver, color }: CheckGroupProps) => {
    const [expand, setExpand] = useState<boolean>(false);
    const { state, dispatch } = useContext(ChecksetResultContext);


    const hasChildren = (): boolean => {
        // if (group.checkgroups && group.checkgroups.length > 0) return true;
        // if (group.checkDefinitions && group.checkDefinitions.length > 0) return true;
        //
        // return false;
        return true;
    };

    const getPercentageRecursively = (checksetGroup: ChecksetGroup) => {
        let percSum = 0;
        let totalItems = 0;
        for (let item of checksetGroup.items) {
            if (!item.isFolder) {
                let check = state.checksetResultsChecks.find(x => x.checkDefinitionId === item.id);
                if (check && check.checkType !== ChecksetType.FAILED_CHECK) {
                    percSum += check.percentage;
                    totalItems++;
                }
            }
        }
        for (let item of checksetGroup.items) {
            if (item.isFolder) {
                let { percSum: childPercSum, totalItems: childTotalItems } = getPercentageRecursively(item as ChecksetGroup);
                percSum += childPercSum;
                totalItems += childTotalItems;
            }
        }

        // for (let definition of checksetGroup.checkDefinitions) {
        //     let item = state.checksetResultsChecks.find(x => x.checkDefinitionId === definition.id);
        //     if (item && item.checkType !== ChecksetType.FAILED_CHECK) {
        //         percSum += item.percentage;
        //         totalItems++;
        //     }
        // }
        // for (let g of checksetGroup.checkgroups) {
        //     let {percSum: childPercSum, totalItems: childTotalItems} = getPercentageRecursively(g);
        //     percSum += childPercSum;
        //     totalItems += childTotalItems;
        // }

        return { percSum, totalItems };
    }

    const renderScore = () => {
        let { percSum, totalItems } = getPercentageRecursively(group);
        if (totalItems !== 0) {
            return <Typography>{Math.round(percSum / totalItems * 100) / 100} %</Typography>;
        }
        return <Typography>-</Typography>;
    }

    const renderFailedElementSelection = () => {
        //!state.checksetResult ||
        if (!group?.items || !state?.checksetResultsChecks) return <></>;
        let items: string[] = resolveFailedChecksetResults(group.items.filter(x => x.isFolder) as ChecksetGroup[], state.checksetResultsChecks);
        if (!items || items.length == 0) return <></>;
        let allSelected = true;
        for (let elId of items) {
            if (!state.selectedObjects.find(x => x.color === color && x.ids.includes(elId))) {
                allSelected = false;
                break;
            }
        }
        return <FontAwesomeSvgIcon sx={{ "mt": "2px", ml: "6px" }} icon={allSelected ? faSquareCheck : faSquare}
            onClick={() => {
                if (!allSelected) {
                    dispatch(addSelectedObjects(items, color));
                    broadcastTransceiver?.send({
                        key: BroadcastKey.SET_HIGHLIGHTED,
                        value: { elements: items.map(x => x), color }
                    })
                } else {
                    dispatch(removeSelectedObjects(items));
                    broadcastTransceiver?.send({
                        key: BroadcastKey.CLEAR_ITEMS,
                        value: { elements: items.map(x => x) }
                    })
                }
            }} />;
    }

    return (
        <>
            <CheckGroupItemContainer level={level}>
                {hasChildren() ? (
                    <ExpandToggle onClick={() => setExpand((x) => !x)}>
                        <FontAwesomeSvgIcon icon={expand ? faCaretDown : faCaretRight} />
                    </ExpandToggle>
                ) : (
                    <ExpandSpacer />
                )}
                <GroupInfo>
                    <FontAwesomeSvgIcon icon={faFolder} />
                    <Typography onClick={() => setExpand((x) => !x)}>{group.name}</Typography>
                    {renderFailedElementSelection()}
                </GroupInfo>
                <Typography>{renderScore()}</Typography>
            </CheckGroupItemContainer>

            {expand && (
                <>
                    {/*{group.checkgroups.map((group, index) => (*/}
                    {/*    <CheckGroup key={index} level={level + 1} group={group}*/}
                    {/*        broadcastTransceiver={broadcastTransceiver} color={color} />*/}
                    {/*))}*/}

                    {/*{group.checkDefinitions.map((definition, index) => (*/}
                    {/*    <CheckDefinition key={index} level={level + 1} definition={definition} color={color}*/}
                    {/*        broadcastTransceiver={broadcastTransceiver} />*/}
                    {/*))}*/}
                    {group.items.map((item: ChecksetGroup | ChecksetDefinition, index: number) => {
                        if (item.isFolder) {
                            let gr = item as ChecksetGroup;
                            return <CheckGroup key={index} level={level + 1} group={gr}
                                broadcastTransceiver={broadcastTransceiver} color={color} />
                        } else {
                            let def = item as ChecksetDefinition;
                            return <CheckDefinition key={index} level={level + 1} definition={def} color={color}
                                broadcastTransceiver={broadcastTransceiver} />
                        }
                    })
                    }

                </>
            )}
        </>
    );
};

interface ChecksetSectionProps {
    children?: React.ReactNode;
    text: string;
    color: string
}

const ChecksetSection = (props: ChecksetSectionProps) => {
    const [expand, setExpand] = useState<boolean>(true);
    return (<>
        <ExpandToggle onClick={() => setExpand((x) => !x)} sx={{ position: "relative" }}>
            <Typography variant={"h6"} sx={{ display: "inline-flex" }}> {props.text} </Typography>
            <FontAwesomeSvgIcon icon={expand ? faCaretDown : faCaretRight} />
            <Paper sx={{
                height: "20px",
                width: "20px",
                background: props.color.substr(0, 7),
                position: "absolute",
                right: "0px",
                top: "0px"
            }} elevation={1}></Paper>
        </ExpandToggle>
        {expand ? <>{props.children}</> : <></>}

    </>
    )
}

interface CheckDefinitionProps {
    level: number;
    definition: ChecksetDefinition;
    broadcastTransceiver: ForgeBroadcastTransceiver | null;
    color: string;
}


const CheckDefinition = ({ level, definition, broadcastTransceiver, color }: CheckDefinitionProps) => {
    const { state, dispatch } = useContext(ChecksetResultContext);
    const [expand, setExpand] = useState<boolean>(false);
    const renderScore = () => {
        let item = state.checksetResultsChecks.find(x => x.checkDefinitionId === definition.id);
        if (!item) return <Typography>-</Typography>;
        if (item.checkType === ChecksetType.FAILED_CHECK) {
            return <Typography color="error">{item.info}</Typography>;
        }
        return <Typography>{Math.round(item.percentage * 100) / 100} %</Typography>;
    };

    const canRenderDetail = () => {

        let item = state.checksetResultsChecks.find(x => x.checkDefinitionId === definition.id);
        if (!item) return false;
        if (item.checkType === ChecksetType.SIMPLE_PROPERTY_CHECK) {
            return true;
        }
        if (item.checkType === ChecksetType.SIZE_CHECK) {
            return true;
        }
        return false;
    }

    const toggleElement = (x: string) => {
        if (state.selectedObjects.find(a => a.color === color && a.ids.includes(x))) {
            dispatch(removeSelectedObjects([x]));

            if (state.highlightFailedElements) {
                broadcastTransceiver?.send({
                    key: BroadcastKey.SET_HIGHLIGHTED,
                    value: { elements: [x], color: failedObjectColor }
                })
            } else {
                broadcastTransceiver?.send({
                    key: BroadcastKey.SET_HIGHLIGHTED,
                    value: { elements: [x], color: scopeObjectColor }
                })
            }
        } else {
            dispatch(addSelectedObjects([x], color));
            broadcastTransceiver?.send({
                key: BroadcastKey.SET_HIGHLIGHTED,
                value: { elements: [x], color }
            })
        }
    };
    const renderFailedElementSelection = () => {
        let item = state.checksetResultsChecks.find(x => x.checkDefinitionId === definition.id);
        if (!item) return <></>;
        if (item.checkType !== ChecksetType.SIMPLE_PROPERTY_CHECK) return <></>
        let simplePropItem = item as DocumentChecksetResultSimplePropertyChecks;
        if (simplePropItem?.failedElementIds?.length == 0) return <></>;
        let allSelected = true;
        for (let elId of simplePropItem.failedElementIds) {
            if (!state.selectedObjects?.find(x => x.color === color)?.ids?.includes(elId)) {
                allSelected = false;
                break;
            }
        }
        return <FontAwesomeSvgIcon sx={{ "mt": "2px", ml: "6px" }} icon={allSelected ? faSquareCheck : faSquare}
            onClick={() => {
                if (!allSelected) {
                    dispatch(addSelectedObjects(simplePropItem.failedElementIds, color));
                    broadcastTransceiver?.send({
                        key: BroadcastKey.SET_HIGHLIGHTED,
                        value: { elements: simplePropItem.failedElementIds.map(x => x), color }
                    })
                } else {
                    dispatch(removeSelectedObjects(simplePropItem.failedElementIds));
                    broadcastTransceiver?.send({
                        key: BroadcastKey.CLEAR_ITEMS,
                        value: { elements: simplePropItem.failedElementIds.map(x => x) }
                    })
                }
            }} />;
    }
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const renderDetail = () => {
        let item = state.checksetResultsChecks.find(x => x.checkDefinitionId === definition.id);
        if (!item) return <></>;
        if (item.checkType === ChecksetType.SIMPLE_PROPERTY_CHECK) {
            let simplePropItem = item as DocumentChecksetResultSimplePropertyChecks;
            return <Box sx={{ display: "flex", flexDirection: "column", ml: 4 }}>
                <Typography>Elements in scope:{simplePropItem.elementsInScope}</Typography>
                <Typography>Failed elements: {simplePropItem.failedElements}</Typography>
                {simplePropItem.failedElementsPerCategory && Object.keys(simplePropItem.failedElementsPerCategory).length > 0 ?
                    <Typography>{Object.keys(simplePropItem.failedElementsPerCategory).map((y) => {
                        if (simplePropItem.failedElementsPerCategory[y].length == 0) {
                            return <a>{y}: no failed elements</a>
                        }
                        let allSelected = !!state.selectedObjects.find(x => x.color == color && x.ids.every(z => simplePropItem.failedElementsPerCategory[y]));
                        console.log("allSelected", state.selectedObjects);
                        const open = Boolean(anchorEl);
                        const id = open ? 'simple-popover' + y : undefined;

                        return <Box sx={{ ml: 2 }}>
                            <Typography>{y} <FontAwesomeSvgIcon sx={{ "mt": "2px", ml: "6px", fontSize: "14px" }}
                                icon={allSelected ? faSquareCheck : faSquare} /></Typography>
                            <Button aria-describedby={id} variant="text" onClick={handleClick}
                                sx={{ textTransform: "none" }}>
                                <FontAwesomeSvgIcon sx={{ fontSize: 14 }}
                                    icon={open ? faCaretDown : faCaretRight} /> Failed elements
                            </Button>
                            <Popover sx={{ p: 2, maxWidth: 300, maxHeight: 200 }}
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                onClose={handleClose}>
                                {simplePropItem.failedElementsPerCategory[y].map((x, index) =>
                                    <>
                                        <Button
                                            onClick={() => toggleElement(x)}
                                            style={{
                                                textDecoration: "underline",
                                                color: (state?.selectedObjects && state.selectedObjects?.find(y => y.color === color)?.ids?.includes(x) ? "red" : "black")
                                            }}>
                                            {x}
                                        </Button>
                                    </>
                                )}
                            </Popover>
                        </Box>
                    })
                    }
                    </Typography> : <></>
                }
            </Box>
        } else if (item.checkType === ChecksetType.SIZE_CHECK) {
            let sizeProp = item as DocumentChecksetResultSizeChecks;
            return <Box sx={{ display: "flex", flexDirection: "column", ml: 4 }}>
                <Typography>{sizeProp.message}</Typography>
            </Box>
        }
        return <></>;
    };

    return (
        <CheckGroupItemContainer level={level}>
            <ExpandSpacer />

            <GroupInfo sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", flexFlow: 1, width: "100%" }}>
                    <FontAwesomeSvgIcon icon={faFileCheckLight} />
                    <Typography onClick={() => {
                        setExpand(!expand)
                    }}>{definition.name}</Typography>
                    {canRenderDetail() ?
                        <><ExpandToggle sx={{ "mt": "2px" }}>
                            <FontAwesomeSvgIcon icon={expand ? faCaretDown : faCaretRight} onClick={() => {
                                setExpand(!expand)
                            }} />

                            {renderFailedElementSelection()}

                        </ExpandToggle>
                        </>
                        : <></>}
                </Box>
                {canRenderDetail() && expand ? <Box sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "row",
                    flexFlow: 1,
                    width: "100%",
                    mb: "1px"
                }}>{renderDetail()}</Box> : <></>}


            </GroupInfo>
            <Box
                sx={{ display: "flex", flexWrap: "wrap", flexDirection: "row", alignSelf: "flex-start" }}
            >
                {renderScore()}
            </Box>
        </CheckGroupItemContainer>
    );
};

