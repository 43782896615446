import {ActionType, SetActiveProjectModel, BobActions, ClearSelectedModel, SelectTask, SelectPlanning, SetDateRange} from "./Actions";
import {BobState, VisualiseType} from "./State";
import {ProjectResponse} from "../../Connectors/projects";
import {DataFolderDocument} from "../../Connectors/_Models/DataFolderDocument";
import { Planning } from "../../Connectors/_Models/Planning";
import { PlanningTask } from "../../Connectors/_Models/PlanningTask";

const bobReducer = (state: BobState, action: BobActions): BobState => {
    switch (action.type) {
        case ActionType.SetActiveProjectModel:
            return {...state, project: action.project, model: action.model, visualiseType: action.visualiseType};
        case ActionType.ClearSelectedModel:
            return {...state, project: null, model: null,planning:null,planningTask:null};
        case ActionType.SelectTask:
            return {...state, planning: action.planning, planningTask: action.planningTask};
        case ActionType.SelectPlanning:
            return {...state, planning: action.planning};
        case ActionType.SetDateRange:
            return {...state, startDate: action.startDate, endDate: action.endDate};
        default:
            return state;
    }
};

export default bobReducer;

export const setActiveProjectModel = (project: ProjectResponse, model: DataFolderDocument, visualiseType: VisualiseType): SetActiveProjectModel => ({
    type: ActionType.SetActiveProjectModel,
    project,
    model,
    visualiseType
});


export const clearSelectedModel = (): ClearSelectedModel => ({
    type: ActionType.ClearSelectedModel,
});

export const selectTask = (planning: Planning | null, planningTask: PlanningTask | null): SelectTask => ({
    type: ActionType.SelectTask,
    planning,
    planningTask
});

export const selectPlanning = (planning: Planning | null): SelectPlanning => ({
    type: ActionType.SelectPlanning,
    planning,
});

export const setDateRange = (startDate: Date, endDate: Date): SetDateRange => ({
    type: ActionType.SetDateRange,
    startDate,
    endDate,
});