import { Checkset, ChecksetDefinition, ChecksetGroup } from "../../Connectors/_Models/Checkset";
import {
    ActionType,
    AddChildGroup,
    AddGroup,
    ChangeChecksetDefinitionName,
    ChecksetDefinitionActions,
    CloseChecksetDefinitionConfigDrawer,
    CloseChecksetDefinitionDrawer,
    LoadChecksetDefinition,
    NewChecksetDefinition,
    OpenChecksetDefinitionConfigDrawer,
    OpenChecksetDefinitionDrawer,
    RemoveGroup,
    LoadCheck,
    NewCheck,
    UpdateCheck,
    RemoveCheck,
    SaveCheck,
    ChangeGroupName,
    ChangeCheckName,
    LoadCustomProperties,
    AddCustomProperty,
    SetRemoteProperty,
    SetActiveChecksetIsEdited,
    ClearActiveChecksetEdited,
    ClearCurrentChecksetConfigurationEdited,
    SetCurrentChecksetConfigurationEdited,
    EnableChecksetValidation,
    DisableChecksetValidation,
} from "./Actions";
import { ChecksetDefinitionState } from "./State";
import { uuid4 } from "@sentry/utils";
import { DatasourceProperty } from "../../Connectors/_Models/DatasourceProperty";

const propertyStorageKey = "customProperties";

export const recursiveItemsToChecksetGroup = (items: (ChecksetGroup | ChecksetDefinition)[]): ChecksetGroup[] => {
    let groups: ChecksetGroup[] = [];
    for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.isFolder) {
            const group = item as ChecksetGroup;
            group.items = recursiveItemsToChecksetGroup(group.items);
            groups.push(group);
        } else {
            const check = item as ChecksetDefinition;
            groups.push({
                id: check.id,
                name: check.name,
                index: check.index,
                order: check.order,
                isFolder: false,
                items: [],
                checkgroups: [],
                checkDefinitions: [],
            });
        }
    }

    return groups;


}

const findGroup = (groups: ChecksetGroup[], groupId: string): ChecksetGroup | null => {
    for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.id === groupId) return group;

        group.items = [...group.items];
        if (group.items && group.items.length > 0) {
            const childGroup = findGroup(group.items.filter(x => x.isFolder) as ChecksetGroup[], groupId);
            if (childGroup) return childGroup;
        }
    }

    return null;
};

const findParentGroup = (groups: ChecksetGroup[], groupId: string): ChecksetGroup | string | null => {
    for (let i = 0; i < groups.length; i++) {
        let group = groups[i];

        if (group.id === groupId) return "root";
        if (group.items && group.items.length > 0) {
            const findGroupRecursive = (groups: ChecksetGroup[], groupId: string): ChecksetGroup | null => {
                for (let i = 0; i < groups.length; i++) {
                    const currentGroup = groups[i];
            
                    if (currentGroup.id === groupId) return currentGroup;
            
                    currentGroup.items = [...currentGroup.items];
                    if (currentGroup.items && currentGroup.items.length > 0) {
                        const childGroup = findGroupRecursive(currentGroup.items.filter(x => x.isFolder) as ChecksetGroup[], groupId);
                        if (childGroup){ 
                            group = currentGroup;
                            return childGroup;
                        }
                    }
                }
            
                return null;
            };

            const childGroup = findGroupRecursive(group.items.filter(x => x.isFolder) as ChecksetGroup[], groupId);
            if (childGroup) return group;
        }
    }

    return null;
};

const findChecksetGroup = (groups: ChecksetGroup[], checksetId: string): ChecksetGroup | null => {
    for (let i = 0; i < groups.length; i++) {
        const group = groups[i];
        if (group.isFolder) {
            let groups = group.items?.filter(x => x.isFolder) as ChecksetGroup[];
            if (groups && groups.length > 0) {
                const childGroup = findChecksetGroup(groups, checksetId);
                if (childGroup) return childGroup;
            }
        }else{

            let defs = group.items?.filter(x => !x.isFolder) as ChecksetDefinition[];
            if (defs) {
                const check = defs.find((x) => x.id === checksetId);
                if (check) return group;
            }
        }

    }

    return null;
};

const findNextIndex = (groups: ChecksetGroup[]): number => {
    if (groups.length === 0) return 0;
    return groups[groups.length - 1].index + 1;
};

const checksetDefinitionReducer = (state: ChecksetDefinitionState, action: ChecksetDefinitionActions): ChecksetDefinitionState => {
    switch (action.type) {
        case ActionType.NewChecksetDefinition:
            return {
                ...state,
                checkset: {
                    name: "New Checkset",
                    checkgroups: [],
                },
            };

        case ActionType.LoadChecksetDefinition:
            return { ...state, checkset: action.payload.checkset };

        case ActionType.ChangeChecksetDefinitionName: {
            let checkset: Checkset | null = null;
            if (state.checkset) {
                checkset = { ...state.checkset };
                checkset.name = action.payload.name;
            }

            return { ...state, checkset };
        }

        case ActionType.AddGroup: {
            if (!state.checkset) return state;
            const checkset = { ...state.checkset };
            if (!checkset.checkgroups) checkset.checkgroups = [];
            checkset.checkgroups = [
                ...checkset.checkgroups,
                {
                    id: uuid4(),
                    name: action.payload.name,
                    items: [],
                    checkDefinitions:[],
                    checkgroups:[],
                    isFolder: true,
                    order:0,
                    index: findNextIndex(checkset.checkgroups),
                },
            ];

            return { ...state, checkset };
        }

        case ActionType.AddChildGroup: {
            if (!state.checkset) return state;

            const checkset: Checkset = JSON.parse(JSON.stringify(state.checkset));
            if (!checkset.checkgroups) checkset.checkgroups = [];
            const group = findGroup([...checkset.checkgroups], action.payload.parent.id!);
            if (group) {
                group.items = [
                    ...group.items,
                    {
                        id: uuid4(),
                        name: action.payload.name,
                        checkDefinitions: [],
                        checkgroups: [],
                        index: findNextIndex(group.checkgroups),
                        isFolder: true,
                        items: [],
                        order:0,
                    },
                ];
                group.checkgroups = recursiveItemsToChecksetGroup(group.items);
            }

            return { ...state, checkset };
        }

        case ActionType.ChangeGroupName: {
            if (!state.checkset) return state;

            const checkset: Checkset = JSON.parse(JSON.stringify(state.checkset));
            if (!checkset.checkgroups) checkset.checkgroups = [];
            const group = findGroup([...checkset.checkgroups], action.payload.group.id!);
            if (group) {
                group.name = action.payload.name;
            }

            return { ...state, checkset };
        }

        case ActionType.RemoveGroup: {
            if (!state.checkset) return state;

            const checkset = { ...state.checkset };
            const group = findParentGroup(checkset.checkgroups ?? [], action.payload.group.id!);
            if (group === "root") {
                if (!checkset.checkgroups) checkset.checkgroups = [];
                checkset.checkgroups = checkset.checkgroups.filter((x) => x.id !== action.payload.group.id);
            } else if (group) {
                const grp = group as ChecksetGroup;
                grp.items = grp.items?.filter((x) => x.id !== action.payload.group.id);
                grp.checkgroups = recursiveItemsToChecksetGroup(grp.items);
            }

            return { ...state, checkset };
        }

        case ActionType.NewCheck: {
            return { ...state, activeCheck: { ...action.payload.check }, activeCheckIsNew: true, activeCheckTarget: action.payload.target };
        }

        case ActionType.LoadCheck: {
            return { ...state, activeCheck: action.payload.check, activeCheckIsNew: false, activeCheckTarget: action.payload.group };
        }

        case ActionType.ChangeCheckName: {
            let check: ChecksetDefinition | null = null;
            if (state.activeCheck) {
                check = { ...state.activeCheck };
                check.name = action.payload.name;
            }

            return { ...state, activeCheck: check };
        }

        case ActionType.UpdateCheck: {
            let check: ChecksetDefinition | null = null;
            if (state.activeCheck) {
                check = { ...state.activeCheck, ...action.payload.check };
                check.name = state.activeCheck.name;
            }

            return { ...state, activeCheck: check };
        }

        case ActionType.SaveCheck: {
            if (!state.checkset) return state;
            if (!state.activeCheck || !state.activeCheckTarget) return state;

            const checkset: Checkset = JSON.parse(JSON.stringify(state.checkset));
            if (!checkset.checkgroups) checkset.checkgroups = [];
            const group = findGroup([...checkset.checkgroups], state.activeCheckTarget.id!);
            if (group) {
                if (!state.activeCheckIsNew) {
                    console.log("checkDefinitions:", JSON.parse(JSON.stringify(group.checkDefinitions)));

                    group.items = group.items.filter((x) => x.id !== state.activeCheck!.id);
                }
                group.items = [...group.items, { ...state.activeCheck, id: uuid4(),isFolder:false,order:0 }];
                group.checkgroups = recursiveItemsToChecksetGroup(group.items);
            }

            return { ...state, checkset: checkset, activeCheck: null, activeCheckTarget: null, activeCheckIsNew: false };
        }

        case ActionType.RemoveCheck: {
            if (!state.checkset) return state;
            const checkset: Checkset = JSON.parse(JSON.stringify(state.checkset));
            if (!checkset.checkgroups) checkset.checkgroups = [];
            const group = findChecksetGroup([...checkset.checkgroups], action.payload.check.id);
            if (group) {
                group.items = group.items.filter((x) => x.id !== action.payload.check.id);
                group.checkgroups = recursiveItemsToChecksetGroup(group.items);
            }

            return { ...state, checkset: checkset };
        }

        case ActionType.OpenChecksetDefinitionDrawer: {
            return { ...state, checksetDefinitionDrawerActive: true };
        }

        case ActionType.CloseChecksetDefinitionDrawer: {
            return { ...state, checksetDefinitionDrawerActive: false };
        }

        case ActionType.OpenChecksetDefinitionConfigDrawer: {
            return { ...state, checksetDefinitionConfigDrawerActive: true };
        }

        case ActionType.CloseChecksetDefinitionConfigDrawer: {
            return { ...state, checksetDefinitionConfigDrawerActive: false };
        }

        case ActionType.SetRemoteProperty: {
            return { ...state, remoteProperties: action.payload.datasourceProperties };
        }

        case ActionType.LoadCustomProperties: {
            let properties: DatasourceProperty[] = []
            let strProperties = localStorage.getItem(propertyStorageKey);
            if (strProperties) {
                properties = JSON.parse(strProperties);
            }
            return { ...state, customProperties: properties };
        }
        case ActionType.AddCustomProperty: {
            let properties: DatasourceProperty[] = []
            let strProperties = localStorage.getItem(propertyStorageKey);
            if (strProperties)
                properties = JSON.parse(strProperties);
            let dsp = action.payload.datasourceProperty;
            if (!properties.find(x => x.groupName === dsp.groupName && x.name === dsp.name)) {
                properties.push(dsp);
                localStorage.setItem(propertyStorageKey, JSON.stringify(properties));
            }
            return { ...state, customProperties: properties };
        }

        case ActionType.SetActiveCheckesIsEdited: {
            return { ...state, activeChecksetEdited: true };
        }
        case ActionType.ClearActiveChecksetEdited: {
            return { ...state, activeChecksetEdited: false };
        }



        case ActionType.SetCurrentChecksetConfigurationEdited: {
            return { ...state, currentChecksetConfigurationEdited: true };
        }
        case ActionType.ClearCurrentChecksetConfigurationEdited: {
            return { ...state, currentChecksetConfigurationEdited: false };
        }

        case ActionType.DisableChecksetValidation: {
            return { ...state, validateChecksets: false };
        }
        case ActionType.EnableChecksetValidation: {
            return { ...state, validateChecksets: true };
        }



        default:
            return state;
    }
};

export default checksetDefinitionReducer;

export const newChecksetDefinition = (): NewChecksetDefinition => ({
    type: ActionType.NewChecksetDefinition,
});

export const loadChecksetDefinition = (checkset: Checkset | null): LoadChecksetDefinition => ({
    type: ActionType.LoadChecksetDefinition,
    payload: { checkset },
});

export const changeChecksetDefinitionName = (name: string): ChangeChecksetDefinitionName => ({
    type: ActionType.ChangeChecksetDefinitionName,
    payload: { name },
});

export const addGroup = (name: string): AddGroup => ({
    type: ActionType.AddGroup,
    payload: { name },
});

export const addChildGroup = (name: string, parent: ChecksetGroup): AddChildGroup => ({
    type: ActionType.AddChildGroup,
    payload: { name, parent },
});

export const changeGroupName = (name: string, group: ChecksetGroup): ChangeGroupName => ({
    type: ActionType.ChangeGroupName,
    payload: { name, group },
});

export const removeGroup = (group: ChecksetGroup): RemoveGroup => ({
    type: ActionType.RemoveGroup,
    payload: { group },
});

export const newCheck = (check: ChecksetDefinition, target: ChecksetGroup): NewCheck => ({
    type: ActionType.NewCheck,
    payload: { check, target },
});

export const loadCheck = (check: ChecksetDefinition, group: ChecksetGroup): LoadCheck => ({
    type: ActionType.LoadCheck,
    payload: { check, group },
});

export const changeCheckName = (name: string): ChangeCheckName => ({
    type: ActionType.ChangeCheckName,
    payload: { name },
});
export const updatecheck = (check: ChecksetDefinition): UpdateCheck => ({
    type: ActionType.UpdateCheck,
    payload: { check },
});

export const saveCheck = (): SaveCheck => ({
    type: ActionType.SaveCheck,
});

export const removeCheck = (check: ChecksetDefinition): RemoveCheck => ({
    type: ActionType.RemoveCheck,
    payload: { check },
});

export const openChecksetDefinitionDrawer = (): OpenChecksetDefinitionDrawer => ({
    type: ActionType.OpenChecksetDefinitionDrawer,
});

export const closeChecksetDefinitionDrawer = (): CloseChecksetDefinitionDrawer => ({
    type: ActionType.CloseChecksetDefinitionDrawer,
});

export const openChecksetDefinitionConfigDrawer = (): OpenChecksetDefinitionConfigDrawer => ({
    type: ActionType.OpenChecksetDefinitionConfigDrawer,
});

export const closeChecksetDefinitionConfigDrawer = (): CloseChecksetDefinitionConfigDrawer => ({
    type: ActionType.CloseChecksetDefinitionConfigDrawer,
});

export const setRemoteProperty = (datasourceProperties: DatasourceProperty[]): SetRemoteProperty => ({
    type: ActionType.SetRemoteProperty,
    payload: { datasourceProperties }
});

export const loadCustomProperties = (): LoadCustomProperties => ({
    type: ActionType.LoadCustomProperties,
});

export const addCustomProperty = (datasourceProperty: DatasourceProperty): AddCustomProperty => ({
    type: ActionType.AddCustomProperty,
    payload: { datasourceProperty }
});

export const setActiveChecksetIsEdited = (): SetActiveChecksetIsEdited => ({
    type: ActionType.SetActiveCheckesIsEdited,
});

export const clearActiveChecksetEdited = (): ClearActiveChecksetEdited => ({
    type: ActionType.ClearActiveChecksetEdited,
});

export const clearCurrentChecksetConfigurationEdited = (): ClearCurrentChecksetConfigurationEdited => ({
    type: ActionType.ClearCurrentChecksetConfigurationEdited,
});

export const setCurrentChecksetConfigurationEdited = (): SetCurrentChecksetConfigurationEdited => ({
    type: ActionType.SetCurrentChecksetConfigurationEdited,
});


export const enableChecksetValidation = (): EnableChecksetValidation => ({
    type: ActionType.EnableChecksetValidation,
});

export const disableChecksetValidation = (): DisableChecksetValidation => ({
    type: ActionType.DisableChecksetValidation,
});
