import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import { ForgeFolderEntry, ForgeHub, ForgeProject } from "./_Models";
import { ConvertFolderStatus } from "./_Models/ConvertFolderStatus";

export const authorizeForge = async (referer: string, code: string): Promise<void> => {
    await axios.post(`api/ForgeDms/AuthorizeCode?referer=${referer}`, code, { headers: { "Content-Type": "application/json" }, baseURL: configuration.api });
};

export const getHubs = async (): Promise<ForgeHub[]> => {
    const result = await axios.get(`api/ForgeDms/Hubs`, { baseURL: configuration.api });

    return result.data as ForgeHub[];
};

export const getProjects = async (hubId: string): Promise<ForgeProject[]> => {
    const result = await axios.get(`api/ForgeDms/Hubs/${hubId}/Projects`, { baseURL: configuration.api });

    return result.data as ForgeProject[];
};

export const getRootFolder = async (hubId: string, projectId: string): Promise<ForgeFolderEntry> => {
    const result = await axios.get(`api/ForgeDms/Hubs/${hubId}/Projects/${projectId}/RootFolder`, { baseURL: configuration.api });

    return result.data as ForgeFolderEntry;
};

export const getFolder = async (hubId: string, projectId: string, folderId: string): Promise<ForgeFolderEntry> => {
    const result = await axios.get(`api/ForgeDms/Hubs/${hubId}/Projects/${projectId}/Folders/${folderId}`, { baseURL: configuration.api });

    return result.data as ForgeFolderEntry;
};

export const checkForgeToken = async (): Promise<boolean> => {
    try {
        const result = await axios.get(`api/ForgeDms/CheckToken`, { baseURL: configuration.api });
        return result!.status === 200;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            if (err.response.status === 404) return false;
        }

        throw err;
    }
};



export const getConvertFolderStatus = async (convertFolderId:string): Promise<ConvertFolderStatus> => {
    const result = await axios.get(`api/ForgeDms/Convert/Ifc/${convertFolderId}`, { baseURL: configuration.api });
    return result.data as ConvertFolderStatus;
};


export const getForgeToken = async (): Promise<string|null> => {
    try {
        const result = await axios.get(`api/ForgeDms/ForgeToken`, { baseURL: configuration.api });
        return result.data as string;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
        }

        return null;
    }
};