import { ProjectCameraResponse } from "../../Connectors/projects";

export interface ViewerCallbacks {
    setHighlighted: (elementsId: string[], color:string) => void;
    clearItem: (elementId: number[]|string[]) => void;
    clearAllItems: () => void;
    getAllElements: () => number[]|string[];
    loadPosition:(posObject:string)=>void;
}

export type TokenRequestCallback = (token: string, validTimeInS: number) => void;

export enum ModelViewerType{
    Forge,
    Ifc
}

export interface ModelViewerProps {
    onDocumentLoad?: any;
    url: string;
    onTokenRequest: (tokenRequestCallback: TokenRequestCallback) => void;
    initializeCallbacks: (viewer: any, viewerCallbacks: ViewerCallbacks) => void;
    loadedDocumentCallback: () => void;
    selectedItem?: (index:number) => void;
    cameras?: ProjectCameraResponse[];//todo fix
    savePositionCallback: (posObject: string) => void;
    type:ModelViewerType;
    trueColor?:boolean;
    onPicked?:(id:string)=>void;
    highlighedIfcs?:string[];
    visibleIfcs?:string[];
    highlightedCameras?:number[];
    showViewerCube:boolean;
    hideControls?:boolean;
    transparent?:boolean;
    voxelData? : number[][]
}