import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import { Planning } from "./_Models/Planning";
import { PlanningTask, PlanningTaskRequest } from "./_Models/PlanningTask";
import { PlanningTaskScheduleRequest } from "./_Models/PlanningTaskSchedule";



export const getPlanningsForDocument = async (projectId:string): Promise<Planning[]> => {
    const result = await axios.get(`api/Bob/Projects/${projectId}/Plannings`, { baseURL: configuration.api });
    if(result.status>300) return [];
    return result.data as Planning[];
};

export const getAllTasksForPlanning = async (projectId:string): Promise<PlanningTask[]> => {
    const result = await axios.get(`api/Bob/Projects/${projectId}/Plannings/Tasks`, { baseURL: configuration.api });
    if(result.status>300) return [];
    return result.data as PlanningTask[];
};
// export const getAllScheduleForTask = async (planningId: string,taskId: string): Promise<PlanningTaskSchedule[]> => {
//     const result = await axios.get(`api/Bob/Projects/${projectId}/Plannings/${planningId}/Tasks/${taskId}/Schedules`, { baseURL: configuration.api });
//     if(result.status>300) return [];
//     return result.data as PlanningTaskSchedule[];
// };

export const createPlanning = async (projectId:string,planning:Planning): Promise<string> => {
    const result = await axios.post(`api/Bob/Projects/${projectId}/Plannings`, planning,{ baseURL: configuration.api });
    if(result.status>300) return "";
    return result.data as string;
};

export const createPlanningTask = async (projectId:string,planningId: string,task:PlanningTask): Promise<string> => {
    const result = await axios.post(`api/Bob/Projects/${projectId}/Plannings/${planningId}/Tasks`, task,{ baseURL: configuration.api });
    if(result.status>300) return "";
    return result.data as string;
};
export const createScheduleTask = async (projectId:string,planningId: string,taskId: string,schedule:PlanningTaskScheduleRequest): Promise<string> => {
    const result = await axios.post(`api/Bob/Projects/${projectId}/Plannings/${planningId}/Tasks/${taskId}/Schedules`, schedule,{ baseURL: configuration.api });
    if(result.status>300) return "";
    return result.data as string;
};

export const createTask = async (projectId:string,planningId: string,schedule:PlanningTaskRequest): Promise<string> => {
    const result = await axios.post(`api/Planning/${planningId}/Tasks`, schedule,{ baseURL: configuration.api });
    if(result.status>300) return "";
    return result.data as string;
};

 
export const setElementsForScheduleAsync = async (projectId:string,scheduleId:string,elements:string[]): Promise<string> => {
    const result = await axios.post(`api/Bob/Projects/${projectId}/Plannings/Tasks/${scheduleId}/Elements`, {elements},{ baseURL: configuration.api });
    if(result.status>300) return "";
    return result.data as string;
};

//planning response
//[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","name":"My1TestPlan","description":"first test plan","vesion":"1.0","documentId":"ab1b144c-00af-4b8a-b4d2-52829671edaa"},{"id":"ab1b144c-00af-4b8a-b4d2-52829671edea","name":"My2testPlan","description":"second test plan","vesion":"2.0","documentId":"ab1b144c-00af-4b8a-b4d2-52829671edaa"}]


//schedule
//[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","name":"MyPT1","description":"my first planningtask","icon":"","planningTaskSchedules":[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","plannedStart":"2023-01-19T13:00:00","plannedEnd":"2023-01-19T15:30:00","actualStart":"2019-01-23T14:30:00","actualEnd":"2019-01-23T13:30:00","percentage":100},{"id":"ab1b144c-00af-4b8a-b4e2-52829671edaa","plannedStart":"2023-01-19T14:00:00","plannedEnd":"2023-01-19T18:30:00","actualStart":"2019-01-23T15:30:00","actualEnd":"2019-01-23T13:30:00","percentage":75}]},{"id":"ab1b144c-00af-4b8a-b4d2-52829671edae","name":"MyPt2","description":"my secondplanning","icon":"","planningTaskSchedules":[]}]

//scheduletask
//[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","plannedStart":"2023-01-19T13:00:00","plannedEnd":"2023-01-19T15:30:00","actualStart":"2019-01-23T14:30:00","actualEnd":"2019-01-23T13:30:00","percentage":100},{"id":"ab1b144c-00af-4b8a-b4e2-52829671edaa","plannedStart":"2023-01-19T14:00:00","plannedEnd":"2023-01-19T18:30:00","actualStart":"2019-01-23T15:30:00","actualEnd":"2019-01-23T13:30:00","percentage":75}]