import { Box, CircularProgress } from "@mui/material";

const LoadingIndicator = () => {
    return (
        <Box sx={{ margin: "auto" }}>
            <CircularProgress />
        </Box>
    );
};

export default LoadingIndicator;
