import {useContext, useEffect, useState} from "react";
import {
    Autocomplete,
    Box, Button,
    Card,
    CardContent,
    Checkbox,
    Chip,
    CircularProgress,
    IconButton, Popover,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import {styled, keyframes} from "@mui/system";
import {useNavigate} from 'react-router-dom';

import LinearProgress, {LinearProgressProps} from "@mui/material/LinearProgress";
import {
    deleteDocument,
    deleteFolder,
    getDocuments, getDocumentsFulltree,
    linkChecksetToDocument, linkLatestVersion,
    runCheckset, syncFolder
} from "../../Connectors/dataFolders";
import {HealthCheckContext} from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import LoadingIndicator from "../LoadingIndicator";
import {setChecksets} from "../../Context/HealthCheckContext/Reducer";
import {
    removeDocument,
    setDocuments,
    updateDocumentChecksets,
    setActiveDataFolder,
    patchFolder, patchAndActivateFolder
} from "../../Context/HealthCheckDocumentsContext/Reducer";
import {getChecksets} from "../../Connectors/checksets";
import {Checkset} from "../../Connectors/_Models/Checkset";
import {
    faArrowsRotate,
    faFileCheck,
    faPlay,
    faTrash,
    faFolder,
    faSort,
    faSortDown,
    faSortUp,
    faLinkSimple, faPenToSquare, faCloud, faRepeat,
} from "@fortawesome/pro-duotone-svg-icons";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import {DataFolderDocument} from "../../Connectors/_Models/DataFolderDocument";
import {ChecksetResultContext} from "../../Context/ChecksetResultContext/ChecksetResultContextProvider";
import {loadChecksetResult, openChecksetDrawerForDocument} from "../../Context/ChecksetResultContext/Reducer";
import {HealthCheckDocumentsContext} from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";
import {DataFolder} from "../../Connectors/_Models";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import {openForgeDocumentDrawer, setLinkFolder} from "../../Context/ForgeDocumentBrowserContext/Reducer";
import {
    ForgeDocumentBrowserContext
} from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import {EForgeBrowseMode} from "../../Context/ForgeDocumentBrowserContext/State";
import FolderEditor from "../FolderEditor/FolderEditor";
import {Bim360DataFolderConfig} from "../../Connectors/_Models/DataFolder";
import {getWorkOrders} from "../../Connectors/version";
import {delay} from "../../Utils/delay";


enum EHealthCheckDocumentsSort {
    None = 0,
    ByName = 1,
    ByDate = 2,
    Desc = 4
}

// TODO: Complete styling and all checkset options


const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    return (
        <Box sx={{display: "flex", alignItems: "center"}}>
            <Box sx={{width: "100%", mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
};

const HealthCheckDocuments = () => {
    const {state, dispatch} = useContext(HealthCheckContext);
    const {
        state: healthCheckDocumentsState,
        dispatch: healthCheckDocumentsDispatch
    } = useContext(HealthCheckDocumentsContext);
    const {dispatch: forgeDocumentDispatch} = useContext(ForgeDocumentBrowserContext);

    const [loading, setLoading] = useState<boolean>(false);
    const [fetchingDocuments, setFetchingDocuments] = useState<boolean>(false);
    const [refreshDocInterval, setRefreshDocInterval] = useState<ReturnType<typeof setInterval> | null>(null);
    const [orderBy, setOrderby] = useState<EHealthCheckDocumentsSort>(EHealthCheckDocumentsSort.None);
    const navigate = useNavigate()



    useEffect(() => {
        if (!healthCheckDocumentsState.activeDataFolder) return;
        if (loading) return;
        //setLoading(false);
        // if (!healthCheckDocumentsState.documents) {
        //     setLoading(true);
        //     getDocuments(healthCheckDocumentsState.activeDataFolder.id).then((data) => {
        //         dispatch(setDocuments(data));
        //         setLoading(false);
        //     });
        // }
        //
        if (!state.checksets) {
            getChecksets().then((data) => {
                dispatch(setChecksets(data));
            });
        }


        //load forge folder and merge
        if (!healthCheckDocumentsState.activeDataFolder.linkLoaded) {
            //loadBim360Folder();
        }

        if (!healthCheckDocumentsState.activeDataFolder.documents || healthCheckDocumentsState.activeDataFolder.documents.length === 0) return;
        if (!healthCheckDocumentsState.activeDataFolder.documents.find(x => x.latestChecksetResults !== undefined)) {
            if (!healthCheckDocumentsState.activeDataFolder.checksLoaded) {
                getDocuments(healthCheckDocumentsState.activeDataFolder.id).then(x => {
                    if (!healthCheckDocumentsState?.activeDataFolder) return;
                    let folder = healthCheckDocumentsState.activeDataFolder;
                    let documents = x;
                    if (folder) {
                        for (let doc of folder?.documents) {
                            let docIndex = documents.findIndex(y => y.fileName === doc.fileName);
                            if (docIndex === -1) {
                                documents.push(doc);
                            } else {
                            }
                        }

                        folder.checksLoaded = true;
                        folder.documents = documents;
                        healthCheckDocumentsDispatch(patchAndActivateFolder(folder));
                    }

                });
            }
        }
        setOrderby(EHealthCheckDocumentsSort.None);
        if (healthCheckDocumentsState?.activeDataFolder?.documents != null) {
            startRefreshInterval();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthCheckDocumentsState.activeDataFolder]);
    // useEffect(() => {
    //     if (state.documents != null) {
    //         startRefreshInterval();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state.documents]);
    const pathcFolder = (x: DataFolderDocument[]) => {
        if (!healthCheckDocumentsState?.activeDataFolder) return;
        let folder = healthCheckDocumentsState.activeDataFolder;
        let documents = x;
        if (folder) {
            for (let doc of folder?.documents) {
                if (!documents.find(y => y.fileName === doc.fileName)) {
                    documents.push(doc);
                }
            }

            folder.checksLoaded = true;
            folder.documents = documents;
            healthCheckDocumentsDispatch(patchFolder(folder));
        }

    };
    const startRefreshInterval = () => {
        if (refreshDocInterval) {
            clearInterval(refreshDocInterval);
            setRefreshDocInterval(null);
        }
        if (!healthCheckDocumentsState?.activeDataFolder?.documents) return;
        let docs = healthCheckDocumentsState.activeDataFolder.documents;
        let interval = setInterval(async () => {
            if (!checksRunning(docs)) {
                clearInterval(interval);
                setRefreshDocInterval(null);
                return;
            }
            if (!fetchingDocuments) {
                try {
                    setFetchingDocuments(true);
                    let data = await getDocuments(healthCheckDocumentsState.activeDataFolder!.id);
                    if (!checksRunning(data)) {
                        pathcFolder(data);
                    }
                    docs = data;
                    setFetchingDocuments(false);
                } catch (ex) {
                    setFetchingDocuments(false);
                }
            }
        }, 7500);
        // setRefreshDocInterval(interval);
    };

    const checksRunning = (docs: DataFolderDocument[] | null) => {
        if (!docs) return false;
        for (let doc of docs) {
            if (!doc.latestChecksetResults) return false;
            for (let check of doc.latestChecksetResults) {
                if (new Date(check.runCompletionDate) < new Date('0002-02-02T00:00:00') || check.status === "Queued") {
                    return true;
                }
            }
        }
        return false;
    };

    const changeFolder = (index: number) => {
        if (healthCheckDocumentsState.activeDataFolder == null) return;
        if (!healthCheckDocumentsState.activeDataFolder.children) return;
        let folder = healthCheckDocumentsState.activeDataFolder.children[index];
        if (!folder.children) {
            folder.children = [];
        }
        if (!folder.documents) {
            folder.documents = [];
        }

        if (folder.linkLoaded === false) {
            setLoading(true);
            getDocumentsFulltree(folder.id).then((x) => {
                x.linkLoaded = true;
                if (folder.parentFolder) {
                    x.parentFolder = folder.parentFolder;
                }
                healthCheckDocumentsDispatch(patchAndActivateFolder(x));
                setLoading(false);
            }).catch(ex => {
                setLoading(false);
            });
        } else {
            folder.parentFolder = healthCheckDocumentsState.activeDataFolder;
            healthCheckDocumentsDispatch(setActiveDataFolder(folder));
            healthCheckDocumentsDispatch(setDocuments(folder.documents));
        }
    };

    const linkFolder = (index: number) => {
        if (healthCheckDocumentsState.activeDataFolder == null) return;
        if (!healthCheckDocumentsState.activeDataFolder.children) return;
        let folder = healthCheckDocumentsState.activeDataFolder.children[index];
        if (folder) {
            forgeDocumentDispatch(setLinkFolder(folder));
            navigate("/health-check/onboarding");
            //forgeDocumentDispatch(openForgeDocumentDrawer(EForgeBrowseMode.Folder));
        }
    };
    const [syncingFolder, setSyncingFolder] = useState<boolean>(false);
    const onSyncFolder = async (index: number) => {
        if (healthCheckDocumentsState.activeDataFolder == null) return;
        if (!healthCheckDocumentsState.activeDataFolder.children) return;
        let folder = healthCheckDocumentsState.activeDataFolder.children[index];
        if (folder) {
            setSyncingFolder(true);
            try {
                let res = await syncFolder(folder.id);
                let status = "Queued";
                while (status === "Queued" || status === "0" || status === "Running") {
                    let workOrderStatus = await getWorkOrders(res);
                    status = workOrderStatus.status;
                    await delay(2000);
                }

                let patchedFolder = {...folder};
                patchedFolder.linkLoaded = undefined;
                patchedFolder.checksLoaded = undefined;
                patchedFolder.children = [];
                patchedFolder.documents = [];
                patchFolder(patchedFolder);
            } catch (e) {
                console.error("couldn't sync folder");
            }
            setSyncingFolder(false);
        }
    }
    const onDeleteFolder = async (index: number) => {
        if (healthCheckDocumentsState.activeDataFolder == null) return;
        if (!healthCheckDocumentsState.activeDataFolder.children) return;
        let folder = healthCheckDocumentsState.activeDataFolder.children[index];
        if (folder) {
            await deleteFolder(folder.id);
            let activeFolder = {...healthCheckDocumentsState.activeDataFolder};
            if (activeFolder.children) {
                activeFolder.children = activeFolder.children.filter(x => x.id != folder.id);
            }
            healthCheckDocumentsDispatch(patchFolder(activeFolder));

        }
    };

    const onStartRenameFolder = async (index: number) => {
        alert("Not implemented");
    };

    const applyOrderBy = (sort: EHealthCheckDocumentsSort) => {
        let checkFlag = sort & orderBy;
        let toggle = false;
        let asc = true;
        if (checkFlag !== 0) toggle = true;
        else asc = false;
        if (toggle) {
            if ((orderBy & EHealthCheckDocumentsSort.Desc) !== 0) {
                sort &= ~EHealthCheckDocumentsSort.Desc;
                asc = false;
            } else {
                sort |= EHealthCheckDocumentsSort.Desc;
            }
        }
        if (healthCheckDocumentsState.activeDataFolder === null || healthCheckDocumentsState.activeDataFolder.documents.length === 0) return

        let newdocs = [...healthCheckDocumentsState.activeDataFolder.documents];
        let newFolders: DataFolder[] = [];
        if (healthCheckDocumentsState?.activeDataFolder?.children) {
            newFolders = [...healthCheckDocumentsState.activeDataFolder.children];
        }

        switch (sort & (EHealthCheckDocumentsSort.ByDate | EHealthCheckDocumentsSort.ByName)) {
            case EHealthCheckDocumentsSort.ByName:
                newdocs = newdocs.sort((a, b) => a.fileName.localeCompare(b.fileName));
                newFolders = newFolders.sort((a, b) => a.name.localeCompare(b.name));
                break;
            case EHealthCheckDocumentsSort.ByDate:
                newdocs = newdocs.sort((a) => new Date(a.creationDate).getTime());
                newFolders = newFolders.sort((a, b) => a.name.localeCompare(b.name));
                break;
        }
        if (!asc) {
            newdocs = newdocs.reverse();
            newFolders = newFolders.reverse();
        }
        let activeFolder = healthCheckDocumentsState.activeDataFolder;
        activeFolder.documents = newdocs;
        activeFolder.children = newFolders;
        healthCheckDocumentsDispatch(patchFolder(activeFolder));
        setOrderby(sort);
    };
    const orderByIcon = (sort: EHealthCheckDocumentsSort) => {
        let checkFlag = sort & orderBy;
        let color: "primary" | "info" = "primary";
        if (checkFlag !== 0) {
            color = "info";
        } else {
            return <>
                <FontAwesomeSvgIcon color={color} icon={faSort} sx={{fontSize: 12, mr: 1}}/>
            </>
        }
        if ((orderBy & EHealthCheckDocumentsSort.Desc) != 0) {
            return <>
                <FontAwesomeSvgIcon color={color} icon={faSortDown} sx={{fontSize: 12, mr: 1}}/>
            </>
        }
        return <>
            <FontAwesomeSvgIcon color={color} icon={faSortUp} sx={{fontSize: 12, mr: 1}}/>
        </>
    };
    const ScrollContainer = styled(Box)(({theme}) => ({
        overflowY: "scroll",
        padding: theme.spacing(0),
        flex: 1,
        display: "flex",
        flexDirection: "column",
        ...GetScrollbarStyle(theme),
    }));

    const isCloudFolder = (df: DataFolder) => {
        if (df.config?.type === "bim360") {
            let cfg = df.config as Bim360DataFolderConfig;
            return cfg.mirrorFolders;
        }
        return false;
    }

    return loading || !healthCheckDocumentsState.activeDataFolder || syncingFolder ? (
        <LoadingIndicator/>
    ) : (
        <Card sx={{flex: 1, display: "flex", height: "fit-content", overflow: "unset", m: 1}}>
            <ScrollContainer>
                {!healthCheckDocumentsState.activeDataFolder?.documents && !healthCheckDocumentsState.activeDataFolder?.children ? (
                    <CardContent sx={{flex: 1, display: "flex", overflow: "unset"}}>
                        <Typography sx={{m: "auto"}}>No available documents, start adding documents now</Typography>
                    </CardContent>
                ) : (
                    <CardContent sx={{m: 0, p: 0, flex: 1, ":last-child": {pb: 0}}}>
                        <TableContainer sx={{overflowX: "unset"}}>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="button" sx={{mr: 2, cursor: "pointer"}}
                                                        onClick={() => applyOrderBy(EHealthCheckDocumentsSort.ByName)}>{orderByIcon(EHealthCheckDocumentsSort.ByName)} Name</Typography>
                                            <Typography variant="button" sx={{cursor: "pointer"}}
                                                        onClick={() => applyOrderBy(EHealthCheckDocumentsSort.ByDate)}>{orderByIcon(EHealthCheckDocumentsSort.ByDate)} Date</Typography>
                                        </TableCell>
                                        <TableCell width={50}></TableCell>
                                        <TableCell width={250}>
                                            <Typography variant="button">Linked Checkset</Typography>
                                        </TableCell>
                                        <TableCell width={200}>
                                            <Typography variant="button">Status</Typography>
                                        </TableCell>
                                        <TableCell width={350}>
                                            <Typography variant="button">Latest Result</Typography>
                                        </TableCell>
                                        <TableCell width={125}>
                                            <Typography variant="button">Actions</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!healthCheckDocumentsState.activeDataFolder?.children ? <></> :
                                        healthCheckDocumentsState.activeDataFolder.children
                                            .map((row, index) => {
                                                return (<TableRow hover tabIndex={-1} sx={{cursor: "pointer"}}>
                                                    <TableCell onClick={() => changeFolder(index)} colSpan={3}>
                                                        {isCloudFolder(row) ?
                                                            <FontAwesomeSvgIcon icon={faCloud}
                                                                                sx={{fontSize: 12, mr: 1}}/> :
                                                            <FontAwesomeSvgIcon icon={faFolder}
                                                                                sx={{fontSize: 12, mr: 1}}/>
                                                        }

                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {row.config !== undefined ?
                                                            <FontAwesomeSvgIcon icon={faLinkSimple}
                                                                                sx={{
                                                                                    fontSize: 12,
                                                                                    ml: 3,
                                                                                    mr: 1
                                                                                }}/> : <></>}
                                                        {row.config?.type}
                                                    </TableCell>
                                                    <TableCell>
                                                    </TableCell><TableCell>
                                                    <Box sx={{display: "flex"}}>
                                                        {row.config === undefined ? <Box sx={{ml: 1}}>
                                                                <Tooltip title="Link folder">
                                                                    <span>
                                                                    <IconButton onClick={() => linkFolder(index)}>
                                                                    <FontAwesomeSvgIcon icon={faLinkSimple}
                                                                                        sx={{fontSize: 24}}/>
                                                                    </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            :
                                                            <></>
                                                        }
                                                        <Box>
                                                            <Tooltip title="Rename folder">
                                                                    <span>
                                                                        <FolderEditor folder={row}/>
                                                                        
                                                                      
                                                                    </span>
                                                            </Tooltip>
                                                        </Box>
                                                        <Box>
                                                            <Tooltip title="Remove folder">
                                                                    <span>
                                                                        <IconButton
                                                                            onClick={() => onDeleteFolder(index)}>
                                                                            <FontAwesomeSvgIcon icon={faTrash}
                                                                                                sx={{fontSize: 24}}/>
                                                                        </IconButton>
                                                                    </span>
                                                            </Tooltip>
                                                        </Box>
                                                        {isCloudFolder(row) ?
                                                            <Box>
                                                                <Tooltip title="Sync folder">
                                                                    <span>
                                                                            <IconButton
                                                                                onClick={() => onSyncFolder(index)}>
                                                                                <FontAwesomeSvgIcon icon={faRepeat}
                                                                                                    sx={{fontSize: 24}}/>
                                                                            </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                            : <></>}
                                                    </Box>
                                                </TableCell>
                                                </TableRow>)
                                            })}
                                    {healthCheckDocumentsState?.activeDataFolder?.documents ? healthCheckDocumentsState.activeDataFolder.documents
                                        .map(row => (
                                            <DocumentEntry key={row.id} document={row}/>
                                        )) : <></>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                )}
            </ScrollContainer>
        </Card>
    );
};

export default HealthCheckDocuments;

interface DocumentEntryProps {
    document: DataFolderDocument;
}

const DocumentEntry = ({document}: DocumentEntryProps) => {
    const {state: healthCheckState, dispatch: healthCheckDispatch} = useContext(HealthCheckContext);
    const {
        state: healthCheckDocumentsState,
        dispatch: healthCheckDocumentsDispatch
    } = useContext(HealthCheckDocumentsContext);

    const {dispatch: checksetResultDispatch} = useContext(ChecksetResultContext);

    const [checksetsLoading, setChecksetsLoading] = useState<boolean>(true);
    const [selectedCheck, setSelectedCheck] = useState<Checkset[]>([]);

    useEffect(() => {
        if (!healthCheckState.checksets) setChecksetsLoading(true);
        else setChecksetsLoading(false);
    }, [healthCheckState.checksets]);

    useEffect(() => {
        if (!healthCheckState.checksets) setChecksetsLoading(true);
        else setChecksetsLoading(false);
    }, [healthCheckState.checksets]);


    useEffect(() => {
        if (!healthCheckState.checksets || healthCheckState.checksets.length === 0) return;

        if (document.checksets && document.checksets.length > 0) {
            const sc = healthCheckState.checksets.find((x) => x.id === document.checksets[0]) || null;
            if (document?.checksets?.length > 0 && healthCheckState?.checksets) {
                let mappedChecksets = document.checksets.map(x => healthCheckState?.checksets?.find(y => y.id === x)).filter(x => x) as Checkset[];
                setSelectedCheck(mappedChecksets);
            } else {
                setSelectedCheck([]);
            }
        }
    }, [healthCheckState.checksets, document.checksets]);

    const onUpdateLinkedCheckset = (value: Checkset[]) => {
        if (!healthCheckDocumentsState.activeDataFolder) return;

        setChecksetsLoading(true);
        linkChecksetToDocument(healthCheckDocumentsState.activeDataFolder.id, document.id, value.filter(x => x).map(x => x.id) as string[]).then((data) => {
            setChecksetsLoading(false);
            healthCheckDocumentsDispatch(
                updateDocumentChecksets(
                    document,
                    data.map((x) => x.id)
                )
            );
        });
    };


    const onRunCheckset = async () => {
        if (!healthCheckDocumentsState.activeDataFolder || !selectedCheck) return;//TODO
        for (let checkset of selectedCheck) {
            try {
                await runCheckset(healthCheckDocumentsState.activeDataFolder.id, document.version.versionId, checkset.id!);

            } catch (e) {
                console.error("couldn't run healtcheck on", document?.id, checkset?.id)
            }
        }
        getDocuments(healthCheckDocumentsState.activeDataFolder!.id).then((data) => {
            healthCheckDocumentsDispatch(setDocuments(data));
        });
    };
    const onInspectResult = () => {
        checksetResultDispatch(loadChecksetResult(document));
    };

    const onDelete = () => {
        if (!healthCheckDocumentsState.activeDataFolder) return;

        deleteDocument(healthCheckDocumentsState.activeDataFolder.id, document.id).then(() => {
            healthCheckDocumentsDispatch(removeDocument(document.id));
        });
    };
    const isQueued = (): boolean => {
        return document.latestChecksetResults.find(x => x.status === "Queued") !== null;
    };

    const rotationAnim = keyframes`
    0% {
        background-color: #FF0000; // Dark gray
    }
    50% {
        background-color: #a3a3a3; // Light gray
    }
    100% {
        background-color: #FF0000;//Dark gray
    }
    `
    const PulsingChip = styled(Chip)(({theme}) => ({
        animationIterationCount: "infinite",
        animationDuration: "2s",
        animationName: rotationAnim,
        animationTimingFunction: "linear"
    }));
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        console.log("event", event.currentTarget);
        setAnchorEl(event.currentTarget);
    };

    const closeVersionChanger = () => {
        setTimeout(() => {
            setAnchorEl(null);
        }, 1);// this click will also click on the cell that opens the modal. so single tick timeout will run this afterwards
    }
    const changeVersion = () => {
        setTimeout(async () => {
            if (!healthCheckDocumentsState.activeDataFolder) return;
            if (await linkLatestVersion(healthCheckDocumentsState.activeDataFolder.id, document.id)) {
                let acd = {...healthCheckDocumentsState.activeDataFolder}
                let index = acd.documents.findIndex(x => x.id === document.id);
                if (index !== -1) {
                    acd.documents[index].version.versionLabel = acd.documents[index].version?.latestAvailableVersionLabel
                }
                patchFolder(acd);
            }
            setAnchorEl(null);
        }, 1);// this click will also click on the cell that opens the modal. so single tick timeout will run this afterwards
    }

    const renderVersionLabel = () => {
        if (document?.version?.versionLabel) {
            if (document?.version?.latestAvailableVersionLabel && document.version.latestAvailableVersionLabel !== document.version.versionLabel) {
                return <>
                    <Popover anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                             onClose={() => closeVersionChanger()}
                             transformOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'center',
                             }} open={anchorEl !== null} anchorEl={anchorEl}>
                        <Typography variant="h6" sx={{p: 1}}>Change version</Typography>
                        <Typography variant="subtitle1" sx={{p: 1}}>Remote version
                            is {document.version.latestAvailableVersionLabel} Would you like to update</Typography>
                        <Box>
                            <Button onClick={() => changeVersion()}>Yes</Button>
                            <Button onClick={() => closeVersionChanger()}>Cancel</Button>
                        </Box>
                    </Popover>
                    <PulsingChip label={document?.version?.versionLabel ? document.version.versionLabel : ""}
                                 size="small"/>
                </>
            } else {

                return <Chip component={"span"}
                             label={document?.version?.versionLabel ? document.version.versionLabel : ""}
                             size="small"/>
            }
        }
        return <></>;
    };

    const renderStatus = () => {
        const anyQueued = document.latestChecksetResults?.find((x) => x.status === "Queued");
        if (anyQueued) return <Typography>{anyQueued.status}</Typography>;
        const anyError = document.latestChecksetResults?.find((x) => x.status === "Error");
        if (anyError) return <Typography>{anyError.status}</Typography>;
        const anyDone = document.latestChecksetResults?.find((x) => x.status === "Done");
        if (anyDone) return <Typography>{anyDone.status}</Typography>;
        return <Typography>-</Typography>;
    };

    /**
     * Create a metadata string with filetype & filetypeversion
     * @param document DataFolderDocument for which the metadata is generated
     * @returns string of the metadata for the given document
     */
    const getDocumentMetadata = (document: DataFolderDocument) => {
        let stringBuilder = [
            document?.fileType,
            document?.version?.revitVersion,
            `${Math.floor(document?.version?.filesize / 1000000)}MB`,
        ];
        return stringBuilder.filter(x => x).join(" ").trim()
    };

    const renderResult = () => {
        let percentage = 0;
        if (!document?.latestChecksetResults || document?.latestChecksetResults.length == 0) return <>-</>;
        for (let cr of document.latestChecksetResults) {
            if (percentage === -1 || cr.percentage === -1) percentage = -1;
            else percentage += cr.percentage;
        }
        if (percentage === -1) {
            return <>-</>
        }
        percentage /= document.latestChecksetResults.length;
        let color: "success" | "warning" | "error";
        if (percentage >= 90) color = "success";
        else if (percentage >= 50) color = "warning";
        else color = "error";

        return <LinearProgressWithLabel color={color} value={percentage}/>;
    };

    return (
        <TableRow hover tabIndex={-1} sx={{cursor: "pointer"}}>
            <TableCell component="th" scope="row">
                <Typography>{document.fileName}</Typography>
                <Typography variant="caption">{getDocumentMetadata(document)}</Typography>
            </TableCell>
            <TableCell onClick={handleClick}>
                {renderVersionLabel()}

            </TableCell>
            <TableCell>
                <Autocomplete
                    id="hub-selection"
                    multiple
                    sx={{width: 400}}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => option.name}
                    options={healthCheckState.checksets?.sort((a, b) => a.name.localeCompare(b.name)) ?? []}
                    loading={checksetsLoading}
                    disabled={checksetsLoading}
                    value={selectedCheck}
                    onChange={(event: any, newValue: Checkset[]) => {
                        onUpdateLinkedCheckset(newValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={selectedCheck.length == 0 ? "Linked Checkset" : ""}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        {checksetsLoading ? <CircularProgress color="inherit" size={20}/> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option, {selected}) => (
                        <li {...props}>
                            <Checkbox style={{marginRight: 8}} checked={selected}/>
                            {option.name}
                        </li>
                    )}
                />
            </TableCell>
            <TableCell>{renderStatus()}</TableCell>
            <TableCell>{renderResult()}</TableCell>
            <TableCell>
                <Box sx={{display: "flex"}}>
                    <Box>
                        <Tooltip title="Run selected checkset">
                            <span>
                                <IconButton onClick={() => onRunCheckset()}>
                                    <FontAwesomeSvgIcon icon={faPlay} sx={{fontSize: 24}}/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>


                    <Box sx={{ml: 1}}>
                        <Tooltip title="View checkset result">
                            <span>
                                <IconButton onClick={() => onInspectResult()}>
                                    <FontAwesomeSvgIcon icon={faFileCheck} sx={{fontSize: 24}}/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>

                    <Box sx={{ml: 1}}>
                        <Tooltip title="Remove document">
                            <span>
                                <IconButton onClick={() => onDelete()}>
                                    <FontAwesomeSvgIcon icon={faTrash} sx={{fontSize: 24}}/>
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Box>
                </Box>
            </TableCell>
        </TableRow>
    );
};
