import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import { Checkset, ChecksetGroup } from "./_Models/Checkset";

export interface Datasource {
    source: "Forge";
}

export const getChecksets = async (): Promise<Checkset[]> => {
    const result = await axios.get(`api/Checksets`, { baseURL: configuration.api });
    let checksets = result.data as Checkset[];
    let fixCheckgroupItems = (checkgroups: ChecksetGroup[]) => {
        console.log(checkgroups);
        for (let checkgroup of checkgroups) {
            if (!checkgroup.items) checkgroup.items = [];
            for (let cg of checkgroup.checkgroups) {
                cg.isFolder=true;
                checkgroup.items.push(cg);
            }
            for (let check of checkgroup.checkDefinitions) { 
                check.isFolder=false;
                checkgroup.items.push(check);
            }
            fixCheckgroupItems(checkgroup.checkgroups);
            checkgroup.items.sort((a, b) => a.order - b.order);
            checkgroup.isFolder=true;
        }
    };

    for (let checkset of checksets) {
        if (!checkset.checkgroups) continue;
        
        fixCheckgroupItems(checkset.checkgroups);
    }

    return checksets;
};

export const getCheckset = async (id: string): Promise<Checkset> => {
    const result = await axios.get(`api/Checksets/${id}`, { baseURL: configuration.api });

    return result.data as Checkset;
};

export const deleteCheckset = async (id: string): Promise<void> => {
    await axios.delete(`api/Checksets/${id}`, { baseURL: configuration.api });
};

export const createCheckset = async (data: Checkset): Promise<Checkset> => {
    const result = await axios.post(`api/Checksets`, data, { headers: { "Content-Type": "application/json" }, baseURL: configuration.api });

    return result.data as Checkset;
};

export const updateCheckset = async (id: string, data: Checkset): Promise<Checkset> => {
    const result = await axios.put(`api/Checksets/${id}`, data, { headers: { "Content-Type": "application/json" }, baseURL: configuration.api });

    return result.data as Checkset;
};

export const getChecksetVersion = async (id: string, versionId: string): Promise<Checkset> => {
    const result = await axios.get(`api/Checksets/${id}/History/${versionId}`, { baseURL: configuration.api });

    return result.data as Checkset;
};

export const exportCheckset = async (id: string, filename: string): Promise<void> => {
    const response = await axios.get(`api/Checksets/${id}/Export`, { baseURL: configuration.api, responseType: "blob" });
    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};

export const importCheckset = async (file: File): Promise<Checkset> => {
    const formData = new FormData();
    formData.append("File", file);

    const result = await axios.post(`api/Checksets/Import`, formData, { baseURL: configuration.api });

    return result.data as Checkset;
}