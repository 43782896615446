import axios from "axios";
import { configuration } from "../_Configuration/configuration";



export const startPowerBiExtract = async (dataFolderId:string,documentId:string): Promise<boolean> => {
    const result = await axios.post(`api/PowerBi/${dataFolderId}/Documents/${documentId}/StartPowerBiExtract`,{}, { baseURL: configuration.api });
    return result.data as boolean;
};


export const generatePbit = async (dataFolderId:string,documentId:string,name:string): Promise<any> => {
    const result = await axios.get(`api/PowerBi/GeneratePbit?foldersIds=${escape(dataFolderId)}&documentsIds=${escape(documentId)}&filesNames=${escape(name)}&template=${escape("demo_template2")}`, { baseURL: configuration.api,responseType:"blob" });
    return result.data;

};


export const getPowerbiMaterials = async (dataFolderId:string,documentId:string): Promise<string|null> => {
    try{
        const result = await axios.get(`api/PowerBi/${dataFolderId}/Documents/${documentId}/Materials`, { baseURL: configuration.api,responseType:"blob" });
        return result.data as string;
    }
    catch(e){
        return null;
    }
};