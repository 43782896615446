import { DatasourceDataType } from "../../../Connectors/_Models/DatasourceDataType";
import { DatasourceProperty } from "../../../Connectors/_Models/DatasourceProperty";
export enum PropertyAddAlertTypes {
    PropertyAddedType,
    FailedPropertyAdd,
    InvalidProperty,
    InvalidDataType,
}
export interface PropertyAddAlert {
    text: string
    visible: boolean,
    alertType: PropertyAddAlertTypes
    handle: ReturnType<typeof setTimeout> | null
}
export interface PropertySelectProperties {
    props: DatasourceProperty[];
    dataTypes: DatasourceDataType[];
    target: "scope" | "ruleset";
    propertyAddAlert?: PropertyAddAlert;
}
export type retval = () => JSX.Element;