import {
    Box,
    Checkbox,
    Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import {styled} from "@mui/material/styles";
import {visuallyHidden} from "@mui/utils";
import {EForgeBrowseMode, ForgeDocumentBrowserItem} from "../../Context/ForgeDocumentBrowserContext/State";
import {ChangeEvent, MouseEvent, useContext, useEffect, useState} from "react";
import {
    ForgeDocumentBrowserContext
} from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import {
    deselectItem,
    findItem,
    loadFolder,
    selectItem,
    setActiveFolder
} from "../../Context/ForgeDocumentBrowserContext/Reducer";
import LoadingIndicator from "../LoadingIndicator";
import {getFolder} from "../../Connectors/forgeDms";
import FolderEmpty from "./FolderEmpty";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import {HealthCheckContext} from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import {faFile, faFolder} from "@fortawesome/pro-duotone-svg-icons";

const ScrollTableContainer = styled(TableContainer)(({ theme }) => ({
    maxHeight: "calc(100% - 50px)",
    ...GetScrollbarStyle(theme),
}));

const RowContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    ">svg": {
        marginRight: theme.spacing(1),
        fontSize: 16,
    },
}));

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T): number => {
    const lhs = ((a[orderBy] as unknown as string) ?? "").toString();
    const rhs = ((b[orderBy] as unknown as string) ?? "").toString();

    return rhs.localeCompare(lhs, "en", { numeric: true, sensitivity: "base" });
};

const getComparator = <T,>(order: Order, forcedOrderBy: keyof T, orderBy: keyof T): ((a: T, b: T) => number) => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, forcedOrderBy) || descendingComparator(a, b, orderBy)
        : (a, b) => descendingComparator(a, b, forcedOrderBy) || -descendingComparator(a, b, orderBy);
};

const tableHeader: readonly HeadCell<ForgeDocumentBrowserItem>[] = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
        width: "100%",
    },
];

const ForgeDocumentManagementContent = () => {
    const { state: healthCheckState } = useContext(HealthCheckContext);
    const { state: forgeDocumentState, dispatch: forgeDocumentDispatch } = useContext(ForgeDocumentBrowserContext);
    const [order, setOrder] = useState<Order>("asc");
    const [orderBy, setOrderBy] = useState<keyof ForgeDocumentBrowserItem>("name");
    const [folder, setFolder] = useState<ForgeDocumentBrowserItem | null>();

    useEffect(() => {
        if (forgeDocumentState.root) {
            const activeFolder = findItem(forgeDocumentState.root, forgeDocumentState.currentFolder);
            setFolder(activeFolder);
        }
    }, [forgeDocumentState.root, forgeDocumentState.currentFolder]);

    const handleRequestSort = (event: MouseEvent<unknown>, property: keyof ForgeDocumentBrowserItem) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // TODO: Implement select all
    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        // if (event.target.checked) {
        //     const newSelected = rows.map((n) => n.name);
        //     setSelected(newSelected);
        //     return;
        // }
        // setSelected([]);
    };

    const retrieveFolderContents = (hub: string, project: string, folder: string) => {
        getFolder(hub, project, folder).then((data) => {
            forgeDocumentDispatch(loadFolder(data));
        });
    };

    const handleClick = (event: MouseEvent<unknown>, item: ForgeDocumentBrowserItem) => {
        if (item.isFolder) {
            forgeDocumentDispatch(setActiveFolder(item.id));
            if (!item.isFetched) {
                retrieveFolderContents(healthCheckState.forgeHub!, healthCheckState.forgeProject!, item.id);
            }

            return;
        }

        if (isAlreadyAdded(item.urn)) return;

        if (isSelected(item.id)) forgeDocumentDispatch(deselectItem(item.id));
        else forgeDocumentDispatch(selectItem(item.id));
    };

    const isSelected = (id: string): boolean => forgeDocumentState.selection.indexOf(id) !== -1;
    const isAlreadyAdded = (id?:string)=>{return false};//TODO:(id?: string): boolean => (id ? (healthCheckDocumentsState.documents?.findIndex((doc) => doc.externalId === id) ?? -1) > -1 : false);
    useEffect(()=>{console.log("forgemode",forgeDocumentState.forgeBrowseMode)},[forgeDocumentState.forgeBrowseMode]);
    return folder ? (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Paper sx={{ width: "100%", height: "100%", mb: 2 }}>
                <TableContainer>
                    <Table aria-labelledby="tableTitle">
                        <SortableTableHead
                            numSelected={0}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={folder.items?.length ?? 0}
                        />
                    </Table>
                </TableContainer>
                <Divider />

                {!folder.isFetched && folder.items && folder.items.length === 0 && (
                    <Box sx={{ display: "flex", height: 200 }}>
                        <LoadingIndicator />
                    </Box>
                )}

                {folder.isFetched && folder.items && folder.items.length === 0 && <FolderEmpty />}
                <ScrollTableContainer>
                    <Table aria-labelledby="tableTitle">
                        <TableBody sx={{ overflow: "auto" }}>
                            { folder.items
                                ?.slice()
                                .sort(getComparator(order, "isFolder", orderBy))
                                .filter(x=>forgeDocumentState.forgeBrowseMode !== EForgeBrowseMode.Folder || x.isFolder)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.id);
                                    const isItemAlreadyAdded = isAlreadyAdded(row.id);
                                    const labelId = `forge-dms-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row)}
                                            role="checkbox"
                                            aria-checked={isItemAlreadyAdded || isItemSelected}
                                            tabIndex={-1}
                                            key={row.name}
                                            selected={isItemAlreadyAdded || isItemSelected}
                                            sx={{ cursor: "pointer" }}
                                        >
                                            <TableCell padding="checkbox">
                                                {forgeDocumentState.forgeBrowseMode!==EForgeBrowseMode.Folder ?<Checkbox
                                                    color="primary"
                                                    checked={isItemAlreadyAdded || isItemSelected}
                                                    inputProps={{
                                                        "aria-labelledby": labelId,
                                                    }}
                                                    disabled={row.isFolder || isItemAlreadyAdded}
                                                />:<></>
                                                }
                                            </TableCell>
                                            <TableCell id={labelId} scope="row" padding="none">
                                                <RowContainer>
                                                    {row.isFile && <FontAwesomeSvgIcon icon={faFile} />}
                                                    {row.isFolder && <FontAwesomeSvgIcon icon={faFolder} />}
                                                    <Typography>{row.name}</Typography>
                                                </RowContainer>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            }
                        </TableBody>
                    </Table>
                </ScrollTableContainer>
            </Paper>
        </Box>
    ) : null;
};

export default ForgeDocumentManagementContent;

type Order = "asc" | "desc";

interface HeadCell<T> {
    disablePadding: boolean;
    id: keyof T;
    label: string;
    numeric: boolean;
    width: string;
}

interface SortableTableHeadProps {
    numSelected: number;
    onRequestSort: (event: MouseEvent<unknown>, property: keyof ForgeDocumentBrowserItem) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

const SortableTableHead = ({ order, orderBy, numSelected, rowCount, onRequestSort, onSelectAllClick }: SortableTableHeadProps) => {
    const createSortHandler = (property: keyof ForgeDocumentBrowserItem) => (event: MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all files",
                        }}
                        disabled
                    />
                </TableCell>
                {tableHeader.map((value: any) => (
                    <TableCell
                        key={value.id}
                        align={value.numeric ? "right" : "left"}
                        padding={value.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === value.id ? order : false}
                        width={value.width}
                    >
                        <TableSortLabel active={orderBy === value.id} direction={orderBy === value.id ? order : "asc"} onClick={createSortHandler(value.id)}>
                            <Typography variant="button">{value.label}</Typography>
                            {orderBy === value.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
