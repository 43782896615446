import { ActionType, CollapseSidebar, CollapseApplicationbar,ExpandApplicationbar,  ExpandSidebar, GlobalActions } from "./Actions";
import { GlobalState } from "./State";

const globalReducer = (state: GlobalState, action: GlobalActions): GlobalState => {
    switch (action.type) {
        case ActionType.CollapseSidebar:
            return { ...state, sideBarCollapsed: true };

        case ActionType.ExpandSidebar:
            return { ...state, sideBarCollapsed: false };
        case ActionType.CollapseApplicationbar:
            return { ...state, applicationBarCollapsed: true };

        case ActionType.ExpandApplicationbar:
            return { ...state, applicationBarCollapsed: false };

        default:
            return state;
    }
};

export default globalReducer;

export const collapseSidebar = (): CollapseSidebar => ({
    type: ActionType.CollapseSidebar,
});

export const expandSidebar = (): ExpandSidebar => ({
    type: ActionType.ExpandSidebar,
});

export const collapseApplicationbar = (): CollapseApplicationbar => ({
    type: ActionType.CollapseApplicationbar,
});

export const expandApplicationbar = (): ExpandApplicationbar => ({
    type: ActionType.ExpandApplicationbar,
});
