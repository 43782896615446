import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Step,
    StepLabel,
    Stepper, styled,
    Typography
} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {setDataFolderForgeLink, syncFolder} from "../../Connectors/dataFolders";
import {authorizeForge} from "../../Connectors/forgeDms";
import {DataFolder, ForgeLink} from "../../Connectors/_Models";
import {HealthCheckDocumentsContext} from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";
import ForgeLinkPicker from "../ForgeFileBrowser";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import {setActiveDataFolder, setRawFolder} from "../../Context/HealthCheckDocumentsContext/Reducer";
import {
    ForgeDocumentBrowserContext
} from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import {setLinkFolder} from "../../Context/ForgeDocumentBrowserContext/Reducer";
import {getWorkOrders} from "../../Connectors/version";
import {delay} from "../../Utils/delay";

const steps = ["Information", "Hub Selection", "Done"];

interface ForgeRedirectCache {
    activeDataFolder: DataFolder;
    rawFolders: DataFolder;
    linkFolder: DataFolder;
    selectedFolders: number[];
}

const ForgeOnboarding = () => {
    const {state, dispatch} = useContext(HealthCheckDocumentsContext);
    const {state: forgeState, dispatch: forgeDocumentDispatch} = useContext(ForgeDocumentBrowserContext);

    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>({});

    const [link, setLink] = useState<ForgeLink | null>(null);
    const [linkingForge, setLinkingForge] = useState<boolean>(false);

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (location.pathname === "/health-check/forge-callback") {
            const cacheDataString = sessionStorage.getItem("forgeRedirectCache");
            const code = searchParams.get("code");
            if (cacheDataString) {
                const cacheData = JSON.parse(cacheDataString) as ForgeRedirectCache;
                forgeDocumentDispatch(setLinkFolder(cacheData.linkFolder));
                if (!code) return;
                window.history.replaceState({}, document.title, `${window.location.origin}/health-check/onboarding`);

                authorizeForge(`${window.location.origin}/health-check/forge-callback`, code).then(() => goToStep(1));
            } else {
                if (!code) return;
                authorizeForge(`${window.location.origin}/health-check/forge-callback`, code).then(() => navigate(`/health-check`));

            }

            //TODO else ???
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const goToStep = (step: number) => {
        const newCompleted = completed;
        newCompleted[step - 1] = true;
        setCompleted(newCompleted);
        setActiveStep(step);
    };

    const recursiveRemoveParentFolder = (dt: DataFolder): DataFolder => {
        if (dt.parentFolder) {
            dt.parentFolder = undefined;
        }
        if (dt.children) {
            for (let ch in dt.children) {
                if (dt.children) {
                    dt.children[ch] = recursiveRemoveParentFolder(dt.children[ch]);
                }
            }
        }
        return dt;
    }

    // TODO: Move to utils
    const onForgeAuthenticate = () => {
        if (!forgeState.linkFolder) return;
        if (!state.activeDataFolder) return;
        let cacheData: ForgeRedirectCache = {
            activeDataFolder: recursiveRemoveParentFolder(state.activeDataFolder),
            rawFolders: recursiveRemoveParentFolder(state.rawFolders),
            linkFolder: recursiveRemoveParentFolder(forgeState.linkFolder),
            selectedFolders: state.selectedFolders
        };
        sessionStorage.setItem("forgeRedirectCache", JSON.stringify(cacheData));
        const clientId = "7R3mhWP3uL6tOAbGyL19uS0mHNiLaa2b";
        const redirect_uri = `${window.location.origin}/health-check/forge-callback`;
        const url = `https://developer.api.autodesk.com/authentication/v1/authorize?client_id=${clientId}&redirect_uri=${redirect_uri}&response_type=code&scope=data:read data:write`;
        window.location.href = url;
    };

    const onLinkForge = (mirror: boolean) => {
        if (!link) return;
        link.mirrorFolders = mirror;
        setLinkingForge(true);
        setDataFolderForgeLink(forgeState.linkFolder!.id, link).then(async () => {
            if (mirror) {
                try{
                    let res = await syncFolder(forgeState.linkFolder!.id);
                    let status = "Queued";
                    while (status === "Queued" || status === "0" || status === "Running") {
                        let workOrderStatus = await getWorkOrders(res);
                        status = workOrderStatus.status;
                        await delay(2000);
                    }
                }catch(e){
                    console.error("couldn't mirror folder");
                }
            }
            setLinkingForge(false);
            goToStep(2);
        });
    };

    const onComplete = () => {
        const cacheDataString = sessionStorage.getItem("forgeRedirectCache");
        let mydir = "";
        if (cacheDataString) {
            const cacheData = JSON.parse(cacheDataString) as ForgeRedirectCache;
            if (cacheData.selectedFolders.length > 0) {
                mydir = `/folder/${btoa(JSON.stringify(cacheData.selectedFolders))}`;
            }
        }
        navigate(`/health-check${mydir}`);
    };

    return (
        <>
            <Card sx={{m: 1}}>
                <CardContent sx={{":last-child": {pb: 2}}}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                                <StepLabel color="inherit">
                                    <Typography variant="button">{label}</Typography>
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </CardContent>
                {activeStep === 0 && (
                    <>
                        <CardContent sx={{":last-child": {pb: 2}}}>
                            Some placeholder text to inform the user that files are linked from a BIM360 environment
                            and that they can get started by linking
                            their BIM360 account here.
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" sx={{ml: "auto"}} onClick={onForgeAuthenticate}>
                                Link BIM360 Account
                            </Button>
                        </CardActions>
                    </>
                )}
                {activeStep === 1 && (
                    <>
                        <CardContent sx={{":last-child": {pb: 2}}}>
                            Some placeholder text to indicate that the user needs to select the folder within the
                            project and hub they want to use as entry
                            point for the Health Check application.
                        </CardContent>
                        <CardContent>
                            <ForgeLinkPicker onSelectFolder={(x) => setLink(x)}/>
                        </CardContent>
                        <CardActions>
                            <Box sx={{m: 1, ml: "auto", mr: 1, position: "relative"}}>
                                <Button variant="contained" disabled={linkingForge || !link}
                                        onClick={() => onLinkForge(false)}>
                                    Link
                                </Button>
                                <Button variant="contained" sx={{ml: 1}} disabled={linkingForge || !link}
                                        onClick={() => onLinkForge(true)}>
                                    Import as cloud folder
                                </Button>
                                {linkingForge && (
                                    <CircularProgress
                                        size={24}
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: "-12px",
                                            marginLeft: "-12px",
                                        }}
                                    />
                                )}
                            </Box>
                        </CardActions>
                    </>
                )}
                {activeStep === 2 && (
                    <>
                        <CardContent sx={{":last-child": {pb: 2}}}>
                            Some message stating that the user is done linking their BIM360 account to their Health
                            Check App account and that they can get
                            started using this application using the button below
                        </CardContent>
                        <CardActions>
                            <Button variant="contained" sx={{ml: "auto"}} onClick={onComplete}>
                                Get Started
                            </Button>
                        </CardActions>
                    </>
                )}
            </Card>
        </>
    );
};
const ScrollContainer = styled(ForgeOnboarding)(({theme}) => ({
    overflowY: "scroll",
    padding: theme.spacing(0),
    flex: 1,
    display: "flex",
    flexDirection: "column",
    ...GetScrollbarStyle(theme),
}));

export default ScrollContainer;
