import { createContext, Dispatch, useReducer } from "react";
import { HealthCheckDocumentsActions } from "./Actions";
import healthCheckDocumentsReducer from "./Reducer";
import { HealthCheckDocumentsState, initialHealthCheckDocumentsState } from "./State";

interface HealthCheckContextProps {
    children: JSX.Element | JSX.Element[];
}

const HealthCheckDocumentsContext = createContext<{ state: HealthCheckDocumentsState; dispatch: Dispatch<HealthCheckDocumentsActions> }>({
    state: initialHealthCheckDocumentsState,
    dispatch: () => undefined,
});

const HealthCheckContextDocumentsProvider = ({ children }: HealthCheckContextProps) => {
    const [state, dispatch] = useReducer(healthCheckDocumentsReducer, initialHealthCheckDocumentsState);

    return <HealthCheckDocumentsContext.Provider value={{ state, dispatch }}>{children}</HealthCheckDocumentsContext.Provider>;
};

export { HealthCheckDocumentsContext, HealthCheckContextDocumentsProvider };
