import { styled } from "@mui/material/styles";
import { Autocomplete, CircularProgress, Divider, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getHubs, getProjects } from "../../Connectors/forgeDms";
import { ForgeHub, ForgeProject } from "../../Connectors/_Models";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import ForgeDocumentManagementTree from "../ForgeDocumentManagement/ForgeDocumentManagementTree";
import { ForgeDocumentBrowserContext } from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import { setActiveFolder } from "../../Context/ForgeDocumentBrowserContext/Reducer";

const ScrollContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    maxHeight: "calc(100vh - 96px)",
    overflowY: "scroll",
    paddingRight: theme.spacing(0.5),
    ...GetScrollbarStyle(theme),
}));

export interface Link {
    hub: string;
    project: string;
    folder: string;
}

interface ForgeFileBrowserProps {
    onSelectFolder?: (link: Link) => void;
}

// TODO: Rework the layout of this component

const ForgeFileBrowser = ({ onSelectFolder }: ForgeFileBrowserProps) => {
    const { state, dispatch } = useContext(ForgeDocumentBrowserContext);

    const [hubs, setHubs] = useState<ForgeHub[]>([]);
    const [hubsLoading, setHubsLoading] = useState<boolean>(true);
    const [selectedHub, setSelectedHub] = useState<ForgeHub | null>(null);

    const [projects, setProjects] = useState<ForgeProject[]>([]);
    const [projectsLoading, setProjectsLoading] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<ForgeProject | null>(null);

    useEffect(() => {
        setHubsLoading(true);
        getHubs().then((data) => {
            setHubs(data);
            setHubsLoading(false);
        });
    }, []);

    useEffect(() => {
        if (!selectedHub) return;

        setProjectsLoading(true);
        setSelectedProject(null);

        getProjects(selectedHub.id).then((data) => {
            setProjects(data);
            setProjectsLoading(false);
        });
    }, [selectedHub]);

    useEffect(() => {
        if (selectedHub && selectedProject && state.currentFolder) {
            onSelectFolder && onSelectFolder({ hub: selectedHub.id, project: selectedProject.id, folder: state.currentFolder });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedHub, selectedProject, state.currentFolder]);

    return (
        <>
            <Autocomplete
                id="hub-selection"
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={hubs}
                loading={hubsLoading}
                value={selectedHub}
                onChange={(event: any, newValue: ForgeHub | null) => {
                    setSelectedHub(newValue);
                    dispatch(setActiveFolder(undefined));
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Hub"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {hubsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Autocomplete
                id="project-selection"
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={projects}
                loading={projectsLoading}
                value={selectedProject}
                onChange={(event: any, newValue: ForgeProject | null) => {
                    setSelectedProject(newValue);
                    dispatch(setActiveFolder(undefined));
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Project"
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {projectsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <ScrollContainer sx={{ minHeight: 150, maxHeight: 250 }}>
                {selectedHub && selectedProject && <ForgeDocumentManagementTree hub={selectedHub.id} project={selectedProject.id} folder={null} />}
            </ScrollContainer>
        </>
    );
};

export default ForgeFileBrowser;
