import { useEffect, useState } from "react";
import { getDashboardData } from "./dahsboardata";
import { Box, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import FontAwesomeSvgIcon from "../../Components/FontAwesomeSvgIcon";
import { Button } from "@mui/material";
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-duotone-svg-icons";
function onlyUnique(value: any, index: any, array: any) {
    return array.indexOf(value) === index;
}

const Dashboard = () => {
    const [data, setData] = useState<any>(null);
    const [datas, setDatas] = useState<any>([]);
    const [datasDer, setDatasDer] = useState<any>([]);
    const [avg, setAvg] = useState<any>();
    const [avgDer, setAvgDer] = useState<any>();
    const [docIndex, setDocIndex] = useState(-1);
    const [checksets, setChecksets] = useState<any>([]);
    const [checksetIndex, setChecksetIndex] = useState<number>(-1);

    const recalc = () => {
        getDashboardData().then((res: any) => {
            (window as any).data = res;
            let data = res.map((x: any) => {
                return x.map((y: any) => {
                    return y.documentVersions.map((z: any) => z.checksetResults).flat();
                })
            });

            let dt = data.flat().flat().map((x: any) => new Date(x.runRequestDate)).sort((x: any, y: any) => y.getTime() - x.getTime());
            var startDate = dt[0].getTime();
            var endDate = dt[dt.length - 1].getTime() - startDate;
            let datas: any[] = [];
            let index = 0;
            for (let d of data) {
                if (docIndex != -1 && index != docIndex) continue;
                index++;
                for (let dv of d) {
                    let myData: number[] = Array(20).fill(-1);
                    for (let check of dv) {
                        //for(let check of ds.checksetResults)
                        let skipCheck = false;
                        if (checksetIndex != -1) {
                            if (!check.checkResults.find((x: any) => x.id == checksets[checksetIndex].id)) {
                                skipCheck = true;
                            }
                        }
                        if (check.percentage != -1 && !skipCheck) {
                            let lerpValue = Math.round((new Date(check.runCompletionDate).getTime() - startDate) / (endDate) * 2);
                            myData[lerpValue] = check.percentage;
                        }
                    }
                    let nonNulls = myData.map((x, y) => ({ value: x, index: y })).filter((x: any) => x.value != -1)
                    if (nonNulls.length == 1) {
                        myData = Array(20).fill(nonNulls[0].value);
                        datas.push(myData.map((x, y) => ({ x: y, y: x })));
                    } else if (nonNulls.length > 0) {
                        //lerp between non nulls if start or end just take the first or last
                        for (let i = 0; i < nonNulls.length; i++) {
                            {
                                let nn = nonNulls[i];
                                if (nn.index == 0) {
                                    for (let i = 0; i < nn.index; i++) {
                                        myData[i] = nn.value;
                                    }
                                }
                                if (i == nonNulls.length - 1) {
                                    for (let i = nn.index; i < myData.length; i++) {
                                        myData[i] = nn.value;
                                    }
                                } else {

                                    for (let x = nn.index; x < nonNulls[i + 1].index; x++) {
                                        myData[x] = nn.value + (nonNulls[i + 1].value - nn.value) * (x - nn.index) / (nonNulls[i + 1].index - nn.index);
                                    }
                                }

                            }
                        }
                        datas.push(myData.map((x, y) => ({ x: y, y: x })));

                    }

                    console.log(myData);
                }
            }
            if (datas.length > 0 && datas[0].length > 0) {
                let ln = datas[0].length;
                let avg: any[] = [];
                for (let i = 0; i < ln; i++) {
                    let sum = 0;
                    for (let j = 0; j < datas.length; j++) {
                        sum += datas[j][i].y;
                    }
                    avg.push(sum / datas.length);

                }
                console.log(avg);
                setAvg(avg);
                let avgDer: any[] = [];
                for (let i = 1; i < avg.length; i++) {
                    avgDer.push(avg[i] - avg[i - 1]);
                }
                setAvgDer(avgDer);
            }
            setDatas(datas);
            let datasDer: any[] = [];
            for (let d of datas) {
                let dDer: any[] = [];
                for (let i = 1; i < d.length; i++) {
                    dDer.push(d[i].y - d[i - 1].y);
                }
                datasDer.push(dDer);
            }
            let ln = datasDer.length;
            let avg2: any[] = [];
            for (let i = 0; i < ln; i++) {
                let sum = 0;
                for (let j = 0; j < datasDer.length; j++) {
                    sum += datasDer[j][i];
                }
                avg2.push(sum / datasDer.length);

            }
            setDatasDer(avg2);

        });

    }
    useEffect(() => {
        getDashboardData().then((res: any) => {
            (window as any).data = res;
            let data = res.map((x: any) => {
                return x.map((y: any) => {
                    return y.documentVersions.map((z: any) => z.checksetResults).flat();
                })
            });
            let ids = data.flat().flat().map((x: any) => x.checkResults).flat().map((x: any) => x.id);
            let uIds = ids.filter(onlyUnique);
            setChecksets(uIds);
            console.log("uids", uIds);
        });
        recalc();
    }, []);
    useEffect(() => {
        recalc();
    }, [docIndex, checksetIndex]);
    return <>
        <div style={{ "textAlign": "center", "width": "100%" }}>
            {docIndex != -1 ? <Button onClick={() => setDocIndex(docIndex - 1)}><FontAwesomeSvgIcon icon={faCaretLeft} /></Button> : null}
            <span>{docIndex == -1 ? "All Docs" : "Doc" + (docIndex + 1)}</span>
            {docIndex != 1 ? <Button onClick={() => setDocIndex(docIndex + 1)}><FontAwesomeSvgIcon icon={faCaretRight} /></Button> : null}

        </div>

        <div style={{ "textAlign": "center", "width": "100%" }}>
            {checksetIndex != -1 ? <Button onClick={() => setChecksetIndex(checksetIndex - 1)}><FontAwesomeSvgIcon icon={faCaretLeft} /></Button> : null}
            <span>{checksetIndex == -1 ? "All Checksets" : checksets[checksetIndex]}</span>
            {checksetIndex < checksets.length - 1 ? <Button onClick={() => setChecksetIndex(checksetIndex + 1)}><FontAwesomeSvgIcon icon={faCaretRight} /></Button> : null}

        </div>
        {avg && <VictoryChart

            theme={VictoryTheme.material}
        >
            <VictoryLine
                data={avg.map((x: any, y: any) => ({ x: y, y: x }))}
            />
        </VictoryChart>}
        <VictoryChart
            theme={VictoryTheme.material}
        >
            {datas.map((x: any) =>
            (<VictoryLine
                data={x}
            />))}

        </VictoryChart>

        {avgDer && <VictoryChart

            theme={VictoryTheme.material}
        >
            <VictoryLine
                data={avgDer.map((x: any, y: any) => ({ x: y, y: x }))}
            />
        </VictoryChart>}
        {datasDer && <VictoryChart

            theme={VictoryTheme.material}
        >
            <VictoryLine
                data={datasDer.map((x: any, y: any) => ({ x: y, y: x }))}
            />
        </VictoryChart>}

    </>;
}


export default Dashboard;