import React, { ReactElement, useState } from "react";
import { ThemeProvider, CssBaseline } from "@mui/material";
import {
  Tree,
  NodeModel,
  MultiBackend,
  getBackendOptions,
  DndProvider,
  RenderParams
} from "@minoru/react-dnd-treeview";
import { CustomNode } from "./CustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { Placeholder } from "./Placeholder";
import { theme } from "./theme";
import styles from "./App.module.css";
import { ChecksetDefinition, ChecksetGroup } from "../../../Connectors/_Models/Checkset";
interface ChecksetDefinitionTreeProps {
  treeData: NodeModel<NodeModelBody>[];
  setTreeData: (nm: NodeModel<NodeModelBody>[]) => void;
  render: (nm: NodeModel<NodeModelBody>, opt: RenderParams) => ReactElement
}

export interface NodeModelBody {
  id: string;
  parent?: ChecksetGroup;
  folder?: ChecksetGroup;
  definition?: ChecksetDefinition;
}

export const ChecksetDefinitionTree = ({ treeData, setTreeData, render }: ChecksetDefinitionTreeProps) => {
  const handleDrop = (newTree: NodeModel<NodeModelBody>[]) => setTreeData(newTree);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <div>
          <Tree
            tree={treeData}
            rootId={0}
            sort={false}
            insertDroppableFirst={false}
            dropTargetOffset={10}
            canDrop={(tree, { dragSource, dropTargetId }) => {
              if (dragSource?.parent === dropTargetId) {
                return true;
              }
            }}
            render={(node, options) => 
            <CustomNode node={node} {...options}>
              {render(node,options)}
            </CustomNode>}
            dragPreviewRender={(monitorProps) => (
              <CustomDragPreview monitorProps={monitorProps} />
            )}
            placeholderRender={(node, { depth }) => (
              <Placeholder node={node} depth={depth} />
            )}
            onDrop={handleDrop}
            classes={{
              root: styles.treeRoot,
              draggingSource: styles.draggingSource,
              placeholder: styles.placeholderContainer
            }}
          />
        </div>
      </DndProvider>
    </ThemeProvider>
  );
}

