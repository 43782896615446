import { ChangeEvent, MouseEvent, useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Divider,
    Drawer,
    IconButton,
    Input,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TextField,
    Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { faCaretDown, faCaretRight, faEllipsis, faTimes } from "@fortawesome/pro-solid-svg-icons";
import FontAwesomeSvgIcon from "../../Components/FontAwesomeSvgIcon";
import { ChecksetDefinitionContext } from "../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
import { DndProvider } from "react-dnd";
import { Tree, NodeModel, MultiBackend, getBackendOptions } from "@minoru/react-dnd-treeview";
import {
    addGroup,
    changeChecksetDefinitionName,
    closeChecksetDefinitionDrawer,
    openChecksetDefinitionConfigDrawer,
    loadCheck,
    newCheck,
    addChildGroup,
    removeGroup,
    removeCheck,
    changeGroupName,
    setActiveChecksetIsEdited,
    clearActiveChecksetEdited,
    loadChecksetDefinition,
} from "../../Context/ChecksetDefinitionContext/Reducer";
import { ChecksetDefinition, ChecksetGroup, ChecksetType, FilenameCheck, SimplePropertyCheck, SizeCheck } from "../../Connectors/_Models/Checkset";
import { faChevronRight, faFolder, faFileCheck as faFileCheckLight } from "@fortawesome/pro-light-svg-icons";
import { faClone, faFileCheck, faFolderPlus, faICursor, faPencil, faTrash } from "@fortawesome/pro-duotone-svg-icons";
import { createCheckset, updateCheckset } from "../../Connectors/checksets";
import { uuid4 } from "@sentry/utils";
import { addCheckset, updateCheckset as updateChecksetContext } from "../../Context/HealthCheckContext/Reducer";
import { HealthCheckContext } from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import { ChecksetDefinitionTree, NodeModelBody } from "./ChecksetDefinitionTree/index"
const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const GroupsContainer = styled("ul")({
    padding: 0,
    margin: 0,
});

const ChecksetDefinitionDrawer = () => {
    const { state: checksetDefinitionState, dispatch: checksetDefinitionDispatch } = useContext(ChecksetDefinitionContext);
    const { dispatch: healthCheckDispatch } = useContext(HealthCheckContext);

    const [pushed, setPushed] = useState<boolean>(false);

    const [groupModalVisible, setGroupModalVisible] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<string>("");

    const [saving, setSaving] = useState<boolean>(false);
    const [closeDialog, setCloseDialog] = useState<boolean>(false);
    const [treeData, setTreeData] = useState<NodeModel<NodeModelBody>[]>([]);

    const theme = useTheme();

    useEffect(() => {
        checksetDefinitionDispatch(clearActiveChecksetEdited());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        checksetDefinitionDispatch(clearActiveChecksetEdited());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checksetDefinitionState.checksetDefinitionDrawerActive])

    useEffect(() => {
        setPushed(checksetDefinitionState.checksetDefinitionConfigDrawerActive);
        setSaving(false);
    }, [checksetDefinitionState.checksetDefinitionConfigDrawerActive]);

    const onDuplicate = (data: NodeModel<NodeModelBody>) => {
        if (!data.data?.definition || data.data?.folder) return;
        let tree = [...treeData];
        let name = `${data.text} (1)`;
        let cloneIndex = 0;
        while (tree.find(x => x.text === name)) {
            cloneIndex++;
            name = `${data.text} (${cloneIndex})`;
        }
        let newData = { ...data };
        newData.text = name;
        let definition = { ...data.data.definition };
        definition.name = name;
        definition.id = uuid4();
        newData.id = getUniqueOrExistingTreeDataId(definition.id);
        newData.data = {
            id: definition.id,
            parent: data.data.parent,
            definition
        }
        tree.push(newData)
        onTreeChanged(tree);
    };

    const onTreeChanged = (data: NodeModel<NodeModelBody>[]) => {
        if (!checksetDefinitionState.checkset) return;
        for (let nodeModel of data) {
            if (nodeModel.parent == 0) {
                let foundModel = treeData.find(x => x.id == nodeModel.id);
                if (foundModel) {
                    nodeModel.parent = foundModel.parent;
                }
            }
            for (let nodeModel2 of data) {
                if (nodeModel.parent === nodeModel2.id && nodeModel.data?.parent && nodeModel2.data?.folder) {
                    nodeModel.data.parent = nodeModel2.data.folder;
                }
            }
        }

        let groups: ChecksetGroup[] = [];
        //
        for (let nodeModel of data) {
            if (nodeModel.data?.folder) {
                // nodeModel.data.folder.checkDefinitions = [];
                // nodeModel.data.folder.checkgroups = [];
                nodeModel.data.folder.items = [];

            }
        }

        for (let nodeModel of data) {
            if (nodeModel.data?.folder) {
                for (let data2 of data) {
                    if (nodeModel.id === data2.parent) {
                        if (data2.data?.folder) {
                            data2.data.folder.isFolder = true;
                            nodeModel.data.folder.items.push(data2.data.folder);
                            //nodeModel.data.folder.checkgroups.push(data2.data.folder);
                        }
                        if (data2.data?.definition) {
                            data2.data.definition.isFolder = false;
                            nodeModel.data.folder.items.push(data2.data.definition);
                            //nodeModel.data.folder.checkDefinitions.push(data2.data.definition);
                        }
                    }
                }
            }
        }

        for (let data of treeData) {
            if (data.data?.folder && data.parent === 0) {
                groups.push(data.data.folder);
            }
        }
        console.log(groups);

        let checkset = { ...checksetDefinitionState.checkset };
        checkset.checkgroups = groups;
        checksetDefinitionDispatch(loadChecksetDefinition(checkset));


        //setTreeData(data);

        //setTreeData(data);
    };

    useEffect(() => {
        if (checksetDefinitionState.checkset) {
            console.log(checksetDefinitionState.checkset);
            setTreeData(getTreeData());
        }
    }, [checksetDefinitionState.checkset]);

    useEffect(() => {

    }, [treeData])

    const onClose = () => {
        if (saving) return;
        if (checksetDefinitionState.activeChecksetEdited) {
            setCloseDialog(true);
            return;
        }
        setTreeData([]);
        checksetDefinitionDispatch(loadChecksetDefinition(null));
        checksetDefinitionDispatch(closeChecksetDefinitionDrawer());
    };

    const onCloseDiscarded = () => {
        if (saving) return;
        setCloseDialog(true);
        setCloseDialog(false);
        checksetDefinitionDispatch(closeChecksetDefinitionDrawer());
    };

    const onCreateGroup = () => {
        setGroupName("");
        setGroupModalVisible(true);
    };

    const onAddGroup = () => {
        checksetDefinitionDispatch(addGroup(groupName));
        setGroupModalVisible(false);
        checksetDefinitionDispatch(setActiveChecksetIsEdited());
    };

    const onCloseCreateGroup = () => setGroupModalVisible(false);

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        checksetDefinitionDispatch(changeChecksetDefinitionName(e.currentTarget.value));
    };

    const onSave = () => {
        if (!checksetDefinitionState.checkset) return;
        console.log(checksetDefinitionState.checkset);
        setSaving(true);

        const recursiveFixOrderAndConvertItemToCheckgroupAndCheckdefinition = (checkgroup: ChecksetGroup): ChecksetGroup[] => {
            let retValue: ChecksetGroup[] = [];
            if (checkgroup.items) {
                let items = checkgroup.items.map((x, y) => {
                    x.order = y;
                    return x;
                })
                checkgroup.checkDefinitions = items.filter(x => !x.isFolder) as ChecksetDefinition[];
                retValue = items.filter(x => x.isFolder) as ChecksetGroup[];
                
                
                checkgroup.checkgroups = [];
                for (let checkgroupIndex in retValue) {
                    retValue[checkgroupIndex].checkgroups = recursiveFixOrderAndConvertItemToCheckgroupAndCheckdefinition(retValue[checkgroupIndex]);
                    console.log("qsdf");
                }
            }
            return retValue;
        }

        if (checksetDefinitionState.checkset.checkgroups) {
            //let order = 0;
            for (let checkgroup of checksetDefinitionState.checkset.checkgroups) {
                checkgroup.checkgroups = recursiveFixOrderAndConvertItemToCheckgroupAndCheckdefinition(checkgroup);
            }
        }

        if (checksetDefinitionState.checkset.id) {
            updateCheckset(checksetDefinitionState.checkset.id!, checksetDefinitionState.checkset).then((data) => {
                //todo extract to helper method
                let fixCheckgroupItems = (checkgroups: ChecksetGroup[]) => {
                    console.log(checkgroups);
                    for (let checkgroup of checkgroups) {
                        if (!checkgroup.items) checkgroup.items = [];
                        for (let cg of checkgroup.checkgroups) {
                            cg.isFolder = true;
                            checkgroup.items.push(cg);
                        }
                        for (let check of checkgroup.checkDefinitions) {
                            check.isFolder = false;
                            checkgroup.items.push(check);
                        }
                        fixCheckgroupItems(checkgroup.checkgroups);
                        checkgroup.items.sort((a, b) => a.order - b.order);
                    }
                };

                fixCheckgroupItems(data.checkgroups!);
                healthCheckDispatch(updateChecksetContext(data));
                checksetDefinitionDispatch(closeChecksetDefinitionDrawer());
                setSaving(false);
            });
        } else {
            createCheckset(checksetDefinitionState.checkset).then((data) => {
                healthCheckDispatch(addCheckset(data));
                checksetDefinitionDispatch(closeChecksetDefinitionDrawer());
                setSaving(false);
            });
        }
    };

    const getUniqueOrExistingTreeDataId = (name: string) => {
        let id = 0;
        let existing = treeData.find(x => x.data?.id === name)
        if (existing) {
            return existing.id as number;
        }
        while (treeData.find(x => x.id === id) || id === 0) {
            id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
        }
        return id;
    }

    const getTreeData = () => {
        let data: NodeModel<NodeModelBody>[] = [];
        const populateModelFromGroup = (checkgroups: ChecksetGroup[], parent: number) => {
            for (let group of checkgroups) {
                let parentId = parent;
                if (parent == 0) {

                    parentId = getUniqueOrExistingTreeDataId(group.id ? group.id : group.name);
                    let folder: NodeModel<NodeModelBody> = {
                        id: parentId,
                        parent,
                        droppable: true,
                        text: group.name,
                        data: {
                            id: group.id ? group.id : group.name,
                            folder: group
                        }
                    };
                    data.push(folder);
                }
                if (!group.items) continue;
                for (let item of group.items) {
                    //if(item.isFolder)continue;
                    let def = item as ChecksetDefinition;
                    let id = getUniqueOrExistingTreeDataId(def.id);
                    if (item.isFolder) {
                        let fldr: NodeModel<NodeModelBody> = {
                            id: id,
                            parent: parentId,
                            droppable: true,
                            text: item.name,
                            data: {
                                id: item.id ? item.id : item.name,
                                folder: item as ChecksetGroup
                            }
                        };
                        data.push(fldr);
                        let checkGroupItem = item as ChecksetGroup;
                        if (checkGroupItem.items.length > 0) {
                            populateModelFromGroup([checkGroupItem], fldr.id as number);
                        }
                    } else {

                        let file: NodeModel<NodeModelBody> = {
                            id: id,
                            parent: parentId,
                            droppable: false,
                            text: item.name,
                            data: {
                                id: def.id,
                                parent: group,
                                definition: def
                            }
                        };
                        data.push(file);
                    }
                }

            }
        }
        if (checksetDefinitionState.checkset?.checkgroups) {
            populateModelFromGroup(checksetDefinitionState.checkset.checkgroups, 0)
        }
        return data;
    };

    return (
        checksetDefinitionState.checkset && (
            <Drawer
                variant="temporary"
                anchor="right"
                open={checksetDefinitionState.checksetDefinitionDrawerActive}
                onClose={onClose}
                sx={{
                    display: {
                        xs: "none",
                        sm: "block",
                        width: "50%",
                    },
                    "& .MuiDrawer-paper": {
                        boxSizing: "border-box",
                        width: "50%",
                        overflow: "hidden",
                        ...(pushed && {
                            transform: "translate(-50%, 0) !important",
                        }),
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={onClose}>
                        <FontAwesomeSvgIcon icon={faTimes} />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        <Input
                            type="text"
                            value={checksetDefinitionState.checkset.name}
                            onChange={onChangeName}
                            sx={{
                                display: "flex",
                                placeContent: "center",
                                fontFamily: "'Poppins', sans-serif",
                                fontWeight: 600,
                                fontSize: "inherit",
                                ml: 1,
                            }}
                            disabled={saving}
                        />
                    </Typography>
                    <Box
                        sx={{
                            ml: "auto",
                            display: "flex",
                        }}
                    >
                        <Button variant="contained" onClick={onCreateGroup} disabled={saving}>
                            Add Group
                        </Button>
                        <Box sx={{ ml: 1, position: "relative" }}>
                            <Button variant="contained" disabled={saving} onClick={onSave}>
                                Save
                            </Button>
                            {saving && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        marginTop: "-12px",
                                        marginLeft: "-12px",
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </DrawerHeader>

                <Divider />

                <Box
                    sx={{
                        p: 1,
                        backgroundColor: theme.palette.background.default,
                        flex: 1,
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    {(!checksetDefinitionState.checkset.checkgroups || checksetDefinitionState.checkset.checkgroups.length === 0) && (
                        <Card sx={{ m: 1 }}>
                            <CardContent sx={{ ":last-child": { pb: 2 } }}>
                                <Typography>
                                    Some message to inform the user about this new checkset and that they should begin by adding their first checkset group to
                                    add their checks to.
                                </Typography>
                            </CardContent>
                        </Card>
                    )}

                    {checksetDefinitionState.checkset.checkgroups && checksetDefinitionState.checkset.checkgroups.length > 0 && (
                        <Card sx={{ m: 1 }}>
                            <CardContent sx={{ ":last-child": { pb: 2 } }}>
                                <ChecksetDefinitionTree
                                    treeData={treeData}
                                    setTreeData={onTreeChanged}
                                    render={
                                        (node, options) => (<div
                                        >
                                            {node.droppable ?
                                                node?.data?.folder ? (<CheckGroup level={0} group={node.data.folder} setExpand={options.onToggle} expand={options.isOpen} />) : (<></>)
                                                :
                                                node?.data?.definition && node?.data.parent ? (<CheckDefinition definition={node.data.definition} group={node.data.parent} level={0}
                                                    onDuplicate={() => onDuplicate(node)}

                                                />) : (<></>)
                                            }
                                        </div>)
                                    }
                                />
                                {/* <DndProvider backend={MultiBackend} options={getBackendOptions()}
                                >
                                    <Tree tree={treeData}
                                        sort={false}
                                        rootId={0}
                                        onDrop={onTreeChanged}
                                        render={(node, { depth, isOpen, onToggle }) => {
                                            return <div>
                                                {node.droppable ?
                                                    node?.data?.folder ? (<CheckGroup level={0} group={node.data.folder} setExpand={onToggle} expand={isOpen} />) : (<></>)
                                                    :
                                                    node?.data?.definition && node?.data.parent ? (<CheckDefinition definition={node.data.definition} group={node.data.parent} level={0}
                                                        onDuplicate={() => onDuplicate(node)}

                                                    />) : (<></>)
                                                }
                                            </div>
                                        }}
                                        dragPreviewRender={(properdeProp) => (<span>{properdeProp.item.text}</span>)}
                                        placeholderRender={() => <span style={{ position: "absolute", left: 0, right: 0, top: 0, height: "2px", backgroundColor: "blue" }} />}
                                        listComponent={(x) => {
                                            if (x?.children?.length > 1 && x.children[1]?.props?.depth === 0) {
                                                return <ul style={{ listStyle: "none", padding: 0, position: "relative" }}>{x.children}</ul>
                                            }
                                            return <ul style={{ listStyle: "none", position: "relative" }}>{x.children}</ul>
                                        }}
                                    />

                                </DndProvider> */}
                            </CardContent>
                        </Card>
                    )}

                    <Dialog open={groupModalVisible} onClose={onCloseCreateGroup}>
                        <DialogTitle>Add new group</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Some information about a group? (optional information, could also skip text here and just show the input)
                            </DialogContentText>
                            <TextField
                                value={groupName}
                                onChange={(e) => setGroupName(e.currentTarget.value)}
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Group Name"
                                type="text"
                                fullWidth
                                variant="standard"
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={onCloseCreateGroup}>Cancel</Button>
                            <Button onClick={onAddGroup}>Add</Button>
                        </DialogActions>
                    </Dialog>
                </Box>
                <Dialog
                    open={closeDialog}
                    onClose={() => setCloseDialog(false)}
                >
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            You have pending changes! Are you sure you want to discard these by closing without saving?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onCloseDiscarded()}>Close</Button>
                        <Button onClick={() => setCloseDialog(false)} >Cancel</Button>
                    </DialogActions>
                </Dialog>
            </Drawer>
        )
    );
};

export default ChecksetDefinitionDrawer;

interface CheckGroupProps {
    level: number;
    group: ChecksetGroup;
    setExpand: () => void;
    expand: boolean
}

const CheckGroupItemContainer = styled("li")<{ level: number }>(({ level, theme }) => ({
    listStyle: "none",
    marginLeft: theme.spacing(level * 3),
    display: "flex",
    alignItems: "center",
    "&:hover": {
        // background: theme.palette.grey[200],
    },
}));

const ExpandToggle = styled("div")(({ theme }) => ({
    margin: theme.spacing(0.5),
    cursor: "pointer",
    "&>svg": {
        fontSize: 14,
    },
}));

const ExpandSpacer = styled("div")(({ theme }) => ({
    margin: theme.spacing(0.5),
    width: 14,
}));

const GroupInfo = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    flex: 1,
    "&>svg": {
        fontSize: 16,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
}));

const CheckGroup = ({ level, group, setExpand, expand }: CheckGroupProps) => {
    const { dispatch } = useContext(ChecksetDefinitionContext);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [subAnchorEl, setSubAnchorEl] = useState<HTMLElement | null>(null);

    const [groupModalVisible, setGroupModalVisible] = useState<boolean>(false);
    const [renameGroupModalVisible, setRenameGroupModalVisible] = useState<boolean>(false);
    const [groupName, setGroupName] = useState<string>("");

    const open = Boolean(anchorEl);
    const subOpen = Boolean(subAnchorEl);

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleMenuClick = (e: MouseEvent<HTMLElement>) => {
        setSubAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        handleSubClose();
    };

    const handleSubClose = () => {
        setSubAnchorEl(null);
    };

    const hasChildren = (): boolean => {
        // if (group.checkgroups && group.checkgroups.length > 0) return true;
        // if (group.checkDefinitions && group.checkDefinitions.length > 0) return true;
        //
        // return false;
        return true;
    };

    const onCreateGroup = () => {
        setGroupName("");
        setGroupModalVisible(true);
        handleClose();
    };

    const onAddGroup = () => {
        dispatch(addChildGroup(groupName, group));
        setGroupModalVisible(false);
        dispatch(setActiveChecksetIsEdited());
    };

    const onCloseCreateGroup = () => setGroupModalVisible(false);

    const findNextIndex = (group: ChecksetGroup): number => {
        // if (group.checkDefinitions.length === 0) return 0;
        // return group.checkDefinitions[group.checkDefinitions.length - 1].index + 1;
        return 0;
    };

    const onCreateSimplePropertyCheck = () => {
        const check: SimplePropertyCheck = {
            id: uuid4(),
            name: "New Check",
            order: 0,
            checkType: ChecksetType.SIMPLE_PROPERTY_CHECK,
            scope: null,
            ruleSet: null,
            isFolder: false,
            index: findNextIndex(group),
        };

        dispatch(newCheck(check, group));
        dispatch(openChecksetDefinitionConfigDrawer());
        handleClose();
    };
    const onCreateSizeCheck = () => {
        const check: SizeCheck = {
            id: uuid4(),
            name: "New Check",
            order: 0,
            checkType: ChecksetType.SIZE_CHECK,
            minSize: 0,
            maxSize: 0,
            isFolder: false,
            index: findNextIndex(group),
        };

        dispatch(newCheck(check, group));
        dispatch(openChecksetDefinitionConfigDrawer());
        handleClose();
    };
    const onCreateFilenameCHeck = () => {
        const check: FilenameCheck = {
            id: uuid4(),
            name: "New Check",
            order: 0,
            checkType: ChecksetType.FILENAME_CHECK,
            regex: "",
            isFolder: false,
            index: findNextIndex(group),
        };

        dispatch(newCheck(check, group));
        dispatch(openChecksetDefinitionConfigDrawer());
        handleClose();
    };

    const onDeleteGroup = () => {
        dispatch(removeGroup(group));
        handleClose();
    };

    const onRename = () => {
        setGroupName(group.name);
        setRenameGroupModalVisible(true);
        handleClose();
    };

    const onRenameGroup = () => {
        dispatch(changeGroupName(groupName, group));
        setRenameGroupModalVisible(false);
    };

    const onCloseRenameGroup = () => {
        setRenameGroupModalVisible(false);
    };

    return (
        <>
            <CheckGroupItemContainer level={level}>

                <GroupInfo onClick={() => setExpand()}>
                    <FontAwesomeSvgIcon icon={faFolder} />
                    <Typography>{group.name}</Typography>
                    {hasChildren() ? (
                        <ExpandToggle onClick={() => setExpand()}>
                            <FontAwesomeSvgIcon icon={expand ? faCaretDown : faCaretRight} />
                        </ExpandToggle>
                    ) : (
                        <ExpandSpacer />
                    )}

                </GroupInfo>
                <IconButton
                    sx={{ ml: "auto" }}
                    id="checkgroup-context-button"
                    aria-controls={open ? "checkgroup-context-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    <FontAwesomeSvgIcon icon={faEllipsis} />
                </IconButton>

                <Menu id="checkgroup-context-menu" aria-labelledby="checkgroup-context-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
                    <MenuItem onClick={onCreateGroup}>
                        <ListItemIcon>
                            <FontAwesomeSvgIcon icon={faFolderPlus} />
                        </ListItemIcon>
                        <ListItemText>Add subgroup</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleMenuClick}>
                        <ListItemIcon>
                            <FontAwesomeSvgIcon icon={faFileCheck} />
                        </ListItemIcon>
                        <ListItemText>Add Check</ListItemText>
                        <Typography variant="body2" color="text.secondary">
                            <FontAwesomeSvgIcon icon={faChevronRight} sx={{ fontSize: 12 }} />
                        </Typography>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={onRename}>
                        <ListItemIcon>
                            <FontAwesomeSvgIcon icon={faICursor} />
                        </ListItemIcon>
                        <ListItemText>Rename</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={onDeleteGroup}>
                        <ListItemIcon>
                            <FontAwesomeSvgIcon icon={faTrash} />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                    </MenuItem>
                </Menu>

                <Menu
                    id="checkgroup-context-menu"
                    aria-labelledby="checkgroup-context-button"
                    anchorEl={subAnchorEl}
                    open={subOpen}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <MenuItem onClick={onCreateSimplePropertyCheck}>
                        <ListItemText>Simple Property Check</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={onCreateSizeCheck}>
                        <ListItemText>Size Check</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={onCreateFilenameCHeck}>
                        <ListItemText>Filename Check</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose} disabled>
                        <ListItemText>Manual Check</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleClose} disabled>
                        <ListItemText>Coordinates Check</ListItemText>
                    </MenuItem>
                </Menu>
            </CheckGroupItemContainer>
            <Dialog open={groupModalVisible} onClose={onCloseCreateGroup}>
                <DialogTitle>Add new group</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Some information about a group? (optional information, could also skip text here and just show the input)
                    </DialogContentText>
                    <TextField
                        value={groupName}
                        onChange={(e) => setGroupName(e.currentTarget.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Group Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseCreateGroup}>Cancel</Button>
                    <Button onClick={onAddGroup}>Add</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={renameGroupModalVisible} onClose={onCloseRenameGroup}>
                <DialogTitle>Rename group</DialogTitle>
                <DialogContent>
                    <TextField
                        value={groupName}
                        onChange={(e) => setGroupName(e.currentTarget.value)}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Group Name"
                        type="text"
                        fullWidth
                        variant="standard"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCloseRenameGroup}>Cancel</Button>
                    <Button onClick={onRenameGroup}>Rename</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

interface CheckDefinitionProps {
    level: number;
    definition: ChecksetDefinition;
    group: ChecksetGroup;
    onDuplicate: () => void;
}

const CheckDefinition = ({ level, definition, group, onDuplicate }: CheckDefinitionProps) => {
    const { dispatch } = useContext(ChecksetDefinitionContext);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const onEditCheck = () => {
        dispatch(loadCheck(definition, group));
        dispatch(openChecksetDefinitionConfigDrawer());
        handleClose();
    };

    const onDeleteCheck = () => {
        dispatch(removeCheck(definition));
        handleClose();
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <CheckGroupItemContainer level={level}>
            <GroupInfo>
                <FontAwesomeSvgIcon icon={faFileCheckLight} />
                <Typography>{definition.name}</Typography>
            </GroupInfo>

            <IconButton
                sx={{ ml: "auto" }}
                id="checkgroup-context-button"
                aria-controls={open ? "checkgroup-context-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
            >
                <FontAwesomeSvgIcon icon={faEllipsis} />
            </IconButton>

            <Menu id="checkgroup-context-menu" aria-labelledby="checkgroup-context-button" anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={onEditCheck}>
                    <ListItemIcon>
                        <FontAwesomeSvgIcon icon={faPencil} />
                    </ListItemIcon>
                    <ListItemText>Edit</ListItemText>
                </MenuItem>
                <MenuItem onClick={onDeleteCheck}>
                    <ListItemIcon>
                        <FontAwesomeSvgIcon icon={faTrash} />
                    </ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    onDuplicate();
                }}
                >
                    <ListItemIcon>
                        <FontAwesomeSvgIcon icon={faClone} />
                    </ListItemIcon>
                    <ListItemText>Duplicate</ListItemText>
                </MenuItem>
            </Menu>
        </CheckGroupItemContainer>
    );
};
