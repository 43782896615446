import { Box, List } from "@mui/material";
import { styled } from "@mui/system";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import navigation from "./Navigation";
import SidebarGroup from "./SidebarGroup";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    userSelect: "none",
    overflow: "auto",
    ...GetScrollbarStyle(theme),
}));

const Navigation = styled(List)(({ theme }) => ({
    "& .MuiListItemButton-root": {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderRadius: theme.spacing(0.5),
        margin: 2,
    },
    "& .MuiListItemIcon-root": {
        minWidth: 30,
        marginRight: theme.spacing(2),
        placeContent: "center",
    },
    "& .MuiSvgIcon-root": {
        fontSize: 20,
    },
}));

const renderNavigation = () => {
    const renderGroups = navigation.length > 1;

    return navigation.map((group, index) => <SidebarGroup key={index} group={group} renderGroup={renderGroups} />);
};

const Sidebar = () => {
    return (
        <Container>
            <Navigation disablePadding>{renderNavigation()}</Navigation>
        </Container>
    );
};

export default Sidebar;
