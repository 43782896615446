import { faChartPie, faClipboard, faCubes, faLifeRing, faWavePulse, IconDefinition } from "@fortawesome/pro-duotone-svg-icons";

export interface NavigationGroup {
    label: string;
    description: string;
    children: NavigationItem[];
}

export interface NavigationItem {
    label: string;
    route?: RouteDefinition;
    icon?: IconDefinition;
    children?: NavigationItem[];
    disabled?: boolean;
}

export interface RouteDefinition {
    to: string;
    exact: boolean;
    alternate?: string[];
}

const navigation: NavigationGroup[] = [
    {
        label: "Dashboards",
        description: "Project Information",
        children: [
            {
                label: "Project",
                icon: faClipboard,
                disabled: true,
            },
            {
                label: "Analytics",
                icon: faChartPie,
                disabled: true,
            },
        ],
    },
    {
        label: "Applications",
        description: "Available Applications",
        children: [
            {
                label: "Health Check",
                icon: faWavePulse,
                children: [
                    {
                        label: "Home",
                        route: {
                            to: "/health-check",
                            exact: true,
                            alternate: ["/health-check/forge-callback", "/health-check/onboarding","/health-check/folder/:folders"],
                        },
                    },
                    {
                        label: "Checkset Definitions",
                        route: {
                            to: "/health-check/definitions",
                            exact: false,
                        },
                    },
                    // {
                    //     label: "Results",
                    //     route: {
                    //         to: "/health-check/results",
                    //         exact: false,
                    //     },
                    // },
                ],
            },
            {
                label: "Model Viewer",
                icon: faCubes,
                disabled: true,
            },
            {
                label: "Bob",
                icon: faCubes,
                route:{to:"/bob",exact:true}
            },
            {
                label: "Support",
                icon: faLifeRing,
                children: [
                    {
                        label: "FAQ",
                        route: {
                            to: "/support/faq",
                            exact: true,
                        },
                        disabled: true,
                    },
                    {
                        label: "Contact",
                        route: {
                            to: "/support/contact",
                            exact: true,
                        },
                        disabled: true,
                    },
                ],
            },
        ],
    },
];

export default navigation;
