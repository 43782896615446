import { createRef, useEffect, useRef, useState } from "react";
import ScriptLoader from "../../../Utils/ScriptLoader";
import {ModelViewerProps} from "../ModelViewerProperties";

interface HighlightedObjects{
    ids:number[];
    color:string;
}
interface IdRelation{
    id:number;
    relation:number;
}
let idCache:IdRelation[]=[];

const ForgeViewer = ({url: urn, onTokenRequest, initializeCallbacks, loadedDocumentCallback, savePositionCallback }: ModelViewerProps) => {
    const [enable, setEnable] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [empty, setEmpty] = useState<boolean>(false);
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
    const [modelLoaded, setModelLoaded] = useState<boolean>(false);
    const [camInitialChanged, setCamInitialChanged] = useState<boolean>(false);
    const [camPos, setCamPos] = useState<string>("");
    const [highlightedObject, setHighlightedObject] = useState<HighlightedObjects[]>([]);
    const highlightedObjectRef = useRef(highlightedObject);
    highlightedObjectRef.current = highlightedObject;
    let viewerDiv: any = createRef();
    let resizeHandling: any = null;
    let [viewer, setViewer] = useState<any>(null);
    let views: any = [];
    let docs: any = [];
    let version = "7.*";
    useEffect(() => {
        if (scriptLoaded) {

            (window as any).resizeforge = () => {
                handleResize();
            };

            setViewer(new (window as any).Autodesk.Viewing.Private.GuiViewer3D(viewerDiv.current, {}));


        }

    }, [scriptLoaded]);
    useEffect(() => {
        if (!viewer) return;
        (window as any).viewer=viewer;
        if(viewer?.getCamera())
            viewer.getCamera().isPerspective=true;
        viewer.addEventListener((window as any).Autodesk.Viewing.GEOMETRY_LOADED_EVENT,
            (e: any) => {
                loadedDocumentCallback();
                setModelLoaded(true);

            });
        let options = {
            env: 'AutodeskProduction', getAccessToken: onTokenRequest,
            //api: "derivativeV2_EU",// bimResources.Region === "EU" ? "derivativeV2_EU" : "derivativeV2",
            loaderExtensions: { svf: "Autodesk.MemoryLimited" }, // for allocating more memory: https://forge.autodesk.com/en/docs/viewer/v7/developers_guide/viewer_basics/memory-limit/
            language: "nl",
        };
        getAutodesk().Viewing.Initializer(
            options, handleViewerInit);
       
    }, viewer)

    useEffect(() => {
        if (modelLoaded && camPos) {
            viewer.getCamera().isPerspective=true;
            viewer.getCamera().updateProjectionMatrix();
            if (!camInitialChanged) {
                setCamInitialChanged(true);
                return;
            }
            savePositionCallback(camPos);
        }
    }, [camPos, modelLoaded])

    useEffect(() => {
        if (modelLoaded) {
            applyClearMaterial();
            if (highlightedObject.length !== 0) {
                for(let h of highlightedObject){
                    setHighlighted(h.ids,h.color);
                }
            }
        }
    }, [modelLoaded]);

    useEffect(() => {
        if (viewer) {
            if (highlightedObject.length === 0) {
                applyClearMaterial();
            } else {
                for(let h of highlightedObject){
                    setHighlighted(h.ids,h.color);
                }
            }
        }
    }, [highlightedObject])

    const getAutodesk = () => {
        return (window as any).Autodesk;
    };

    const handleScriptLoad = () => {
        setScriptLoaded(true);

    };
    const selectionChanged = (event: any) => {
        console.log(event);
    };

    const cameraChanged = () => {
        let prevTimeout = (window as any).camchangedTimeout;
        if (prevTimeout) clearTimeout(prevTimeout);
        (window as any).camchangedTimeout = setTimeout(() => {
            let newTimeout = (window as any).camchangedTimeout;
            clearTimeout(newTimeout);
            setCamPos(JSON.stringify(viewer.getState()));
            (window as any).camchangedTimeout = null;
        }, 200);
    };


    let getOrCreateMaterial = (color:string)=>{
        if(!viewer.impl.matman()._materials[color]){
            const material = new (window as any).THREE.MeshPhongMaterial({
                color: color.substr(0,7),
                transparent: false,
                depthTest: false,
                depthWrite: false,
                side: (window as any).THREE.DoubleSide,
                opacity: 1.0,
                flatShading: true,
                specular:0x000000
            });
            material.packedNormals = true;
            const materials = viewer.impl.matman();
            materials.addInstancingSupport(material);
            materials.addMaterial(color, material, true);
            return material;
        }
        return viewer.impl.matman()._materials[color];
    }
    
    const setHighlighted = (objs: number[],color:string) => {
        console.log("highlight", objs,color);
        let highlightMaterial = getOrCreateMaterial(color);
        let dbItems: number[] = [];
        objs.forEach((obj, index) => {
            {
                let cached= idCache.find(x=>x.id==obj);
                if(cached){
                    applyMaterial(viewer.model,cached.relation,highlightMaterial);
                }else{

                    viewer.search(String(obj), (elementIds: number[]) => {
                        dbItems = [...dbItems, ...elementIds];
                        for (let elementId of elementIds) {
                            idCache.push({id:obj,relation:elementId});
                            applyMaterial(viewer.model, elementId, highlightMaterial);
                        }
                        if (objs.length - 1 == index) {
                            viewer.fitToView(dbItems);
                        }
                    })
                }
            }
        });
        viewer.impl.invalidate(true, true, true);
    }
    const setClear = (objs: number[]) => {
        let transparentMaterial = viewer.impl.matman()._materials["transparentMaterial"];
        objs.forEach(obj => {
            viewer.search(String(obj), (elementIds: number[]) => {
                for (let elementId of elementIds) {
                    applyMaterial(viewer.model, elementId, transparentMaterial);
                }
            })
        });
        viewer.impl.invalidate(true, true, true);
    }
    const applyClearMaterial = () => {
        for (let model of viewer.getAllModels()) {
            try {
                viewer.clearThemingColors(model);
                let objs = Object.keys(model.getData().instanceTree.nodeAccess.dbIdToIndex).map(x => parseInt(x));
                let transparentMaterial = viewer.impl.matman()._materials["transparentMaterial"];
                for (let obj of objs) {

                    applyMaterial(model, obj, transparentMaterial);
                }
            } catch (ex) {
                console.log(ex);
            }
        }
    }

    const handleViewerInit = () => {
        var errorCode = viewer.initialize();
        (window as any).viewerInstance = viewer;
        (window as any).urn = urn;
        if (!errorCode) {
            setEnable(true);
            //reviewDocuments();
            viewer.prefs.tag("ignore-producer");
            viewer.prefs.set("reverseMouseZoomDir", true);
            viewer.addEventListener((window as any).Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, selectionChanged);
            viewer.addEventListener((window as any).Autodesk.Viewing.CAMERA_CHANGE_EVENT, () => cameraChanged());
            viewer.loadExtension("Autodesk.Viewing.MemoryLimitedDebug");
            loadDocument(urn);
        }
    };

    const applyMaterial = (model: any, dbid: any, material: any) => {
        const tree = model.getInstanceTree();
        const frags = model.getFragmentList();
        tree.enumNodeFragments(
            dbid,
            (fragid: any) => {
                frags.setMaterial(fragid, material);
            },
            true
        );
        viewer.impl.invalidate(true, true, true);
        model.unconsolidate();
    }


    const handleLoadDocumentSuccess = (doc: any) => {
        let views = doc.getRoot().search(
            { 'type': 'geometry' }, true
        );

        //augment viewables with the doc they came from
        views.forEach((viewable: any) => {
            viewable.doc = doc;
        })

        //raise an event so caller can select a viewable to display
        setViews(views);

        if (initializeCallbacks !== null) {
            initializeCallbacks(viewer, {
                getAllElements: () => {
                    let retVal: number[] = [];
                    for (let model of viewer.getAllModels()) {
                        viewer.clearThemingColors(model);
                        let objs = Object.keys(model.getData().instanceTree.nodeAccess.dbIdToIndex).map(x => parseInt(x));
                        retVal = [...retVal, ...objs];
                    }
                    return retVal;
                },
                setHighlighted: (elementsId: number[] | string[], color) => {
                    let currentHighlighted = highlightedObjectRef.current;
                    let coloredHighlight = currentHighlighted.find(x => x.color == color);
                    let newHighlightedObjects: HighlightedObjects[] = [];
                    for (let c of currentHighlighted) {
                        c.ids = c.ids.filter(x => !(elementsId as number[]).find(y => y === x));
                    }
                    if (coloredHighlight) {
                        newHighlightedObjects =
                            [...currentHighlighted.filter(x => x !== coloredHighlight), { ids: coloredHighlight.ids.concat(elementsId as number[]), color }];
                    } else {
                        newHighlightedObjects = [...currentHighlighted, { ids: elementsId as number[], color }];
                    }
                    setHighlightedObject(newHighlightedObjects);
                },
                clearAllItems: () => {
                    setHighlightedObject([]);
                    //applyClearMaterial();
                },
                clearItem: (elementId: number[] | string[]) => {
                    setClear(elementId as number[]);
                },
                loadPosition: (posObject: string) => {
                    viewer.restoreState(JSON.parse(posObject));
                }
            });
        }
        {
            const material = new (window as any).THREE.MeshPhongMaterial({
                opacity: 0.1,
                color: "#555555",
                side: (window as any).THREE.FrontSide,
                depthTest: false,
                depthWrite: false,
                //blending: (window as any).THREE.AdditiveBlending,
                transparent: true
            });
            const materials = viewer.impl.matman();
            materials.addInstancingSupport(material);
            materials.addMaterial('transparentMaterial', material, true);
        }

        {
            const material = new (window as any).THREE.MeshPhongMaterial({
                color: "#FF0000",
                transparent: false,
                depthTest: false,
                depthWrite: false,
                side: (window as any).THREE.DoubleSide,
                opacity: 1.0,
                flatShading: true,
                specular:0x000000
            });
            material.packedNormals = true;
            const materials = viewer.impl.matman();
            materials.addInstancingSupport(material);
            materials.addMaterial('opaqueMaterial', material, true);
        }


        (window as any).save = () => {
            (window as any).pos = JSON.stringify(viewer.getState());
        }

        (window as any).load = () => {
            viewer.restoreState(JSON.parse((window as any).pos));
        }
    };

    const handleLoadDocumentError = (errorCode: any) => {
        setError(true);

        console.error('Error loading Forge document - errorCode:' + errorCode);
    };

    const clearErrors = () => {
        setError(false);
    };

    const clearViews = () => {
        console.log('clearing all views.');
        views = {};
        if (viewer) {
            //restart viewer, for lack of ability to unload models
            viewer.tearDown();
            viewer.start();
        }
    };

    const reviewDocuments = () => {
        if (viewer) {
            clearErrors();
            console.log('reviewing documents...');
            //let keys = Object.keys(this.docs);
            setEmpty(docs.length == 0);
            docs.forEach((urn: any) => {
                loadDocument(urn);
            });
        }
    };
    const getBrowserContext = () => {
        return document;
    }
    const loadDocument = (urn: string) => {
        console.log('Forge Viewer is loading document:', urn);

        let documentId = `urn:${urn}`;
        let successHandler = handleLoadDocumentSuccess.bind(this);
        let errorHandler = handleLoadDocumentError.bind(this);
        (window as any).Autodesk.Viewing.Document.load(
            documentId, successHandler, errorHandler
        );
    };

    const loadView = (view: any) => {
        console.log('loading view:', view.guid);
        views[view.viewableID] = view;

        let svfUrl = view.doc.getViewablePath(view);
        let modelOptions = {
            sharedPropertyDbPath: view.doc.getFullPath()
        };

        //load the specified model
        viewer.loadModel(
            svfUrl, modelOptions, null, null
        );
        handleResize();
    };

    const isArrayDifferent = (current: any, next: any) => {
        if (current == null && next == null)
            return false;
        else if (current == null || next == null)
            return true;
        else if (current.length != next.length)
            return true;

        for (var i = 0; i < current.length; i++)
            if (current[i] != next[i])
                return true;
        return false;
    };

    const shouldComponentUpdateURN = (nextProps: any, nextState: any) => {
        //console.log('props urn:', this.props.urn, ' next props urn:', nextProps.urn)
        //new urn is null, empty or empty array
        if (!nextProps.urn || nextProps.urn === '' || typeof nextProps.urn === 'undefined' ||
            (Array.isArray(nextProps.urn) && nextProps.urn.length == 0)) {
            //clear out views if any document was previously loaded
            if (docs.length > 0) {
                setDocuments([]);
            }
        } else if (Array.isArray(nextProps.urn)) {
            //always have to check array because equivalence is per element
            if (isArrayDifferent(urn, nextProps.urn)) {
                setDocuments(nextProps.urn);
            }
        } else if (nextProps.urn != urn) {
            setDocuments([nextProps.urn]);
        }
    }


    const setDocuments = (list: any) => {
        docs = list;
        clearViews();
        reviewDocuments(); //defer loading until viewer ready
    };
    let curView: any = null
    const setViews = (list: any) => {
        if (curView == list[0]) return;
        //check to see if views were added or removed from existing list
        let existing = Object.assign({}, views);
        let incremental: any[] = [list[0]];
        curView = list[0];
        console.log("views", list);
        // list.forEach((view: any) => {
        //     if (existing.hasOwnProperty(view.viewableID))
        //         //the view was previously in the list
        //         delete existing[view.viewableID];
        //     else {
        //         //the view is newly added to the list
        //         incremental.push(view);
        //     }
        // });

        //anything left in old's keys should be unloaded
        let keys = Object.keys(existing);
        if (keys.length > 0) {
            //views were removed, so restart viewer for lack of 'unload'
            viewer.tearDown();
            viewer.start();
            list.forEach((view: any) => {
                loadView(view);
            });
        } else {
            //load views incrementally rather than a complete teardown
            incremental.forEach(view => {
                loadView(view);
            });
        }
    }


    const handleResize = () => {
        //cancel any previous handlers that were dispatched
        if (resizeHandling)
            clearTimeout(resizeHandling)

        //defer handling until resizing stops
        resizeHandling = setTimeout(() => {
            if (viewer) viewer.resize()
            viewerDiv?.current?.resize();
        }, 100)
    };


    return <>
        <div className="ForgeViewer" style={{ width: "100%", height: "100%", position: "relative" }}>
            <div ref={viewerDiv}></div>
            <link rel="stylesheet" type="text/css" href={`https://developer.api.autodesk.com/modelderivative/v2/viewers/style.min.css?v=v${version}`} />
            <ScriptLoader url={`https://developer.api.autodesk.com/modelderivative/v2/viewers/viewer3D.min.js?v=v${version}`}
                onLoad={handleScriptLoad.bind(this)}
            />

            {empty &&
                <div className="scrim">
                    <div className="message">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M12.89 1.45l8 4A2 2 0 0 1 22 7.24v9.53a2 2 0 0 1-1.11 1.79l-8 4a2 2 0 0 1-1.79 0l-8-4a2 2 0 0 1-1.1-1.8V7.24a2 2 0 0 1 1.11-1.79l8-4a2 2 0 0 1 1.78 0z"></path><polyline points="2.32 6.16 12 11 21.68 6.16"></polyline><line x1="12" y1="22.76" x2="12" y2="11"></line></svg>
                    </div>
                </div>
            }

            {error &&
                <div className="scrim">
                    <div className="message">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12" y2="16"></line></svg>
                        <div>Viewer Error</div>
                    </div>
                </div>
            }

            {!enable &&
                <div className="scrim">
                    <div className="message">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon></svg>
                        <div>Starting Viewer...</div>
                    </div>
                </div>
            }
        </div>
    </>
}

export default ForgeViewer;