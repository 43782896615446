import { useEffect } from "react";


interface ScriptLoaderProps {
    attributes?: any;
    onCreate?: any;
    onError?: any;
    onLoad?: any;
    url?: any;

}

class ScriptLoaderGlobals {
    static loadedScripts: any = {};
    static erroredScripts: any = {};
    static scriptObservers: any = {};

};

const ScriptLoader = ({ attributes, onCreate, onError, onLoad, url }: ScriptLoaderProps) => {
    useEffect(() => {
        if (ScriptLoaderGlobals.loadedScripts[url]) {
            onLoad();
            return;
        }
        if (ScriptLoaderGlobals.erroredScripts[url]) {
            onError();
            return;
        }
        if (ScriptLoaderGlobals.scriptObservers[url]) {
            ScriptLoaderGlobals.scriptObservers[url] = true;
            return;
        }
        ScriptLoaderGlobals.scriptObservers[url] = true;
        createScript();
    }, []);

    const createScript=()=> {
        const script = document.createElement('script');
        if(onCreate)
            onCreate();
    
        // add 'data-' or non standard attributes to the script tag
        if (attributes) {
          Object.keys(attributes).forEach(prop => script.setAttribute(prop, attributes[prop]));
        }
    
        script.src = url;
    
        // default async to true if not set with custom attributes
        if (!script.hasAttribute('async')) {
          script.async = true;
        }
    

        script.onload = () => {
          ScriptLoaderGlobals.loadedScripts[url] = true;
          onLoad()
        };
    
        script.onerror = () => {
          ScriptLoaderGlobals.erroredScripts[url] = true;
          onError();
        };
    
        document.body.appendChild(script);
      }

    return <>
    </>
};


export default ScriptLoader;