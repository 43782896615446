import { createContext, Dispatch, useReducer } from "react";
import { GlobalActions } from "./Actions";
import globalReducer from "./Reducer";
import { GlobalState, initialGlobalState } from "./State";

interface GlobalContextProps {
    children: JSX.Element | JSX.Element[];
}

const GlobalContext = createContext<{ state: GlobalState; dispatch: Dispatch<GlobalActions> }>({
    state: initialGlobalState,
    dispatch: () => undefined,
});

const GlobalContextProvider = ({ children }: GlobalContextProps) => {
    const [state, dispatch] = useReducer(globalReducer, initialGlobalState);

    return <GlobalContext.Provider value={{ state, dispatch }}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
