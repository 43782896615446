import { useContext, useState } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getFolder } from "../../Connectors/forgeDms";
import { ForgeDocumentBrowserContext } from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { faCaretRight, faCaretDown } from "@fortawesome/pro-solid-svg-icons";
import { faFolder } from "@fortawesome/pro-light-svg-icons";
import { collapseFolder, expandFolder, loadFolder, setActiveFolder } from "../../Context/ForgeDocumentBrowserContext/Reducer";
import { ForgeDocumentBrowserItem } from "../../Context/ForgeDocumentBrowserContext/State";

const FolderExpandToggle = styled("div")(({ theme }) => ({
    margin: theme.spacing(0.5),
    cursor: "pointer",
    "&>svg": {
        fontSize: 14,
    },
}));

const FolderExpandSpacer = styled("div")(({ theme }) => ({
    margin: theme.spacing(0.5),
    width: 14,
}));

const FolderInfo = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    flex: 1,
    "&>svg": {
        fontSize: 16,
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(1),
    },
}));

const FolderItem = styled("li")<{ selected?: boolean; level: number }>(({ selected, theme, level }) => ({
    height: 35,
    listStyle: "none",
    color: selected ? theme.palette.secondary.main : "inherit",
    marginLeft: theme.spacing(level * 3),
    display: "flex",
    alignItems: "center",
    "&:hover": {
        background: theme.palette.grey[200],
    },
}));

interface FolderDisplayProps {
    level: number;
    hub: string;
    project: string;
    folder: ForgeDocumentBrowserItem;
}

const FolderDisplay = ({ level, hub, project, folder }: FolderDisplayProps) => {
    const { state, dispatch } = useContext(ForgeDocumentBrowserContext);
    const [fetching, setFetching] = useState<boolean>(false);

    const onToggleExpand = () => {
        if (folder.isExpanded) dispatch(collapseFolder(folder.id));
        else dispatch(expandFolder(folder.id));
        if (folder.isFetched) return;
        retrieveFolderContents(hub, project, folder.id);
    };

    const onSelectFolder = () => {
        dispatch(setActiveFolder(folder.id));
        if (folder.isFetched) return;
        retrieveFolderContents(hub, project, folder.id);
    };

    const retrieveFolderContents = (hub: string, project: string, folder: string) => {
        setFetching(true);
        getFolder(hub, project, folder).then((data) => {
            setFetching(false);
            dispatch(loadFolder(data));
        });
    };

    const hasSubfolders = (): boolean => {
        if (!folder.isFetched) return true;
        if (!folder.items) return false;
        return folder.items.filter((x) => x.isFolder).length > 0;
    };

    return (
        <>
            <FolderItem level={level} selected={folder.id === state.currentFolder}>
                {hasSubfolders() ? (
                    <FolderExpandToggle onClick={onToggleExpand}>
                        <FontAwesomeSvgIcon icon={folder.isExpanded ? faCaretDown : faCaretRight} />
                    </FolderExpandToggle>
                ) : (
                    <FolderExpandSpacer />
                )}

                <FolderInfo onClick={onSelectFolder}>
                    <FontAwesomeSvgIcon icon={faFolder} />
                    <Typography>{folder.displayName}</Typography>
                </FolderInfo>
            </FolderItem>

            {folder.isExpanded && !folder.isFetched && fetching && (
                <FolderItem level={level + 1}>
                    <FolderExpandSpacer />
                    <CircularProgress size={20} />
                </FolderItem>
            )}

            {folder.isExpanded &&
                folder.items
                    ?.filter((item) => item.isFolder)
                    .map((folder) => <FolderDisplay key={folder.id} level={level + 1} hub={hub} project={project} folder={folder} />)}
        </>
    );
};

export default FolderDisplay;
