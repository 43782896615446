import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {ChangeEvent, useContext, useRef, useState} from 'react';
import {CircularProgress, IconButton} from '@mui/material';
import FontAwesomeSvgIcon from '../FontAwesomeSvgIcon';
import {faPenToSquare} from '@fortawesome/pro-duotone-svg-icons';
import {HealthCheckDocumentsContext} from '../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider';
import {patchAndActivateFolder, patchFolder, setDocuments} from '../../Context/HealthCheckDocumentsContext/Reducer';
import {addDocument} from '../../Connectors/dataFolders';
import LoadingIndicator from '../LoadingIndicator';
import {DataFolder} from "../../Connectors/_Models";
import {uuid4} from "@sentry/utils";

interface FileUploaderProps {
    folder: DataFolder;
}

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

const FileUploader = ({folder}: FileUploaderProps) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const {state, dispatch} = useContext(HealthCheckDocumentsContext);
    const [uploading, setUploading] = useState<boolean>(false);
    const handleClickOpen = () => {
        inputRef.current!.click()
    };
    
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files) {
            return;
        }
        try {
            let file = e.target.files[0];
            setUploading(true);
            if (file.name.includes(".ifc")) {
                let data = await addDocument(folder.id, {
                    fileName: file.name,
                    autoTrackChanges: false,
                    externalId: generateUUID(),//todo uuid4();??
                    creationDate: new Date().toISOString(),
                    dataSource: "Local",
                    fileType: "ifc",
                    file
                });

                if (state.activeDataFolder) {
                    let newFolder = {...state.activeDataFolder};
                    if (!newFolder.documents) newFolder.documents = [];
                    newFolder.documents.push(data);
                    dispatch(patchAndActivateFolder(newFolder));
                }
            }
        } catch (err) {
            console.error("couldn't upload");
        }
        setUploading(false);
        e.target.value = "";
    };


    return (
        <>
            {uploading?<CircularProgress color="inherit" size={20}/>:
                <Button sx={{mr:1}} onClick={handleClickOpen}>
                Upload File
                </Button>
            }
            <input
                type="file"
                ref={inputRef}
                onChange={handleFileChange}
                style={{display: "none"}}
            />
        </>
    );
}
export default FileUploader;