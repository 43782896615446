import {DataFolder, ForgeFolderEntry} from "../../Connectors/_Models";
import {
    ActionType,
    CloseForgeDocumentDrawer,
    CollapseFolder,
    DeselectItem,
    ExpandFolder,
    ForgeDocumentBrowserActions,
    InitializeRootFolder,
    LoadFolder,
    OpenForgeDocumentDrawer,
    Reset,
    SelectItem,
    SetActiveFolder, SetLinkFolder,
} from "./Actions";
import {
    EForgeBrowseMode,
    ForgeDocumentBrowserItem,
    ForgeDocumentBrowserState,
    initialForgeDocumentBrowserState
} from "./State";

const transform = (folder: ForgeFolderEntry): ForgeDocumentBrowserItem => {
    const root: ForgeDocumentBrowserItem = {
        id: folder.id,
        name: folder.name,
        displayName: folder.displayName,
        isFetched: true,

        isFile: false,

        isExpanded: false,
        isFolder: true,
        items: [],
    };

    folder.subFolders.forEach((folder) => {
        root.items?.push({
            id: folder.id,
            name: folder.name,
            displayName: folder.displayName,
            isFetched: false,

            isFile: false,

            isExpanded: false,
            isFolder: true,
            items: [],
        });
    });

    folder.files.forEach((file) => {
        root.items?.push({
            id: file.id,
            name: file.name,
            displayName: file.displayName,
            isFetched: false,

            urn: file.urn,
            fileType: file.fileType,
            isFile: true,

            isExpanded: false,
            isFolder: false,
        });
    });

    return root;
};

export const findItem = (set: ForgeDocumentBrowserItem, id?: string): ForgeDocumentBrowserItem | null => {
    if (!id) return set;

    if (set.id === id && set.isFolder) return set;

    if (set.items && set.items.length > 0) {
        for (let i = 0; i < set.items.length; i++) {
            const item = set.items[i];

            if (item.id === id) return item;
            if (item.items && item.items.length > 0) {
                const childResult = findItem(item, id);
                if (childResult) return childResult;
            }
        }
    }

    return null;
};

const forgeDocumentBrowserReducer = (state: ForgeDocumentBrowserState, action: ForgeDocumentBrowserActions): ForgeDocumentBrowserState => {
    switch (action.type) {
        case ActionType.InitializeRootFolder: {
            const root = transform(action.payload.folder);
            return { ...initialForgeDocumentBrowserState, root, forgeDocumentSelectionDrawerActive: state.forgeDocumentSelectionDrawerActive,forgeBrowseMode:state.forgeBrowseMode,linkFolder:state.linkFolder };
        }

        case ActionType.LoadFolder: {
            const root = { ...state.root! };
            const toUpdate = findItem(root, action.payload.folder.id);
            const fetched = transform(action.payload.folder);

            if (toUpdate) {
                toUpdate.isFetched = true;
                toUpdate.items = fetched.items;
            }

            return { ...state, root };
        }

        case ActionType.CollapseFolder: {
            const root = { ...state.root! };
            const toCollapse = findItem(root, action.payload.id);
            if (toCollapse) {
                toCollapse.isExpanded = false;
            }

            return { ...state, root };
        }

        case ActionType.ExpandFolder: {
            const root = { ...state.root! };
            const toExpand = findItem(root, action.payload.id);
            if (toExpand) {
                toExpand.isExpanded = true;
            }

            return { ...state, root };
        }

        case ActionType.SetActiveFolder: {
            return { ...state, currentFolder: action.payload.id };
        }

        case ActionType.SelectItem: {
            const selection = [...state.selection];
            if (selection.indexOf(action.payload.id) === -1) {
                selection.push(action.payload.id);
            }

            return { ...state, selection };
        }

        case ActionType.DeselectItem: {
            const selection = state.selection.filter((id) => id !== action.payload.id);

            return { ...state, selection };
        }

        case ActionType.Reset: {
            return { ...initialForgeDocumentBrowserState, forgeDocumentSelectionDrawerActive: state.forgeDocumentSelectionDrawerActive };
        }

        case ActionType.OpenForgeDocumentDrawer: {
            return { ...state, forgeDocumentSelectionDrawerActive: true,forgeBrowseMode:action.payload.browseMode };
        }

        case ActionType.CloseForgeDocumentDrawer: {
            return { ...state, forgeDocumentSelectionDrawerActive: false };
        }

       case ActionType.SetLinkFolder: {
            return { ...state, linkFolder:action.payload.linkFolder };
        }

        default:
            return state;
    }
};

export default forgeDocumentBrowserReducer;

export const initializeRootFolder = (folder: ForgeFolderEntry): InitializeRootFolder => ({
    type: ActionType.InitializeRootFolder,
    payload: { folder },
});

export const loadFolder = (folder: ForgeFolderEntry): LoadFolder => ({
    type: ActionType.LoadFolder,
    payload: { folder },
});

export const collapseFolder = (folderId: string): CollapseFolder => ({
    type: ActionType.CollapseFolder,
    payload: { id: folderId },
});

export const expandFolder = (folderId: string): ExpandFolder => ({
    type: ActionType.ExpandFolder,
    payload: { id: folderId },
});

export const setActiveFolder = (folderId: string | undefined): SetActiveFolder => ({
    type: ActionType.SetActiveFolder,
    payload: { id: folderId },
});

export const selectItem = (fileId: string): SelectItem => ({
    type: ActionType.SelectItem,
    payload: { id: fileId },
});

export const deselectItem = (fileId: string): DeselectItem => ({
    type: ActionType.DeselectItem,
    payload: { id: fileId },
});

export const reset = (): Reset => ({
    type: ActionType.Reset,
});

export const openForgeDocumentDrawer = (browseMode: EForgeBrowseMode): OpenForgeDocumentDrawer => ({
    type: ActionType.OpenForgeDocumentDrawer,
    payload:{browseMode}
});

export const closeForgeDocumentDrawer = (): CloseForgeDocumentDrawer => ({
    type: ActionType.CloseForgeDocumentDrawer,
});

export const setLinkFolder = (linkFolder:DataFolder): SetLinkFolder => ({
    type: ActionType.SetLinkFolder,
    payload: {linkFolder}
});
