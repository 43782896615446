import { faBars } from "@fortawesome/pro-solid-svg-icons";
import { Divider, IconButton, Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext/GlobalContextProvider";
import { collapseSidebar } from "../../Context/GlobalContext/Reducer";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import Sidebar from "../Sidebar";

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
}));

const NavigationDrawerContent = () => {
    const { dispatch: globalDispatch } = useContext(GlobalContext);
    const theme = useTheme();

    const onDrawerClose = () => {
        globalDispatch(collapseSidebar());
    };

    return (
        <>
            <DrawerHeader>
                <Typography
                    variant="h5"
                    noWrap
                    sx={{
                        mr: "auto",
                        display: "flex",
                        placeContent: "center",
                        padding: theme.spacing(1),
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 600,
                        userSelect: "none",
                    }}
                >
                    BIM
                    <Typography variant="h5" component="span" sx={{ color: "#f37221", fontWeight: 500 }}>
                        Cradle
                    </Typography>
                    {/* <img src="https://dstudio.be/wp-content/themes/dstudio/img/logo.svg" alt="logo" height={32} /> */}
                </Typography>
                <IconButton onClick={() => onDrawerClose()}>
                    <FontAwesomeSvgIcon icon={faBars} />
                </IconButton>
            </DrawerHeader>
            <Divider />
            <Sidebar />
        </>
    );
};

export default NavigationDrawerContent;
