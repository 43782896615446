import { createRoot } from "react-dom/client";
import App from "./App";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { GlobalContextProvider } from "./Context/GlobalContext/GlobalContextProvider";
import { configuration } from "./_Configuration/configuration";

import "./Themes/Animations.css"

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: configuration.sentry?.dsn,
    environment: configuration.sentry?.env,
    tracesSampleRate: configuration.sentry?.sampleRate,
    integrations: [new BrowserTracing()],
    ignoreErrors: [
        "BrowserAuthError",
        "InteractionRequiredAuthError",
    ]
});

const container = document.getElementById("root")!;
const root = createRoot(container);
const msalInstance = new PublicClientApplication(configuration.msal);

root.render(
    <GlobalContextProvider>
        <MsalProvider instance={msalInstance}>
            <App />
        </MsalProvider>
    </GlobalContextProvider>
);
