import {DataFolder} from "../../Connectors/_Models";
import {DataFolderDocument} from "../../Connectors/_Models/DataFolderDocument";
import {
    ActionType,
    AddDataFolder,
    GoBackFolders,
    HealthCheckDocumentsActions,
    PatchFolder,
    RemoveDocument,
    SetActiveDataFolder,
    SetDocuments,
    SetRawFolder,
    UpdateDocumentChecksets,
} from "./Actions";
import {HealthCheckDocumentsState} from "./State";

const mergeMutatedFolderToState = (state: HealthCheckDocumentsState, dataFolder: DataFolder, overrideActiveFolder: boolean = true) => {
    if(dataFolder.name==="root"){
        return {...state,rawFolders:dataFolder,activeDataFolder:dataFolder};
    }
    let rawFolders = {...state.rawFolders}; // Clone rawFolders
    const getFolder = (checkFolder: DataFolder): DataFolder | null => {
        if (dataFolder.id === checkFolder.id) {
            return dataFolder;
        }
        if(checkFolder.children){
            for (let f of checkFolder.children) {
                let ff = getFolder(f);
                if (ff !== null) return ff;
            }
        }
        return null;
    }
    let folder = getFolder(rawFolders);
    if (!folder?.parentFolder?.children) return state;
    let index =folder.parentFolder.children.findIndex(x=>x.id === dataFolder.id);
    if(index===-1){
        folder.parentFolder.children.push(dataFolder);
    }else{
        folder.parentFolder.children[index] = dataFolder;
    } 
    dataFolder.parentFolder=folder.parentFolder;
    let activeFolder = dataFolder;
    if (!overrideActiveFolder && state.activeDataFolder && activeFolder.id != state.activeDataFolder?.id) activeFolder = state.activeDataFolder;
    let selectedFolders = generateSelectedFolders(state.rawFolders, activeFolder);
    return {...state, rawFolders, activeDataFolder: activeFolder,selectedFolders};
}

const generateSelectedFolders = (root: DataFolder, current: DataFolder): number[] => {
    if (root === current) return [];
    let selectedFiles: number[] = [];
    const recursiveSelectedFolders = (root2: DataFolder) => {
        if(!root2.children) return false;
        for (let i = 0; i < root2.children.length; i++) {
            if (root2.children[i].id == current.id) {
                selectedFiles.push(i);
                return true;
            }
            if (recursiveSelectedFolders(root2.children[i])) {
                    selectedFiles.push(i);
                    return true;
            }
        }
        return false;
    }
    recursiveSelectedFolders(root);
    selectedFiles.reverse();
    return selectedFiles;
}

const healthCheckDocumentsReducer = (state: HealthCheckDocumentsState, action: HealthCheckDocumentsActions): HealthCheckDocumentsState => {
    switch (action.type) {
        case ActionType.AddDataFolder: {
            if (state.activeDataFolder != null) {
                let dfClone = {...state.activeDataFolder};
                if (!dfClone.children) dfClone.children = [];
                if (dfClone.children.find(x => x.name === action.payload.dataFolder.name)) return state;
                dfClone.children.push(action.payload.dataFolder);
                return mergeMutatedFolderToState(state, dfClone);
            }
            return state;
        }

        case ActionType.SetActiveDataFolder: {
            let selectedFolders = generateSelectedFolders(state.rawFolders, action.payload.dataFolder);
            return {...state, selectedFolders, activeDataFolder: action.payload.dataFolder};
        }

        case ActionType.SetRawFolder: {
            return {...state, rawFolders: action.payload.dataFolder}
        }

        case ActionType.SetDocuments: {
            let activeDataFolder = {...state.activeDataFolder} as DataFolder;
            activeDataFolder.documents = action.payload.documents;
            return mergeMutatedFolderToState(state, activeDataFolder)
        }


        case ActionType.GoBackFolders: {
            let parentFolder = state.rawFolders;
            if (!parentFolder) return state;
            for (let i = 0; i < state.selectedFolders.length - action.payload.amountOfFolders; i++) {
                if(parentFolder?.children && parentFolder?.children.length>state.selectedFolders[i])
                    parentFolder = parentFolder.children[state.selectedFolders[i]];
            }
            let selectedFolders = generateSelectedFolders(state.rawFolders, parentFolder);
            return {...state, selectedFolders, activeDataFolder: parentFolder};
        }

        case ActionType.UpdateDocumentChecksets: {

            const documents = state.activeDataFolder ? state.activeDataFolder.documents : [];
            const document = documents.find((x) => x.id === action.payload.document.id);
            if (document) {
                document.checksets = action.payload.checksets;
            }
            let activeDataFolder = {...state.activeDataFolder, documents} as DataFolder;
            return {...state, activeDataFolder};
        }

        case ActionType.RemoveDocument: {
            if (!state.activeDataFolder) return state;

            const documents = state.activeDataFolder.documents.filter((x) => x.id !== action.payload.id);
            let activeDataFolder = {...state.activeDataFolder, documents} as DataFolder;
            return {...state, activeDataFolder};
        }

        case ActionType.PatchFolder: {
            let newState = {...state};
            if(action.payload.rawFolders){
                newState.rawFolders= action.payload.rawFolders; 
            }
            return mergeMutatedFolderToState(state, action.payload.dataFolder, action.payload.activateFolder);
        }

        default:
            return state;
    }
};

export default healthCheckDocumentsReducer;

export const setRawFolder = (dataFolder: DataFolder): SetRawFolder => ({
    type: ActionType.SetRawFolder,
    payload: {dataFolder},
});

export const addDataFolder = (dataFolder: DataFolder): AddDataFolder => ({
    type: ActionType.AddDataFolder,
    payload: {dataFolder},
});

export const setActiveDataFolder = (dataFolder: DataFolder): SetActiveDataFolder => ({
    type: ActionType.SetActiveDataFolder,
    payload: {dataFolder},
});


export const setDocuments = (documents: DataFolderDocument[]): SetDocuments => ({
    type: ActionType.SetDocuments,
    payload: {documents},
});

export const updateDocumentChecksets = (document: DataFolderDocument, checksets: string[]): UpdateDocumentChecksets => ({
    type: ActionType.UpdateDocumentChecksets,
    payload: {document, checksets},
});

export const removeDocument = (id: string): RemoveDocument => ({
    type: ActionType.RemoveDocument,
    payload: {id},
});

export const goBackFolders = (amountOfFolders: number): GoBackFolders => ({
    type: ActionType.GoBackFolders,
    payload: {amountOfFolders},
});


export const patchFolder = (dataFolder: DataFolder): PatchFolder => ({
    type: ActionType.PatchFolder,
    payload: {dataFolder,activateFolder:false},
});
export const patchAndActivateFolder = (dataFolder: DataFolder,rawFolders:DataFolder|undefined=undefined): PatchFolder => ({
    type: ActionType.PatchFolder,
    payload: {dataFolder,activateFolder:true,rawFolders:rawFolders},
});