import AppContainer from "../../Features/AppContainer";
import RequestInterceptor from "../../Utils/Interceptors/RequestInterceptor";
import Bob from "./Bob";
import {BobContextProvider} from "../../Context/BobContext/BobContextProvider";


const BobIndex = () => {
    return (
        <RequestInterceptor>
            <AppContainer>
                <BobContextProvider>
                    <Bob />
                </BobContextProvider>
            </AppContainer>
        </RequestInterceptor>
    );
};

export default BobIndex;

