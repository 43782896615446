import { InteractionStatus } from "@azure/msal-browser";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";

const SignInSignOutButton = () => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <Button onClick={() => instance.logoutRedirect()}>Logout</Button>;
    } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
        return <Button onClick={() => instance.loginRedirect()}>Login</Button>;
    } else {
        return null;
    }
};

const Landing = () => {
    return (
        <Box>
            <Typography>Landing page moet nog ontwikkeld worden, hier komen de verschillende apps als tegels e.d.</Typography>
            <Typography>Ver 0.12</Typography>
            <Divider />
            <SignInSignOutButton />
            <Divider />
            <UnauthenticatedTemplate>Nog niet ingelogd, apps enkel beschikbaar indien ingelogd!</UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Link to="/health-check">Health Check</Link>
            </AuthenticatedTemplate>
        </Box>
    );
};

export default Landing;
