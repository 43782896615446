import { DataFolder } from "../../Connectors/_Models";
import { Checkset } from "../../Connectors/_Models/Checkset";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";
import {
    ActionType,
    AddCheckset,
    AddDataFolder,
    AddDocument,
    HealthCheckActions,
    RemoveCheckset,
    RemoveDocument,
    SetActiveDataFolder,
    SetChecksets,
    SetDataFolders,
    SetDocuments,
    SetForgeHub,
    SetForgeLink,
    SetForgeProject,
    SetForgeRootFolder,
    UpdateCheckset,
    UpdateDocumentChecksets,
} from "./Actions";
import { HealthCheckState } from "./State";

const healthCheckReducer = (state: HealthCheckState, action: HealthCheckActions): HealthCheckState => {
    switch (action.type) {
        case ActionType.AddDataFolder: {
            throw new Error("AddDataFolder action not implemented");
        }

        case ActionType.SetForgeHub: {
            return { ...state, forgeHub: action.payload.id };
        }

        case ActionType.SetForgeProject: {
            return { ...state, forgeProject: action.payload.id };
        }

        case ActionType.SetForgeRootFolder: {
            console.log("forge root folder", action.payload.id);
            return { ...state, forgeRootFolder: action.payload.id };
        }
        
        case ActionType.SetForgeLink: {
            if (action.payload) {
                console.log("forge set", action.payload);
                return {
                    ...state,
                    forgeHub: action.payload.hub,
                    forgeProject: action.payload.project,
                    forgeRootFolder: action.payload.folder,
                };
            }

            return {
                ...state,
                forgeHub: null,
                forgeProject: null,
                forgeRootFolder: null,
            };
        }
        
        case ActionType.SetChecksets: {
            return { ...state, checksets: action.payload.checksets };
        }

        case ActionType.AddCheckset: {
            const checksets = state.checksets ? [...state.checksets] : [];
            checksets.push(action.payload.checkset);

            return { ...state, checksets };
        }

        case ActionType.UpdateCheckset: {
            if (!state.checksets) return state;

            const checksets = state.checksets.filter((x) => x.id !== action.payload.checkset.id);
            checksets.push(action.payload.checkset);

            return { ...state, checksets };
        }

        case ActionType.RemoveCheckset: {
            if (!state.checksets) return state;

            const checksets = state.checksets.filter((x) => x.id !== action.payload.id);

            return { ...state, checksets };
        }

        default:
            return state;
    }
};

export default healthCheckReducer;

export const setDataFolders = (dataFolders: DataFolder[]): SetDataFolders => ({
    type: ActionType.SetDataFolders,
    payload: { dataFolders },
});

export const addDataFolder = (dataFolder: DataFolder): AddDataFolder => ({
    type: ActionType.AddDataFolder,
    payload: { dataFolder },
});

export const setActiveDataFolder = (dataFolder: DataFolder): SetActiveDataFolder => ({
    type: ActionType.SetActiveDataFolder,
    payload: { dataFolder },
});

export const setForgeHub = (id: string): SetForgeHub => ({
    type: ActionType.SetForgeHub,
    payload: { id },
});

export const setForgeProject = (id: string): SetForgeProject => ({
    type: ActionType.SetForgeProject,
    payload: { id },
});

export const setForgeRootFolder = (id: string): SetForgeRootFolder => ({
    type: ActionType.SetForgeRootFolder,
    payload: { id },
});

export const setForgeLink = (hub: string, project: string, folder: string): SetForgeLink => ({
    type: ActionType.SetForgeLink,
    payload: { hub, project, folder },
});

// TODO: Remove this
export const clearForgeLink = (): SetForgeLink => ({
    type: ActionType.SetForgeLink,
    payload: null,
});

export const setDocuments = (documents: DataFolderDocument[]): SetDocuments => ({
    type: ActionType.SetDocuments,
    payload: { documents },
});

export const addDocument = (document: DataFolderDocument): AddDocument => ({
    type: ActionType.AddDocument,
    payload: { document },
});

export const updateDocumentChecksets = (document: DataFolderDocument, checksets: string[]): UpdateDocumentChecksets => ({
    type: ActionType.UpdateDocumentChecksets,
    payload: { document, checksets },
});

export const removeDocument = (id: string): RemoveDocument => ({
    type: ActionType.RemoveDocument,
    payload: { id },
});

export const setChecksets = (checksets: Checkset[]): SetChecksets => ({
    type: ActionType.SetChecksets,
    payload: { checksets },
});

export const addCheckset = (checkset: Checkset): AddCheckset => ({
    type: ActionType.AddCheckset,
    payload: { checkset },
});

export const updateCheckset = (checkset: Checkset): UpdateCheckset => ({
    type: ActionType.UpdateCheckset,
    payload: { checkset },
});

export const removeCheckset = (id: string): RemoveCheckset => ({
    type: ActionType.RemoveCheckset,
    payload: { id },
});

