import { useEffect, useRef, useState } from "react";
import {
    Button, 
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemText, 
    Popover, 
    styled
} from "@mui/material";
import { TreeWalker } from "react-vtree/dist/lib/Tree"
import { Tree } from "@minoru/react-dnd-treeview";
import { FixedSizeTree, VariableSizeNodeData } from "react-vtree";
import FontAwesomeSvgIcon from "../../FontAwesomeSvgIcon";
import {
    faCaretDown,
    faCaretRight,
    faClipboard, 
    faEye,
    faEyeSlash, 
    faLowVision, faVectorSquare,
} from "@fortawesome/pro-duotone-svg-icons";
import { Rnd } from "react-rnd";
interface treeviewcompprops {
    treeview: IfcTreeviewObject;
    onClose: () => void;
}

interface treeviewLeaf {
    data: any;
    nestingLevel: number;
    node: IfcTreeviewObject;
}

type DataNode = Readonly<{
    children: DataNode[];
    id: number;
    name: string;
}>;

type StackElement = Readonly<{
    nestingLevel: number;
    node: IfcTreeviewObject;
}>;

type ExtendedData = VariableSizeNodeData &
    Readonly<{
        isLeaf: boolean;
        name: string;
        nestingLevel: number;
        renderers: any;
    }>;

interface RenderItemProps {
    node: IfcTreeviewObject;
}

// const renderItem= ({node:IfcTreeviewObject})=>{
//     return <>{node.name}</>
// }

// <div className="tree-item-actions" onClick={(e) => e.stopPropagation()}>
//     {renderers.actions(data)}
// </div>
const ActionItemRecord = styled("div")(() => ({
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end"
}));

export const ActionItem = ({ node }: RenderItemProps) => {
    const [visible, setVisible] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [hoovering, setHoovering] = useState<boolean>(false);
    const hooverRef = useRef(hoovering);
    hooverRef.current = hoovering;

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setTimeout(() => {
            console.log("close", hooverRef.current);
            if (!hooverRef.current)
                setAnchorEl(null);
        }, 600)
    };

    const open = Boolean(anchorEl);
    const handleHover = (event: React.MouseEvent<HTMLElement>) => {
        setHoovering(true);
    }
    const handleEnt = (event: React.MouseEvent<HTMLElement>) => {
        setHoovering(true);
    }
    const handleLv = (event: React.MouseEvent<HTMLElement>) => {
        setHoovering(false);
        setAnchorEl(null);
    }
    return <ActionItemRecord onClick={(e) => {
        e.stopPropagation()
    }}>
        <IconButton sx={{ color: "white" }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
            <FontAwesomeSvgIcon sx={{ fontSize: "12px" }} icon={visible ? faEye : faEyeSlash} />
        </IconButton>
        <IconButton sx={{ color: "white" }}>
            <FontAwesomeSvgIcon sx={{ fontSize: "12px" }} icon={faClipboard} />
        </IconButton>


        <Popover
            id="mouse-over-popover"
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}

            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <div id="mytest"
                onMouseOver={handleHover}
                onMouseEnter={handleEnt}
                onMouseLeave={handleLv}
            >
                <List>
                    <ListItem>
                        <IconButton>
                            <FontAwesomeSvgIcon sx={{ fontSize: "14px" }} icon={faEyeSlash} />
                        </IconButton>
                        <ListItemText primary="Hide element" />
                    </ListItem>
                    <Divider />
                    <ListItem>

                        <IconButton>
                            <FontAwesomeSvgIcon sx={{ fontSize: "14px" }} icon={faLowVision} />
                        </IconButton>
                        <ListItemText primary="Enable x-ray" />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <IconButton>
                            <FontAwesomeSvgIcon sx={{ fontSize: "14px" }} icon={faVectorSquare} />
                        </IconButton>
                        <ListItemText primary="Isolate" />
                    </ListItem>
                </List>
            </div>
        </Popover>
    </ActionItemRecord>
}

export const IfcTreeView = ({ treeview, onClose }: treeviewcompprops) => {

    const [open, setOpen] = useState<boolean>(false);
    const treeRef = useRef<any>(null);

    // The `treeWalker` function runs only on tree re-build which is performed
    // whenever the `treeWalker` prop is changed.
    function* treeWalker(
        refresh: boolean
    ): Generator<ExtendedData | string | symbol, void, boolean> {
        const stack: StackElement[] = [];

        stack.push({
            nestingLevel: 0,
            node: treeview
        });

        while (stack.length !== 0) {
            const { node, nestingLevel } = stack.pop()!;
            try {
                const id = node.id.toString();

                const isOpened = yield refresh
                    ? {
                        defaultHeight: 10,
                        id,
                        isLeaf: node.children ? node.children.length === 0 : true,
                        isOpenByDefault: true,
                        name: node.name,
                        nestingLevel,
                        renderers: {
                            item: (node: IfcTreeviewObject) => {
                                return <>{node.name}</>
                            },
                            actions: (node: IfcTreeviewObject) => {

                                return <ActionItem node={node} />
                            },
                        }
                    }
                    : id;

                if (node.children && node.children.length !== 0 && isOpened) {
                    for (let i = node.children.length - 1; i >= 0; i--) {
                        stack.push({
                            nestingLevel: nestingLevel + 1,
                            node: node.children[i]
                        });
                    }
                }
            } catch (e) {
                console.log("error on node", node);
            }
        }
    }

    const [width, setWidth] = useState<number>(500);
    const [height, setHeight] = useState<number>(800);
    const [posX, setPosX] = useState<number>(20);
    const [posY, setPosY] = useState<number>(20);
    return <Rnd
        style={{ boxShadow: "rgb(0 0 0 / 28%) 0px 0px 20px 4px" }}
        size={{ width, height }}
        position={{ x: posX, y: posY }}
        onDragStop={(e, d) => {
            //this.setState({x: d.x, y: d.y})
            setPosX(d.x);
            setPosY(d.y);
        }}
        onResizeStop=
        {
            (e, direction, ref, delta, position) => {
                setWidth(Number(ref.style.width.replaceAll("[^\\d.]", "")));
                setHeight(Number(ref.style.height.replaceAll("[^\\d.]", "")));
                // this.setState({
                //     width: ref.style.width,
                //     height: ref.style.height,
                //     ...position,
                // });
            }
        }
    >
        <div style={{
            width: "100%",
            height: "100%",
            paddingTop: "40px",
            background: "#000000A0",
            borderRadius: "3px",
            overflow: "hidden",
            WebkitMaskImage: "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC)"
        }}>
            <Button onClick={() => onClose()}
                sx={{ zIndex: "999", position: "absolute", top: "10px", right: "10px", color: "white" }}>x</Button>
            <div style={{
                position: "absolute",
                background: "#00000080",
                left: "0",
                right: "0",
                top: "0",
                height: "40px"
            }}></div>
            <div style={{
                position: "absolute",
                background: "#00000080",
                left: "0",
                bottom: "0",
                top: "40px",
                width: "10px"
            }}></div>
            <div style={{
                position: "absolute",
                background: "#00000080",
                right: "0",
                bottom: "0",
                top: "40px",
                width: "10px"
            }}></div>
            <div style={{
                position: "absolute",
                background: "#00000080",
                right: "10px",
                left: "10px",
                bottom: "0",
                height: "10px"
            }}></div>
            <div style={{
                maxWidth: "calc( 100% - 20px)",
                margin: "10px",
                maxHeight: "calc( 100% - 10px)",
                width: "100%",
                marginTop: "0px",
                height: "100%",
                overflow: "auto"
            }}
                onMouseDown={(e) => {
                    e.stopPropagation()
                }}
                onMouseUp={(e) => {
                    e.stopPropagation()
                }}
            >
                <FixedSizeTree
                    className="model-tree"
                    treeWalker={treeWalker}
                    height={800}
                    width={500}
                    ref={treeRef}
                    itemSize={28}
                    overscanCount={5}
                >
                    {TreeNode}
                </FixedSizeTree>
            </div>
        </div>
    </Rnd>
}


const TreeNode = (props: any) => {
    const { data, isOpen, style, toggle } = props;
    const { isLeaf, id, elementId, name, type, isClassGroup, nestingLevel, renderers } = data;

    useEffect(() => {
        console.log("test2", props);
    }, []);

    return type === "empty" ? null : type === "error" ? (
        <div
            className="tree-item"
            style={{
                ...style,
                display: "flex",
                boxSizing: "border-box",
                padding: "3px 0",
                paddingLeft: `${nestingLevel * 8}px`,
                height: "unset",
            }}
        >
            <div
                className="tree-item-error-value"
                onClick={(e) => e.stopPropagation()}
                style={{ textOverflow: "unset", whiteSpace: "unset", margin: 5 }}
            >
                {renderers.item(data)}
            </div>
        </div>
    ) : (
        <div
            className="tree-item"
            style={{
                ...style,
                display: "flex",
                boxSizing: "border-box",
                padding: "3px 0",
                paddingLeft: `${nestingLevel * 8}px`,
                borderBottom: "2px solid black"
            }}
            onClick={() => props.toggle()}
            data-tip={isClassGroup ? "" : `${name || type}<br />${elementId}`}
        >
            <div>
                <IconButton sx={{ color: "white" }}>
                    {!isLeaf ? <FontAwesomeSvgIcon sx={{ fontSize: "12px" }} icon={isOpen ? faCaretDown : faCaretRight} />
                        : <></>}
                </IconButton>
            </div>
            <div className="tree-item-value" onClick={(e) => e.stopPropagation()}>
                {renderers.item(data)}
            </div>
            {renderers.actions(data)}

        </div>
    );
}



export interface IfcTreeviewObject {
    id: string;
    type: string;
    family?: any;
    name: string;
    parent?: any;
    children?: IfcTreeviewObject[];
}
