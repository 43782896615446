import {
    ActionType,
    ChecksetResultActions,
    OpenChecksetDrawerForDocument,
    CloseChecksetResult,
    SetChecksetResultChecks,
    ClearChecksetResultChecks,
    ClearSelectedObjects,
    AddSelectedObjects,
    RemoveSelectedObjects, LoadChecksetResult, LoadChecksetElements, ToggleHighlightFailedElements
} from "./Actions";
import {ChecksetResultState, SelectedObjects} from "./State";
import {
    DataFolderDocument,
    DocumentChecksetResult,
    DocumentChecksetResultChecks
} from "../../Connectors/_Models/DataFolderDocument";

const checksetResultReducer = (state: ChecksetResultState, action: ChecksetResultActions): ChecksetResultState => {
    switch (action.type) {
        case ActionType.LoadChecksetResult:
            return {
                ...state,
                checksetResultDocument: action.payload.document,
                checksetResultDrawerActive: true,
                checksetResultsChecks: [],
            };
        case ActionType.LoadChecksetElements:
            return {
                ...state,
                objectsInScope: action.payload.objects,
                failedElements: action.payload.failedElements,
            };
        case ActionType.ToggleHighlightFailedElements:
            return {
                ...state,
                highlightFailedElements: !state.highlightFailedElements
            };
        case ActionType.OpenChecksetDrawerForDocument:
            return {
                ...state,
                checksetResultDocument: action.payload.document,
                checksetResultDrawerActive: true,
                checksetResultsChecks: [],
                selectedObjects: []
            };
        case ActionType.CloseChecksetResult:
            return {
                ...state,
                checksetResultDocument: null,
                checksetResultDrawerActive: false,
                checksetResultsChecks: [],
                selectedObjects: []
            };

        case ActionType.SetChecksetResultChecks:
            return {...state, checksetResultsChecks: action.payload.checks};
        case ActionType.ClearChecksetResultChecks:
            return {...state, checksetResultsChecks: []};


        case ActionType.ClearSelectedObjects:
            return {...state, selectedObjects: []};
        case ActionType.RemoveSelectedObjects: {
            let newSelectedObjects: SelectedObjects[] = [...state.selectedObjects];
            newSelectedObjects = newSelectedObjects.map(x => {
                return {
                    color: x.color,
                    ids: x.ids.filter(y => !action.payload.objects.includes(y + ""))
                }
            });
            return {...state, selectedObjects: newSelectedObjects};
        }

        case ActionType.AddSelectedObjects: {
            let newSelectedObjects: SelectedObjects[] = [...state.selectedObjects];
            newSelectedObjects = newSelectedObjects.map(x => {
                return {
                    color: x.color,
                    ids: x.ids.filter(y => !action.payload.objects.includes(y))
                }
            });
            
            let colorExists = newSelectedObjects.find(x => x.color === action.payload.color);
            if (colorExists) {
                colorExists.ids = colorExists.ids.concat(action.payload.objects.map(x => x));
            } else {
                newSelectedObjects.push({
                    color: action.payload.color,
                    ids: action.payload.objects
                });
            }
            
            return {...state, selectedObjects: newSelectedObjects};
        }
        default:
            return state;
    }
};

export default checksetResultReducer;

export const openChecksetDrawerForDocument = (document: DataFolderDocument): OpenChecksetDrawerForDocument => ({
    type: ActionType.OpenChecksetDrawerForDocument,
    payload: {document},
});

export const closeChecksetResult = (): CloseChecksetResult => ({
    type: ActionType.CloseChecksetResult,
});

export const setChecksetResultChecks = (checks: DocumentChecksetResultChecks[]): SetChecksetResultChecks => ({
    type: ActionType.SetChecksetResultChecks,
    payload: {checks},
});

export const clearChecksetResultChecks = (): ClearChecksetResultChecks => ({
    type: ActionType.ClearChecksetResultChecks,
});

export const clearSelectedObjects = (): ClearSelectedObjects => ({
    type: ActionType.ClearSelectedObjects,
});

export const removeSelectedObjects = (objects: string[]): RemoveSelectedObjects => ({
    type: ActionType.RemoveSelectedObjects,
    payload: {objects}
});


export const addSelectedObjects = (objects: string[], color: string): AddSelectedObjects => ({
    type: ActionType.AddSelectedObjects,
    payload: {objects, color}
});

export const loadChecksetResult = (document: DataFolderDocument): LoadChecksetResult => ({
    type: ActionType.LoadChecksetResult,
    payload: { document },
});

export const loadChecksetElements = (objects:string[],failedElements:string[] ): LoadChecksetElements => ({
    type: ActionType.LoadChecksetElements,
    payload: { objects,failedElements },
});

export const toggleHighlightFailedElements = ( ): ToggleHighlightFailedElements => ({
    type: ActionType.ToggleHighlightFailedElements,
    payload: { },
});
