import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import { ConvertFolderConfig } from "./_Models/ConvertFolderConfig";
import { ConversionReport } from "./_Models/ConversionReport";

//todo extract to separate file
export const createConvertFolderConfig = async (folderId: string,configName:string,exportSettings:string,exportFolder:string,region:string,viewName:string,
    targetFolderHub:string,targetFolderProject:string,detailedSettings:any
    ): Promise<string> => {
    const result = await axios.post(`api/Ifc/Datafolder/${folderId}/ExportConfigurations`,{
        ExportSettings:exportSettings,
        ExportView:viewName,
        ConfigName:configName,
        SettingsName:configName,
        TargetFolder:exportFolder,
        TargetFolderRegion:region,
        TargetFolderHub:targetFolderHub,
        TargetFolderProject:targetFolderProject,
        DetailedSettings:detailedSettings
    }, { baseURL: configuration.api });
    
    return result.data as string;
};

  

export const getConvertFolderConfigs = async (folderId: string
    ): Promise<ConvertFolderConfig[]> => {
    const result = await axios.get(`api/Ifc/Datafolder/${folderId}/ExportConfigurations`, { baseURL: configuration.api });
    
    return result.data as ConvertFolderConfig[];
};


export const convertFolder = async (folderId: string,configId:string
    ): Promise<string> => {
    const result = await axios.post(`api/Ifc/Datafolder/${folderId}/ExportConfigurations/${configId}/Run`,{}, { baseURL: configuration.api });
    
    return result.data as string;
};

  

export const createConversionReports = async (folderId: string,configId:string): Promise<ConversionReport[]> => {
    const result = await axios.get(`api/Ifc/Datafolder/${folderId}/ExportConfigurations/${configId}/Report`, { baseURL: configuration.api });
    return result.data as ConversionReport[];
};
