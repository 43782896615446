import { useContext, useEffect, useState, MouseEvent, useRef } from "react";
import {
    Box,
    Button,
    ButtonGroup,
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import LoadingIndicator from "../../Components/LoadingIndicator";
import PageHeader from "../../Components/PageHeader";
import GetScrollbarStyle from "../../Themes/ScrollbarStyle";
import { HealthCheckContext } from "../../Context/HealthCheckContext/HealthCheckContextProvider";
import { addCheckset, removeCheckset, setChecksets } from "../../Context/HealthCheckContext/Reducer";
import { setActiveDataFolder } from "../../Context/HealthCheckDocumentsContext/Reducer";

import ChecksetDefinitionDrawer from "../../Features/ChecksetDefinitionDrawer";
import ChecksetDefinitionConfigDrawer from "../../Features/ChecksetDefinitionConfigDrawer";
import { getDataFolders } from "../../Connectors/dataFolders";
import { ChecksetDefinitionContext } from "../../Context/ChecksetDefinitionContext/ChecksetDefinitionContextProvider";
import { loadChecksetDefinition, newChecksetDefinition, openChecksetDefinitionDrawer } from "../../Context/ChecksetDefinitionContext/Reducer";
import { deleteCheckset, exportCheckset, getChecksets, importCheckset } from "../../Connectors/checksets";
import { Checkset, ChecksetGroup } from "../../Connectors/_Models/Checkset";
import { faDownload, faPlus, faTrash, faUpload } from "@fortawesome/pro-duotone-svg-icons";
import FontAwesomeSvgIcon from "../../Components/FontAwesomeSvgIcon";
import { HealthCheckDocumentsContext } from "../../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";

const ScrollContainer = styled(Box)(({ theme }) => ({
    overflowY: "scroll",
    padding: theme.spacing(0),
    // flex: 1,
    display: "flex",
    flexDirection: "column",
    ...GetScrollbarStyle(theme),
}));

const ChecksetDefinitions = () => {
    const { state: healthCheckDocumentsState, dispatch: healthCheckDocumentsDispatch } = useContext(HealthCheckDocumentsContext);
    const { state: healthCheckState, dispatch: healthCheckDispatch } = useContext(HealthCheckContext);

    const { dispatch: checksetDefinitionDispatch } = useContext(ChecksetDefinitionContext);

    const [loading, setLoading] = useState<boolean>(true);
    const [importing, setImporting] = useState<boolean>(false);
    const checksetUploadRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (healthCheckDocumentsState.activeDataFolder && healthCheckState.checksets) {
            setLoading(false);
            return;
        }

        if (!healthCheckDocumentsState.activeDataFolder) {
            getDataFolders().then((data) => {
                healthCheckDocumentsDispatch(setActiveDataFolder(data[0]));
            });
        }

        if (!healthCheckState.checksets) {
            getChecksets().then((checksets) => {
                healthCheckDispatch(setChecksets(checksets));
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthCheckDocumentsState.activeDataFolder, healthCheckState.checksets]);

    const createChecksetDefinition = () => {
        checksetDefinitionDispatch(newChecksetDefinition());
        checksetDefinitionDispatch(openChecksetDefinitionDrawer());
    };

    const openChecksetDefinition = (definition: Checkset) => {
        checksetDefinitionDispatch(loadChecksetDefinition(definition));
        checksetDefinitionDispatch(openChecksetDefinitionDrawer());
    };

    const onExport = (e: MouseEvent<HTMLSpanElement>, id: string, name: string) => {
        exportCheckset(id, `${name}.cradle-checkset`);
        e.stopPropagation();
    };

    const onDelete = (e: MouseEvent<HTMLSpanElement>, id: string) => {
        deleteCheckset(id).then(() => healthCheckDispatch(removeCheckset(id)));
        e.stopPropagation();
    };

    const handleUploadFileSelection = () => {
        const { files } = checksetUploadRef.current!;

        if (!files) return;

        setImporting(true);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            importCheckset(file).then((data) => {
                healthCheckDispatch(addCheckset(data));
                setImporting(false);
            });
        }

        checksetUploadRef.current!.value = "";
    };

    return loading ? (
        <LoadingIndicator />
    ) : (
        <>
            <PageHeader title={healthCheckDocumentsState.activeDataFolder!.name} breadcrumb={["Health Check", "Checkset Definitions"]}>
                <Box sx={{ display: "flex" }}>
                    <input
                        id="model-file-input"
                        type="file"
                        accept=".cradle-checkset"
                        ref={checksetUploadRef}
                        style={{ display: "none" }}
                        onChange={() => handleUploadFileSelection()}
                    />
                    <Box sx={{ mr: 1, position: "relative" }}>
                        <Button startIcon={<FontAwesomeSvgIcon icon={faUpload} />} onClick={() => checksetUploadRef.current!.click()} disabled={importing}>
                            Import checkset
                        </Button>
                        {importing && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    marginTop: "-12px",
                                    marginLeft: "-12px",
                                }}
                            />
                        )}
                    </Box>
                    <Button startIcon={<FontAwesomeSvgIcon icon={faPlus} />} onClick={createChecksetDefinition} variant="contained">
                        Create new checkset
                    </Button>
                </Box>
            </PageHeader>

            <ScrollContainer>
                <Card sx={{ flex: 1, display: "flex", height: "fit-content", overflow: "unset", m: 1 }}>
                    <CardContent sx={{ m: 0, p: 0, flex: 1, ":last-child": { pb: 0 } }}>
                        <TableContainer>
                            <Table aria-labelledby="tableTitle">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="button">Name</Typography>
                                        </TableCell>
                                        <TableCell width={300}>
                                            <Typography variant="button">Created</Typography>
                                        </TableCell>
                                        <TableCell width={300}>
                                            <Typography variant="button">Edited</Typography>
                                        </TableCell>
                                        <TableCell width={150}>
                                            <Typography variant="button">Actions</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ overflow: "auto" }}>
                                    {healthCheckState.checksets
                                        ?.slice()
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((row, index) => {
                                            return (
                                                <TableRow
                                                    hover
                                                    tabIndex={-1}
                                                    key={row.id}
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => openChecksetDefinition(row)}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ width: 300 }}>
                                                        <Typography>{row.name}</Typography>
                                                    </TableCell>
                                                    <TableCell scope="row" sx={{ width: 300 }}>
                                                        <Typography>{row.creationDate && new Date(row.creationDate).toLocaleString()}</Typography>
                                                    </TableCell>
                                                    <TableCell scope="row">
                                                        <Typography>{row.versionDate && new Date(row.versionDate).toLocaleString()}</Typography>
                                                    </TableCell>
                                                    <TableCell scope="row">
                                                        <Box sx={{ display: "flex" }}>
                                                            <Box>
                                                                <Tooltip title="Export checkset definition">
                                                                    <span>
                                                                        <IconButton onClick={(e) => onExport(e, row.id!, row.name)}>
                                                                            <FontAwesomeSvgIcon icon={faDownload} sx={{ fontSize: 24 }} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>

                                                            <Box sx={{ ml: 1 }}>
                                                                <Tooltip title="Remove checkset definition">
                                                                    <span>
                                                                        <IconButton onClick={(e) => onDelete(e, row.id!)}>
                                                                            <FontAwesomeSvgIcon icon={faTrash} sx={{ fontSize: 24 }} />
                                                                        </IconButton>
                                                                    </span>
                                                                </Tooltip>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            </ScrollContainer>

            <ChecksetDefinitionDrawer />
            <ChecksetDefinitionConfigDrawer />
        </>
    );
};

export default ChecksetDefinitions;
