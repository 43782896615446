import {useEffect, useRef, useState} from "react";

export function useConditionalFetch<S>(defaultVal: S, condition: any, fetchMethod: any, ...args: any[]): [boolean, S, (val: S) => void] {
    const [loading, setLoading] = useState<boolean>(true);
    const [lastCondition, setLastCondition] = useState<any>(null);
    const lastConditionRef = useRef(lastCondition);
    lastConditionRef.current = lastCondition;
    const [value, setValue] = useState<any>(defaultVal);
    useEffect(() => {
        if (!condition) {
            setLoading(false);
            return;
        }
        if (lastConditionRef.current !== condition) {
            setLoading(true);
            setLastCondition(condition);
            fetchMethod(...args).then((x: any) => {
                setValue(x);
                setLoading(false);
            });
        }
    },);
    return [loading, value as S, (val: S) => {
        setValue(val)
    }];
}


