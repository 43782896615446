import { createContext, Dispatch, useReducer } from "react";
import { ForgeDocumentBrowserActions } from "./Actions";
import forgeDocumentBrowserReducer from "./Reducer";
import { ForgeDocumentBrowserState, initialForgeDocumentBrowserState } from "./State";

interface ForgeDocumentBrowserContextProps {
    children: JSX.Element | JSX.Element[];
}

const ForgeDocumentBrowserContext = createContext<{ state: ForgeDocumentBrowserState; dispatch: Dispatch<ForgeDocumentBrowserActions> }>({
    state: initialForgeDocumentBrowserState,
    dispatch: () => undefined,
});

const ForgeDocumentBrowserContextProvider = ({ children }: ForgeDocumentBrowserContextProps) => {
    const [state, dispatch] = useReducer(forgeDocumentBrowserReducer, initialForgeDocumentBrowserState);

    return <ForgeDocumentBrowserContext.Provider value={{ state, dispatch }}>{children}</ForgeDocumentBrowserContext.Provider>;
};

export { ForgeDocumentBrowserContext, ForgeDocumentBrowserContextProvider };
