import { createContext, Dispatch, useReducer } from "react";
import { BobActions } from "./Actions";
import bobReducer from "./Reducer";
import { BobState, initialBobState } from "./State";

interface BobContextProps {
    children: JSX.Element | JSX.Element[];
}

const BobContext = createContext<{ state: BobState; dispatch: Dispatch<BobActions> }>({
    state: initialBobState,
    dispatch: () => undefined,
});

const BobContextProvider = ({ children }: BobContextProps) => {
    const [state, dispatch] = useReducer(bobReducer, initialBobState);

    return <BobContext.Provider value={{ state, dispatch }}>{children}</BobContext.Provider>;
};

export { BobContext, BobContextProvider };
