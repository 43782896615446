import { Button, Dialog, DialogContent, DialogTitle, List, ListItem, TextField, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Task, TaskType, ViewMode } from "../../Components/ReactGanttChart";
import { GanttOriginal } from "../../Components/ReactGanttChart/GanttOriginal";
import { Planning } from "../../Connectors/_Models/Planning";
import { PlanningTask } from "../../Connectors/_Models/PlanningTask";
import { ScheduledPlanningTaskIfcElement } from "../../Connectors/_Models/ScheduledPlanningTaskIfcElement";

var lastDate = new Date(0);//todo remove
export const normalizeForDemo = (dateToNormalize: Date) => {//todo remove
	// Define the date ranges
	// const daterange1 = {
	// 	start: new Date("2022-11-06T17:07:00.807Z"),
	// 	end: new Date("2022-11-08T02:07:00.807Z")
	// };

	// const daterange2 = {
	// 	start: new Date("2022-02-08"),
	// 	end: new Date("2022-09-23")
	// };

	// // Normalize the date to a fraction of the duration of daterange2
	// const durationOfRange2 = daterange2.end.getTime() - daterange2.start.getTime();
	// const fractionOfDuration = (dateToNormalize.getTime() - daterange2.start.getTime()) / durationOfRange2;
	// const durationOfRange1 = daterange1.end.getTime() - daterange1.start.getTime();
	// const normalizedDuration = fractionOfDuration * durationOfRange1;
	// const normalizedDate = new Date(daterange1.start.getTime() + normalizedDuration);
	return dateToNormalize;
}

const BobPlanningGantt = ({ planning, onClickGantt, onIfcRangeChange, onDateSelected, onTaskAdded, onTaskModified,targetDate,highlightedTasks,activityTaskSelected,tasks,setTasks,planningTasks }: {
	planning: Planning[],
	onClickGantt: (sched: PlanningTask) => void,
	onIfcRangeChange: (ifcs: string[]) => void,
	onDateSelected: (date: Date) => void,
	onTaskAdded: (task: Task) => void,
	onTaskModified: (task: Task) => void,
	targetDate:Date|null,
	highlightedTasks: Number[],
	activityTaskSelected: (taskIndex: number) => void;
	tasks: Task[],
	setTasks: (tasks: Task[]) => void,
	planningTasks: PlanningTask[],
}) => {
	const [startDate, setStartDate] = useState(new Date(2023, 1, 1));
	const [endDate, setEndDate] = useState(new Date());
	const [spotlightStart, setSpotlightStart] = useState(new Date(2023, 1, 5));
	const [statusLineDate, setStatusLineDate] = useState(new Date(2023, 1, 10));
	const [spotlightEnd, setSpotlightEnd] = useState(new Date(2023, 1, 15));
	const [view, setView] = useState<ViewMode>(ViewMode.HalfDay);
	const [open, setOpen] = useState(false);
	const [createStartDate, setCreateStartDate] = useState<Date>(new Date());
	const [createEndDate, setCreateEndDate] = useState<Date>(new Date());
	const [parentTask, setParentTask] = useState<Task | null>(null);
	const handleClose = () => setOpen(false);
	const [newTaskName, setNewTaskName] = useState<string>('');
	const [changedTasks, setChangedTasks] = useState<string[]>([]);

	useEffect(() => {
		if(targetDate && targetDate!=statusLineDate){
			setStatusLineDate(targetDate);
		}
	}, [targetDate]);


	useEffect(() => {
		onDateSelected(statusLineDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusLineDate]);

	

	useEffect(() => {
		if (tasks.length > 0) {

			let newStartDate = new Date(tasks[0].start);
			let newEndDate = new Date(tasks[0].end);
			for (let task of tasks) {
				if (task.start < newStartDate) {
					newStartDate = task.start;
				}
				if (task.end > newEndDate) {
					newEndDate = task.end;
				}
			}

			setSpotlightEnd(new Date(newEndDate));
			setSpotlightStart(new Date(newStartDate));
			let lineal = new Date(newStartDate);
			lineal.setDate(lineal.getDate() + 1);
			setStatusLineDate(lineal);

			newStartDate.setDate(newStartDate.getDate() - 2);
			setStartDate(newStartDate);
			newEndDate.setDate(newEndDate.getDate() + 2);
			setEndDate(newEndDate);
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (tasks.length > 0) {
			let newStartDate = new Date(tasks[0].start);
			let newEndDate = new Date(tasks[0].end);
			for (let task of tasks) {
				if (task.start < newStartDate) {
					newStartDate = task.start;
				}
				if (task.end > newEndDate) {
					newEndDate = task.end;
				}
			}
			if (newEndDate.getTime() > spotlightEnd.getTime()) {
				setSpotlightEnd(new Date(newEndDate));
			}
			if (newStartDate.getTime() < spotlightStart.getTime()) {
				setSpotlightStart(new Date(newStartDate));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tasks]);

	useEffect(() => {
		if (spotlightStart.getTime() > spotlightEnd.getTime()) {
			let newDateStart = new Date(statusLineDate);
			newDateStart.setDate(newDateStart.getDate() - 3);
			setSpotlightStart(newDateStart);
			let newDateEnd = new Date(statusLineDate);
			newDateEnd.setDate(newDateEnd.getDate() + 3);
			setSpotlightEnd(newDateEnd);
			return;
		}
		if (spotlightStart.getTime() > statusLineDate.getTime()) {
			let newDate = new Date(spotlightStart);
			newDate.setDate(newDate.getDate() + 2);
			setStatusLineDate(newDate);
		}
		if (spotlightEnd.getTime() < statusLineDate.getTime()) {
			let newDate = new Date(spotlightEnd);
			newDate.setDate(newDate.getDate() - 2);
			setStatusLineDate(newDate);
		}
	}, [spotlightEnd, spotlightStart, statusLineDate]);


	const addChangedTask = (taskId: string) => {
		if (!changedTasks.includes(taskId)) {
			setChangedTasks([...changedTasks, taskId]);
		}
	};
	const handleTaskChange = (task: Task) => {
		console.log("On date change Id:" + task.id);

		let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
		{
			let p = planningTasks.find(x => x.id === task.id)
			if (p) {
				addChangedTask(p.id);
				p.startDate = task.start;
				p.endDate = task.end;
			}
		}
		//let proj = task.project;
		const setParentsDates = (proj: string) => {
			const [start, end] = getStartEndDateForProject(newTasks, proj);
			const project =
				newTasks[newTasks.findIndex((t) => t.id === proj)];

			if (
				project.start.getTime() !== start.getTime() ||
				project.end.getTime() !== end.getTime()
			) {
				const changedProject = { ...project, start, end };
				newTasks = newTasks.map((t) =>
					t.id === proj ? changedProject : t
				);
			}
			if (project.project) {
				setParentsDates(project.project);
			}
			let p = planningTasks.find(x => x.id === project.id)
			if (p) {
				p.startDate = task.start;
				addChangedTask(p.id);
				p.endDate = task.end;
			}

		}
		if (task.project) {
			setParentsDates(task.project);
		}
		onTaskModified(task)
		setTasks(newTasks);
	};

	const handleTaskDelete = (task: Task) => {
		const conf = window.confirm("Are you sure about " + task.name + " ?");
		if (conf) {
			setTasks(tasks.filter((t) => t.id !== task.id));
		}
		return conf;
	};


	const applyStartDate = (e: string) => {
		const dt = e + ':00Z';
		setCreateStartDate(new Date(dt));
	}

	const applyEndDate = (e: string) => {
		const dt = e + ':00Z';
		setCreateEndDate(new Date(dt));
	}

	const createSchedule = async () => {
		if (!parentTask) return;
		const t: Task = {
			id: new Date().getTime().toString(),
			name: newTaskName,
			start: parentTask.start,
			end: parentTask.end,
			type: "task",
			progress: parentTask.progress,
			project: parentTask.id,
			hideChildren: parentTask.hideChildren,
			indent: parentTask.indent + 1
		};
		onTaskAdded(t);
		let parentIndex = tasks.findIndex(x => x.id === parentTask.id);
		if (parentIndex > -1) {
			let firstHalf = tasks.slice(0, parentIndex + 1);
			let secondHalf = tasks.slice(parentIndex + 1);
			setTasks([...firstHalf, t, ...secondHalf]);
		}
		setOpen(false);
	}

	const handleAddClick = async (task: Task) => {
		setCreateStartDate(task.start);
		setCreateEndDate(task.end);
		setParentTask(task);
		setNewTaskName("");
		setOpen(true);

	}
	const handleProgressChange = async (task: Task) => {
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
		let p = planningTasks.find(x => x.id === task.id)
		if (p) {
			addChangedTask(p.id);
			p.percentage = task.progress;
		}
	};

	const handleDblClick = (task: Task) => {

	};

	const handleSelect = (task: Task, isSelected: boolean) => {
		let sched = planningTasks.find(x => x.id === task.id);
		if (sched) {
			onClickGantt(sched);
		}
		console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
	};
	const handleExpanderClick = (task: Task) => {
		console.log("On expander click Id:" + task.id);
		setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
	};
	if (tasks.length === 0) {
		return <div>Loading...</div>
	}

	return <>
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<DialogTitle>Create a new Task</DialogTitle>
			<DialogContent>
				<List id="modal-modal-description" sx={{ mt: 2 }}>
					<ListItem>
						<Typography>Name</Typography>
					</ListItem>
					<ListItem>
						<TextField value={newTaskName} onChange={e => setNewTaskName(e.target.value)} ></TextField>
					</ListItem>
					<ListItem>
						<Typography>Start date</Typography>
					</ListItem>
					<ListItem>
						<input type="datetime-local" onChange={(e) => applyStartDate(e.target.value)}
							value={createStartDate.toISOString().substring(0, 16)} />
					</ListItem>
					<ListItem>
						<Typography>End date</Typography>
					</ListItem>
					<ListItem>
						<input type="datetime-local" onChange={(e) => applyEndDate(e.target.value)}
							value={createEndDate.toISOString().substring(0, 16)} />
					</ListItem>
					<ListItem><Button onClick={() => createSchedule()}>Create</Button></ListItem>
				</List>
			</DialogContent>
		</Dialog>
		<ViewSwitcher
			onViewModeChange={(viewMode) => setView(() => viewMode)}
		/>
		{tasks.length > 0 ? <GanttOriginal
			tasks={tasks}
			spotlightStart={spotlightStart}
			spotlightEnd={spotlightEnd}
			statusLineDate={statusLineDate}
			viewMode={view}
			columnWidth={200}
			ganttHeight={50 * (tasks.length + 1)}
			visualStartDate={startDate}
			visualEndDate={endDate}
			onDateChange={handleTaskChange}
			onDelete={handleTaskDelete}
			onProgressChange={handleProgressChange}
			onAddClick={handleAddClick}
			onDoubleClick={handleDblClick}
			onSelect={handleSelect}
			onExpanderClick={handleExpanderClick}
			onLinialChange={(date: Date, type: "spotlightStart" | "spotlightEnd" | "linial"): void => {
				switch (type) {
					case "spotlightStart":
						if (date.getTime() === spotlightStart.getTime())
							return;
						setSpotlightStart(date);
						break;
					case "spotlightEnd":
						if (date.getTime() === spotlightEnd.getTime())
							return;
						setSpotlightEnd(date);
						break;
					case "linial":
						if (date.getTime() === statusLineDate.getTime())
							return;
						setStatusLineDate(date);
						let ifcs: string[] = [];
						for (let sched of planningTasks) {
							if (sched.ifcElements.length > 0) {
								if (normalizeForDemo(new Date(sched.startDate)).getTime() < date.getTime()) {
									ifcs = [...ifcs, ...sched.ifcElements.map(x => x.ifcElement)];
								}
							}
						}
						onIfcRangeChange(ifcs);
						break;
				}

			} } highlightedTasks={highlightedTasks} activityTaskSelected={activityTaskSelected} /> : <></>}
	
	
	</>
};

export default BobPlanningGantt;



function getStartEndDateForProject(tasks: Task[], projectId: string): [Date, Date] {
	const projectTasks = tasks.filter((t) => t.project === projectId);
	let start = projectTasks[0].start;
	let end = projectTasks[0].end;

	for (let i = 0; i < projectTasks.length; i++) {
		const task = projectTasks[i];
		if (start.getTime() > task.start.getTime()) {
			start = task.start;
		}
		if (end.getTime() < task.end.getTime()) {
			end = task.end;
		}
	}
	return [start, end];
}


// *** OTHER ***

// *** TYPES ***
interface IProps {
	onViewModeChange: (viewMode: ViewMode) => void;
}

const ViewSwitcher = (props: IProps) => {
	// *** PROPS ***
	const { onViewModeChange } = props;
	const [currentViewMode, setCurrentViewMode] = useState(ViewMode.HalfDay);
	const setViewMode = (viewMode: ViewMode) => {
		setCurrentViewMode(viewMode);
		onViewModeChange(viewMode);
	};
	return (
		<>
			<Button
				variant={currentViewMode === ViewMode.QuarterDay ? "contained" : "text"}
				onClick={() => setViewMode(ViewMode.QuarterDay)}
			>
				Quarter of Day
			</Button>
			<Button
				variant={currentViewMode === ViewMode.HalfDay ? "contained" : "text"}
				onClick={() => setViewMode(ViewMode.HalfDay)}
			>
				Half of Day
			</Button>
			<Button
				variant={currentViewMode === ViewMode.Day ? "contained" : "text"}
				onClick={() => setViewMode(ViewMode.Day)}>
				Day
			</Button>
			<Button
				variant={currentViewMode === ViewMode.Week ? "contained" : "text"}
				onClick={() => setViewMode(ViewMode.Week)}
			>
				Week
			</Button>
			<Button
				variant={currentViewMode === ViewMode.Month ? "contained" : "text"}
				onClick={() => setViewMode(ViewMode.Month)}
			>
				Month
			</Button>
		</>
	);
};
