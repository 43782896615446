export interface SimpleChecksetResponse {
    id: string;
    name: string;
}

export interface Checkset {
    id?: string;
    name: string;
    creationDate?: Date;
    checksetVersionId?: string;
    versionDate?: Date;
    checkgroups?: ChecksetGroup[];
}

export interface ChecksetGroup {
    id?: string;
    name: string;
    index: number;
    order: number;
    isFolder:boolean;
    items: (ChecksetGroup|ChecksetDefinition)[];
    
    checkgroups: (ChecksetGroup)[];
    checkDefinitions: ChecksetDefinition[];
}

export enum ChecksetType {
    SIMPLE_PROPERTY_CHECK="simplePropertyCheck",
    MANUAL_CHECK="manualCheck",
    COORDINATES_CHECK="coordinatesCheck",
    FAILED_CHECK="failedCheck",
    SIZE_CHECK="sizeCheck",
    FILENAME_CHECK="filenameCheck",
}

export interface ChecksetDefinition {
    id: string;
    name: string;
    checkType: ChecksetType;
    isFolder:boolean;
    index: number;
    order:number;
}

export interface SimplePropertyCheck extends ChecksetDefinition {
    checkType: ChecksetType.SIMPLE_PROPERTY_CHECK;
    scope: ChecksetScope | null;
    ruleSet: ChecksetRuleSet | null;
}

export interface SizeCheck extends ChecksetDefinition {
    checkType: ChecksetType.SIZE_CHECK;
    minSize:number;
    maxSize:number;
}


export interface FilenameCheck extends ChecksetDefinition {
    checkType: ChecksetType.FILENAME_CHECK;
    regex:string;
}

export interface ChecksetScope {
    targetCategories: string[];
    ruleSet: ChecksetRuleSet | null;
}

export interface ChecksetRuleSet {
    ruleBehaviour: string;
    simplePropertyRules: SimplePropertyRule[];
}

export interface SimplePropertyRule {
    property: string;
    propertyGroup: string;
    propertyId?: string;
    propertyGroupId?: string;
    operatorId?: string;
    dataTypeId?: string;
    value: any;
    unitId?: string;
    isCaseSensitive: boolean;
}
