import { createContext, Dispatch, useReducer } from "react";
import { HealthCheckActions } from "./Actions";
import healthCheckReducer from "./Reducer";
import { HealthCheckState, initialHealthCheckState } from "./State";

interface HealthCheckContextProps {
    children: JSX.Element | JSX.Element[];
}

const HealthCheckContext = createContext<{ state: HealthCheckState; dispatch: Dispatch<HealthCheckActions> }>({
    state: initialHealthCheckState,
    dispatch: () => undefined,
});

const HealthCheckContextProvider = ({ children }: HealthCheckContextProps) => {
    const [state, dispatch] = useReducer(healthCheckReducer, initialHealthCheckState);

    return <HealthCheckContext.Provider value={{ state, dispatch }}>{children}</HealthCheckContext.Provider>;
};

export { HealthCheckContext, HealthCheckContextProvider };
