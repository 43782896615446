import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import {WorkOrderStatus} from "./_Models/workOrderStatus";


export const getVersion = async (): Promise<string> => {
    const result = await axios.get("api/Version", { baseURL: configuration.api });

    return result.data as string;
};


export const getWorkOrders = async (workOrderGuid:string): Promise<WorkOrderStatus> => {
    const result = await axios.get(`api/Version/WorkOrders/${workOrderGuid}`, { baseURL: configuration.api });
    return result.data as WorkOrderStatus;
};