import { Checkset, ChecksetDefinition, ChecksetGroup } from "../../Connectors/_Models/Checkset";
import { DatasourceCategory } from "../../Connectors/_Models/DatasourceCategory";
import { DatasourceProperty } from "../../Connectors/_Models/DatasourceProperty";

export interface ChecksetDefinitionState {
    checkset: Checkset | null;

    categories: DatasourceCategory[] | null;

    activeCheck: ChecksetDefinition | null;
    activeCheckIsNew: boolean;
    activeCheckTarget: ChecksetGroup | null;

    checksetDefinitionDrawerActive: boolean;
    checksetDefinitionConfigDrawerActive: boolean;
    remoteProperties: DatasourceProperty[];
    customProperties: DatasourceProperty[];

    activeChecksetEdited: boolean;
    currentChecksetConfigurationEdited: boolean;

    validateChecksets: boolean;
}

export const initialChecksetDefinitionState: ChecksetDefinitionState = {
    checkset: null,

    categories: null,

    activeCheck: null,
    activeCheckIsNew: false,
    activeCheckTarget: null,

    checksetDefinitionDrawerActive: false,
    checksetDefinitionConfigDrawerActive: false,
    remoteProperties:[],
    customProperties:[],

    activeChecksetEdited: false,
    currentChecksetConfigurationEdited: false,

    validateChecksets: false
};
