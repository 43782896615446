import axios from "axios";
import { configuration } from "../_Configuration/configuration";
import { PlanningTaskRequest } from "./_Models/PlanningTask";



export const patchPlanningTask = async (planningId: string,schedule:PlanningTaskRequest) => {
    await axios.put(`api/PlanningTasks/${planningId}`, schedule,{ baseURL: configuration.api });
};

//planning response
//[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","name":"My1TestPlan","description":"first test plan","vesion":"1.0","documentId":"ab1b144c-00af-4b8a-b4d2-52829671edaa"},{"id":"ab1b144c-00af-4b8a-b4d2-52829671edea","name":"My2testPlan","description":"second test plan","vesion":"2.0","documentId":"ab1b144c-00af-4b8a-b4d2-52829671edaa"}]


//schedule
//[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","name":"MyPT1","description":"my first planningtask","icon":"","planningTaskSchedules":[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","plannedStart":"2023-01-19T13:00:00","plannedEnd":"2023-01-19T15:30:00","actualStart":"2019-01-23T14:30:00","actualEnd":"2019-01-23T13:30:00","percentage":100},{"id":"ab1b144c-00af-4b8a-b4e2-52829671edaa","plannedStart":"2023-01-19T14:00:00","plannedEnd":"2023-01-19T18:30:00","actualStart":"2019-01-23T15:30:00","actualEnd":"2019-01-23T13:30:00","percentage":75}]},{"id":"ab1b144c-00af-4b8a-b4d2-52829671edae","name":"MyPt2","description":"my secondplanning","icon":"","planningTaskSchedules":[]}]

//scheduletask
//[{"id":"ab1b144c-00af-4b8a-b4d2-52829671edaa","plannedStart":"2023-01-19T13:00:00","plannedEnd":"2023-01-19T15:30:00","actualStart":"2019-01-23T14:30:00","actualEnd":"2019-01-23T13:30:00","percentage":100},{"id":"ab1b144c-00af-4b8a-b4e2-52829671edaa","plannedStart":"2023-01-19T14:00:00","plannedEnd":"2023-01-19T18:30:00","actualStart":"2019-01-23T15:30:00","actualEnd":"2019-01-23T13:30:00","percentage":75}]