import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { faBoxOpen } from "@fortawesome/pro-duotone-svg-icons";

const Container = styled(Box)(({ theme }) => ({
    display: "flex",
    height: 300,
    flexDirection: "column",
    alignItems: "center",
    placeContent: "center",
    "&>svg": {
        fontSize: 72,
    },
}));

const FolderEmpty = () => {
    return (
        <Container>
            <FontAwesomeSvgIcon icon={faBoxOpen} />
            <Typography>This folder is empty.</Typography>
        </Container>
    );
};

export default FolderEmpty;
