import { DataFolder } from "../../Connectors/_Models";
import { Checkset } from "../../Connectors/_Models/Checkset";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";

export enum ActionType {
    SetDataFolders,
    AddDataFolder,
    SetActiveDataFolder,

    SetForgeHub,
    SetForgeProject,
    SetForgeRootFolder,
    SetForgeLink,

    SetDocuments,
    AddDocument,
    UpdateDocumentChecksets,
    RemoveDocument,

    SetChecksets,
    AddCheckset,
    UpdateCheckset,
    RemoveCheckset,
}

export interface SetDataFolders {
    type: ActionType.SetDataFolders;
    payload: { dataFolders: DataFolder[] };
}

export interface AddDataFolder {
    type: ActionType.AddDataFolder;
    payload: { dataFolder: DataFolder };
}

export interface SetActiveDataFolder {
    type: ActionType.SetActiveDataFolder;
    payload: { dataFolder: DataFolder };
}

export interface SetForgeHub {
    type: ActionType.SetForgeHub;
    payload: { id: string };
}

export interface SetForgeProject {
    type: ActionType.SetForgeProject;
    payload: { id: string };
}

export interface SetForgeRootFolder {
    type: ActionType.SetForgeRootFolder;
    payload: { id: string };
}

// TODO: Remove available null value
export interface SetForgeLink {
    type: ActionType.SetForgeLink;
    payload: {
        hub: string;
        project: string;
        folder: string;
    } | null;
}

export interface SetDocuments {
    type: ActionType.SetDocuments;
    payload: { documents: DataFolderDocument[] };
}

export interface AddDocument {
    type: ActionType.AddDocument;
    payload: { document: DataFolderDocument };
}

export interface UpdateDocumentChecksets {
    type: ActionType.UpdateDocumentChecksets;
    payload: { document: DataFolderDocument; checksets: string[] };
}

export interface RemoveDocument {
    type: ActionType.RemoveDocument;
    payload: { id: string };
}

export interface SetChecksets {
    type: ActionType.SetChecksets;
    payload: { checksets: Checkset[] };
}

export interface AddCheckset {
    type: ActionType.AddCheckset;
    payload: { checkset: Checkset };
}

export interface UpdateCheckset {
    type: ActionType.UpdateCheckset;
    payload: { checkset: Checkset };
}

export interface RemoveCheckset {
    type: ActionType.RemoveCheckset;
    payload: { id: string };
}

export type HealthCheckActions =
    | SetDataFolders
    | AddDataFolder
    | SetActiveDataFolder
    | SetForgeHub
    | SetForgeProject
    | SetForgeRootFolder
    | SetForgeLink
    | SetDocuments
    | AddDocument
    | UpdateDocumentChecksets
    | RemoveDocument
    | SetChecksets
    | AddCheckset
    | UpdateCheckset
    | RemoveCheckset;
