import { useContext, useEffect, useState } from "react";
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { ConvertFolderConfig } from "../Connectors/_Models/ConvertFolderConfig";
import { HealthCheckDocumentsContext } from "../Context/HealthCheckDocumentsContext/HealthCheckContextProvider";
import {
  convertFolder,
  createConversionReports,
  createConvertFolderConfig,
  getConvertFolderConfigs,
} from "../Connectors/ifc";
import { HealthCheckContext } from "../Context/HealthCheckContext/HealthCheckContextProvider";
import ForgeDocumentManagementTree from "./ForgeDocumentManagement/ForgeDocumentManagementTree";
import { ForgeDocumentBrowserContext } from "../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import ForgeFileBrowser from "./ForgeFileBrowser";
import { Link } from "./ForgeFileBrowser/ForgeFileBrowser";
import { ConversionReport } from "../Connectors/_Models/ConversionReport";

const RevitToIfcConverter = ({
  convertIfc,
  setConvertIfc,
}: {
  convertIfc: boolean;
  setConvertIfc: (x: boolean) => void;
}) => {
  const {
    state: healthCheckDocumentsState,
    dispatch: healthCheckDocumentsDispatch,
  } = useContext(HealthCheckDocumentsContext);
  const { dispatch: forgeDocumentDispatch, state: forgeDocumentState } =
    useContext(ForgeDocumentBrowserContext);
  const [exportConfigs, setExportConfigs] = useState<ConvertFolderConfig[]>([]);
  const [exportSettings, setExportSettings] = useState<string>();
  const [configName, setConfigName] = useState<string>();
  const [viewName, setViewName] = useState<string>("");
  const [converionReports, setConverionReports] = useState<ConversionReport[]>(
    []
  );
  const [linkFolder, setLinkFolder] = useState<Link | null>(null);
  const [tabValue, setValue] = useState(0);
  const [emea, setEmea] = useState(true);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [configState, setConfigState] = useState<any>({});

  const setExportConfigValue = (label: string, value: any) => {
    let newConfig = { ...configState };
    newConfig[label] = value;
    setConfigState(newConfig);
  };

  useEffect(() => {
    setConfigName("");
    setExportSettings("");
    setViewName("");
    setEmea(true);
  }, [tabValue]);
  useEffect(() => {
    if (convertIfc) {
      setLinkFolder(null);
      if (!healthCheckDocumentsState.activeDataFolder) return;
      getConvertFolderConfigs(
        healthCheckDocumentsState.activeDataFolder.id
      ).then((convertConfigs) => {
        setExportConfigs(convertConfigs);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertIfc]);

  const StartIfcConversion = () => {
    setConvertIfc(false);
    if (!healthCheckDocumentsState.activeDataFolder) return;
    // if (!healthCheckDocumentsState.activeDataFolder.config) return;
    // if (healthCheckDocumentsState.activeDataFolder.config.type === "bim360")
    //let config = healthCheckDocumentsState.activeDataFolder.config as Bim360DataFolderConfig;
    //let result = prompt("Enter export settings name");
    createConvertFolderConfig(
      healthCheckDocumentsState.activeDataFolder.id,
      configName ?? "",
      exportSettings ?? "",
      linkFolder?.folder ?? "",
      emea?"emea":"na",
      viewName,
      linkFolder?.hub ?? "",
      linkFolder?.project ?? "",
      configState
    )
      .then((x) => {
        // let fetchConvertStatus = () => {
        //     getConvertFolderStatus(x).then(y => {
        //         setConvertingStatus(y);
        //         if (y.finished || y.error) clearInterval(interval);
        //     });
        // }
        // fetchConvertStatus();
        // let interval = setInterval(() => {
        //     fetchConvertStatus();
        // }, 10000);
      })
      .catch((x) => {});
  };
  return (
    <Dialog open={convertIfc} onClose={() => setConvertIfc(false)}>
      <DialogTitle>IFCCONVERT</DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Run conversion" />
            <Tab label="Create report" />
            <Tab label="Create config" />
            <Tab label="Edit config" />
          </Tabs>
        </Box>

        {tabValue === 0 ? (
          exportConfigs.length > 0 ? (
            exportConfigs.map((x) => (
              <Box>
                <Button
                  onClick={() => {
                    if (!healthCheckDocumentsState.activeDataFolder) return;
                    convertFolder(
                      healthCheckDocumentsState.activeDataFolder.id,
                      x.id
                    ).then((x) => {});
                  }}
                >{`${x.settingsName}`}</Button>
              </Box>
            ))
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {tabValue === 1 ? (
          <>
            {exportConfigs.length > 0 ? (
              exportConfigs.map((x) => (
                <Box>
                  <Button
                    onClick={() => {
                      if (!healthCheckDocumentsState.activeDataFolder) return;
                      createConversionReports(
                        healthCheckDocumentsState.activeDataFolder.id,
                        x.id
                      ).then((x: ConversionReport[]) => {
                        setConverionReports(x);
                      });
                    }}
                  >{`${x.settingsName}`}</Button>
                </Box>
              ))
            ) : (
              <></>
            )}
            {converionReports.length > 0 ? (
              converionReports.map((x) => (
                <>
                  <Card sx={{ m: 1 }}>
                    <List>
                      <ListItem>Name:{x.documentName}</ListItem>
                      <ListItem>QueueDate:{x.queueDate}</ListItem>
                      <ListItem>Status:{x.status}</ListItem>
                      <ListItem>Message:{x.message ?? ""}</ListItem>
                    </List>
                  </Card>
                </>
              ))
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        {tabValue === 2 ? (
          <>
            <TextField
              onChange={(e) => setConfigName(e.currentTarget.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Config name"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              onChange={(e) => setExportSettings(e.currentTarget.value)}
              autoFocus
              margin="dense"
              id="name"
              label="Export settings name"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              onChange={(e) => setViewName(e.currentTarget.value)}
              autoFocus
              margin="dense"
              id="viewname"
              label="View name"
              type="text"
              fullWidth
              variant="standard"
            />
            <FormCheckBox
              label={"emea"}
              state={configState}
              onChange={(label: string, value) =>
                setEmea(value)
              }
            />
            <FormText
              label={"name"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"ifcVersion"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"exchangeRequirement"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"ifcFileType"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"spaceBoundaries"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"sitePlacement"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"activePhase"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />

            <FormCheckBox
              label={"exportBaseQuantities"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"splitWallsAndColumns"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportInternalRevitPropertySets"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportIFCCommonPropertySets"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"export2DElements"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"visibleElementsOfCurrentView"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"use2DRoomBoundaryForVolume"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"useFamilyAndTypeNameForReference"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportPartsAsBuildingElements"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportSolidModelRep"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportSchedulesAsPsets"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportUserDefinedPsets"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"exportUserDefinedPsetsFileName"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportUserDefinedParameterMapping"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"exportUserDefinedParameterMappingFileName"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportBoundingBox"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"includeSiteElevation"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"useActiveViewGeometry"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportSpecificSchedules"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"tessellationLevelOfDetail"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"useOnlyTriangulation"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"storeIFCGUID"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportRoomsInView"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"exportLinkedFiles"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"excludeFilter"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"coBieCompanyInfo"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"coBieProjectInfo"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"includeSteelElements"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"useTypeNameOnlyForIfcType"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"useVisibleRevitNameAsEntityName"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"geoRefCRSName"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"geoRefCRSDesc"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"geoRefEPSGCode"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"geoRefGeodeticDatum"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"geoRefMapUnit"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"isBuiltIn"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormCheckBox
              label={"isInSession"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />
            <FormText
              label={"fileVersionDescription"}
              state={configState}
              onChange={(label: string, value) =>
                setExportConfigValue(label, value)
              }
            />

            {
              <>
                <Divider sx={{ m: 2 }} />
                <ForgeFileBrowser
                  onSelectFolder={(x) => {
                    setLinkFolder(x);
                  }}
                />
              </>
            }
            <Button
              onClick={StartIfcConversion}
              variant="contained"
              disabled={!linkFolder}
            >
              Convert
            </Button>
          </>
        ) : (
          <></>
        )}
        {tabValue === 3 ? (
          exportConfigs.length > 0 ? (
            exportConfigs.map((x) => (
              <Box>
                <Button
                  onClick={() => {
                    console.log(x);
                    setValue(2);
                    setTimeout(() => {
                      setLinkFolder({
                        folder: x.targetFolder,
                        hub: x.targetFolderHub,
                        project: x.targetFolderHub,
                      });
                      setConfigName(x.settingsName);
                      setViewName(x.exportView);
                      setEmea(x.targetFolderRegion==="emea");
                      setExportSettings(x.exportSettings);
                      setConfigState(x.detailedSettings)
                    },250);

                  }}
                >{`${x.settingsName}`}</Button>
              </Box>
            ))
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
      </DialogContent>
    </Dialog>
  );
};

const FormText = ({
  label,
  state,
  onChange,
}: {
  label: string;
  state: any;
  onChange: (label: string, value: string) => void;
}) => {
  useEffect(() => {
    onChange(label, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <TextField
      onChange={(e) => onChange(label, e.currentTarget.value)}
      autoFocus
      margin="dense"
      id="name"
      label={label}
      type="text"
      fullWidth
      variant="standard"
      value={state[label]}
    />
  );
};

const FormCheckBox = ({
  label,
  state,
  onChange,
}: {
  label: string;
  state: any;
  onChange: (label: string, value: any) => void;
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          value={(state[label]==="on" || state[label]===true)?"on":"off"}
          onChange={(e) => onChange(label, e.currentTarget.value==="on"?true:false)}
        />
      }
      label={label}
    />
  );
};

export default RevitToIfcConverter;
