import {DataFolder, ForgeFolderEntry} from "../../Connectors/_Models";
import {EForgeBrowseMode} from "./State";

export enum ActionType {
    InitializeRootFolder,
    LoadFolder,
    CollapseFolder,
    ExpandFolder,
    SetActiveFolder,
    SelectItem,
    DeselectItem,
    Reset,

    OpenForgeDocumentDrawer,
    CloseForgeDocumentDrawer,

    SetLinkFolder,
}

export interface InitializeRootFolder {
    type: ActionType.InitializeRootFolder;
    payload: { folder: ForgeFolderEntry };
}

export interface LoadFolder {
    type: ActionType.LoadFolder;
    payload: { folder: ForgeFolderEntry };
}

export interface SetActiveFolder {
    type: ActionType.SetActiveFolder;
    payload: { id: string | undefined };
}

export interface CollapseFolder {
    type: ActionType.CollapseFolder;
    payload: { id: string };
}

export interface ExpandFolder {
    type: ActionType.ExpandFolder;
    payload: { id: string };
}

export interface SelectItem {
    type: ActionType.SelectItem;
    payload: { id: string };
}

export interface DeselectItem {
    type: ActionType.DeselectItem;
    payload: { id: string };
}

export interface Reset {
    type: ActionType.Reset;
}

export interface OpenForgeDocumentDrawer {
    type: ActionType.OpenForgeDocumentDrawer;
    payload: { browseMode: EForgeBrowseMode };
}


export interface CloseForgeDocumentDrawer {
    type: ActionType.CloseForgeDocumentDrawer;
}

export interface SetLinkFolder {
    type: ActionType.SetLinkFolder;
    payload: {linkFolder:DataFolder}
}

export type ForgeDocumentBrowserActions =
    | InitializeRootFolder
    | LoadFolder
    | ExpandFolder
    | CollapseFolder
    | SetActiveFolder
    | SelectItem
    | DeselectItem
    | Reset
    | OpenForgeDocumentDrawer
    | CloseForgeDocumentDrawer
    | SetLinkFolder;
