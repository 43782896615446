import { AuthError, BrowserAuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, useAccount } from "@azure/msal-react";
import axios from "axios";
import { loginRequest } from "../../_Configuration/configuration";

interface RequestInterceptorProps {
    children: JSX.Element;
}

class LoginSemaphore {
    private static locked = false;
    private static timeoutLock: ReturnType<typeof setTimeout> | null = null;

    private static clearLock() {
        LoginSemaphore.locked = false;
        if (LoginSemaphore.timeoutLock)
            clearTimeout(LoginSemaphore.timeoutLock);
        LoginSemaphore.timeoutLock = null;
    };

    public static lock(): Promise<boolean> {
        return new Promise((res, err) => {

            let returnVal = LoginSemaphore.locked;
            if (!returnVal) {
                returnVal = true;
                if (LoginSemaphore.timeoutLock)
                    clearTimeout(LoginSemaphore.timeoutLock);
                LoginSemaphore.timeoutLock = setTimeout(() => LoginSemaphore.clearLock(), 10000);
                res(true);
            } else {
                let start = new Date();
                let interval = setInterval(() => {
                    let end = new Date();
                    let diff = end.getTime() - start.getTime();
                    if (!LoginSemaphore.locked || diff > 10000) {
                        clearInterval(interval);
                        res(false);
                    }
                }, 100);
            }

        })
    }
    public static unlock() {
        LoginSemaphore.clearLock();
    }
}




const RequestInterceptor = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error("No active account! Verify a user has been signed in.");
        }

        let accessTokenRequest = {
            ...loginRequest,
            account,
        };
        let accessToken = "";
        try {
            const response = await instance.acquireTokenSilent(accessTokenRequest);
            accessToken = response.accessToken;


        } catch (e) {
            if (
                e instanceof InteractionRequiredAuthError ||
                (e instanceof AuthError && e.errorCode === 'state_mismatch')
            ) {
                if (await LoginSemaphore.lock()) {
                    await instance.acquireTokenRedirect(accessTokenRequest);
                    LoginSemaphore.unlock();
                }
                else {
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    accessToken = response.accessToken;
                }
            }
            throw e
        }

        const bearer = `Bearer ${accessToken}`;
        config.headers!.Authorization = bearer;

        return config;
    });

    return <>{children}</>;
};

export default RequestInterceptor;
