import { faChevronDown, faChevronUp } from "@fortawesome/pro-duotone-svg-icons";
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useEffect, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";
import FontAwesomeSvgIcon from "../FontAwesomeSvgIcon";
import { NavigationItem, RouteDefinition } from "./Navigation";

interface SidebarItemProps {
    item: NavigationItem;
}

const shouldExpandOnLoad = (location: Location, item: NavigationItem): boolean => {
    const hasChildren = item.children && item.children.length > 0;
    if (!hasChildren) {
        return false;
    }

    return item.children!.some((child) => {
        if (child.route) {
            return child.route.exact
                ? child.route.to === location.pathname || (child.route.alternate && child.route.alternate.some((r) => r === location.pathname)) || false
                : location.pathname.startsWith(child.route.to) || (child.route.alternate && child.route.alternate.some((r) => location.pathname.startsWith(r)));
        }

        return false;
    });
};

const routeMatchesLocation = (route: RouteDefinition | undefined, location: Location): boolean => {
    if (!route) return false;

    return route.exact
        ? route.to === location.pathname || (route.alternate && route.alternate.some((r: string) => r === location.pathname)) || false
        : location.pathname.startsWith(route.to) || (route.alternate && route.alternate.some((r) => location.pathname.startsWith(r))) || false;
};

const SidebarItem = ({ item }: SidebarItemProps) => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setExpanded(shouldExpandOnLoad(location, item));
    }, [location, item]);

    const renderItem = (item: NavigationItem) => {
        const hasChildren = item.children && item.children.length > 0;
        const isSelected = routeMatchesLocation(item.route, location);
        const onClickAction = hasChildren
            ? () => setExpanded((expanded) => !expanded)
            : item.route
            ? () => navigate(item.route!.to, { replace: false })
            : () => {};

        return (
            <>
                <ListItemButton disabled={item.disabled} onClick={onClickAction} selected={isSelected}>
                    <ListItemIcon>{item.icon && <FontAwesomeSvgIcon icon={item.icon} sx={{ color: "#f37221" }} />}</ListItemIcon>
                    <ListItemText>{item.label}</ListItemText>
                    {hasChildren && <FontAwesomeSvgIcon icon={expanded ? faChevronUp : faChevronDown} />}
                </ListItemButton>
                {hasChildren && (
                    <Collapse in={expanded} timeout={"auto"}>
                        <List component={"div"} disablePadding>
                            {item.children?.map((child, index) => (
                                <SidebarItem key={index} item={child} />
                            ))}
                        </List>
                    </Collapse>
                )}
            </>
        );
    };

    return renderItem(item);
};

export default SidebarItem;
