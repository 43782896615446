import { useContext, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { getFolder, getRootFolder } from "../../Connectors/forgeDms";
import { ForgeDocumentBrowserContext } from "../../Context/ForgeDocumentBrowserContext/ForgeDocumentBrowserContextProvider";
import LoadingIndicator from "../LoadingIndicator";
import { initializeRootFolder, reset } from "../../Context/ForgeDocumentBrowserContext/Reducer";
import FolderDisplay from "./FolderDisplay";

const FolderDisplayContainer = styled("ul")({
    padding: 0,
    margin: 0,
    maxHeight: "100%",
});

interface ForgeFolderBrowserProps {
    hub: string;
    project: string;
    folder: string | null;
}

const ForgeDocumentManagementTree = ({ hub, project, folder }: ForgeFolderBrowserProps) => {
    const { state, dispatch } = useContext(ForgeDocumentBrowserContext);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            dispatch(reset());
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!hub || !project) {
            return;
        }

        if (folder) {
            retrieveFolderContents(hub, project, folder);
            return;
        }

        retrieveRootFolderContents(hub, project);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hub, project, folder]);

    const retrieveRootFolderContents = (hub: string, project: string) => {
        setLoading(true);
        getRootFolder(hub, project).then((data) => {
            setLoading(false);
            dispatch(initializeRootFolder(data));
        });
    };

    const retrieveFolderContents = (hub: string, project: string, folder: string) => {
        setLoading(true);
        getFolder(hub, project, folder).then((data) => {
            setLoading(false);
            dispatch(initializeRootFolder(data));
        });
    };

    return loading ? (
        <LoadingIndicator />
    ) : (
        <FolderDisplayContainer>
            {state.root?.items
                ?.filter((item) => item.isFolder)
                .map((folder) => (
                    <FolderDisplay key={folder.id} level={0} hub={hub} project={project} folder={folder} />
                ))}
        </FolderDisplayContainer>
    );
};

export default ForgeDocumentManagementTree;
