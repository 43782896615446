import { AppConfiguration, EnvConfiguration } from "./AppConfiguration";
import { development } from "./development";
import { localhost } from "./localhost";
import { staging } from "./staging";
import { production } from "./production";

const getEnvConfiguration = (env?: string): EnvConfiguration => {
    switch (env) {
        case "development":
            return development;
        case "staging":
            return staging;
        case "production":
            return production;
        default:
            return localhost;
    }
};

export const configuration: AppConfiguration = {
    muiLicenseKey:
        "7f0559479f20532440d7caf0d8c9cdadTz02MzQ2OSxFPTE3MTIxMjk1Njk5NTUsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=",
    ...getEnvConfiguration(process.env.REACT_APP_STAGE),
};

// Scopes you add here will be prompted for consent during login
export const loginRequest = {
    scopes: ["openid", "profile", "https://bedstudiodevb2c.onmicrosoft.com/bimcradle-api/bimcradle.access"],
};