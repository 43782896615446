import { DataFolder } from "../../Connectors/_Models";
import { Checkset } from "../../Connectors/_Models/Checkset";
import { DataFolderDocument } from "../../Connectors/_Models/DataFolderDocument";

export interface HealthCheckDocumentsState {
    activeDataFolder: DataFolder|null;
    rawFolders: DataFolder;
    selectedFolders:number[];//todo rename folderhistory
}

var counter = new Date();
const createFile = (fn:string)=>{
    counter.setSeconds(counter.getSeconds()+10);
return {
    id: "",
    fileName: fn,
    fileType: "",
    fileTypeVersion: "",
    autoTrackChanges: false,
    creationDate: counter,
    externalId: "",
    versionId: "",
    objectId: "",
    version: "",
    versionDate: counter,
    versionUrn: "",
    checksets: [],
    latestChecksetResults: [],
}

}

export const initialHealthCheckDocumentsState: HealthCheckDocumentsState = {
    activeDataFolder: null,
    rawFolders: {id:"",name:"root",children:[],documents:[]}, 
    selectedFolders:[]
};
